/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import appGlobals from "../../appGlobals";

const logFunction = (path, message) => {
  if (appGlobals.currentUser) {
    return window.cliApi({
      method: "POST",
      url: path,
      data: { message, path: window.location.pathname }
    });
  }
};

const serverLogger = {
  log: (...message) => logFunction("logs/log", message),
  error: (...message) => logFunction("logs/error", message),
  info: (...message) => logFunction("logs/info", message),
  warn: (...message) => logFunction("logs/warn", message)
};

export { serverLogger };
