/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import LandingPageTimeline from "../LandingPageTimeline";
import {
  safeUpsert,
  safeDelete,
  safeQuery,
  deleteWithQuery,
  updateWithQuery
} from "../../../src-shared/apolloMethods";
import "./style.css";

if (process.env.NODE_ENV !== "production") {
  window.safeUpsert = safeUpsert;
  window.safeDelete = safeDelete;
  window.deleteWithQuery = deleteWithQuery;
  window.updateWithQuery = updateWithQuery;
  window.deleteAll = async modelOrModels => {
    const helper = async model => {
      const items = await safeQuery([model, "id"]);
      await safeDelete(
        model,
        items.map(item => item.id)
      );
    };
    let models = modelOrModels;
    if (!Array.isArray(modelOrModels)) {
      models = [modelOrModels];
    }
    for (const model of models) {
      await helper(model);
    }
  };
}

const WelcomePage = () => (
  <>
    <Helmet title="Home" />
    <LandingPageTimeline />
  </>
);

export default WelcomePage;
