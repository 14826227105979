/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5ReportLibraryFragment on j5Report {
    id
    name
    design {
      id
      name
    }
    originalDesign {
      id
      name
    }
    j5LogMessages {
      id
    }
    assemblyMethod
    status
  }
`;
