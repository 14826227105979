/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { uniq } = require("lodash");
const { userRoleCodes, labRoleCodes } = require("./ext-utils");

function getLoggedInFields(userInfo) {
  const { id, appRoleCodes, userLogins, labRoles } = userInfo;

  const isLabAdmin = labRoles.some(
    role => role.roleCode === labRoleCodes.ADMIN_ROLE
  );

  const loggedInFields = {
    id,
    username: userLogins[0].loginName,
    roles: appRoleCodes,
    // API needs a user prop with just the role Codes, not sure though if the "roles" prop is being used
    // so just in case I'm adding the new 'roleCodes' prop with just the code strings.
    // Also all users should have the "MEMBER" role by default.
    // LAB_ADMIN role is required in some endpoints.
    roleCodes: uniq([
      ...Object.keys(appRoleCodes),
      userRoleCodes.MEMBER_ROLE,
      ...(isLabAdmin ? [userRoleCodes.LAB_ADMIN] : [])
    ])
  };
  return loggedInFields;
}

module.exports = { getLoggedInFields };
