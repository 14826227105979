/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { mapSeries } from "bluebird";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import {
  aliasModels,
  tagModels,
  barcodeModels
} from "../../../tg-iso-shared/constants";
import { addTagFilterToQuery } from "../utils/tagUtils";
import { isDesign } from "../../../tg-iso-shared/src/utils/isModule";
import { safeQuery } from "../apolloMethods";
import {
  shouldShowProjects,
  unassignedProjectFilter
} from "../utils/projectUtils";
import appGlobals from "../appGlobals";
import { UniversalSearchModel } from "../utils/universalSearchSettings";

type ModelMap<TEntity = any> = {
  [model: string]: {
    displayName: string;
    route?: string;
    entityCount: number;
    entities: TEntity[];
  };
};

export async function getFilteredRecords({
  modelsToSearch,
  noNameModels,
  universalSearchTerm,
  tagFilterParams,
  timeField,
  setModelMap,
  isStaleSearch
}: {
  modelsToSearch: (UniversalSearchModel | string)[];
  noNameModels: string[];
  universalSearchTerm: string;
  tagFilterParams: any;
  timeField: string; // updatedAt in build
  setModelMap: (modelMap: ModelMap) => void;
  isStaleSearch: () => boolean;
}) {
  const searchTerms = universalSearchTerm.split(",");
  const [firstTerm, ...otherTerms] = searchTerms;

  const modelMap: ModelMap = {};
  const nameKeyCounter: { [model: string]: number } = {};

  await mapSeries(modelsToSearch, async maybeModel => {
    // new search came in. cancel
    if (isStaleSearch()) {
      return;
    }
    let model: string;
    let additionalFilter:
      | null
      | ((qb: QueryBuilder, searchTerms: string[]) => QueryBuilder) = null;
    let route: string | undefined = undefined;
    let displayName: string | undefined = undefined;

    if (typeof maybeModel === "string") {
      model = maybeModel;
    } else {
      model = maybeModel.model;
      additionalFilter = maybeModel.additionalFilter;
      route = maybeModel.route;
      displayName = maybeModel.displayName;
    }

    const qb = new QueryBuilder(model);
    const hasBarcodes = barcodeModels.includes(model);
    const hasProjects = shouldShowProjects(model);
    const hasAliases = aliasModels.includes(model);
    const noName = noNameModels.includes(model);
    const hasTags = tagModels.includes(model) && !isEmpty(tagFilterParams.tags);

    if (!hasTags && !universalSearchTerm) {
      return [];
    }

    if (additionalFilter) {
      additionalFilter(qb, searchTerms);
    }

    if (universalSearchTerm) {
      const allFilters: { [attribute: string]: any }[] = [];
      const addFilter = (term: string) => {
        if (!term.includes(" ") || noName) {
          // for aliquots which don't have a name add this filter so that we don't
          // get random results
          allFilters.push({
            id: term
          });
        }
        if (!noName) {
          allFilters.push({
            name: qb.contains(term)
          });
        }
        if (hasBarcodes) {
          allFilters.push({
            "barcode.barcodeString": term
          });
        }
        if (hasAliases) {
          allFilters.push({
            "aliases.name": qb.contains(term)
          });
        }
      };
      addFilter(firstTerm);
      if (otherTerms.length) {
        otherTerms.forEach(term => {
          if (term) {
            addFilter(term);
          }
        });
      }

      if (isDesign() && model === "sequence") {
        qb.whereAll({
          isInLibrary: true
        });
      } else if (isDesign() && model === "part") {
        qb.whereAll({
          "sequence.isInLibrary": true
        });
      }

      qb.whereAny(...allFilters);
    }
    if (hasTags) {
      addTagFilterToQuery(tagFilterParams.tags, qb, model);
    }

    if (hasProjects) {
      // query for items in user projects or not assigned to a project
      const allProjectsTagId = window.frontEndConfig.allProjectsTagId;
      const anyFilters: { [attribute: string]: any }[] = [
        {
          "projectItems.project.projectRoles.userId": appGlobals.currentUser!.id
        },
        unassignedProjectFilter(qb, model)
      ];
      if (allProjectsTagId) {
        anyFilters.push({
          "projectItems.projectId": allProjectsTagId
        });
      }
      qb.whereAny(...anyFilters);
    }

    const filter = qb.toJSON();
    let fragment = "id " + (noName ? "" : "name ") + timeField;

    if (hasBarcodes) {
      fragment += " barcode { id barcodeString }";
    }
    if (hasAliases) {
      fragment += " aliases { id name }";
    }
    if (hasProjects) {
      fragment +=
        " projectItems { id project { id name color projectRoles { id userId } } }";
    }

    const entities = await safeQuery([model, fragment], {
      variables: {
        filter,
        pageSize: 5,
        sort: [`-${timeField}`]
      }
    });
    if (isStaleSearch()) return;
    const entityCount = entities.totalResults;
    if (!entityCount || !entities.length) return;
    const modelMapEntry = {
      entities: entities,
      entityCount,
      route,
      displayName:
        displayName ||
        modelNameToReadableName(model, {
          upperCase: true,
          plural: true
        })
    };
    let modelNameKey = model;
    if (modelMap[model]) modelNameKey += "-" + nameKeyCounter[model]++;
    else {
      nameKeyCounter[model] = 1;
    }
    modelMap[modelNameKey] = modelMapEntry;
    setModelMap(modelMap);
  });
}
