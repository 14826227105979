/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { headers } from "@teselagen/auth-utils";
import { headerToHasuraDynamicSessionVariable } from "./headerToHasuraDynamicSessionVariable.js";
const { moduleSelectorHeaderKey } = headers;

const SESSION_VARIABLE_NAMES = {
  ALLOWED_ROLES: headerToHasuraDynamicSessionVariable("allowed-roles"),
  DEFAULT_ROLE: headerToHasuraDynamicSessionVariable("default-role"),
  ROLE: headerToHasuraDynamicSessionVariable("role"),
  LAB_ROLE: headerToHasuraDynamicSessionVariable("lab-role"),
  MODULE_SELECTOR: headerToHasuraDynamicSessionVariable(
    moduleSelectorHeaderKey
  ),
  USER_ID: headerToHasuraDynamicSessionVariable("user-id"),
  LAB_IDS: headerToHasuraDynamicSessionVariable("lab-ids"),
  HASURA_ADMIN_SECRET: headerToHasuraDynamicSessionVariable("admin-secret")
};

export { SESSION_VARIABLE_NAMES };
