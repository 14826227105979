/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { isBuild } from "../../../tg-iso-shared/src/utils/isModule";
import buildAliasFragment from "../fragments/buildAliasFragment";
import simpleAliasFragment from "../fragments/simpleAliasFragment";
import withQuery from "../withQuery";

/**
 * For a given componenet with `model` and `recordId` in the props, query for
 * the aliases on that record.
 */

let fragment = simpleAliasFragment;
if (isBuild()) {
  fragment = buildAliasFragment;
}
export default withQuery(fragment, {
  isPlural: true,
  options: ({ record }) => {
    return {
      variables: {
        filter: {
          [record.__typename + "Id"]: record.id
        }
      }
    };
  }
});
