/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import pluralize from "pluralize";
import queryBuilder from "tg-client-query-builder";
import { isBrowser } from "browser-or-node";
import { isoContext } from "@teselagen/utils";
import { camelCase, keyBy, isEmpty, get, forEach, flatMap } from "lodash";
import modelNameToReadableName from "./modelNameToReadableName";
import { modelTypeMap, modelTypeToModelMap } from "./modelTypeMap";
import { formatDate } from "./dateUtils";
import { normalizeCsvHeader } from "./fileUtils";
import caseInsensitiveFilter from "./caseInsensitiveFilter";
import { extendedPropertyLinkableLimsModels } from "@teselagen/utils";
import { extendedPropertyModels } from "../../constants";
import concatWarningStrs from "../../utils/concatWarningStrs";

export const extendedPropertyUploadFragment = `
  extendedValues {
    id
    value
    extendedPropertyId
  }
  extendedCategoryValues {
    id
    extendedCategoryId
    extendedPropertyId
  }
  extendedMeasurementValues {
    id
    value
    measurementUnitId
    extendedPropertyId
  }
`;

export const extendedPropertyDisplaySimpleValueFragment = `
  extendedValues {
    id
    extendedPropertyId
    value
  }
  extendedCategoryValues {
    id
    extendedCategoryId
    extendedCategory {
      id
      name
    }
    extendedPropertyId
  }
  extendedMeasurementValues {
    id
    value
    measurementUnitId
    measurementUnit {
      id
      abbreviation
    }
    extendedPropertyId
  }
`;

export const recordExtendedValuesString = `
id
 extendedValues {
   id
   extendedProperty {
     id
     name
   }
 }
 extendedCategoryValues {
   id
   extendedProperty {
     id
     name
   }
 }
 extendedMeasurementValues {
   id
   extendedProperty {
     id
     name
   }
 }
 `;

export const worklistCounterAllowedModels = [
  "containerArray",
  "aliquot",
  "aliquotContainer",
  "sample",
  "material",
  "additiveMaterial",
  "lot"
];

const normalizeExtPropString = (modelTypeCode, name) =>
  normalizeCsvHeader(modelTypeCode) + "-" + name.toLowerCase();

function getExtPropNameFromSingleHeader(header, { returnEmpty } = {}) {
  // eslint-disable-next-line no-unused-vars
  const [ext, type, ...nameArray] = header.split("-");
  if (ext === "ext" || ext === "EXT") {
    if (nameArray.join("-")) {
      return {
        type,
        name: nameArray.join("-")
      };
    } else if (returnEmpty) {
      return {
        empty: true,
        type: type
      };
    }
  }
}

export const getPropValue = (record, propName) => {
  const propValue =
    record.extendedValues &&
    record.extendedValues.find(
      extendedValue => extendedValue.extendedProperty.name === propName
    );
  return propValue && propValue.value;
};

export function getTargetModelTypeOptions() {
  return extendedPropertyLinkableLimsModels.map(model => ({
    label: modelNameToReadableName(model, { upperCase: true }),
    value: model
  }));
}

export function getModelTypeOptions(isWorklistCounter) {
  const models = isWorklistCounter
    ? worklistCounterAllowedModels
    : extendedPropertyModels;
  return models
    .map(model => ({
      label: modelNameToReadableName(model, {
        upperCase: true
      }),
      value: modelTypeMap[model]
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export const collectExtendedProperties = (record, map) => {
  const valueTables = [
    "extendedValues",
    "extendedCategoryValues",
    "extendedMeasurementValues"
  ];
  valueTables.forEach(table => {
    record[table].forEach(({ extendedProperty }) => {
      map[extendedProperty.id] = extendedProperty;
    });
  });
};

export function getExistingExtendedProperties(containerArrays) {
  const existingExtendedProperties = {
    containerArray: {},
    aliquot: {}
  };
  containerArrays.forEach(plate => {
    collectExtendedProperties(plate, existingExtendedProperties.containerArray);
    plate.aliquotContainers.forEach(ac => {
      if (ac.aliquot) {
        collectExtendedProperties(
          ac.aliquot,
          existingExtendedProperties.aliquot
        );
      }
    });
  });

  return {
    containerArray: Object.values(existingExtendedProperties.containerArray),
    aliquot: Object.values(existingExtendedProperties.aliquot)
  };
}

export function getLinkValueItems(extendedLinkValue) {
  const targetModel = extendedLinkValue.extendedProperty.targetModel || "";
  const links = extendedLinkValue[targetModel + "Elvs"] || [];
  return links
    .map(l => l[camelCase("source_" + targetModel)])
    .filter(item => item)
    .map(item => {
      return {
        ...item,
        display:
          item.name || modelNameToReadableName(item.__typename) + " " + item.id
      };
    });
}

async function createUploadProperties() {
  const {
    extendedCategoryValues,
    extendedMeasurementValues,
    extendedValues,
    extendedCategoryValueUpdates,
    extendedMeasurementValueUpdates,
    extendedValueUpdates,
    extendedCategoryValueDeletes,
    extendedMeasurementValueDeletes,
    extendedValueDeletes,
    safeUpsert,
    safeDelete
  } = this;
  await safeUpsert("extendedValue", extendedValues, {
    excludeResults: true
  });
  await safeUpsert("extendedValue", extendedValueUpdates, {
    excludeResults: true
  });
  await safeUpsert("extendedCategoryValue", extendedCategoryValues, {
    excludeResults: true
  });
  await safeUpsert("extendedCategoryValue", extendedCategoryValueUpdates, {
    excludeResults: true
  });
  await safeUpsert("extendedMeasurementValue", extendedMeasurementValues, {
    excludeResults: true
  });
  await safeUpsert(
    "extendedMeasurementValue",
    extendedMeasurementValueUpdates,
    {
      excludeResults: true
    }
  );
  await safeDelete("extendedValue", extendedValueDeletes);
  await safeDelete("extendedCategoryValue", extendedCategoryValueDeletes);
  await safeDelete("extendedMeasurementValue", extendedMeasurementValueDeletes);
}

function getExtPropUploadValue({ prop, value, recordId, model }) {
  const modelKey = getExtendedValueModelKey(model);
  if (prop.extendedPropertyClassCode === "CATEGORY") {
    const category = prop.extendedCategoryClass.extendedCategories.find(
      category => category.name.toLowerCase() === value.toLowerCase()
    );
    if (category) {
      return {
        [modelKey]: recordId,
        extendedPropertyId: prop.id,
        extendedCategoryId: category.id
      };
    } else {
      throw new Error(
        `Could not find category "${value}" for extended property ${
          prop.name
        }. Valid categories are: ${prop.extendedCategoryClass.extendedCategories
          .map(c => c.name)
          .join(", ")}.`
      );
    }
  } else if (prop.extendedPropertyClassCode === "MEASUREMENT") {
    const number = parseFloat(value, 10);
    if (!isNaN(number)) {
      const userUnit = value.replace(number.toString(), "").trim();
      const measurementUnit =
        prop.extendedMeasurementUnitClass.measurementUnits.find(unit =>
          unit.abbreviation.includes(userUnit)
        );
      if (measurementUnit) {
        return {
          [modelKey]: recordId,
          extendedPropertyId: prop.id,
          measurementUnitId: measurementUnit.id,
          value: number
        };
      }
    }
  } else if (prop.extendedPropertyClassCode === "VALUE") {
    if (prop.extendedTypeCode === "number") {
      const number = Number(value);
      if (isNaN(number)) {
        return;
      }
    }
    const extendedValue = {
      [modelKey]: recordId,
      extendedPropertyId: prop.id,
      value
    };
    if (prop.extendedTypeCode === "boolean") {
      extendedValue.value =
        value === true ||
        value?.toLowerCase() === "true" ||
        value?.toLowerCase() === "t" ||
        value?.toLowerCase() === "yes";
    } else if (prop.extendedTypeCode === "timestamp") {
      const date = new Date(value);
      if (isNaN(date)) {
        return;
      }
      extendedValue.value = date;
    }
    return extendedValue;
  }
}

function getExtendedPropertyValuesForRecord({
  extendedPropertyValues, // [ { value: "some string", extendedProperty: {} }]
  record,
  recordId,
  model,
  returnValues,
  rowIndex,
  warnings
}) {
  const {
    extendedCategoryValues,
    extendedCategoryValueUpdates,
    extendedCategoryValueDeletes,
    extendedMeasurementValues,
    extendedMeasurementValueUpdates,
    extendedMeasurementValueDeletes,
    extendedValues,
    extendedValueUpdates,
    extendedValueDeletes
  } = this;

  const extendedValuesForRecord = [];
  const extendedMeasurementValuesForRecord = [];
  const extendedCategoryValuesForRecord = [];

  const keyedExistingPropValues = {};
  const addToKeyed = (val, deleteList) => {
    const key = val.extendedPropertyId || val.extendedProperty?.id;
    if (keyedExistingPropValues[key]) {
      deleteList.push(val.id);
    }
    keyedExistingPropValues[key] = val;
  };
  if (record) {
    const {
      extendedValues = [],
      extendedCategoryValues = [],
      extendedMeasurementValues = []
    } = record;
    extendedValues.forEach(v => addToKeyed(v, extendedValueDeletes));
    extendedCategoryValues.forEach(v =>
      addToKeyed(v, extendedCategoryValueDeletes)
    );
    extendedMeasurementValues.forEach(v =>
      addToKeyed(v, extendedMeasurementValueDeletes)
    );
  }

  extendedPropertyValues.forEach(rawValue => {
    const { value, extendedProperty: existingProp } = rawValue;

    const extValue = getExtPropUploadValue({
      value,
      prop: existingProp,
      model,
      recordId
    });
    if (warnings && !extValue) {
      warnings.push(
        `Could not process value provided for extended property ${
          existingProp.name
        } on row ${rowIndex + 1}.`
      );
    }
    if (extValue) {
      if (returnValues) {
        // if we are returning values we do not need the foreign key
        delete extValue[getExtendedValueModelKey(model)];
      }
      const existingValue = keyedExistingPropValues[existingProp.id];
      if (existingProp.extendedPropertyClassCode === "CATEGORY") {
        if (existingValue) {
          if (
            existingValue.extendedCategoryId !== extValue.extendedCategoryId
          ) {
            extendedCategoryValueUpdates.push({
              id: existingValue.id,
              extendedCategoryId: extValue.extendedCategoryId
            });
          }
        } else {
          extendedCategoryValues.push(extValue);
          if (returnValues) {
            extendedCategoryValuesForRecord.push(extValue);
          }
        }
      } else if (existingProp.extendedPropertyClassCode === "MEASUREMENT") {
        if (existingValue) {
          if (
            existingValue.measurementUnitId !== extValue.measurementUnitId ||
            existingValue.value !== extValue.value
          ) {
            extendedMeasurementValueUpdates.push({
              id: existingValue.id,
              value: extValue.value,
              measurementUnitId: extValue.measurementUnitId
            });
          }
        } else {
          extendedMeasurementValues.push(extValue);
          if (returnValues) {
            extendedMeasurementValuesForRecord.push(extValue);
          }
        }
      } else if (existingProp.extendedPropertyClassCode === "VALUE") {
        if (existingValue) {
          if (existingValue.value !== extValue.value) {
            extendedValueUpdates.push({
              id: existingValue.id,
              value: extValue.value
            });
          }
        } else {
          extendedValues.push(extValue);
          if (returnValues) {
            extendedValuesForRecord.push(extValue);
          }
        }
      }
    }
  });
  if (returnValues) {
    return {
      extendedValues: extendedValuesForRecord,
      extendedMeasurementValues: extendedMeasurementValuesForRecord,
      extendedCategoryValues: extendedCategoryValuesForRecord
    };
  }
}

function getCsvRowExtProps({
  row,
  record,
  rowIndex,
  recordId,
  model: _model,
  modelTypeCode: _modelTypeCode,
  typeFilter: _typeFilter = [],
  returnValues,
  warnings
}) {
  const model = _model || modelTypeToModelMap[_modelTypeCode];
  const modelTypeCode = _modelTypeCode || modelTypeMap[model];
  const { deduplicateMap, properties } = this;

  if (deduplicateMap[recordId]) return;
  deduplicateMap[recordId] = true;

  const extendedPropertyValues = [];
  const typeFilter = Array.isArray(_typeFilter) ? _typeFilter : [_typeFilter];
  Object.keys(row).forEach(key => {
    const isProp = getExtPropNameFromSingleHeader(key, { returnEmpty: true });
    if (isProp) {
      const { name, type, empty } = isProp;
      const value = row[key];
      if (!value) return;
      if (typeFilter.length && !typeFilter.includes(type)) {
        return;
      }
      if (empty) {
        if (value.includes("upload accepts extended properties")) {
          // if they left the template text in ignore it.
          return;
        } else {
          // throw error so that user will know that the value is not going to be saved
          throw new Error(
            `Invalid extended property header ext-${type} is missing name. Should be ext-${type}-propertyName.`
          );
        }
      }
      const existingProp =
        properties[normalizeExtPropString(modelTypeCode, name)];
      if (existingProp && existingProp.modelTypeCode === modelTypeCode) {
        extendedPropertyValues.push({
          extendedProperty: existingProp,
          value
        });
      }
    }
  });
  const boundGetValuesForRecord = getExtendedPropertyValuesForRecord.bind(this);
  return boundGetValuesForRecord({
    extendedPropertyValues,
    record,
    recordId,
    model,
    returnValues,
    rowIndex,
    warnings
  });
}

export async function getBoundExtendedPropertyUploadHelpers(
  headers,
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const { properties, missingProperties } = await getExtPropFromCsvHeaders(
    headers,
    headers.returnMissing,
    safeQuery
  );

  const values = {
    extendedCategoryValues: [],
    extendedCategoryValueUpdates: [],
    extendedCategoryValueDeletes: [],
    extendedMeasurementValues: [],
    extendedMeasurementValueUpdates: [],
    extendedMeasurementValueDeletes: [],
    extendedValues: [],
    extendedValueUpdates: [],
    extendedValueDeletes: [],
    deduplicateMap: {},
    properties,
    ...ctx
  };
  return {
    createUploadProperties: createUploadProperties.bind(values),
    getCsvRowExtProps: getCsvRowExtProps.bind(values),
    properties,
    missingProperties
  };
}

export const extPropTypeFilterAdditionalItems = {
  protein: "functionalProteinUnit",
  plate: "containerArray",
  rack: "containerArray",
  tube: "aliquotContainer",
  cds: "sequence",
  plasmid: "sequence",
  "cell line": "strain"
};

forEach(extPropTypeFilterAdditionalItems, (val, key) => {
  extPropTypeFilterAdditionalItems[normalizeCsvHeader[key]] = val;
});

export const extendedPropertyImportFragment = gql`
  fragment extendedPropertyImportFragment on extendedProperty {
    id
    name
    modelTypeCode
    extendedTypeCode
    extendedPropertyClassCode
    extendedCategoryClass {
      id
      name
      extendedCategories {
        id
        name
      }
    }
    extendedMeasurementUnitClass {
      id
      name
      baseMeasurementUnitId
      baseMeasurementUnit {
        id
        name
        abbreviation
        conversionFactor
      }
      measurementUnits {
        id
        name
        abbreviation
        conversionFactor
      }
    }
  }
`;

async function getExtPropFromCsvHeaders(headers, returnMissing, safeQuery) {
  const propNames = [];
  const propKeysThatNeedToExist = [];

  headers.forEach(header => {
    const { name, type } =
      getExtPropNameFromSingleHeader(normalizeCsvHeader(header)) || {};
    if (name) {
      propNames.push(name);
      const modelToUse =
        extPropTypeFilterAdditionalItems[type] || camelCase(type);
      const modelTypeCode = modelTypeMap[modelToUse];
      propKeysThatNeedToExist.push(normalizeExtPropString(modelTypeCode, name));
    }
  });

  if (propNames.length) {
    const props = await safeQuery(extendedPropertyImportFragment, {
      variables: {
        filter: caseInsensitiveFilter("extendedProperty", "name", propNames)
      }
    });
    const keyedProps = keyBy(props, prop =>
      normalizeExtPropString(prop.modelTypeCode, prop.name)
    );
    const missingProps = {};
    propKeysThatNeedToExist.forEach(key => {
      if (!keyedProps[key]) {
        const [type, name] = key.split("-");
        missingProps[type] = missingProps[type] || [];
        if (!missingProps[type].includes(name)) {
          missingProps[type].push(name);
        }
      }
    });

    const missingProperties = [];
    if (!isEmpty(missingProps)) {
      if (returnMissing) {
        forEach(missingProps, names => {
          missingProperties.push(...names);
        });
      } else {
        let message;
        if (isBrowser) {
          message =
            "Your file was not uploaded because these extended properties do not exist:\n\n";
        } else {
          message = "These extended properties do not exist:\n\n";
        }
        Object.keys(missingProps).forEach(type => {
          const model = modelTypeToModelMap[type];
          const readableName = modelNameToReadableName(model);
          message += `${missingProps[type]
            .map(n => `"${n}"`)
            .join(", ")} for ${readableName}.\n`;
        });
        message +=
          "\n\nPlease add them in app settings before continuing with upload.";
        // if (isBrowser) {
        //   window.showConfirmationDialog({
        //     intent: "danger",
        //     icon: "warning-sign",
        //     text: message,
        //     cancelButtonText: null
        //   });
        // }
        throw new Error(message);
      }
    }
    return { properties: keyedProps, missingProperties };
  } else {
    return {};
  }
}

export function exportExtPropCsvRowStringValues(record) {
  if (!record || !record.extendedStringValueViews) return {};
  return record.extendedStringValueViews.reduce((acc, val) => {
    acc[val.extendedProperty.name] = val.value;
    return acc;
  }, {});
}

export function exportExtendedPropertyCsvHelper({ row, record, type }) {
  const {
    extendedValues = [],
    extendedCategoryValues = [],
    extendedMeasurementValues = []
  } = record;
  const allValues = extendedValues
    .concat(extendedMeasurementValues)
    .concat(extendedCategoryValues);
  allValues.forEach(val => {
    const columnHeader = `ext-${type}-${val.extendedProperty.name}`;
    let value;
    if (val.extendedCategory) {
      value = val.extendedCategory.name;
    } else {
      value = val.value;
      const abbrev = get(val, "measurementUnit.abbreviation");
      if (abbrev) {
        value += ` ${abbrev}`;
      }
    }
    if (value) {
      row[columnHeader] = value;
    }
  });
}

export function getExtendedValueModelKey(model) {
  return camelCase(`${model}Id`);
}

/**
 * For a given extended property of the type VALUE, get a string
 * representation of its value. We need this to render dates and
 * booleans correctly.
 * @param {Object} prop
 */
export function getValueExtendedPropertyValue(prop) {
  const extendedTypeCode = prop.extendedProperty.extendedTypeCode;
  if (extendedTypeCode === "timestamp") {
    return prop.value ? formatDate(prop.value) : "";
  } else if (extendedTypeCode === "boolean") {
    return prop.value ? "True" : "False";
  } else if (prop.displayValue) {
    return prop.displayValue;
  } else {
    return prop.value;
  }
}

export function displayPropertyValue(value) {
  if (value.__typename === "extendedValue") {
    return getValueExtendedPropertyValue(value);
  } else if (value.__typename === "extendedCategoryValue") {
    return value.extendedCategory.name;
  } else if (value.__typename === "extendedMeasurementValue") {
    return `${value.value} ${value.measurementUnit.abbreviation}`;
  } else if (value.__typename === "extendedLinkValue") {
    return getLinkValueItems(value).map(linkValue => {
      return linkValue.display;
    });
  }
}

export function collectAllExtendedValues(record) {
  const {
    extendedValues = [],
    extendedCategoryValues = [],
    extendedMeasurementValues = []
  } = record;
  return extendedValues.concat(
    extendedCategoryValues,
    extendedMeasurementValues
  );
}

const collectExistingExtendedPropertyValues = record => {
  const extendedPropertyMap = {};
  [
    "extendedValues",
    "extendedCategoryValues",
    "extendedMeasurementValues"
  ].forEach(table => {
    (record[table] || []).forEach(({ id, extendedProperty, __typename }) => {
      extendedPropertyMap[extendedProperty.id] = {
        valueId: id,
        type: __typename
      };
    });
  });
  return extendedPropertyMap;
};

export const addAndRemoveExtendedProperties = (
  record,
  {
    extendedValues = [],
    extendedValuesToDelete = {},
    extendedValuesToCreate = {},
    propIdsToRemove = [],
    propAddedMap,
    propRemovedMap
  }
) => {
  const extendedPropertyMap = collectExistingExtendedPropertyValues(record);
  const recordId = record.id || `&${record.cid}`;
  // add new extended values
  if (extendedValues.length) {
    if (propAddedMap && !propAddedMap[recordId]) propAddedMap[recordId] = [];
    extendedValues.forEach(ev => {
      const extendedPropertyId = ev.extendedProperty.id;
      // if this worklist has already added a value for this extended property
      // it shouldn't add another one
      if (propAddedMap && propAddedMap[recordId].includes(extendedPropertyId))
        return;
      propAddedMap && propAddedMap[recordId].push(extendedPropertyId);
      const existingProp = extendedPropertyMap[extendedPropertyId];
      if (existingProp) {
        extendedValuesToDelete[existingProp.type].push(existingProp.valueId);
      }
      let val = ev.value;
      if (ev.value === "true" || ev.value === "false") {
        // transform boolean into boolean value
        val = ev.value === "true";
      }
      const valToCreate = {
        value: val,
        extendedPropertyId,
        [record.__typename + "Id"]: recordId
      };
      if (ev.extendedCategory) {
        valToCreate.extendedCategoryId = ev.extendedCategory.id;
      } else if (ev.measurementUnit) {
        valToCreate.measurementUnitId = ev.measurementUnit.id;
      }
      extendedValuesToCreate[ev.__typename].push(valToCreate);
    });
  }

  // remove extended values
  if (propRemovedMap && !propRemovedMap[recordId])
    propRemovedMap[recordId] = [];
  propIdsToRemove.forEach(propId => {
    const existingProp = extendedPropertyMap[propId];
    propRemovedMap && propRemovedMap[recordId].push(propId);
    if (
      existingProp &&
      !extendedValuesToDelete[existingProp.type].includes(existingProp.valueId)
    ) {
      extendedValuesToDelete[existingProp.type].push(existingProp.valueId);
    }
  });
};

export function getExtendedPropertyValue(extValue) {
  const { value: maybeValue, extendedCategory, measurementUnit } = extValue;
  let value = maybeValue;

  if (extendedCategory) {
    value = extendedCategory.name;
  } else if (measurementUnit) {
    value = `${value} ${measurementUnit.abbreviation}`;
  }
  return value;
}

export const collectExistingExtendedPropertyValuesForTable = (record, map) => {
  [
    "extendedValues",
    "extendedCategoryValues",
    "extendedMeasurementValues"
  ].forEach(table => {
    record[table].forEach(extValue => {
      const { id, extendedProperty, __typename } = extValue;
      const value = getExtendedPropertyValue(extValue);

      map[extendedProperty.id] = {
        ...extValue,
        id: `${__typename}:${id}`,
        property: extendedProperty.name,
        value
      };
    });
  });
};

export function createWorklistExtPropEntities({
  propsToRemove,
  extendedValues,
  destIds,
  model,
  additionalFields,
  joinTableName = "worklistExtendedProperty"
}) {
  return destIds.map(destId => {
    const worklistEntity = {
      [`${model}Id`]: destId,
      [pluralize(joinTableName)]: propsToRemove.map(prop => ({
        extendedPropertyToRemoveId: prop.id
      })),
      ...additionalFields
    };
    if (extendedValues.length) {
      worklistEntity.extendedCategoryValues = [];
      worklistEntity.extendedValues = [];
      worklistEntity.extendedMeasurementValues = [];
      extendedValues.forEach(ev => {
        const extendedPropertyId = ev.extendedProperty.id;
        const valToCreate = {
          value: ev.value,
          extendedPropertyId
        };
        if (ev.extendedCategory) {
          valToCreate.extendedCategoryId = ev.extendedCategory.id;
        } else if (ev.measurementUnit) {
          valToCreate.measurementUnitId = ev.measurementUnit.id;
        }
        worklistEntity[pluralize(ev.__typename)].push(valToCreate);
      });
    }

    return worklistEntity;
  });
}

/**
 * Because measurement values are different database objects, they can end up having
 * the same id. This can mess with the selected entities in the data table. This creates
 * a unique id.
 * @param {Object} val
 * @returns {string}
 */
function getIdOfValue(val) {
  return `${val.__typename}:${val.id}`;
}

export function transformSingleExtendedValue(ev) {
  const { extendedProperty, value, extendedCategory, measurementUnit } = ev;
  const returnValue = {
    ...ev,
    _id: ev.id,
    id: getIdOfValue(ev),
    property: extendedProperty.name
  };
  if (extendedProperty.targetModel) {
    returnValue.value = getLinkValueItems(ev)
      .map(item => item.display)
      .join(", ");
  } else if (extendedCategory) {
    returnValue.value = extendedCategory.name;
  } else if (measurementUnit) {
    returnValue.measurementValue = value;
    returnValue.value = `${value} ${measurementUnit.abbreviation}`;
  } else {
    returnValue.value = value;
  }
  return returnValue;
}

export function transformExtendedValues(extendedValues) {
  return extendedValues.map(transformSingleExtendedValue);
}
