/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5ReportFragment on j5Report {
    id
    user {
      id
      username
    }
    name
    assemblyMethod
    assemblyType
    createdAt
    updatedAt
    jsonUpdateProgress
    namingOverrides
    version
    lab {
      id
      name
    }
    design {
      id
      name
    }
    originalDesign {
      id
      name
    }
    j5ReportLinkage {
      id
      mainJ5ReportId
      sequencingJ5ReportId
    }
    part {
      id
      name
    }
    assemblyBatchId
    assemblyReactionEnzymes {
      id
      restrictionEnzyme {
        id
        name
      }
    }
  }
`;
