/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { size, find, partition, pick, reduce, some, orderBy } from "lodash";
import shortid from "shortid";
import { getSequenceWithinRange } from "@teselagen/range-utils";
import {
  aliasedEnzymesByName,
  getCutsitesFromSequence,
  getReverseComplementSequenceString
} from "@teselagen/sequence-utils";
import { designPartFragment } from "../../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { getDigestPartRecord } from "../../../../../../tg-iso-shared/utils/digestPartUtils";
import { safeQuery, safeUpsert } from "../../../../../src-shared/apolloMethods";

export const PART_FRAGMENT = [
  "part",
  "id name start end strand isDigestPart isDigestValid"
];

function cutsInsidePart(cutSites, partStart, partEnd, crossOriginPart) {
  const cutsStartPosition = cutSites.map(cutSite =>
    cutSite.forward ? cutSite.topSnipPosition : cutSite.bottomSnipPosition
  );
  return some(cutsStartPosition, cutStart =>
    crossOriginPart
      ? cutStart < partStart && cutStart > partEnd + 1
      : cutStart > partStart && cutStart < partEnd
  );
}

function overhangsCompatible(cutSites, overhangs, crossOriginPart) {
  const { leftOverhang, rightOverhang } = overhangs;

  // This assumes there's no more than 2 cut sites.
  const [startCut, endCut] = orderBy(
    cutSites,
    "topSnipPosition",
    crossOriginPart ? "desc" : "asc"
  );
  const leftOverhangCompatible = startCut.overhangBps === leftOverhang;
  const rightOverhangCompatible =
    !endCut || endCut.overhangBps === rightOverhang;
  return leftOverhangCompatible && rightOverhangCompatible;
}

function findCutSitesForMatches({ matches, enzyme }) {
  const enzymes = Object.values(
    pick(aliasedEnzymesByName, enzyme.name.toLowerCase())
  );
  Object.keys(matches).forEach(matchIndex => {
    const match = matches[matchIndex];
    const results = getCutsitesFromSequence(
      match.fullSequence,
      match.circular,
      enzymes
    );
    if (size(results))
      match.cutSites = results[enzyme.name].map(cs => ({
        ...cs,
        // We need the restrictionEnzyme record instead of the aliasedEnzymeByName one.
        restrictionEnzyme: enzyme
      }));
  });
  return matches;
}

function validateCutSites({ matches, overhangs, partSequence }) {
  Object.keys(matches).forEach(matchIndex => {
    const matchedSequence = matches[matchIndex];

    const { cutSites, circular } = matchedSequence;

    let cutSiteValidationMessage;
    const { start: partStart, end: partEnd } = findMatchRegion(
      partSequence,
      matchedSequence
    );

    const crossOriginPart = circular && partStart > partEnd;

    if (size(cutSites)) {
      if (cutSites.length !== 2) {
        cutSiteValidationMessage = `Has ${cutSites.length} cut site${
          cutSites.length > 1 ? "s" : ""
        }, expected 2.`;
      }
      // removes enzyme if it cuts inside the part
      if (cutsInsidePart(cutSites, partStart, partEnd, crossOriginPart)) {
        cutSiteValidationMessage = `Found an internal cut site`;
      }
      // removes enzyme if overhangs are not compatible.
      if (!overhangsCompatible(cutSites, overhangs, crossOriginPart)) {
        cutSiteValidationMessage = `Cut sites found have incompatible overhangs`;
      }
    } else {
      cutSiteValidationMessage = `Not cut sites found`;
    }
    Object.assign(matches[matchIndex], {
      cutSiteValidationMessage
    });
  });

  return matches;
}

async function searchForPartInInventory(props) {
  const {
    part,
    overhangs,
    onMatches,
    searchReverse,
    restrictionEnzyme
  } = props;
  try {
    const partSequence = getSequenceWithinRange(
      {
        start: part.start,
        end: part.end
      },
      part.sequence.fullSequence
    );
    const searchBody = {
      sequence: partSequence,
      searchId: part.id,
      forwardOnly: !searchReverse
    };

    const body = { ...searchBody, type: "CONTAINS" };
    const resp = await window.cliApi({
      method: "POST",
      moduleName: "build",
      url: `/search/sequences?inInventory=true`,
      data: body
    });
    if (resp.data?.matches) {
      let matchesByIndex = reduce(
        resp.data?.matches,
        (acc, match, index) => {
          acc[index] = match;
          return acc;
        },
        {}
      );
      if (overhangs.leftOverhang && overhangs.rightOverhang) {
        matchesByIndex = findCutSitesForMatches({
          matches: matchesByIndex,
          enzyme: restrictionEnzyme
        });
        matchesByIndex = validateCutSites({
          matches: matchesByIndex,
          overhangs,
          partSequence
        });
      }
      const [exactMatches, containsMatches] = partition(
        matchesByIndex,
        match => match.size === partSequence.length
      );
      onMatches("EXACT", exactMatches);
      onMatches("CONTAINS", containsMatches);
    }
  } catch (error) {
    console.error(error);
  }
}

async function getSequenceWithParts(sequenceId) {
  try {
    const newSequenceWithParts = await safeQuery(
      [
        "sequence",
        `id name fullSequence circular size parts { ${PART_FRAGMENT[1]} }`
      ],
      {
        variables: {
          id: sequenceId
        }
      }
    );
    return newSequenceWithParts;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Returns the first found match region for the sequence.
 */
function findMatchRegion(partSequence, sourceSequence, opts) {
  const {
    fullSequence: _fullSequence,
    circular,
    size: sourceSequenceSize
  } = sourceSequence;
  const partSequenceLength = partSequence.length;

  const matchRegion = {
    strand: 1
  };

  const fullSequence = circular ? _fullSequence + _fullSequence : _fullSequence;
  let start = fullSequence.search(partSequence);

  // The match might bein the reverse strand
  if (opts?.searchReverse && start === -1) {
    const reverseSequence = getReverseComplementSequenceString(fullSequence);
    start = reverseSequence.search(partSequence);
    matchRegion.strand = -1;
  }
  const end = start + partSequenceLength - 1;
  matchRegion.start = start;
  if (circular && end > sourceSequenceSize) {
    matchRegion.end = end - sourceSequenceSize;
  } else {
    matchRegion.end = end;
  }

  return matchRegion;
}

async function createPartForNewSequence(part, sequenceId) {
  try {
    return await safeUpsert(PART_FRAGMENT, { ...part, sequenceId });
  } catch (error) {
    console.error(error);
  }
}

function findPartInNewSequence(part, newSequence) {
  return find(
    newSequence.parts,
    _part =>
      _part.name === part.name &&
      _part.start === part.start &&
      _part.end === part.end &&
      _part.strand === part.strand &&
      _part.isDigestPart === part.isDigestPart
  );
}

async function handleBuildSequencePart(
  part,
  newSequence,
  { digestInfo, searchReverse }
) {
  let newPart;
  const partSequence = getSequenceWithinRange(
    {
      start: part.start,
      end: part.end
    },
    part.sequence.fullSequence
  );
  const sequenceWithParts = await getSequenceWithParts(newSequence.id);
  const matchRegion = findMatchRegion(partSequence, sequenceWithParts, {
    searchReverse
  });
  const newPartCandidate = {
    ...matchRegion,
    name: part.name,
    // NOTE: note sure if we are actually using this field
    // or where/how it is computed. It is passed around a lot though...
    overlapsSelf: false
  };
  if (digestInfo)
    Object.assign(newPartCandidate, {
      ...getDigestPartRecord({
        digestInfo: {
          ...digestInfo,
          ...newPartCandidate
        }
      })
    });
  newPart = findPartInNewSequence(newPartCandidate, sequenceWithParts);
  if (!newPart) {
    [newPart] = await createPartForNewSequence(
      newPartCandidate,
      newSequence.id
    );
  }
  return newPart;
}

async function createNewDesignPart({
  part,
  element,
  fas,
  elementDesignInfo,
  createElements,
  changeFas
}) {
  const designPart = await safeQuery(designPartFragment, {
    variables: {
      id: part.id
    }
  });

  const elementCid = shortid();
  const newElement = {
    elementGuids: [elementCid],
    cardId: elementDesignInfo.cardId,
    binId: elementDesignInfo.binId,
    values: { part: designPart },
    elementIdsToDelete: [element.id],
    cellIndex: elementDesignInfo.index
  };
  createElements(newElement);
  changeFas({
    fas: fas.name,
    elementId: elementCid,
    cardId: elementDesignInfo.cardId
  });
}

export {
  createNewDesignPart,
  handleBuildSequencePart,
  searchForPartInInventory
};
