/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes, Button } from "@blueprintjs/core";
import { compose } from "recompose";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { pushHelper } from "../utils/pushHelper";

const ToolTitle = ({ title, history, embedded, stopTool, toolSchema = {} }) => (
  <div>
    <h4>{title}</h4>
    {!toolSchema.toolSchema.notPartOfLibrary && (
      <Button
        className={classNames(Classes.SMALL, Classes.MINIMAL)}
        onClick={e => {
          if (embedded) {
            stopTool();
          } else {
            pushHelper(
              e,
              history.location.pathname.replace("/tools/", "/tools#")
            );
            // if (e.metaKey) {
            //   window.open(
            //     window.location.pathname.replace("/tools/", "/tools#")
            //   );
            // } else {
            //   history.push(
            //     history.location.pathname.replace("/tools/", "/tools#")
            //   );
            // }
          }
        }}
        icon="arrow-left"
        text={embedded ? "Tool Overview" : "Return to tool library"}
      />
    )}
  </div>
);

export default compose(withRouter)(ToolTitle);
