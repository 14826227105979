/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5AnnealedOligosFragment on j5AnnealedOligo {
    id
    name
    tm
    topOligo {
      id
      name
      sequence {
        fullSequence
        id
        size
      }
    }
    bottomOligo {
      id
      name
      sequence {
        fullSequence
        id
        size
      }
    }
    sequence {
      polynucleotideMaterialId
      tm
      fullSequence
      id
      size
      name
      isInLibrary
    }
  }
`;
