/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useEffect } from "react";
import { Loading } from "@teselagen/ui";
import { map } from "lodash";
import isMobile from "is-mobile";
import { Switch } from "react-router-dom";
import classNames from "classnames";
import { ErrorBoundary } from "react-error-boundary";
import PlatformSidebar from "../../PlatformSidebar";
import EnhancedRoute from "../../EnhancedRoute";
import NoResourceFound from "../../NoResourceFound";
import { FallbackRender } from "../../components/fallbackRender";
import CurrentUserContext from "../../context/CurrentUserContext";

const AppBody = ({ appBodyClassName, appRoutes, loggedIn, loggedInLayout }) => {
  const { currentUser } = useContext(CurrentUserContext);
  useEffect(() => {
    if (window.Cypress?.throwError) {
      const style = `
      #react-refresh-overlay {
          display: none;
      `;
      const styleSheet = document.createElement("style");
      styleSheet.innerText = style;
      document.head.appendChild(styleSheet);
      throw new Error("Error thrown from AppBody");
    }
  }, []);

  return (
    <div
      id="app-body"
      className={classNames(appBodyClassName, {
        // use this instead of loggedIn variables for public routes that can be accessed
        // even while loggedIn. ex. ResetPassword,
        "logged-in": loggedInLayout
      })}
      style={{
        paddingLeft: isMobile() ? 0 : loggedInLayout ? "40px" : "inherit"
      }}
    >
      <Switch>
        {map(appRoutes.publicRoutes, (component, path) => (
          <EnhancedRoute
            key={path}
            currentUser={currentUser}
            fullWidth
            exact
            loggedIn={loggedIn}
            path={path}
            component={component}
          />
        ))}
        {map(appRoutes.privateRoutes, (component, path) => (
          <EnhancedRoute
            key={path}
            privateRoute
            exact
            loggedIn={loggedIn}
            currentUser={currentUser}
            path={path}
            component={component}
          />
        ))}
        <EnhancedRoute component={NoResourceFound} path="/not-found" />
        <EnhancedRoute component={NoResourceFound} />
      </Switch>
    </div>
  );
};

const MainContent = ({
  appBodyClassName,
  appRoutes,
  location,
  currentUser,
  loading,
  logout,
  appHotkeySets,
  loggedIn,
  Header
}) => {
  const isPublicRoute = !!appRoutes.publicRoutes[location.pathname];
  const loggedInLayout = !!(loggedIn && !isPublicRoute);
  const sidebar = loggedInLayout && <PlatformSidebar loggedIn={loggedIn} />;

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {loggedInLayout && (
          <Header
            appRoutes={appRoutes}
            appHotkeySets={appHotkeySets}
            loggedIn={loggedIn}
            currentUser={currentUser}
            logout={logout}
          />
        )}
        {!isMobile() && sidebar}
        <ErrorBoundary fallbackRender={FallbackRender}>
          <AppBody
            loggedInLayout={loggedInLayout}
            loggedIn={loggedIn}
            appBodyClassName={appBodyClassName}
            appRoutes={appRoutes}
          />
        </ErrorBoundary>
      </>
    );
  }
};

export { MainContent };
