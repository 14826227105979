/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
const tooltips = {
  //DESIGN ELEMENT CARD
  Circle: "Card has backbone",
  Linear: "Card has no backbone",
  //SEQUENCES LIBRARY
  CreatePartFromSequence:
    "Create a part with the same name and length as the sequence",
  CreatePartsFromFeatures:
    "Create parts from features on the sequence with the same name, length and position as the features",
  GeneratePartsFromDigest: "Automatically create parts from an enzyme digest",
  CreateFeatureFromSequence:
    "Create a feature with the same name and length as the sequence",
  DeleteFeatureFromSequence: "Delete features from a sequence",
  GeneratePartsFromFeatures:
    "For every 3 bps in the selected features, this will generate parts that span the entire sequence except for those 3 bps",
  ManuallyAnnotateFeatures:
    "Choose 1 or more existing features to use to annotate the selected sequence(s)",
  ManuallyAnnotateParts:
    "Choose 1 or more existing parts to use to annotate the selected sequence(s)",
  ManuallyAnnotateOligos:
    "Choose 1 or more existing oligo to use to annotate the selected sequence(s)",
  //INSPECTOR PANEL
  viewOptions: "View Options",
  info: "Design Details",
  reports: "Assembly Reports",
  operation: "Assembly Reaction Details",
  set: "Bin Details",
  card: "Card Details",
  element: "Part Details",
  lockInspector: "Lock/Unlock Inspector Panel",
  validation: "Junction Details",
  comments: "Design Comments",
  reviews: "Reviews",
  //DESIGN DETAILS -> SUBMIT FOR ASSEMBLY
  SubmitForAssembly:
    "Send your design to our DNA Assembly server for analysis and primer design",
  ValidateArf:
    "Run validation on any Assembly Ready Fragment FAS in your design",
  //Assembly Reaction Details -> Assembly Report Naming Templates
  AssemblyReportNamingTemplates:
    "Customize naming of the plasmids, oligos and direct synthesis resulting from assembly runs",
  //SET DETAILS -> edit & upload icons
  EditIcon: "Change Icon",
  UploadIcon: "Upload Icon",
  // Direct Synthesis Firewall
  dsf: (
    <div style={{ width: 400 }}>
      <strong>Name:</strong> Direct Synthesis Firewall
      <br />
      <strong>Function:</strong> A direct synthesis firewall prevents a direct
      synthesis piece, or an embed_in_primer forward strategy, or an
      embed_in_primer_reverse strategy, from extending from parts in one bin to
      the next bin. A direct synthesis firewall is useful in the context of
      combinatorial assemblies, in which it is often less expensive to keep the
      two parts separated by a universal junction, so that each part may be
      assembled independently of the other, despite the fact that it could be
      cheaper to directly synthesize a single fused piece when assembling a
      single plasmid construct. The direct synthesis firewall effectively
      indicates an assembly junction, as no assembly piece may extend through
      the firewall to contain parts on both sides.
      <br />
    </div>
  ),
  //DIGEST MESSAGES
  assignLeftOverhang:
    "Assign base pairs for the overhang in this card that is to the left of the insert",
  assignRightOverhang:
    "Assign base pairs for the overhang in this card that is to the right of the insert",
  internalizeLeftRecognitionSiteOnFlankingSequence:
    "Require that base pairs of the left recognition site be included in the flanking sequence, not the insert",
  internalizeRightRecognitionSiteOnFlankingSequence:
    "Require that base pairs of the right recognition site be included in the flanking sequence, not the insert",
  externalizeLeftOverhangOnPart:
    "Require that base pairs of the left overhang be included in the flanking sequence of the insert",
  externalizeRightOverhangOnPart:
    "Require that base pairs of the right overhang be included in the flanking sequence of the insert",
  internalizeLeftRecognitionSiteOnPart:
    "Require that base pairs of the left recognition site be included within the insert part definition",
  internalizeRightRecognitionSiteOnPart:
    "Require that base pairs of the right recognition site be included within the insert part definition",
  internalizeLeftOverhangOnPart:
    "Require that base pairs of the left overhang be included within the insert part definition",
  internalizeRightOverhangOnPart:
    "Require that base pairs of the right overhang be included within the insert part definition",
  makeErdamLinear:
    "Convert this card to a linear sequence by removing the backbone bin",
  makeErdamCircular:
    "Convert this card to a circular sequence by adding a backbone bin",
  autoAnnotateFeatures:
    "Automatically create features that match on base pairs with a list of existing features that you select",
  autoAnnotateParts:
    "Automatically create parts that match on base pairs with a list of existing parts that you select",
  createFeaturesFromSequences:
    "Create features on these sequences that span the entire sequence and take on the name of the sequence",
  createPartsFromSequences:
    "Create parts on these sequences that span the entire sequence and take on the name of the sequence",
  assemblePartsInThisBinAsIs: (
    <div style={{ width: 400 }}>
      <strong>Purpose:</strong> Specifies that these parts should be assembled
      as-is, i.e. the unmodified part will be the Assembly Piece and neighboring
      parts will conform their overlaps to accommodate this part.
      <br />
      <br />
      <strong>Details:</strong> This is accomplished by the setting the
      following (you can change these settings to fit your needs): <br />
      1. The "Forced Relative Overhang Position" of the selected bin is set to
      the value of the "Gibson Overlap Bps" in the assembly Parameter Set
      divided by two. This moves the 3' overlap of parts in this bin to be
      entirely contained within them.
      <br />
      2. The "Extra 3' Overlap Bps" of the selected bin is set to 0 (or -1 if
      the overlap length is an odd number). This prevents the 3' overlap from
      being extended outside of parts in the selected bin.
      <br />
      3. The Forced Relative Overhang Position of the prior bin is set to the
      value of the "Gibson Overlap Bps" in the assembly Parameter Set divided by
      two. This moves the 5' overlap of parts in this bin to be entirely
      contained within them.
      <br />
      4. The "Extra 5' Overlap Bps" of the prior bin is set to 0 (or -1 if the
      overlap length is an odd number). This prevents the 5' overlap from being
      extended outside of parts in the selected bin.
      <br />
      <br />
      <strong>Limitations and caveats:</strong>
      <br />
      1. Only available for Gibson/SLIC/CPEC assemblies.
      <br />
      2. Should not be applied to neighboring bins (the Forced Relative Overhang
      Positions and Extra Overlap Bps of neighoring bins will be overwritten).
      <br />
      3. Parts in this bin should be long enough to be an Assembly Piece.
      <br />
    </div>
  ),
  BackTranslateAAParts:
    "Reverse-translate any Amino Acid Parts in your design (denoted with an 'A' icon) to DNA parts. All AA parts must be reverse-translated to DNA prior to assembly."
};

export default tooltips;
