/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { SIMPLE_REFERENCES_TO_TYPE } from "../../../tg-iso-design/constants/designStateConstants";
import createDuplicatedDesignInput from "../../../tg-iso-design/utils/createDuplicatedDesignInput";

/**
 * Convert a design state to the state for the equivalent template.
 *
 * This function will mutate its input.
 * @param {Object} designState
 */
function designStateToTemplate(designState) {
  Object.assign(Object.values(designState.design)[0], {
    type: "grand-design",
    numPlaceholders: null
  });

  for (const bin of Object.values(designState.bin)) {
    bin.isPlaceholder = false;
  }
}

export default async designState => {
  const creates = createDuplicatedDesignInput(designState);
  designStateToTemplate(creates);

  const {
    data: { designIds }
  } = await window.api.request({
    method: "POST",
    url: "/createDesignFromJson",
    data: {
      designJson: creates,
      SIMPLE_REFERENCES_TO_TYPE
    }
  });

  return designIds[0];
};
