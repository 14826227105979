/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { logDebug } = require("../logDebug");
const { getAllLabIdsFromContext } = require("./getAllLabIdsFromContext");
const { getLabIdFromContext } = require("./getLabIdFromContext");
const { isSuperUser } = require("./isSuperUser");
const { getUserFromContext } = require("./getUserFromContext");
const { validateUserLabId } = require("./validateUserLabId");
const { shouldIdBeNull } = require("./shouldIdBeNull");
const { parseHeaders } = require("./parseHeaders");
const { isAdmin } = require("./isAdmin");
const { isSystemUser } = require("./isSystemUser");
const { getUserHighestRole } = require("./getUserHighestRole");

const {
  headers,
  userRoleCodes,
  labRoleCodes,
  BEARER_PREFIX
} = require("./constants");

module.exports = {
  headers,
  userRoleCodes,
  labRoleCodes,
  BEARER_PREFIX,
  logDebug,
  getAllLabIdsFromContext,
  getLabIdFromContext,
  isSuperUser,
  isAdmin,
  isSystemUser,
  getUserFromContext,
  validateUserLabId,
  shouldIdBeNull,
  parseHeaders,
  getUserHighestRole
};
