/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import useTgQuery from "../../apolloUseTgQuery";
import hgql from "graphql-tag";

function HasuraQueries() {
  const { sequences, ...rest } = useTgQuery(
    hgql`
      query MyQuery {
        sequences(order_by: { sequenceFeatures_aggregate: { count: desc } }) {
          nodes {
            name
            hash
            id
            sequenceFeatures {
              id
              name
            }
          }
        }
      }
    `,
    {
      useHasura: true
    }
  );
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  return (
    <div style={{ padding: 40 }}>
      Seqs ordered by # of features:
      {sequences?.nodes?.map((seq, i) => (
        <div key={i}>
          {seq.name} - {seq.sequenceFeatures.length}
        </div>
      ))}
      <br />
    </div>
  );
}

export default HasuraQueries;
