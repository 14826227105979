/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState, useCallback } from "react";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Button, NonIdealState } from "@blueprintjs/core";
import { Loading } from "@teselagen/ui";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import NoResourceFound from "./NoResourceFound";
import { setActiveLab } from "./utils/labUtils";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import getActiveLabId from "../../tg-iso-shared/src/utils/getActiveLabId";
import appGlobals from "./appGlobals";

const WithNotFoundComp = ({
  data,
  error,
  fragment,
  history,
  location,
  match: {
    params: { id: matchParamsId }
  },
  model: _model,
  wasNotFound
}) => {
  const [loading, setLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [labId, setLabId] = useState("");
  const [labName, setLabName] = useState("");
  const [inPrivateLab, setInPrivateLab] = useState(false);
  const [reloadingRecord, setReloadingRecord] = useState(false);

  const model = _model || getModelNameFromFragment(fragment);

  const changeLabAndRefetch = useCallback(
    async maybeLabId => {
      setReloadingRecord(true);
      const newLabId = maybeLabId || labId;
      if (window.Cypress) {
        window.Cypress.hadToChangeLab = true;
      }
      try {
        setActiveLab(newLabId);
        await data.refetch();
      } catch (error) {
        console.error("error:", error);
      }
      setReloadingRecord(false);
    },
    [data, labId]
  );

  useEffect(() => {
    const checkForAccess = async () => {
      setLoading(true);
      setInPrivateLab(false);
      try {
        if (model && matchParamsId) {
          const { data } = await window.api.request({
            method: "POST",
            baseURL: window.frontEndConfig.serverBasePath || "",
            url: "/checkForRecordAccess",
            data: {
              id: matchParamsId,
              model
            }
          });
          if (data.hasAccess) {
            if (data.labId !== getActiveLabId()) {
              window.toastr.warning(
                `Switching to lab ${
                  data.labName
                } to view private ${modelNameToReadableName(model)}.`
              );
            }
            await changeLabAndRefetch(data.labId);
          }
          setHasAccess(data.hasAccess);
          setLabId(data.labId);
          setLabName(data.labName);
        }
      } catch (error) {
        if (error.response?.data?.includes?.("does not have access")) {
          setInPrivateLab(true);
        }
        console.error("error:", error);
      }
      setLoading(false);
    };
    if (wasNotFound) {
      checkForAccess();
    }
  }, [changeLabAndRefetch, matchParamsId, model, wasNotFound]);

  useEffect(() => {
    appGlobals.activeRecordId = matchParamsId;
    return () => {
      delete appGlobals.activeRecordId;
    };
  }, [matchParamsId]);

  const onReturnClick = () => {
    const returnToLocation = location.pathname
      .split("/")
      .slice(0, -1)
      .join("/");
    history.push(returnToLocation);
  };

  const returnToLocation = location.pathname
    .split("/")
    .slice(0, -1)
    .join("/");
  const ReturnToComp = (
    <div>
      <Button onClick={onReturnClick}>Return to {returnToLocation}</Button>
    </div>
  );

  if (error) {
    return (
      <div style={{ alignSelf: "center", height: "100%" }}>
        <NonIdealState title="Error" icon="error">
          <div>{error.toString()}</div>
        </NonIdealState>
      </div>
    );
  } else if (wasNotFound) {
    if (loading) {
      return <Loading />;
    } else if (hasAccess) {
      return (
        <div style={{ alignSelf: "center", height: "100%" }}>
          <NonIdealState title="Private" icon="shield">
            <div>
              This {modelNameToReadableName(model)} is in the private lab{" "}
              {labName}. Would you like to switch labs to view?
            </div>
            <Button
              text={`View ${modelNameToReadableName(model, {
                upperCase: true
              })}`}
              loading={reloadingRecord}
              onClick={() => changeLabAndRefetch(null)}
            />
          </NonIdealState>
        </div>
      );
    } else if (inPrivateLab) {
      return (
        <NonIdealState
          title={`${modelNameToReadableName(model, {
            upperCase: true
          })} is in a Private Lab`}
          icon="lock"
        />
      );
    } else {
      return <NoResourceFound returnTo={ReturnToComp} location={location} />;
    }
  }
  return null;
};

/**
 * @param {Object} options - options.
 * @param {string} options.path - the path to what should be loaded on the props of the component (aka "data.entity")
 * @param {string} options.loadingPath - the path to a loading boolean on the props of the component (aka "data.loading")
 */
export default function withNotFound({ path, loadingPath = "data.loading" }) {
  return compose(withRouter, function WithNotFoundHOC(WrappedComponent) {
    return props => {
      const isLoading = get(props, loadingPath);
      const hasData = get(props, path);
      const wasNotFound = !isLoading && !hasData;
      const error = !isLoading && props.data?.error;
      const notFound = WithNotFoundComp({
        data: props.data,
        error,
        fragment: props.fragment,
        history: props.history,
        location: props.location,
        match: props.match,
        model: props.model,
        path,
        loadingPath,
        wasNotFound
      });
      if (notFound) {
        return notFound;
      }
      return <WrappedComponent {...props} />;
    };
  });
}
