/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useMemo } from "react";
import { Router } from "react-router-dom";
import { useHotkeys } from "@blueprintjs/core";
import { AssignDefaultsModeContext } from "@teselagen/ui/src/AssignDefaultsModeContext";
import AssignDefaultsModeUi from "../../AssignDefaultsModeUi";
import { getOpenDialogType, hideDialog, showDialog } from "../../GlobalDialog";
import { isAdmin } from "../../utils/generalUtils";
import { createBrowserHistory } from "history";
import { createNotification } from "../../utils/createNotification";
import { AppContentManager } from "../AppContentManager";

let historyOptions = {};

if (window.frontEndConfig.clientBasePath) {
  historyOptions = { basename: window.frontEndConfig.clientBasePath };
}

const hist = window.browserHistory || createBrowserHistory(historyOptions);
if (process.env.NODE_ENV === "development" && !window.browserHistory) {
  window.browserHistory = hist;
}

if (window.Cypress) {
  window.Cypress.tgHistory = hist;
  window.Cypress.tg_cy_createNotification = createNotification;
}

export const AppInner = ({
  appHotkeySets,
  Alert,
  appBodyClassName,
  appRoutes,
  Header,
  Helper,
  mainStore,
  modalComponents,
  mobXModalComponents
}) => {
  const [inAssignDefaultsMode, setAssignDefaultsMode] = useState(false);

  // important: hotkeys array must be memoized to avoid infinitely re-binding hotkeys
  const hotkeys = useMemo(
    () => [
      {
        combo: "mod+shift+K",
        label: "Show Keyboard Shortcuts",
        global: true,

        onKeyDown: () => {
          if (getOpenDialogType() === "HotkeysDialog") {
            return hideDialog();
          }
          return showDialog({
            modalType: "HotkeysDialog",
            modalProps: {
              hotkeySets: {
                ...appHotkeySets,
                General: {
                  ...appHotkeySets.General,
                  toggleAssignDefaultsMode: "shift+d"
                }
              },
              isOpen: true,
              onClose: hideDialog
            }
          });
        }
      },
      {
        global: true,
        combo: "shift+d",
        label: "Toggle Assign Defaults Mode",
        onKeyDown: e => {
          if (!isAdmin()) return;
          setAssignDefaultsMode(prev => !prev);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    ],
    [appHotkeySets]
  );

  useHotkeys(hotkeys);

  return (
    <AssignDefaultsModeContext.Provider
      value={{
        inAssignDefaultsMode,
        setAssignDefaultsMode
      }}
    >
      <Router history={hist}>
        <div tabIndex={0} style={{ width: "100vw", height: "100vh" }}>
          {inAssignDefaultsMode && (
            <AssignDefaultsModeUi
              inAssignDefaultsMode={inAssignDefaultsMode}
              setAssignDefaultsMode={setAssignDefaultsMode}
            />
          )}
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <AppContentManager
              Alert={Alert}
              appBodyClassName={appBodyClassName}
              appHotkeySets={appHotkeySets}
              appRoutes={appRoutes}
              Header={Header}
              Helper={Helper}
              mainStore={mainStore}
              modalComponents={modalComponents}
              mobXModalComponents={mobXModalComponents}
            />
          </div>
        </div>
      </Router>
    </AssignDefaultsModeContext.Provider>
  );
};
