/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment microserviceTaskLibraryDesignFragment on microserviceQueue {
    id
    status
    result
    service
    input
    createdAt
    updatedAt
    startedOn
    completedOn
  }
`;
