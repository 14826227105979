/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { kebabCase } from "lodash";
import { some } from "lodash";

let toolSchemas, keyedFullToolSchemas, keyedToolSchemas;

export function initToolSchemas(_toolSchemas) {
  toolSchemas = _toolSchemas;
  keyedFullToolSchemas = toolSchemas.reduce((acc, schema) => {
    acc[schema.code] = schema;
    return acc;
  }, {});
  keyedToolSchemas = toolSchemas.reduce((acc, schema) => {
    acc[schema.code] = schema.toolSchema;
    return acc;
  }, {});
}

export { toolSchemas, keyedFullToolSchemas, keyedToolSchemas };

export function toolOutputsWorklist(toolSchema) {
  return some(toolSchema.output?.ioItems, ioItem => {
    return ioItem.dataItemTypeCode === "WORKLIST";
  });
}

export function getSuggestedNextTools(toolSchema, keyedSchemas) {
  if (!toolSchema) return [];
  const { suggestedNextTools: _suggestedNextTools = [] } = toolSchema;
  let suggestedNextTools = [..._suggestedNextTools];

  const outputsWorklist = toolOutputsWorklist(toolSchema);
  if (outputsWorklist) {
    suggestedNextTools.unshift("executeWorklist");
  }
  suggestedNextTools = suggestedNextTools.filter(
    code => !keyedSchemas[code].disabled
  );
  return suggestedNextTools;
}

export function filterToolSchemasForLibrary(toolSchemas) {
  return toolSchemas
    .filter(tool => {
      const isEnabled = window.frontEndConfig.enabledTools
        ? window.frontEndConfig.enabledTools.includes(tool.code)
        : true;
      const isDeprecated = !window.Cypress && tool.deprecated;
      return (
        isEnabled &&
        !isDeprecated &&
        !tool.workflowOnly &&
        !tool.hideTool &&
        !tool.disabled
      );
    })
    .map(tool => {
      return {
        ...tool,
        tabId: kebabCase(tool.title)
      };
    });
}
