/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import ParallelPartSequenceViewer from "./components/ParallelPartSequenceViewer";
import CodonMapLibraryContainer from "./containers/CodonMapLibraryContainer";
import PartLibraryContainer from "./containers/PartLibraryContainer";
import PartSetLibraryContainer from "./containers/PartSetLibraryContainer";
import DesignEditContainer from "./containers/DesignEditContainer";
import AssemblyLibraryContainer from "./containers/AssemblyLibraryContainer";
import DesignTemplateLibraryContainer from "./containers/DesignTemplateLibraryContainer";
import OligoLibraryContainer from "./containers/OligoLibraryContainer";
import SchematicEditContainer from "./containers/SchematicEditContainer";
import RuleSetEditContainer from "./containers/RuleSetEditContainer";
import SchematicLibraryContainer from "./containers/SchematicLibraryContainer";
import SharedMicroserviceTaskLibrary from "../src-shared/SharedMicroserviceTaskLibrary";
import SharedMicroserviceTaskRecordView from "../src-shared/SharedMicroserviceTaskRecordView";
import PartToSequenceRedirect from "./components/PartToSequenceRedirect";
import AAPartToAASequenceRedirect from "./components/AAPartToAASequenceRedirect";
import WelcomeScreen from "./components/WelcomeScreen";
import AminoAcidSequenceAddView from "./components/AASequence/AddView";
import {
  SequenceAddView,
  OligoAddView
} from "../src-shared/TgSequenceEditor/AddView";
import DesignLibrary from "./components/DesignLibrary";
import AminoAcidPartLibraryContainer from "./containers/AminoAcidPartLibraryContainer";
import DesignRulesetLibrary from "./components/DesignRulesetLibrary";
import EmptyDesign from "./components/EmptyDesign";
import EmptyDesignTemplate from "./components/EmptyDesignTemplate";
import SequenceFeatures from "../src-shared/Library/SequenceFeatures";
import SequenceFeatureRecordView from "../src-shared/SequenceFeatureRecordView";
import { disabledFeaturesConfig } from "../src-shared/utils/generalUtils";
import DNASequenceRecordView from "../src-shared/DNASequenceRecordView";
import J5Report from "./components/J5Report";
import AminoAcidRecordView from "../src-shared/AminoAcidRecordView";

export const appRoutes = {
  privateRoutes: {
    "/": WelcomeScreen,
    "/assembly-reports": AssemblyLibraryContainer,
    "/assembly-reports/:id": J5Report,
    "/designs": DesignLibrary,
    "/designs/new": EmptyDesign,
    "/designs/:id": DesignEditContainer,
    ...(!disabledFeaturesConfig.designRulesets
      ? {
          "/design-rulesets": DesignRulesetLibrary,
          "/design-rulesets/:id": RuleSetEditContainer
        }
      : {}),
    "/amino-acid-sequences/new": AminoAcidSequenceAddView,
    "/amino-acid-sequences/:id": AminoAcidRecordView,
    "/amino-acid-parts": AminoAcidPartLibraryContainer,
    "/amino-acid-parts/:id": AAPartToAASequenceRedirect,
    "/codon-maps": CodonMapLibraryContainer,
    ...(!disabledFeaturesConfig.designTemplates
      ? {
          "/design-templates": DesignTemplateLibraryContainer,
          "/design-templates/new": EmptyDesignTemplate,
          "/design-templates/:id": DesignEditContainer
        }
      : {}),
    "/parts": PartLibraryContainer,
    "/rna-parts": props => <PartLibraryContainer {...props} isRNA />,
    "/parts/:id": PartToSequenceRedirect,
    "/part-sets/:partSetId?": PartSetLibraryContainer,
    "/oligos": OligoLibraryContainer,
    "/dna-sequences/new": SequenceAddView,
    "/dna-sequences/:id": DNASequenceRecordView,
    "/rna-sequences/new": props => <SequenceAddView {...props} isRNA />,
    "/rna-sequences/:id": DNASequenceRecordView,
    "/sequence-features": SequenceFeatures,
    "/sequence-features/:id": SequenceFeatureRecordView,
    "/oligos/new": OligoAddView,
    "/oligos/:id": DNASequenceRecordView,
    ...(!disabledFeaturesConfig.designSchematics
      ? {
          "/schematics": SchematicLibraryContainer,
          "/schematics/:id": SchematicEditContainer
        }
      : {}),
    "/massively-parallel-part-creation/:id": ParallelPartSequenceViewer,
    "/microservice-tasks": SharedMicroserviceTaskLibrary,
    "/microservice-tasks/:id": SharedMicroserviceTaskRecordView
  }
};
