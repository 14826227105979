/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
function getUserFromContext(context) {
  if (!context?.req?.user) {
    const msg = `No user in context for request`;
    console.error(msg);
    throw new Error(msg);
  }

  return context.req.user;
}

module.exports = { getUserFromContext };
