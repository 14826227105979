/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const moleculeMenu = {
  name: "Molecules",
  icon: "graph"
};
export const designMenu = {
  name: "Designs",
  icon: "layout-hierarchy"
};
export const reportMenu = {
  name: "Reports",
  icon: "document"
};
export const dataMenu = {
  name: "Data",
  icon: "database"
};
export const toolMenu = {
  name: "Tools",
  icon: "briefcase"
};
export const taskMenu = {
  name: "Tasks",
  icon: "history"
};
