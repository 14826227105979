/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import vectorEditorMiddleware from "@teselagen/ove/src/redux/middleware";
import sagas from "./sagas";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsBlacklist: []
    })) ||
  compose;

const store = createStore(
  rootReducer,
  undefined, // we don't have an initial state, so this is undefined
  composeEnhancers(
    applyMiddleware(sagaMiddleware, thunk, vectorEditorMiddleware)
  )
);

sagaMiddleware.run(sagas);
if (window.teGlobalStore) {
  throw new Error(
    `window.teGlobalStore should NOT be set yet. This indicates that you're importing files incorrectly (like requiring src-build/some-util from src-design)`
  );
}
window.teGlobalStore = store;

export default store;
