/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { useDrop } from "react-dnd";
import { get } from "lodash";
import { connect } from "react-redux";
import { Colors } from "@blueprintjs/core";
import SBOLIcons from "../../../SBOLIcons/SBOLIcons";

import actions from "../../../../../src-shared/redux/actions";
import { isDesignLocked } from "../../../../../src-shared/utils/designUtils/isDesignLocked";
import getAppColor from "../../../../../src-shared/utils/getAppColor";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../../../../../src-shared/components/HierarchicalDesign/constants";

function BinIcon(props) {
  const { bin, icon, isInjected, iconColor, setBinIcon } = props;
  const { direction, isFixed, isPlaceholder, isLocked } = bin;
  const iconPath = get(icon, "path") || SBOLIcons["USER-DEFINED"].path;

  const iconTransform = !direction ? "rotate(180deg)" : "";

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "SBOL_ICON",
    drop: item => {
      setBinIcon({ binId: bin.id, icon: item.icon });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;
  let backgroundColor;
  if (isActive) {
    backgroundColor = getAppColor();
  } else if (canDrop) {
    if (localStorage.darkMode === "false") {
      backgroundColor = Colors.GRAY3;
    } else {
      backgroundColor = "rgb(52, 59, 64)";
    }
  }

  if (iconPath.match("upload") || iconPath.match("images")) {
    return (
      <img
        ref={drop}
        alt={icon.name}
        src={(window.frontEndConfig.serverBasePath || "") + "/" + iconPath}
        style={{
          width: SBOL_ICON_WIDTH,
          height: SBOL_ICON_HEIGHT,
          transform: iconTransform
        }}
      />
    );
  }

  return (
    <div
      ref={drop}
      style={{
        background: backgroundColor
      }}
    >
      <svg
        viewBox="-10 20 70 60"
        height={SBOL_ICON_HEIGHT}
        width={SBOL_ICON_WIDTH}
        style={{
          transform: iconTransform
        }}
      >
        <path
          stroke={
            iconColor ||
            (isInjected || isFixed || isPlaceholder || isLocked
              ? "#777777"
              : "black")
          }
          strokeWidth="3"
          fill="none"
          d={iconPath}
        />
      </svg>
    </div>
  );
}

export default connect(
  state => ({
    isLocked: !!isDesignLocked(state)
  }),
  {
    setBinIcon: actions.design.setBinIcon
  }
)(BinIcon);
