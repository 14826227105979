/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { uniq, uniqBy, get, kebabCase, snakeCase } from "lodash";
import shortid from "shortid";
import { safeQuery } from "../../src-shared/apolloMethods";
import { SIMPLE_REFERENCES_TO_TYPE } from "../../../tg-iso-design/constants/designStateConstants";
import { computeSequenceHash } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import defaultJ5OutputNamingTemplateMap from "../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

const isVisualReport = design => design.type === "visual-report";

const leavesChildSetsAll = (set, leafSets = []) => {
  if (!set.childSets || !set.childSets.length) leafSets.push(set);
  set.childSets.forEach(child => leavesChildSetsAll(child, leafSets));
  return leafSets;
};

const isRegexRule = rule => !!rule.regex;

const findByKey = (array, keyValue, key) =>
  array.find(value => get(value, key) === keyValue);

const createDesignMutationsFromCSV = async (csvData, fileName) => {
  const binNames = computeBinNamesFromFileData(csvData.data[0]);
  const partNameRows = [];
  csvData.data.map(row => partNameRows.push(Object.values(row)));

  const partNamesByBin = [];
  let uniqPartNames = [];
  let longestColumn = 0;
  const partNameToDbParts = {};

  partNameRows.forEach(row => {
    longestColumn++;
    row.forEach((name, i) => {
      partNamesByBin[i] = partNamesByBin[i] || [];
      partNamesByBin[i].push(name);
    });
    uniqPartNames = uniqPartNames.concat(row);
  });

  uniqPartNames = uniq(uniqPartNames).filter(e => e !== "");

  const fetchedParts = await safeQuery(
    ["part", `id name sequence { id isJ5Sequence isInLibrary }`],
    {
      variables: {
        filter: {
          name: uniqPartNames,
          "sequence.isInLibrary": true
        }
      }
    }
  );

  uniqBy(fetchedParts, "name").forEach(part => {
    partNameToDbParts[part.name] = partNameToDbParts[part.name] || [];
    partNameToDbParts[part.name].push(part);
  });

  const binCids = binNames.map(() => shortid());
  const cardCids = binNames.map(() => shortid());

  const designCid = shortid();
  const reactionCid = shortid();
  const refDesign = "&" + designCid;

  const mutations = [
    {
      entity: "design",
      inputs: {
        cid: designCid,
        name: fileName.slice(0, fileName.lastIndexOf(".")),
        type: "grand-design",
        layoutType: "combinatorial",
        numRows: longestColumn
      }
    },
    {
      entity: "card",
      inputs: {
        designId: refDesign,
        isRoot: true,
        name: "",
        circular: true,
        binCards: binNames.map((binName, i) => ({
          designId: refDesign,
          index: i,
          bin: {
            designId: refDesign,
            cid: binCids[i],
            direction: binName[0] !== "<",
            name:
              binName[0] === "<" || binName[0] === ">"
                ? binName.slice(1)
                : binName,
            iconId: "&USER-DEFINED",
            elements: partNamesByBin[i]
              .map((partName, x) => {
                // handle empty spaces in excel sheet
                if (partName === "") {
                  return null;
                }
                // if no parts found in DB => make unmapped element just give name
                else if (!partNameToDbParts[partName]) {
                  return { name: partName, index: x, designId: refDesign };
                }
                //if 1 part => make normal element, set partId to be DB Id
                else if (
                  partNameToDbParts[partName] &&
                  partNameToDbParts[partName].length === 1
                ) {
                  return {
                    partId: partNameToDbParts[partName][0].id,
                    name: partName,
                    designId: refDesign,
                    index: x
                  };
                }
                // if >1 make a partset
                else if (
                  partNameToDbParts[partName] &&
                  partNameToDbParts[partName].length > 1
                ) {
                  return {
                    name: partName,
                    designId: refDesign,
                    index: x,
                    partset: {
                      name: `${partName} returned ${partNameToDbParts[partName].length} parts`,
                      partsetParts: partNameToDbParts[partName].map(e => ({
                        partId: e.id
                      }))
                    }
                  }; // array of partIds found
                }
                return null;
              })
              .filter(e => e !== null)
          }
        })),
        outputReaction: {
          designId: refDesign,
          cid: reactionCid,
          assemblyMethodId: "&gibson-slic-cpec",
          name: "Gibson/SLIC/CPEC",
          customJ5Parameter: {
            ...getDefaultParamsAsCustomJ5ParamName(),
            isLocalToThisDesignId: refDesign
          },
          reactionJ5OutputNamingTemplates: Object.keys(
            defaultJ5OutputNamingTemplateMap
          ).map(outputTarget => ({
            designId: refDesign,
            j5OutputNamingTemplate: {
              designId: refDesign,
              outputTarget,
              ...defaultJ5OutputNamingTemplateMap[outputTarget]
            }
          })),
          cards: cardCids.map((cardCid, i) => ({
            designId: refDesign,
            inputIndex: i,
            circular: true,
            cid: cardCid,
            name: ""
          }))
        }
      }
    },
    {
      entity: "binCard",
      inputs: cardCids.map((cardCid, i) => ({
        designId: refDesign,
        index: i,
        binId: "&" + binCids[i],
        cardId: "&" + cardCid
      }))
    },
    {
      entity: "junction",
      inputs: binCids.map((binCid, i) => ({
        designId: refDesign,
        junctionTypeCode: "SCARLESS",
        isPhantom: false,
        reactionId: "&" + reactionCid,
        fivePrimeCardId: "&" + cardCids[i],
        fivePrimeCardEndBinId: "&" + binCid,
        fivePrimeCardInteriorBinId: "&" + binCid,
        threePrimeCardId: "&" + cardCids[i % cardCids.length],
        threePrimeCardStartBinId: "&" + binCids[i % binCids.length],
        threePrimeCardInteriorBinId: "&" + binCids[i % binCids.length]
      }))
    }
  ];

  return mutations;
};

const getBpsOfSeqInDesign = (seq, design) => {
  return Object.values(design.sequenceFragment)
    .filter(frag => frag.sequenceId === seq.id)
    .sort((a, b) => a.index - b.index)
    .map(frag => frag.fragment)
    .join("");
};

const swapDuplicatedSequencesInDesign = (file, dupedSeqMap) => {
  const design = file.design;
  const originalSeqIdToExistingIdMap = {};
  const existingSeqIdToHashMap = {};

  Object.entries(dupedSeqMap).forEach(([hash, seq]) => {
    existingSeqIdToHashMap[seq.id] = hash;
  });

  // add hash to sequences in design file if necessary
  // then collect duped seqs for removal and track the existing seq's id
  const seqIdsToRemove = [];
  Object.values(design.sequence).forEach(seq => {
    if (!seq.hash) {
      seq.hash = computeSequenceHash(
        getBpsOfSeqInDesign(seq, design),
        seq.sequenceTypeCode
      );
    }
    if (dupedSeqMap[seq.hash]) {
      originalSeqIdToExistingIdMap[seq.id] = dupedSeqMap[seq.hash].id;
      seqIdsToRemove.push(seq.id);
    }
  });

  // collect sequenceFragments for removal that already exist on a duped seq
  const fragIdsToRemove = [];
  Object.values(design.sequenceFragment).forEach(frag => {
    if (originalSeqIdToExistingIdMap[frag.sequenceId])
      fragIdsToRemove.push(frag.id);
  });

  // collect parts that already exist on existing seqs for removal, or add parts to existing seq
  const partIdsToRemove = [];
  const partToExistingPartIdMap = {};
  Object.values(design.part).forEach(part => {
    if (originalSeqIdToExistingIdMap[part.sequenceId]) {
      const existingSeqId = originalSeqIdToExistingIdMap[part.sequenceId];
      const hash = existingSeqIdToHashMap[existingSeqId];
      if (
        // check if part already exists
        dupedSeqMap[hash].parts.some(existingPart => {
          if (
            existingPart.name === part.name &&
            existingPart.start === part.start &&
            existingPart.end === part.end &&
            existingPart.strand === part.strand
          ) {
            partToExistingPartIdMap[part.id] = existingPart.id;
            return true;
          }
          return false;
        })
      ) {
        // the part is already on the existing sequence so mark it for removal
        partIdsToRemove.push(part.id);
      } else {
        // the part needs to be created on the existing sequence
        part.sequenceId = existingSeqId;
      }
    }
  });

  // collect features that already exist on existing seqs for removal, or add features to existing seq
  const featureIdsToRemove = [];
  const featureToExistingFeatureIdMap = {};
  Object.values(design.sequenceFeature).forEach(sequenceFeature => {
    if (originalSeqIdToExistingIdMap[sequenceFeature.sequenceId]) {
      const existingSeqId =
        originalSeqIdToExistingIdMap[sequenceFeature.sequenceId];
      const hash = existingSeqIdToHashMap[existingSeqId];
      if (
        // check if feature already exists
        dupedSeqMap[hash].sequenceFeatures.some(existingFeature => {
          if (
            existingFeature.name === sequenceFeature.name &&
            existingFeature.start === sequenceFeature.start &&
            existingFeature.end === sequenceFeature.end &&
            !!existingFeature.strand === !!sequenceFeature.strand
          ) {
            featureToExistingFeatureIdMap[sequenceFeature.id] =
              existingFeature.id;
            return true;
          }
          return false;
        })
      ) {
        // the sequenceFeature is already on the existing sequence so mark it for removal
        featureIdsToRemove.push(sequenceFeature.id);
      } else {
        // the sequenceFeature needs to be created on the existing sequence
        sequenceFeature.sequenceId = existingSeqId;
      }
    }
  });

  // remap featureIds or partIds to point to the existing one
  Object.entries(SIMPLE_REFERENCES_TO_TYPE).forEach(([entity, refs]) => {
    Object.entries(refs).forEach(([foreignKeyField, refEntity]) => {
      if (refEntity === "part" && design[entity]) {
        Object.values(design[entity]).forEach(values => {
          values[foreignKeyField] =
            partToExistingPartIdMap[values[foreignKeyField]] ||
            values[foreignKeyField];
        });
      }

      if (refEntity === "sequenceFeature" && design[entity]) {
        Object.values(design[entity]).forEach(values => {
          values[foreignKeyField] =
            featureToExistingFeatureIdMap[values[foreignKeyField]] ||
            values[foreignKeyField];
        });
      }
    });
  });

  // actually do the removals
  seqIdsToRemove.forEach(seqId => delete design.sequence[seqId]);
  fragIdsToRemove.forEach(fragId => delete design.sequenceFragment[fragId]);
  partIdsToRemove.forEach(partId => delete design.part[partId]);
  featureIdsToRemove.forEach(featId => delete design.sequenceFeature[featId]);

  return file;
};
export function getCurrentDesignIdFromWindow() {
  let path = window.location.pathname;
  if (window.location.pathname.endsWith("/")) {
    path = window.location.pathname.slice(0, -1);
  }
  const arr = path.split("/");
  const designId = arr[arr.length - 1];
  return designId;
}

export function computeBinNamesFromFileData(binNamesData) {
  const binNameToIconCid = {};
  const binNames = Object.keys(binNamesData).map(binName => {
    if (binName.indexOf("(") !== -1 && binName.endsWith(")")) {
      const maybeNewBinName = binName.slice(0, binName.indexOf("("));
      const maybeIconName = binName.slice(binName.indexOf("(") + 1, -1);
      if (iconCids[kebabCase(maybeIconName).toUpperCase()]) {
        binNameToIconCid[maybeNewBinName] =
          "&" + kebabCase(maybeIconName).toUpperCase();
        return maybeNewBinName;
      } else if (iconCids[snakeCase(maybeIconName).toUpperCase()]) {
        binNameToIconCid[maybeNewBinName] =
          "&" + snakeCase(maybeIconName).toUpperCase();
        return maybeNewBinName;
      }
    }
    return binName;
  });
  return binNames;
}

export const iconCids = {
  "ASSEMBLED-CONSTRUCT": true,
  ASSEMBLY_JUNCTION: true,
  BLUNT_RESTRICTION_SITE: true,
  CDS: true,
  FIVE_PRIME_OVERHANG: true,
  FIVE_PRIME_RESTRICTION_SITE: true,
  FIVE_PRIME_UTR: true,
  INSULATOR: true,
  OPERATOR_SITE: true,
  ORIGIN_OF_REPLICATION: true,
  PRIMER_BINDING_SITE: true,
  PROMOTER: true,
  PROTEASE_SITE: true,
  PROTEIN_STABILITY_ELEMENT: true,
  RBS: true,
  RESTRICTION_ENZYME_RECOGNITION_SITE: true,
  RIBONUCLEASE_SITE: true,
  RNA_STABILITY_ELEMENT: true,
  SIGNATURE: true,
  TERMINATOR: true,
  THREE_PRIME_OVERHANG: true,
  THREE_PRIME_RESTRICTION_SITE: true,
  "TYPE-IIS-CUTSITE": true,
  "TYPE-IIS-RECOGNITION-SITE": true,
  "USER-DEFINED": true
};

export {
  isVisualReport,
  leavesChildSetsAll,
  isRegexRule,
  findByKey,
  createDesignMutationsFromCSV,
  swapDuplicatedSequencesInDesign
};

export function designLibraryFilter(qb) {
  qb.whereAll({
    // type: qb.inList(["grand-design", "uniform-design", "non-uniform-design"])
    type: qb.notEquals("design-template")
  });
  qb.whereAny(
    {
      lockTypeCode: qb.notEquals("LOCKED_FOR_ASSEMBLY")
    },
    {
      lockTypeCode: qb.isNull()
    }
  );
}
