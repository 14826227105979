/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import getModuleName from "./getModuleName";
import { bteModuleName } from "@teselagen/utils";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function isBuild() {
  return bteModuleName(getModuleName()) === "build";
}
export function isDesign() {
  return getModuleName() === "design";
}
export function isTest() {
  return getModuleName() === "test";
}
export function isEvolve() {
  return getModuleName() === "evolve";
}
