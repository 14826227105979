/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { showConfirmationDialog } from "@teselagen/ui";
import { get, flatMap } from "lodash";
import { Intent } from "@blueprintjs/core";
import { safeDelete, safeQuery, safeUpsert } from "../apolloMethods";
import recurseOnJson from "../../../tg-iso-design/utils/recurseOnJson";
import { showDialog } from "../GlobalDialog";

const partToDesignsFragment = gql`
  fragment partToDesignsFragment on part {
    id
    elements {
      id
      design {
        id
        name
      }
    }
  }
`;

const sequenceToDesignsFragment = gql`
  fragment sequenceToDesignsFragment on sequence {
    id
    isJ5Sequence
    isInLibrary
    parts {
      ...partToDesignsFragment
    }
  }
  ${partToDesignsFragment}
`;

const handlePartOrSequenceLibraryDelete = ({
  isPart,
  items,
  refetch
}) => async () => {
  try {
    const ids = items.map(item => item.id);

    // See if the parts/sequences have any designs associated with them.
    const queryResults = await safeQuery(
      isPart ? partToDesignsFragment : sequenceToDesignsFragment,
      { isPlural: true, variables: { filter: { id: ids } } }
    );
    const itemIdToDesign = queryResults.reduce((acc, result) => {
      const designs = [];
      recurseOnJson(
        result,
        item => {
          if (get(item, "__typename") === "design") designs.push(item);
        },
        { callOnObjectsOnly: true }
      );
      acc[result.id] = designs;
      return acc;
    }, []);

    // If the parts and sequences have no designs associated with them, then
    // just delete them.
    if (
      !Object.values(itemIdToDesign).reduce(
        (tot, designs) => tot + designs.length,
        0
      )
    ) {
      const confirm = await showConfirmationDialog({
        text: `Are you sure you want to delete ${
          ids.length > 1
            ? `these ${isPart ? "parts" : "sequences"}`
            : `this ${isPart ? "part" : "sequence"}`
        }?

        This will not affect any designs. However, you cannot undo this action.`,
        intent: Intent.DANGER,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        canEscapeKeyCancel: true
      });

      if (confirm) {
        if (isPart) {
          const partIds = flatMap(queryResults, r => {
            if (r.parts) {
              return r.parts.map(p => p.id);
            } else {
              return r.id;
            }
          });
          if (partIds.length) await safeDelete("part", partIds);
        } else {
          const normalSequenceIds = queryResults
            .filter(s => s.isJ5Sequence === false && s.isInLibrary === true)
            .map(ns => ns.id);
          const savedConstructIds = queryResults
            .filter(s => s.isJ5Sequence === true && s.isInLibrary === true)
            .map(sc => sc.id);

          if (normalSequenceIds.length) {
            await safeDelete("sequence", normalSequenceIds);
          }

          if (savedConstructIds.length) {
            await safeUpsert(
              "sequence",
              savedConstructIds.map(id => ({ id, isInLibrary: false }))
            );
          }
        }

        await refetch();
      }
      return;
    } else {
      showDialog({
        modalType: "PART_OR_SEQUENCE_DELETE",
        modalProps: {
          items,
          isPart,
          itemIdToDesign,
          refetch
        }
      });
    }
  } catch (e) {
    console.error(e);
    window.toastr.error(`Error deleting ${isPart ? "parts" : "sequences"}.`);
  }
};

export default handlePartOrSequenceLibraryDelete;
