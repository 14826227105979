/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isNumber, get } from "lodash";
import { rowIndexToLetter } from "./rowIndexToLetter";

export function getAliquotContainerLocation(
  {
    rowPosition,
    columnPosition,
    containerArray = {},
    containerArrayType: _containerArrayType
  } = {},
  { force2D, containerFormat = {} } = {}
) {
  if (isNumber(rowPosition) && isNumber(columnPosition)) {
    const label2D = rowIndexToLetter(rowPosition) + (columnPosition + 1);
    if (force2D) {
      return label2D;
    }
    const containerArrayType =
      _containerArrayType || containerArray.containerArrayType;
    const is2DLabeled =
      get(containerArrayType, "containerFormat.is2DLabeled") ||
      containerFormat.is2DLabeled;
    const columnCount =
      get(containerArrayType, "containerFormat.columnCount") ||
      containerFormat.columnCount;
    if (!force2D && !is2DLabeled && columnCount) {
      return rowPosition * columnCount + columnPosition + 1;
    } else {
      return label2D;
    }
  } else {
    return "N/A";
  }
}
