/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { logDebug } = require("./logDebug");
const CONSOLE_TAG = "[AUTH-UTILS]";

function setDefaultLabRoleForUser(user) {
  if (!user.defaultLabId && user.labRoles && user.labRoles.length > 0) {
    logDebug(
      `${CONSOLE_TAG} user ${user.username} doesn't have default lab id but is assigned to labs. Auto adding a defaultLabId...`,
      user
    );
    let eligibleDefaultRoles = user.labRoles.filter(
      ({ roleCode }) => roleCode === "ADMIN"
    );

    if (eligibleDefaultRoles.length < 1) {
      logDebug(
        `${CONSOLE_TAG} user ${user.username} is not a lab admin in any labs... expanding default lab search to all assigned labs`
      );
      eligibleDefaultRoles = [...user.labRoles];
    }

    const defaultLabRole = eligibleDefaultRoles.reduce((acc, val) => {
      if (!acc.id) {
        return val;
      } else if (val.id < acc.id) {
        return val;
      } else {
        return acc;
      }
    }, {});

    logDebug(
      `${CONSOLE_TAG} user ${user.username} is being auto assigned to default lab: ${defaultLabRole.labId}`,
      defaultLabRole
    );
    user.defaultLabId = defaultLabRole.labId;
  }

  return user;
}

module.exports = { setDefaultLabRoleForUser };
