/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { DialogFooter, FileUploadField } from "@teselagen/ui";

import { reduxForm } from "redux-form";

import { Classes } from "@blueprintjs/core";
import { normalizeBasePairs } from "../../../../src-shared/utils/normalizeValues";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import TagField from "../../../../src-shared/TagField";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import sequenceStringToFragments from "../../../../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { startImportCollection } from "../../../../src-shared/utils/importCollection";

class UploadOligosDialog extends React.Component {
  onSubmit = async values => {
    try {
      const { refetchOligos, hideModal } = this.props;
      const { tags, oligoFiles } = values;
      await startImportCollection("Oligo Upload");
      await safeUpsert(
        "sequence",
        addTaggedItemsBeforeCreate(
          oligoFiles[0].parsedData.map(oligo => {
            const seq = oligo.Sequence || oligo.sequence;
            const trimmedSeq = seq.replace(/ /g, "");
            return {
              sequenceTypeCode: "OLIGO",
              name: oligo.Name || oligo.name,
              tm: oligo.tm || oligo.Tm,
              tm3Prime: oligo.tm3Prime || oligo.Tm3Prime,
              size: trimmedSeq.length,
              description: oligo.Description || oligo.description,
              sequenceFragments: sequenceStringToFragments(trimmedSeq) //tnr: no longer forcing the oligo to always be upper cased
            };
          }, tags)
        )
      );

      await refetchOligos();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating/updating oligo.");
    }
  };

  parseBasePairs = bpText => {
    let parsedBp = "";
    if (bpText !== undefined) {
      parsedBp = normalizeBasePairs(bpText);
    }
    return parsedBp;
  };

  render() {
    const { handleSubmit, submitting, hideModal, readOnly, isInspector } =
      this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            accept={getDownloadTemplateFileHelpers({
              validateAgainstSchema: {
                fields: [
                  { isRequired: true, path: "name", example: "Oligo 1" },
                  {
                    isRequired: true,
                    path: "sequence",
                    example: "gtagtcctac",
                    validate: bps => {
                      if (!bps) return "Required";
                      const toTest = bps.replace(/ /g, "").toLowerCase();
                      if (!/^[acgtu]*$/i.test(toTest)) {
                        return "Invalid sequence. Only A, C, G, T, and U are allowed.";
                      }
                    }
                  },
                  { path: "description", example: "example description" }
                ]
              }
            })}
            name="oligoFiles"
          />
          {!readOnly && !isInspector && <TagField />}
        </div>
        <DialogFooter hideModal={hideModal} text="OK" submitting={submitting} />
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  const oligoFiles = values.oligoFiles || [];

  if (!oligoFiles.length) errors.oligoFiles = "Required";

  return errors;
};

export default compose(
  wrapDialog({
    title: "Upload Oligos"
  }),
  reduxForm({
    form: "oligoBulkUploadForm",
    enableReinitialize: true,
    validate
  })
)(UploadOligosDialog);
