/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import cn from "classnames";
import isMobile from "is-mobile";
import UserAccountButton from "../UserAccountButton";
import HeaderLinkButton from "../HeaderLinkButton";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import UniversalSearch, { showUniversalSearch } from "../UniversalSearch";
import {
  Popover,
  Tooltip,
  Menu,
  Position,
  Classes,
  InputGroup,
  Intent,
  Button,
  Drawer
} from "@blueprintjs/core";
import { createMenu, HotkeysDialog, keyboardIcon } from "@teselagen/ui";
import { startCase } from "lodash";
import ChangeLog from "../ChangeLog";
import ActiveLabWidget from "../ActiveLabWidget";
import actions from "../redux-shared/actions";
import getAppColor from "../utils/getAppColor";
import ActiveProjectSelectField from "../ActiveProjectSelectField";
import { ModuleLinks } from "../PlatformSidebar";
import { showDialog } from "../GlobalDialog";
import NavJump from "./NavJump";
import ButtonWithHotkey from "./ButtonWithHotkey";
import "./style.css";

const platformModules = {
  Design: { code: "hde", icon: "draw", enabled: true },
  Build: { code: "lims", icon: "wrench", enabled: true },
  Test: { code: "test", icon: "dashboard", enabled: false },
  Evolve: { code: "ia", icon: "lightbulb", enabled: false }
};

// Apply module config overrides
Object.keys(window.frontEndConfig || {})
  .filter(k => k.endsWith("Module"))
  .forEach(k => {
    let obj;
    if (typeof window.frontEndConfig[k] === "string")
      obj = JSON.parse(window.frontEndConfig[k]);
    else obj = window.frontEndConfig[k];
    const name = startCase(k.replace("Module", ""));
    Object.assign(platformModules[name], obj);
  });

const EnhancedHeaderLinkButton = ({
  displayMenuItem = true,
  menu,
  onClick,
  onMobile,
  name,
  iconName,
  ...rest
}) => {
  if (!displayMenuItem) return null;
  if (onMobile) {
    return (
      <div {...(rest.navTo && { onClick })}>
        <br />
        <HeaderLinkButton icon={iconName} name={name} {...rest} />
        <div onClick={onClick} style={{ marginLeft: 20 }}>
          {menu}
        </div>
      </div>
    );
  }
  return (
    <MediaQuery maxWidth={1000}>
      {lessThan1000 => {
        return (
          <MediaQuery maxWidth={800}>
            {lessThan800 => {
              return (
                <Popover
                  disabled={!menu}
                  className="header-link-button inventory-button-container"
                  content={menu}
                  position={Position.BOTTOM}
                >
                  <Tooltip
                    position={Position.BOTTOM}
                    content={name}
                    disabled={!lessThan800}
                  >
                    <HeaderLinkButton
                      {...{
                        ...(lessThan800 && !onMobile ? {} : { name }),
                        ...(!lessThan1000 || lessThan800
                          ? { icon: iconName }
                          : {}),
                        ...rest
                      }}
                    />
                  </Tooltip>
                </Popover>
              );
            }}
          </MediaQuery>
        );
      }}
    </MediaQuery>
  );
};

const searchHotkey = "mod+shift+space";
const navJumpHotkey = "mod+shift+j";

export const PlatformHeader = ({
  appName,
  appRoutes,
  backgroundColor,
  className = "",
  extraContent,
  hotkeySets,
  leftMenuItems,
  loggedIn,
  logout,
  menuItems,
  navOnLabChange,
  onSearch,
  searchSettings,
  settingsPath = "/settings",
  tagLine = "Mind to Molecule™",
  userExtraItems
}) => {
  const darkTheme = useSelector(state => state.platform.ui.theme.dark);
  const dispatch = useDispatch();
  const [showHotkeysDialog, setShowHotkeysDialog] = useState(false);
  const [showMobileOptions, setShowMobileOptions] = useState(false);

  useEffect(() => {
    // if the user prefers dark mode on their system then make the app match that preference
    const hasUserSetDarkModePreference = localStorage.getItem("darkMode");
    const userPrefersDarkTheme =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (!hasUserSetDarkModePreference && userPrefersDarkTheme && !darkTheme) {
      dispatch(actions.platform.ui.theme.toggle());
    }
  }, [darkTheme, dispatch]);

  const handleSearchKeyPress = e => {
    if (onSearch && e.key === "Enter") {
      onSearch(e.target.value);
    }
  };

  useEffect(() => {
    // TODO maybe move this somewhere else. But altering the body element cannot
    // be done in idiomatic React anyway...
    document.body.classList.toggle(Classes.DARK, darkTheme);
  }, [darkTheme]);

  if (!loggedIn) return null;

  const {
    models: modelsToSearch,
    timeField,
    client,
    noNameModels
  } = searchSettings || {};
  const { topWarning } = window.frontEndConfig;

  const extraMenuItems = [
    { text: "Settings", icon: "cog", navTo: settingsPath },
    ...userExtraItems,
    {
      text: "Release Notes",
      icon: "clipboard",
      onClick: () => {
        showDialog({
          ModalComponent: ChangeLog
        });
      }
    }
  ];

  if (hotkeySets) {
    extraMenuItems.push({
      text: "Keyboard Shortcuts",
      key: "keyboardShortcuts",
      icon: keyboardIcon,
      onClick: () => setShowHotkeysDialog(true)
    });
  }

  const onMobile = isMobile();
  const showMobileMenu = showMobileOptions && onMobile;
  const labChooser = (
    <>
      <ActiveLabWidget navOnChange={navOnLabChange} />
      {window.frontEndConfig.projectTags && <ActiveProjectSelectField />}
      {leftMenuItems}
    </>
  );

  const navSuggestItems = [];

  const addSuggestItems = (menuItems, textPrefix = "") => {
    menuItems.forEach(item => {
      const baseLabel = item.name || item.text;
      let path = baseLabel;
      if (textPrefix) {
        path = `${textPrefix} > ${path}`;
      }
      if (item.submenu) {
        addSuggestItems(item.submenu, path);
      } else if (item.navTo) {
        navSuggestItems.push({
          label: baseLabel,
          path: path === baseLabel ? undefined : path,
          navTo: item.navTo
        });
      }
    });
  };
  addSuggestItems(menuItems);

  if (appRoutes?.toolSchemasMetadata) {
    appRoutes.toolSchemasMetadata.forEach(schema => {
      const label = schema.title || schema.toolSchema.name;
      navSuggestItems.push({
        label,
        path: "Tools",
        navTo: `/tools/${schema.tabId}`
      });
    });
  }

  const routeLinks = (
    <div
      className="header-routing-section"
      style={{
        ...(onMobile && {
          display: "flex",
          flexDirection: "column",
          alignItems: "unset",
          overflowY: "auto",
          height: "80vh",
          marginTop: 10
        })
      }}
    >
      {(menuItems || []).map(item => (
        <EnhancedHeaderLinkButton
          data-test="tg-header-routing"
          key={item.name}
          onMobile={onMobile}
          name={item.name}
          navTo={item.navTo}
          onClick={() => setShowMobileOptions(false)}
          activeOn={item.activeOn}
          icon={item.icon}
          displayMenuItem={item.enabled}
          menu={item.submenu ? <Menu>{createMenu(item.submenu)}</Menu> : null}
        />
      ))}
      {!onMobile && (
        <NavJump navSuggestItems={navSuggestItems} hotkey={navJumpHotkey} />
      )}
    </div>
  );

  return (
    <>
      <Drawer isOpen={showMobileMenu} size="98vw" position="left">
        <div style={{ padding: 5 }}>
          <div
            style={{
              width: "100%",
              display: "flex"
            }}
          >
            <Button
              style={{
                marginLeft: "auto",
                marginBottom: 8
              }}
              onClick={() => setShowMobileOptions(false)}
              icon="cross"
              minimal
            />
          </div>{" "}
          <ModuleLinks onMobile={onMobile} />
          <br />
          {labChooser}
          {routeLinks}
        </div>
      </Drawer>

      <div
        id="platform-header"
        style={{
          backgroundColor: getAppColor() || backgroundColor
        }}
        className={cn("header-container", className, {
          "with-top-warning": !!topWarning
        })}
      >
        {topWarning && (
          <div className="top-warning">
            <span>{topWarning}</span>
          </div>
        )}

        <div
          className="header"
          style={{ ...(onMobile && { justifyContent: "space-between" }) }}
        >
          {!onMobile && (
            <div style={{ height: 10, minWidth: 45, width: 45 }}></div>
          )}
          {onMobile && (
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => setShowMobileOptions(true)}
              icon="menu"
              minimal
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {!onMobile && (
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                <span className="header-app-name">
                  {appName || "TeselaGen"}
                </span>
                <span className="header-app-tagline">{tagLine}</span>
              </Link>
            )}
            {!onMobile && labChooser}
          </div>

          {!onMobile && routeLinks}

          {/* Optional search box */}
          {onSearch && (
            <InputGroup
              className={Classes.ROUND}
              leftIcon="search"
              placeholder="Search..."
              onKeyPress={handleSearchKeyPress}
            />
          )}

          <div className="header-routing-section right-section">
            {extraContent}
            <Tooltip
              content={darkTheme ? "Light Theme" : "Dark Theme"}
              key="theme"
            >
              <Button
                data-test="tg-toggle-dark-mode"
                icon={darkTheme ? "flash" : "moon"}
                intent={darkTheme ? Intent.WARNING : undefined}
                className={Classes.MINIMAL}
                onClick={() => dispatch(actions.platform.ui.theme.toggle())}
              />
            </Tooltip>
            {searchSettings && (
              <ButtonWithHotkey
                tooltip="Show Search"
                hotkey={searchHotkey}
                icon="search"
                onClick={() => showUniversalSearch()}
                minimal
                className="tg-universal-search-button"
              />
            )}
            {searchSettings && (
              <UniversalSearch
                key="search"
                hotKey={searchHotkey}
                modelsToSearch={modelsToSearch}
                timeField={timeField}
                client={client}
                noNameModels={noNameModels}
              />
            )}

            <UserAccountButton
              data-test="tg-user-account"
              logout={logout}
              icon="user"
              extraItems={extraMenuItems}
            />
          </div>
        </div>
        {hotkeySets && (
          <HotkeysDialog
            isOpen={showHotkeysDialog}
            onClose={() => setShowHotkeysDialog(false)}
            hotkeySets={hotkeySets}
          />
        )}
      </div>
    </>
  );
};

export default PlatformHeader;
