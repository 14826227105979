/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, set, identity, startCase, isInteger } from "lodash";
import {
  validateCSVRequiredHeaders,
  validateCSVRow
} from "../../../tg-iso-shared/src/utils/fileUtils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { safeQuery } from "../apolloMethods";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import isValidNonNegativeNumber from "../../../tg-iso-shared/src/utils/isValidNonNegativeNumber";
import isValidPositiveInteger from "../../../tg-iso-shared/src/utils/isValidPositiveInteger";
import { shouldShowProjects } from "./projectUtils";
import getActiveProjectId from "../../../tg-iso-shared/src/utils/getActiveProjectId";
import modelNameToReadableName from "../../../tg-iso-shared/src/utils/modelNameToReadableName";
import { capitalize } from "lodash";
import { anOrA } from "./generalUtils";

export {
  validateCSVRequiredHeaders,
  isValidNonNegativeNumber,
  isValidPositiveNumber,
  validateCSVRow
};

export const REQUIRED_ERROR = "This field is required.";

export const arrayToItemValuedOptions = (array = [], options = {}) => {
  const { labelKey = "name", formatLabel = identity } = options;
  return array.map(a => {
    const label = get(a, labelKey);
    return {
      value: a,
      label: formatLabel(label)
    };
  });
};

export const arrayToIdOrCodeValuedOptions = (array = [], options = {}) => {
  const { labelKey = "name", formatLabel = identity } = options;
  return array.map(a => ({
    value: a.id ? a.id : a.code,
    label: formatLabel(a[labelKey])
  }));
};

export const valueEmpty = fieldVal => {
  return !fieldVal || (Array.isArray(fieldVal) && !fieldVal.length);
};

export const validateConditionallyRequiredFields = (fields, values, errors) => {
  const allEmpty = fields.every(field => valueEmpty(get(values, field)));
  if (allEmpty) {
    fields.forEach(field => {
      set(errors, field, REQUIRED_ERROR);
    });
  }
};

export const notLessThan = (value, { integer } = {}) => formValue => {
  if (formValue < value) {
    return value;
  } else if (integer && !isNaN(parseFloat(formValue))) {
    return Math.round(formValue);
  } else {
    return formValue;
  }
};

export const notMoreThan = (value, { integer } = {}) => formValue => {
  if (formValue > value) {
    return value;
  } else if (integer && !isNaN(parseFloat(formValue))) {
    return Math.round(formValue);
  } else {
    return formValue;
  }
};

export const inRange = ([min, max], { integer } = {}) => formValue => {
  formValue = notLessThan(min, { integer })(formValue);
  formValue = notMoreThan(max, { integer })(formValue);
  return formValue;
};

export const getContainerFormatOptions = containerFormats =>
  containerFormats
    .map(containerFormat => {
      return {
        label: startCase(containerFormat.code.toLowerCase()) + "s",
        value: containerFormat
      };
    })
    .sort((a, b) => {
      return a.value.name - b.value.name;
    });

export { throwFormError } from "@teselagen/ui";

export const removeDecimal = s => (s ? s.replace(/\./, "&decimal") : s);

export function validatePositiveNumber(num = "") {
  return isValidPositiveNumber(num)
    ? undefined
    : "Please enter a positive number";
}

export function validatePositiveInteger(num = "") {
  if (!isValidPositiveInteger(num)) {
    return "Please enter a positive integer";
  }
}

export function validateNonNegativeNumber(num = "") {
  return isValidNonNegativeNumber(num)
    ? undefined
    : "Please enter a number greater than or equal to 0";
}

export function validateNonNegativeInteger(num = "") {
  if (!isValidNonNegativeNumber(num) || !isInteger(num)) {
    return "Please enter an integer greater than or equal to 0";
  }
}

export async function asyncValidateFieldDuplicate({
  values = {},
  field,
  msg,
  idAs = "id",
  initialValues = {},
  model
}) {
  msg = msg || `That ${field} is already in use.`;
  const fieldValue = values[field];
  if (fieldValue?.toLowerCase() === initialValues[field]?.toLowerCase())
    return Promise.resolve();
  let errorMsg;
  try {
    const hasProject = shouldShowProjects(model);
    let frag = idAs;
    if (hasProject) {
      frag += ` projectItems { id projectId project { id name } }`;
    }
    const res = await safeQuery([model, frag], {
      variables: {
        pageSize: 1,
        filter: caseInsensitiveFilter(model, field, [fieldValue])
      }
    });
    const activeProjectId = getActiveProjectId();
    if (hasProject) {
      const [item] = res;
      if (item) {
        errorMsg = msg;
        if (item.projectItems.length) {
          const inActiveProject =
            activeProjectId &&
            item.projectItems.some(pi => pi.projectId === activeProjectId);
          if (!inActiveProject) {
            const simpleName = modelNameToReadableName(model);
            errorMsg = `${capitalize(
              anOrA(simpleName)
            )} ${simpleName} with this ${field} already exists in the project ${
              item.projectItems[0].project.name
            }.`;
            if (activeProjectId) {
              errorMsg += ` An admin can add this ${simpleName} to this project or All Projects to proceed.`;
            } else {
              errorMsg += ` An admin must unassign this ${simpleName} from projects.`;
            }
          }
        }
      }
    } else if (res.length) {
      errorMsg = msg;
    }
  } catch (error) {
    console.error("error:", error);
    errorMsg = "Error checking for duplication";
  }
  if (errorMsg) {
    // eslint-disable-next-line no-throw-literal
    throw { [field]: errorMsg };
  }
}

export async function asyncValidateBarcodeHandler({
  values,
  ownProps,
  model,
  errors = {}
}) {
  const { initialValues = {} } = ownProps;
  if (!values.generateBarcode) {
    let barcodeToCheck;
    const editBarcode = values.barcode?.barcodeString;
    if (editBarcode) {
      const initBarcode = initialValues.barcode?.barcodeString;
      const wasEdited = editBarcode !== initBarcode;
      if (wasEdited) {
        barcodeToCheck = editBarcode;
      }
    } else if (values.userAddedBarcode) {
      barcodeToCheck = values.userAddedBarcode;
    }
    if (barcodeToCheck) {
      const [dupBarcode] = await safeQuery([model, "id"], {
        variables: {
          pageSize: 1,
          filter: {
            "barcode.barcodeString": barcodeToCheck
          }
        }
      });
      if (dupBarcode) {
        const error = `This barcode is already in use.`;
        if (editBarcode) {
          errors.barcode = {
            barcodeString: error
          };
        } else {
          errors.userAddedBarcode = error;
        }
      }
    }
  }
}

export async function asyncValidateNameHandler({
  values,
  ownProps,
  model,
  errors = {}
}) {
  try {
    await asyncValidateFieldDuplicate({
      values,
      field: "name",
      initialValues: ownProps.initialValues,
      model
    });
  } catch (error) {
    errors.name = error.name;
  }
}

function asyncValidateNameFunction(values, dispatch, ownProps) {
  return asyncValidateFieldDuplicate({
    values,
    initialValues: ownProps.initialValues,
    idAs: ownProps.idAs,
    model: ownProps.model,
    field: "name"
  });
}

export const asyncValidateName = {
  asyncBlurFields: ["name"],
  asyncValidate: asyncValidateNameFunction
};

export const isFormValueEmpty = s => {
  return !s || !s.trim || s.trim() === "";
};
