/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import classNames from "classnames";
import { flatMap } from "lodash";
import "./style.css";

interface RecordInfoTableProps {
  sections: Array<[string, string]>;
  fill: boolean;
}

const RecordInfoTable = ({ sections, fill }: RecordInfoTableProps) => (
  <table className={classNames("record-info-table", { fill })}>
    <tbody>
      {flatMap(sections, recordInfoSection =>
        recordInfoSection
          .filter(x => x)
          .map((labelAndValue, i) => {
            if (!labelAndValue) return;
            const [label, value] = labelAndValue;
            return (
              <tr
                key={label}
                className={i === 0 ? "section-start" : ""}
                style={{ marginBottom: 5 }}
              >
                <td>{label}</td>
                <td className="preserve-newline">{value}</td>
              </tr>
            );
          })
      )}
    </tbody>
  </table>
);

export default RecordInfoTable;
