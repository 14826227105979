/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import io from "socket.io-client";
import actions from "../redux/actions";
import joinUrl from "url-join";
import getActiveLabId from "../../../tg-iso-shared/src/utils/getActiveLabId";
import modelNameToLink from "../utils/modelNameToLink";
import getActiveProjectId from "../../../tg-iso-shared/src/utils/getActiveProjectId";
import { fullUrl } from "../utils/generalUtils";
import { isDesign } from "../../../tg-iso-shared/src/utils/isModule";

const port = window.location.port === "" ? "" : ":" + window.location.port;
const socketUrl = `${window.location.protocol}//${window.location.hostname}${port}`;

/**
 * Toastr appears to treat new lines like spaces. This function
 * enables us to render new lines as actual new lines.
 * @param {string} error
 */
function processErrorMessage(error) {
  return (
    <div>
      {error.split("\n").map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </div>
  );
}

class SocketWrapper {
  constructor() {
    if (!isDesign()) return; //tnr: only being used by design. please keep it that way and eventually we can remove this code..
    if (window.frontEndConfig.serverBasePath) {
      this.socket = io(socketUrl, {
        path: joinUrl(window.frontEndConfig.serverBasePath, "socket.io/"),
        autoConnect: false
        // forceNew: true
        // transports: ["websocket"]
      });
    } else {
      this.socket = io(socketUrl);
    }
    const oldEmit = this.socket.emit;
    this.socket.emit = (event, opts, ...args) => {
      const projectId = getActiveProjectId();
      return oldEmit.call(
        this.socket,
        event,
        {
          labId: getActiveLabId(),
          projectId,
          userId: window.localStorage.getItem("userId"),
          uniqueTabId: window.sessionStorage.getItem("uniqueTabId"),
          ...opts
        },
        ...args
      );
    };
    this.j5RunsCurrentlyRunning = {};
    this.registered = false;
    this.currentUser = null;
    this.interval = null;
    this.socket.on("error", error => {
      console.error(`Socket.io Error: `, error);
    });
    this.socket.on("disconnect", reason => {
      console.warn(`Socket.io disconnect reason:`, reason);
      if (this.registered) {
        window.toastr.error(
          "Lost server connection! Changes will not be saved! Please wait to be reconnected...",
          { timeout: 999999999 }
        );
      }
    });
    this.socket.on("connect_error", error => {
      console.error(`Socket.io Connection Error: `, error);
    });
    this.socket.on("reconnect_error", error => {
      console.error(`Socket.io Re-connection Error: `, error);
    });
    this.socket.on("reconnect", () => {
      console.info(`Socket.io reconnected!`);
      if (this.registered) {
        window.__tgClearAllToasts();
        window.toastr.success(
          "Reconnected to server! Please reload for best results",
          {
            action: {
              text: "Reload",
              onClick: () => window.location.reload()
            },
            timeout: 999999999
          }
        );
      }
    });
  }

  init(currentUser) {
    const { id: userId, username, email } = currentUser;
    window.localStorage.setItem("username", username);
    window.localStorage.setItem("userEmail", email);
    window.localStorage.setItem("userId", userId);
    this.subscribeToEvents();
    this.currentUser = currentUser;
    console.info("Socket initialized");
  }

  async register(designId) {
    // We set registered first so that if a shutdown is in progress
    // we don't try to close the socket
    this.registered = designId;
    await this.connect();
    // Registration causes the socket to join a room identified
    // by the current uniqueTabId
    // it doesn't hurt to try to join the same room twice so we always send the registration event
    this.socket.emit("registerSocket"); // Register socket so the server can manage queue
    console.info("Design socket registered! " + this.registered);
  }

  async connect() {
    if (!this.socket.connected) {
      return new Promise(resolve => {
        this.socket.once("connect", () => {
          console.info("Design socket connected!");
          resolve(this.socket.connected);
        });
        this.socket.connect();
      });
    }
    return true;
  }

  subscribeToEvents() {
    if (this.subscribed) {
      console.info("Socket.io already subscribed");
      return;
    }

    this.subscribed = true;

    this.socket.on("pleaseRegisterSocket", () => {
      if (window.localStorage.getItem("userId"))
        this.socket.emit("registerSocket");
      else console.warn("Can't register to socket");
    });

    this.socket.on(
      "designNotSubmittable",
      this.handleDesignNotSubmittable.bind(this)
    );

    this.socket.on(
      "designAssemblyComplete",
      this.handleDesignAssemblyComplete.bind(this)
    );

    // Crickit handlers
    this.socket.on(
      "designNotSubmittableToCrickit",
      this.handleDesignNotSubmittableToCrickit.bind(this)
    );

    this.socket.on(
      "crickitSubmissionComplete",
      this.handleCrickitSubmissionComplete.bind(this)
    );

    this.socket.on(
      "processedCrickitResponse",
      this.handleProcessedCrickitResponse.bind(this)
    );

    this.socket.on("crickitPartialProgress", ({ designId, status }) => {
      return window.toastr.info(`Design:${designId} crickit update: ${status}`);
    });
  }

  /**
   * Request current timestamp from DB
   */
  requestServerTimestamp() {
    return new Promise(resolve =>
      this.socket.emit("serverTimeSync", null, data => resolve(data))
    );
  }

  /**
   * We will get this message when we cannot submit the design for assembly either
   * because it is invalid or it fails validation. In this case, no j5 runs will be
   * started or placed into the task manager.
   *
   * @param {Object} arg
   * @param {string} arg.designId
   * @param {string} arg.error The error message.
   */
  handleDesignNotSubmittable({ designId, invalidCards, error }) {
    console.error(error);
    window.toastr.error(processErrorMessage(error));
    window.teGlobalStore.dispatch(
      actions.j5.submitDesignForAssemblyFailed({ designId })
    );
    if (invalidCards.length)
      window.teGlobalStore.dispatch(
        actions.design.updateCard(
          invalidCards.map(ic => {
            delete ic.cardId;
            return ic;
          })
        )
      );
  }

  /**
   * We will get this message when we cannot submit the design to crickit either
   * because it is invalid or it fails validation
   *
   * @param {Object} arg
   * @param {string} arg.designId
   * @param {string} arg.error The error message.
   */
  handleDesignNotSubmittableToCrickit({ error }) {
    window.toastr.error(processErrorMessage(error));
  }

  handleCrickitSubmissionComplete({ success, error }) {
    if (success) {
      window.toastr.success("Submitted to Crickit");
    } else {
      window.toastr.error(
        `Error with Crickit submission:  ${
          error.message ? error.message : error
        }`,
        {
          timeout: -1
        }
      );
    }
  }

  sendToCrickit({
    designId,
    selectedElements,
    selectedParameterPreset,
    selectedSelectionHeuristic,
    username
  }) {
    this.socket.emit("sendToCrickit", {
      designId,
      username: username || this.currentUser.username,
      selectedElements,
      selectedParameterPreset,
      selectedSelectionHeuristic
    });
  }

  /**
   *
   * @param {Object} arg
   * @param {string} arg.designId ID of the new design with the crickitized part
   * @param {string} arg.error The error message.
   */
  handleProcessedCrickitResponse({ error, designId }) {
    if (error) window.toastr.error(processErrorMessage(error));
    window.toastr.success(
      `Design with id ${designId} has finished crickit optimization`
    );
  }

  /**
   * We will receive this message once all of the j5 runs for a given design
   * are complete.
   */
  handleDesignAssemblyComplete({
    designId,
    j5ReportId,
    success,
    errorMessage
  }) {
    if (success) {
      let link, linkText;
      if (j5ReportId) {
        link = modelNameToLink("j5Report", j5ReportId);
        linkText = "Open Report";
      }
      window.toastr.success("Submit for assembly completed.", {
        link,
        linkText
      });
      window.teGlobalStore.dispatch(
        actions.j5.submitDesignForAssemblyCompleted({ designId })
      );
    } else {
      console.error("errorMessage: ", errorMessage);
      if (errorMessage) {
        window.toastr.error(errorMessage);
      }
      window.teGlobalStore.dispatch(
        actions.j5.submitDesignForAssemblyFailed({ designId })
      );
    }
  }

  /**
   * Tell the server to submit the current design for assembly.
   * @param {string} designId
   */
  startJ5 = ({
    designId,
    lockedDesignId,
    removeInterruptedFeatures,
    designName //design name is only set when calling this from OVE
  }) => {
    this.socket.emit("startJ5", {
      designId,
      lockedDesignId,
      removeInterruptedFeatures
    });
    setTimeout(() => {
      window.toastr.success(
        <div className="tgSubmitForAssemblyToast">
          Submitting{" "}
          {designName ? (
            <a href={fullUrl(`/designs/${designId}`)}>{designName}</a>
          ) : (
            ""
          )}{" "}
          for assembly
        </div>
      );
    }, 1500); // delay a bit to ensure socket message has been sent. TODO wait for confirmation from backend
  };

  /**
   * Tell the server that we have opened a design. This will get the server
   * to create a context.
   * @param {string} designId
   */
  async openDesign(designId) {
    await this.register(designId);
    const socket = this.socket;
    return new Promise((resolve, reject) => {
      try {
        socket.emit("openDesign", { designId }, (error, data) => {
          if (error) {
            console.error("Error opening design", error);
            reject(new Error(error));
          } else resolve(data);
        });
      } catch (error) {
        console.error("Error opening design via socket", error);
        reject(error);
      }
    });
  }

  /**
   * Sync the design state with the one from the server, which
   * comes from the actual data in the database.
   * @param {string} designId
   */
  async syncDesign(designId) {
    return new Promise((resolve, reject) => {
      try {
        this.socket.emit("syncDesign", { designId }, (error, data) => {
          if (error) {
            console.error("Error syncing design", error);
            reject(new Error(error));
          } else resolve(data);
        });
      } catch (error) {
        console.error("Error syncing design via socket", error);
        reject(error);
      }
    });
  }

  /**
   * Tell the user that we closed a design. This will get the
   * server to close the context associated with the design.
   * @param {string} designId
   */
  closeDesign(designId) {
    console.info("Socket closing design: " + designId);
    // We set this registered flag before initiating the
    // shutdown process. If this flag gets set back to true
    // before the shutdown process completes then we won't disconnect
    // the socket.
    this.registered = false;
    this.socket.emit(
      "closeDesign",
      {
        designId
      },
      data => {
        console.info("Closed design on server", data);
        if (!this.registered) {
          console.info("Closing design socket");
          this.socket.disconnect();
        } else {
          console.warn(
            "Not closing design socket because a design is still registered"
          );
        }
      }
    );
  }

  /**
   * Tell the server that we have performed the given changes.
   */
  updateDesign({ designId, updates, creates, deletes }) {
    return new Promise((resolve, reject) => {
      this.socket.emit(
        "updateDesign",
        {
          designId,
          diff: {
            updates,
            creates,
            deletes
          }
        },
        (error, data) => {
          if (error) {
            //tnrtodo: we need to decide if this is where we want to do the error handling for design saving issues or if we want the handling to be in sagas/saveDesign/index.js
            console.error("error 89129879187:", error);
            reject(error);
          } else resolve(data);
        }
      );
    });
  }
}

// This ES6 Pattern ensures singleton for constructed Class
export const socketWrapper = new SocketWrapper();
