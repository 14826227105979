/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect } from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import {
  DataTable,
  DialogFooter,
  BlueprintError,
  wrapDialog,
  IntentText
} from "@teselagen/ui";
import { uniq } from "lodash";
import uploadDnaSequences from "../../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import { setActiveLab } from "../utils/labUtils";
import { safeQuery, safeUpsert } from "../apolloMethods";
import { throwFormError } from "../utils/formUtils";
import UploadOrPasteSequenceFields from "./UploadOrPasteSequenceFields";
import GenericSelect from "../GenericSelect";
import { dateModifiedColumn } from "../utils/libraryColumns";
import TagField from "../TagField";
import pluralIfNeeded from "../utils/pluralIfNeeded";
import modelNameToLink from "../utils/modelNameToLink";
import { openInNewTab } from "../utils/generalUtils";
import routeDoubleClick from "../utils/routeDoubleClick";
import withQuery from "../withQuery";
import { Link } from "react-router-dom";
import { isDesign } from "../../../tg-iso-shared/src/utils/isModule";

const UploadDNASequenceDialog = props => {
  const {
    error,
    genomeId,
    handleSubmit,
    hideModal,
    isGenomicRegionUpload,
    isOligo,
    isRNA,
    isGuideRNA,
    isMaterial,
    refetch,
    rnaTypes = [],
    submitting
  } = props;

  const [casEnzymes, setCasEnzymes] = useState([]);
  const [fileUpload, setFileUpload] = useState(true);
  const [isManualPasteScaffold, setIsManualPasteScaffold] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);

  useEffect(() => {
    if (isGuideRNA && !fileUpload) {
      const getEnzymes = async () => {
        const tempCasEnzymes = await safeQuery(
          ["enzyme", "id name scaffoldSequence { id sequence }"],
          { variables: { reagentEnzymeTypeCode: "CAS" } }
        );
        setCasEnzymes(tempCasEnzymes);
      };
      getEnzymes();
    }
  }, [isGuideRNA, fileUpload]);

  const onSubmit = async values => {
    const {
      labId,
      sequenceUpload,
      sequenceName,
      spacerSequence,
      scaffoldSequence,
      tags
    } = values;

    let { rnaTypeId, sequenceText } = values;
    if (isGuideRNA) {
      rnaTypeId = "&sys-guide-rna";
      if (!fileUpload) {
        if (isManualPasteScaffold) {
          sequenceText = spacerSequence + scaffoldSequence;
        } else {
          sequenceText = spacerSequence + scaffoldSequence.sequence;
        }
      }
    }

    let sequenceTypeCode = values.sequenceTypeCode;
    if (isOligo) {
      sequenceTypeCode = "OLIGO";
    }
    const isFileUpload = fileUpload;
    try {
      let genomes = values.genomes || [];
      const {
        createdMaterials,
        duplicateSequences,
        createdSequences,
        duplicatesOfInputSequences,
        existingSequences,
        warnings,
        importCollectionId,
        userCancelled
      } = await uploadDnaSequences({
        isFileUpload,
        isGenomicRegionUpload,
        isGuideRNA,
        isMaterial,
        isRNA,
        labId,
        promptForDuplicates: isDesign(),
        rnaTypeId,
        scaffoldSequenceStart: spacerSequence?.length,
        scaffoldSequenceId: !isManualPasteScaffold && scaffoldSequence?.id,
        scaffoldSequence: isManualPasteScaffold && scaffoldSequence,
        sequenceNames: [sequenceName],
        sequenceTexts: [sequenceText],
        sequenceUpload,
        sequenceTypeCode,
        setActiveLab,
        tags
      });
      if (userCancelled) {
        return;
      }

      if (isGenomicRegionUpload) {
        if (genomeId) {
          genomes = [
            {
              id: genomeId
            }
          ];
        }
        if (genomes.length) {
          const genomeGenomicRegions = [];
          const allSequenceIds = uniq(
            createdSequences.concat(existingSequences).map(s => s.id)
          );
          genomes.forEach(genome => {
            allSequenceIds.forEach(sequenceId => {
              genomeGenomicRegions.push({
                genomeId: genome.id,
                genomicRegionId: sequenceId
              });
            });
          });
          await safeUpsert("genomeGenomicRegion", genomeGenomicRegions);
        }
      }

      await refetch();

      if (
        createdMaterials.length ||
        createdSequences.length ||
        duplicateSequences.length ||
        duplicatesOfInputSequences.length
      ) {
        setSubmittedData({
          warnings,
          importCollectionId,
          createdMaterials,
          createdSequences,
          duplicateSequences,
          duplicatesOfInputSequences
        });
      } else {
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error uploading sequences");
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <div style={{ marginBottom: "10px", textAlign: "center" }}>
            {(isRNA || isGuideRNA) && (
              <i>
                Pasted or uploaded DNA sequences will be transcribed to RNA.
              </i>
            )}
          </div>
          <UploadOrPasteSequenceFields
            casEnzymes={casEnzymes}
            isRequired
            isFileUpload={fileUpload}
            isManualPasteScaffold={isManualPasteScaffold}
            isGuideRNA={isGuideRNA}
            isRNA={isRNA}
            isMaterial={isMaterial}
            toggleFileUpload={() => setFileUpload(!fileUpload)}
            toggleManualPasteScaffold={() =>
              setIsManualPasteScaffold(!isManualPasteScaffold)
            }
            rnaTypes={rnaTypes}
          />
          {isGenomicRegionUpload && !genomeId && (
            <GenericSelect
              asReactSelect
              fragment={["genome", "id name updatedAt"]}
              isMultiSelect
              label="Select Genomes"
              name="genomes"
              schema={[
                {
                  path: "name"
                },
                dateModifiedColumn
              ]}
            />
          )}
          <TagField />
          <BlueprintError error={error} />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  };

  const renderTable = () => {
    const {
      createdMaterials = [],
      createdSequences = [],
      duplicateSequences = [],
      duplicatesOfInputSequences = [],
      importCollectionId,
      warnings = []
    } = submittedData;

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {!!duplicatesOfInputSequences.length && (
            <>
              <h6 className="header-margin">
                Duplicate sequence files were found on import and will not be
                uploaded (aliases will be created where needed).
              </h6>
              <DataTable
                isSimple
                maxHeight={300}
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="dnaMaterialUploadDuplicateSequenceTable"
                entities={duplicatesOfInputSequences}
              />
              <br />
            </>
          )}
          {!!duplicateSequences.length && (
            <>
              <h6 className="header-margin">
                {`Duplicated ${pluralIfNeeded(
                  "sequence",
                  duplicateSequences
                )} detected and will not be imported (aliases will be created where needed).`}
              </h6>
              <DataTable
                isSimple
                maxHeight={300}
                schema={[
                  {
                    displayName: "Sequence in Upload",
                    path: "name"
                  },
                  {
                    displayName: "Sequence in Inventory",
                    path: "duplicateFound.name"
                  }
                ]}
                formName="dnaMaterialUploadExistingSequenceTable"
                entities={duplicateSequences}
                onDoubleClick={record => {
                  const itemUrl = modelNameToLink(
                    record.duplicateFound.__typename,
                    record.duplicateFound.id
                  );
                  openInNewTab(itemUrl);
                }}
              />
              <br />
            </>
          )}
          {!!createdSequences.length && (
            <>
              <h6 className="header-margin">
                {`Created ${createdSequences.length} New ${pluralIfNeeded(
                  "Sequence",
                  createdSequences
                )}`}
              </h6>
              {importCollectionId ? (
                <Link to={`/import-collections/${importCollectionId}`}>
                  View Import Collection
                </Link>
              ) : null}
              <DataTable
                isSimple
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="dnaMaterialUploadSequenceCreatedSequencesTable"
                entities={createdSequences}
                onDoubleClick={routeDoubleClick}
              />
              <br />
            </>
          )}
          {!!createdMaterials.length && (
            <>
              <h6 className="header-margin">
                {`Created ${createdMaterials.length} New ${pluralIfNeeded(
                  "Material",
                  createdMaterials
                )}`}
              </h6>
              <DataTable
                isSimple
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="dnaMaterialUploadSequenceTable"
                entities={createdMaterials}
                onDoubleClick={routeDoubleClick}
              />
            </>
          )}
          {warnings.map((warning, i) => {
            return (
              <div key={i} style={{ marginBottom: 5 }}>
                <IntentText intent="warning">{warning}</IntentText>
              </div>
            );
          })}
        </div>
        <DialogFooter noCancel onClick={hideModal} text="OK" />
      </div>
    );
  };

  return submittedData ? renderTable() : renderForm();
};

export default compose(
  wrapDialog(props => {
    let title = "Upload Sequences";
    if (props.isGenomicRegionUpload) {
      title = "Upload Genomic Regions";
    } else if (props.isOligo) {
      title = "Upload Oligos";
    } else if (props.isMaterial && props.isRNA) {
      title = "Upload RNA Materials";
    } else if (props.isMaterial) {
      title = "Upload DNA Materials";
    } else if (props.isGuideRNA) {
      title = "Upload guide RNA Sequences";
    } else if (props.isRNA) {
      title = "Upload RNA Sequences";
    }
    return {
      title
    };
  }),
  reduxForm({
    form: "UploadDNASequenceDialog"
  }),
  withQuery(["rnaType", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(UploadDNASequenceDialog);
