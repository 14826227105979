/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { reduce, flatten, map } from "lodash";

export const cartesianProductOf = items => {
  return reduce(
    items,
    function(a, b) {
      return flatten(
        map(a, function(x) {
          return map(b, function(y) {
            return x.concat([y]);
          });
        }),
        true
      );
    },
    [[]]
  );
};
