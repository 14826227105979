/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { sortBy, times, isString, get } from "lodash";
import { isBrowser } from "browser-or-node";
import Big from "big.js";

import { standardizeVolume } from "./unitUtils";
import { getAliquotContainerLocation } from "./getAliquotContainerLocation";

export function generateContainerArray(
  entities,
  containerFormat,
  otherColumns
) {
  const wells = generateEmptyWells(containerFormat, otherColumns);
  const { columnCount } = containerFormat;
  entities.forEach(entity => {
    wells[entity.rowPosition * columnCount + entity.columnPosition] = entity;
  });
  return wells;
}

export function generateEmptyWells(
  { rowCount, columnCount },
  otherColumns = {}
) {
  const numWells = rowCount * columnCount;
  let column = 0,
    row = -1;
  return times(numWells, () => {
    if (column % columnCount === 0) row++;
    return {
      columnPosition: column++ % columnCount,
      rowPosition: row,
      ...otherColumns
    };
  });
}

export function getUploadAliquotContainers({
  newAliquotContainers,
  containerArrayType,
  shouldFillRack,
  aliquotContainerType,
  numTubesToFillRack
}) {
  const addBarcode = ac => {
    if (ac.barcode && !ac.name) {
      return {
        ...ac,
        name: ac.barcode.barcodeString
      };
    } else {
      return ac;
    }
  };
  const containerFormat = containerArrayType.containerFormat;
  if (containerArrayType.isPlate) {
    return generateContainerArray(newAliquotContainers, containerFormat, {
      aliquotContainerTypeCode: containerArrayType.aliquotContainerType.code
    });
  } else if (shouldFillRack) {
    let aliquotContainers = generateContainerArray(
      newAliquotContainers,
      containerFormat,
      {
        aliquotContainerTypeCode: aliquotContainerType.code
      }
    ).map(addBarcode);
    if (numTubesToFillRack && numTubesToFillRack < aliquotContainers.length) {
      aliquotContainers = aliquotContainers.slice(0, numTubesToFillRack);
    }
    return aliquotContainers;
  } else {
    return newAliquotContainers.map(addBarcode);
  }
}

export function get2dLocationFromLocationString(location, containerFormat) {
  const position = getPositionFromAlphanumericLocation(
    location,
    containerFormat
  );
  return getAliquotContainerLocation(position, {
    containerFormat,
    force2D: true
  });
}

export function getPositionFromAlphanumericLocation(
  egA1orD4 = "",
  containerFormat
) {
  // can be a numeric position for boxes
  if (Number(egA1orD4)) {
    if (!containerFormat) {
      throw new Error(
        "Cannot determine numeric well position without containerFormat"
      );
    }
    const { columnCount } = containerFormat;
    let columnPosition = (egA1orD4 % columnCount) - 1;
    if (egA1orD4 % columnCount === 0) {
      columnPosition = columnCount - 1;
    }
    return {
      rowPosition: Math.floor((egA1orD4 - 1) / columnCount),
      columnPosition
    };
  } else {
    const [letter, ...number] = egA1orD4.trim().split("");
    return {
      rowPosition: letter.toLowerCase().charCodeAt(0) - 97,
      columnPosition: number.join("") - 1
    };
  }
}

export function getNormalizedAliquotContainerPosition(location) {
  /** will return A1 when given A1 or 1 */
  const position = getPositionFromAlphanumericLocation(location);
  const location2d = getAliquotContainerLocation(position, {
    force2D: true
  });
  return location2d;
}

export function wellInBounds(_wellLocation = "", containerFormat) {
  const { rowCount, columnCount } = containerFormat;
  let wellLocation = _wellLocation;
  if (!wellLocation) return false;
  if (isString(_wellLocation)) {
    wellLocation = getPositionFromAlphanumericLocation(
      _wellLocation,
      containerFormat
    );
  }
  const { rowPosition, columnPosition } = wellLocation;
  if (isNaN(rowPosition) || isNaN(columnPosition)) return false;
  const tooBig = rowPosition + 1 > rowCount || columnPosition + 1 > columnCount;
  const tooSmall = rowPosition < 0 || columnPosition < 0;
  return !tooBig && !tooSmall;
}

export async function validatePlateUploadFormat(
  data,
  containerArrayType,
  wellPositionHeader,
  { alwaysThrow } = {}
) {
  const { containerFormat } = containerArrayType;

  let mismatchedType = false;
  const plateLocationTracker = {};
  for (const [index, row] of data.entries()) {
    const location = row[wellPositionHeader];
    if (!location) {
      throw new Error(`Row ${index + 1} did not specify a location.`);
    }
    if (
      !mismatchedType &&
      containerFormat.is2DLabeled &&
      !isNaN(Number(location))
    ) {
      mismatchedType = true;
    }
    if (
      !mismatchedType &&
      !containerFormat.is2DLabeled &&
      isNaN(Number(location))
    ) {
      mismatchedType = {
        rowIndex: index,
        location
      };
    }
    if (!wellInBounds(location, containerFormat)) {
      throw new Error(
        `Row with well location ${location} does not fit into the selected plate format.`
      );
    } else {
      const {
        rowPosition,
        columnPosition
      } = getPositionFromAlphanumericLocation(location, containerFormat);
      row.rowPosition = rowPosition;
      row.columnPosition = columnPosition;
      const rowKey = row.rowKey;
      if (rowKey) {
        const cleanedLocation = getAliquotContainerLocation({
          rowPosition,
          columnPosition
        });
        plateLocationTracker[rowKey] = plateLocationTracker[rowKey] || [];
        if (plateLocationTracker[rowKey].includes(cleanedLocation)) {
          const [barcode, name] = rowKey.split(":");
          let readableKey = "";
          if (name) readableKey += name;
          if (barcode) readableKey += (name ? " " : "") + `(${barcode})`;
          throw new Error(
            `There are two rows mapped to the location ${location} for plate ${readableKey}. Please make sure each plate only maps one row to each well.`
          );
        }
        plateLocationTracker[rowKey].push(cleanedLocation);
      }
    }
  }
  if (mismatchedType) {
    const is2d = containerFormat.is2DLabeled;
    let message = `The specified plate type uses ${
      is2d ? "alphanumeric" : "numeric"
    } locations, but the upload contains ${
      is2d ? "numeric" : "alphanumeric"
    } locations`;
    if (isBrowser && !alwaysThrow) {
      message += ", are you sure you would like to continue?";

      return await window.showConfirmationDialog({
        text: message,
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      });
    } else {
      throw new Error(message);
    }
  } else {
    return true;
  }
}

const checkPlateCsvForMissingBarcodes = async (data, barcodeKey, filename) => {
  // if we are calling this from the api then no need to check
  if (!isBrowser) return true;
  const rowsMissingBarcodes = [];
  for (const [index, row] of data.entries()) {
    const barcode = row[barcodeKey];
    if (!barcode) {
      rowsMissingBarcodes.push(index + 1);
    }
  }
  if (rowsMissingBarcodes.length) {
    return await window.showConfirmationDialog({
      text: `These rows ${
        filename ? `in file "${filename}"` : ""
      } did not specify ${barcodeKey}: ${rowsMissingBarcodes.join(
        ", "
      )}. \n\nWould you like to continue the upload?`,
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
  } else {
    return true;
  }
};

export const overwriteBarcodeCheck = async (data, barcodeKey) => {
  // if we are calling this from the api then no need to check
  if (!isBrowser) return true;

  const rowsWithBarcodes = [];
  for (const [index, row] of data.entries()) {
    if (row[barcodeKey]) {
      rowsWithBarcodes.push(index);
    }
  }
  if (rowsWithBarcodes.length) {
    return await window.showConfirmationDialog({
      text:
        "Barcodes on input sheet will be overwritten, do you wish to proceed?",
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
  } else {
    return true;
  }
};

export const maxWellVolumeError = ({
  volume,
  unit,
  containerArrayType,
  aliquotContainerType: maybeAliquotContainerType,
  index
}) => {
  const aliquotContainerType =
    maybeAliquotContainerType || containerArrayType.aliquotContainerType;
  if (
    standardizeVolume(volume, unit) >
    standardizeVolume(
      aliquotContainerType.maxVolume,
      aliquotContainerType.volumetricUnitCode
    )
  ) {
    return `Row ${index + 1} specifies the total volume ${volume} ${unit.code ||
      unit} which is greater than the max well volume of ${
      aliquotContainerType.maxVolume
    } ${aliquotContainerType.volumetricUnitCode}.`;
  }
};

export async function checkBarcodesAndFormat({
  data,
  generateTubeBarcodes = false,
  isTubeUpload = false,
  filename,
  containerArrayType,
  tubeBarcodeKey = "TUBE_BARCODE",
  barcodeKey = "PLATE_BARCODE",
  wellPositionHeader = "WELL_LOCATION"
}) {
  if (generateTubeBarcodes) {
    const continueOverwriteBarcodes = await overwriteBarcodeCheck(
      data,
      tubeBarcodeKey
    );
    if (!continueOverwriteBarcodes) return false;
  }

  if (!isTubeUpload) {
    const continueUpload = await checkPlateCsvForMissingBarcodes(
      data,
      barcodeKey,
      filename
    );
    if (!continueUpload) return false;
    const continueAfterFormatCheck = await validatePlateUploadFormat(
      data,
      containerArrayType,
      wellPositionHeader
    );
    if (!continueAfterFormatCheck) return false;
  }

  return true;
}

// eventually we'll want to refactor this function to include plasmids from strain as well
export const getMicrobialMaterialName = (strainName, plasmidName) => {
  const plasmidNameToUse = Array.isArray(plasmidName)
    ? plasmidName.join(", ")
    : plasmidName;
  if (plasmidNameToUse) {
    return `${strainName} (${plasmidNameToUse})`;
  } else {
    return `${strainName}`;
  }
};

export function getMaterialIdsFromAliquotContainer(ac) {
  const materialId =
    get(ac, "aliquot.sample.materialId") ||
    get(ac, "aliquot.sample.material.id");
  if (materialId) {
    return [materialId];
  } else {
    const sampleFormulations = get(ac, "aliquot.sample.sampleFormulations", []);
    const materialIds = [];
    if (sampleFormulations.length) {
      sampleFormulations.forEach(form => {
        form.materialCompositions.forEach(comp => {
          const materialId = comp.materialId || get(comp, "material.id");
          if (materialId) {
            materialIds.push(materialId);
          }
        });
      });
    }
    return materialIds;
  }
}

export function getReagentIdsFromAliquotContainer(ac) {
  return Object.keys(getKeyReagentsFromAliquotContainer(ac));
}

export function getKeyMaterialsFromAliquotContainer(ac) {
  const material = get(ac, "aliquot.sample.material");

  if (material) {
    return {
      [material.id]: material
    };
  } else {
    const sampleFormulations = get(ac, "aliquot.sample.sampleFormulations", []);
    const materials = {};
    if (sampleFormulations.length) {
      sampleFormulations.forEach(form => {
        form.materialCompositions.forEach(comp => {
          if (comp.material) {
            materials[comp.material.id] = comp.material;
          }
        });
      });
    }
    return materials;
  }
}

export function getKeyReagentsFromAliquotContainer(ac, additiveTarget) {
  const additives = ac.aliquot ? ac.aliquot.additives : ac.additives;
  if (!additives || !additives.length) return {};
  const keyed = {};
  additives.forEach(a => {
    if (a.additiveMaterial) {
      keyed[a.additiveMaterial.id] = additiveTarget ? a : a.additiveMaterial;
    } else if (a.lot && a.lot.additiveMaterial) {
      keyed[a.lot.additiveMaterial.id] = additiveTarget
        ? a
        : a.lot.additiveMaterial;
    }
  });
  return keyed;
}

export function getKeyAdditivesByReagentIdFromAliquotContainer(ac) {
  return getKeyReagentsFromAliquotContainer(ac, true);
}

export function getVolumeOfAliquotContainer(ac, big = false) {
  let acVolume = big ? new Big(0) : 0;
  if (ac.aliquot) {
    if (ac.aliquot.volume) {
      acVolume = standardizeVolume(
        ac.aliquot.volume,
        ac.aliquot.volumetricUnitCode,
        big
      );
    }
  } else {
    if (ac.additives) {
      ac.additives.forEach(additive => {
        if (additive.volume) {
          const volumeOfAdditive = standardizeVolume(
            additive.volume,
            additive.volumetricUnitCode,
            big
          );
          if (big) {
            acVolume = acVolume.add(volumeOfAdditive);
          } else {
            acVolume += volumeOfAdditive;
          }
        }
      });
    }
  }
  return acVolume;
}

export function getAliquotContainerSource(aliquotContainer) {
  const sourceEntity =
    get(aliquotContainer, "aliquot") || get(aliquotContainer, "additives[0]");
  return sourceEntity;
}

export function getAliquotContainerByLocation(plate, alphaNumericLocation) {
  if (!plate.containerArrayType) {
    throw new Error(
      "Cannot determine aliquot container location without plate type."
    );
  }
  const position = getPositionFromAlphanumericLocation(
    alphaNumericLocation,
    plate.containerArrayType.containerFormat
  );

  const matchingAliquotContainer = plate.aliquotContainers.find(
    ac =>
      position.rowPosition === ac.rowPosition &&
      position.columnPosition === ac.columnPosition
  );
  return matchingAliquotContainer;
}

export function getDeadVolumeOfAliquotContainer(
  ac,
  keyedAliquotContainerTypes
) {
  const type = keyedAliquotContainerTypes[ac.aliquotContainerTypeCode];
  return standardizeVolume(
    type.deadVolume || 0,
    type.deadVolumetricUnitCode || "uL",
    true
  );
}

export function getUsableVolumeOfAliquotContainer(
  ac,
  keyedAliquotContainerTypes
) {
  const type = keyedAliquotContainerTypes[ac.aliquotContainerTypeCode];
  const acVolume = getVolumeOfAliquotContainer(ac, true);
  return acVolume.minus(
    standardizeVolume(
      type.deadVolume || 0,
      type.deadVolumetricUnitCode || "uL",
      true
    )
  );
}

export function areAllAliquotContainersUnusableBecauseOfDeadVolume({
  allAliquotContainers,
  keyedAliquotContainerTypes
}) {
  let hasSomeVol = false;
  const allDead = allAliquotContainers.every(ac => {
    const vol = getVolumeOfAliquotContainer(ac, false);
    const deadVol = getDeadVolumeOfAliquotContainer(
      ac,
      keyedAliquotContainerTypes
    );
    if (!vol) return true;
    else {
      hasSomeVol = true;
      return vol < deadVol;
    }
  });
  return hasSomeVol && allDead;
}

export function sortAliquotContainers(aliquotContainers, order) {
  const sortedAcs = sortBy(
    [...aliquotContainers],
    order === "columnFirst"
      ? ["columnPosition", "rowPosition"]
      : ["rowPosition", "columnPosition"]
  );
  return sortedAcs;
}

export function sortToLocationStrings(aliquotContainers, order) {
  return sortAliquotContainers(aliquotContainers, order).map(
    getAliquotContainerLocation
  );
}

export function getPlateLocationMap(plate, { onlyAliquots } = {}) {
  const locationMap = {};
  plate.aliquotContainers.forEach(ac => {
    if (onlyAliquots && !ac.aliquot) return;
    const location = getAliquotContainerLocation(ac, { force2D: true });
    locationMap[location] = ac;
  });
  return locationMap;
}

export function getTypeOfContainerArray(containerArrayType) {
  if (!containerArrayType || containerArrayType.isPlate) {
    return "plate";
  }
  if (get(containerArrayType, "containerFormat.is2DLabeled")) {
    return "rack";
  }
  return "box";
}
