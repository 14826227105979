/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default [
  {
    path: "id",
    displayName: "Id"
  },
  {
    path: "name",
    displayName: "Name"
  },
  {
    path: "j5TargetParts",
    displayName: "Target Parts",
    render: j5TargetParts =>
      j5TargetParts.map(j5tp => j5tp.j5InputPart.part.name).join(", ")
  },
  {
    path: "oligo.sequence.size",
    displayName: "Size"
  },
  {
    path: "cost",
    displayName: "Cost"
  },
  {
    path: "tm",
    displayName: "Tm"
  },
  {
    path: "oligo.sequence.sequenceFragments[0].fragment",
    displayName: "Bps"
  },
  {
    path: "tm3Prime",
    displayName: "Annealing Bps Tm"
  },
  {
    path: "sequence3Prime",
    displayName: "Annealing Bps"
  }
];
