/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon } from "@blueprintjs/core";
import "./style.css";
import { NavLink, withRouter } from "react-router-dom";
import cn from "classnames";
import { startsWith } from "lodash";

const HeaderLinkButton = ({ icon, name, navTo, activeOn, history }) => {
  const contentInner = (
    <span>
      <Icon icon={icon} />
      <span className="header-link-text">{name}</span>
    </span>
  );

  let isActive = false;
  activeOn = activeOn || (navTo && [navTo]);
  if (activeOn) {
    if (activeOn instanceof Array) {
      isActive = activeOn.some(str => {
        if (!str) return false;
        let windowPath = window.location.pathname;

        let pathStr = history.createHref({ pathname: str });
        if (!startsWith(pathStr, "/")) {
          pathStr = "/" + pathStr;
        }

        if (!startsWith(windowPath, "/")) {
          windowPath = "/" + windowPath;
        }

        return startsWith(windowPath, pathStr);
      });
    } else if (typeof activeOn === "function") {
      isActive = activeOn();
    } else {
      console.error(
        "activeOn should either be an array of path substrings or a function"
      );
    }
  }

  return (
    <div
      id={navTo ? navTo : name}
      className={cn("header-link-button", { active: isActive })}
    >
      {navTo ? (
        <NavLink
          exact
          activeClassName="active"
          to={navTo}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {contentInner}
        </NavLink>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a style={{ color: "inherit", textDecoration: "none" }}>
          {contentInner}
        </a>
      )}
    </div>
  );
};

export default withRouter(HeaderLinkButton);
