/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5OligoDownloadFragment on j5OligoSynthesis {
    id
    cost
    name
    tm
    tm3Prime
    sequence3Prime
    j5TargetParts {
      id
      j5InputPart {
        id
        part {
          id
          name
        }
      }
    }
    oligo {
      id
      name
      sequence {
        id
        sequenceFragments {
          id
          fragment
        }
        size
      }
    }
  }
`;
