/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { TgEditableTable } from "./TgEditableTable";
import GenericSelect from "../GenericSelect";

function EditableTableWGenericSelect() {
  return (
    <div style={{ padding: 40 }}>
      <GenericSelect
        {...{
          asReactSelect: true,
          isMultiSelect: true,
          name: "material1",
          isRequired: true,
          label: "Material 1",
          schema: ["name"],
          fragment: ["material", "id name"]
        }}
      />

      <TgEditableTable
        schema={{
          fields: [
            {
              path: "name",
              type: "dropdown",
              isRequired: true,
              values: ["a", "b", "c"]
            },
            {
              path: "weather",
              //should auto validate against list of accepted values, should auto format to try to coerce input values into accepted
              type: "dropdown",
              description: "What it's like outside",
              // defaultValue: "sunny",
              values: ["cloudy", "rainy", "sunny", "overcast"]
            },
            {
              path: "sequences",
              type: "genericSelect",
              isRequired: true,
              isMultiSelect: true,
              schema: [
                "name",
                {
                  type: "number",
                  path: "size"
                }
              ],
              fragment: ["sequence", "name size id"]
            },
            {
              path: "parts",
              type: "genericSelect",
              isRequired: true,
              isMultiSelect: true,
              schema: ["name"],
              fragment: ["part", "id name"]
            }
          ]
        }}
      ></TgEditableTable>
    </div>
  );
}
export default compose(
  reduxForm({
    form: "test-page-2"
  })
  // getEditableTableValidation
)(EditableTableWGenericSelect);
