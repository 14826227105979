/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import pluralize from "pluralize";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import { pick, get, round } from "lodash";
import AbstractRecord from "../AbstractRecord";

import modelNameToLink from "../utils/modelNameToLink";
import TgSequenceEditor, { seqEdEnhancer } from "../TgSequenceEditor";

import MicrobialMaterialCard from "./MicrobialMaterialCard";
import { tagColumnWithRenderNested } from "../utils/tagColumn";
import { showDialog } from "../GlobalDialog";
import { molecularWeightRender } from "../utils/unitUtils";
import GenomeCard from "./GenomeCard";
import { getEnclosingFeatures } from "../utils/sequenceUtils";
import { isBuild } from "../../../tg-iso-shared/src/utils/isModule";
import UpdateSequenceDialog from "../components/Dialogs/UpdateSequenceDialog";
import OligoBindingSitesCard from "./OligoBindingSitesCard";
import { annotationSizeStartEndColumns } from "../utils/libraryColumns";
import { flatMap } from "lodash";
import { safeUpsert } from "../apolloMethods";
import { getMaterialFields } from "../../../tg-iso-shared/src/sequence-import-utils/getMaterialFields";
// import SequenceBPS from "./SequenceBPS";

class DNASequenceRecordView extends Component {
  state = {};

  renderLinkOrNotFound({ sequence, path, route, render }) {
    const routeToUse = route ? route : pluralize(path);
    const item = get(sequence, path);
    return item ? (
      <Link to={`/${routeToUse}/${item.id}`}>
        {render ? render(item) : item.name}
      </Link>
    ) : (
      "Not Found"
    );
  }

  updateShowFunction = () => {
    const { sequence, refetchSequence: refetch } = this.props;
    showDialog({
      ModalComponent: UpdateSequenceDialog,
      modalProps: {
        refetch,
        initialValues: {
          ...pick(sequence, ["id", "name", "description", "sequenceTypeCode"]),
          inductionMethodIds:
            sequence.plasmidInductionMethodPlasmids &&
            sequence.plasmidInductionMethodPlasmids.map(
              pim => pim.inductionMethod.id
            )
        },
        plasmidInductionMethods: sequence.plasmidInductionMethodPlasmids
      }
    });
  };

  onCdsDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.codingDnaSequence.id}`);
  };

  onPlasmidDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.sequence.id}`);
  };

  onProteinDoubleClick = record => {
    this.props.history.push(
      `/functional-protein-units/${record.functionalProteinUnit.id}`
    );
  };

  onSeqFeatDoubleClick = record => {
    this.props.history.push(`/sequence-features/${record.id}`);
  };

  onMicrobialMaterialDblClick = record => {
    this.props.history.push(`/microbial-materials/${record.id}`);
  };

  render() {
    const { sequence, fragment, location } = this.props;
    if (
      sequence?.sequenceTypeCode === "RNA" &&
      sequence?.rnaType?.name === "gRNA"
    ) {
      return (
        <Redirect
          to={{
            ...location,
            pathname: `/guide-rna-sequences/${sequence.id}`
          }}
        />
      );
    }
    const codingDnaSequences = (
      <CollapsibleCard title="Coding Sequences" key="CDS">
        <DataTable
          entities={sequence.sequenceCodingSequences}
          isSimple
          schema={CDSSchema}
          formName="sequenceCodingSequenceForm"
          onDoubleClick={this.onCdsDoubleClick}
        />
      </CollapsibleCard>
    );
    const plasmidsCard = (
      <CollapsibleCard key="plasmids" title="Source Sequences">
        <DataTable
          entities={sequence.codingDnaSequenceSequenceCodingSequences}
          isSimple
          schema={plasmidSchema}
          formName="plasmidForm"
          onDoubleClick={this.onPlasmidDoubleClick}
        />
      </CollapsibleCard>
    );
    const functionalProteinUnits = (
      <CollapsibleCard key="fpus" title="Relevant  Functional Protein Units">
        <DataTable
          entities={sequence.sequenceFpus}
          isSimple
          schema={FPUSchema}
          formName="sequenceFpuForm"
          onDoubleClick={this.onProteinDoubleClick}
        />
      </CollapsibleCard>
    );
    const sequenceFeaturesCard = (
      <CollapsibleCard key="sequenceFeatures" title="Sequence Features">
        <DataTable
          entities={sequence.sequenceFeatures.map(feat => ({
            ...feat,
            sequence
          }))}
          isSimple
          schema={seqFeatureSchema}
          formName="seqFeatForm"
          onDoubleClick={this.onSeqFeatDoubleClick}
        />
      </CollapsibleCard>
    );

    const partsCard = (
      <CollapsibleCard key="parts" title="Sequence Parts">
        <DataTable
          entities={sequence.parts}
          isSimple
          schema={partSchema}
          formName="partForm"
        />
      </CollapsibleCard>
    );
    const additionalCards = [];
    if (
      sequence.sequenceCodingSequences &&
      sequence.sequenceCodingSequences.length
    ) {
      additionalCards.push(codingDnaSequences);
    }
    if (
      sequence.codingDnaSequenceSequenceCodingSequences &&
      sequence.codingDnaSequenceSequenceCodingSequences.length
    ) {
      additionalCards.push(plasmidsCard);
    }
    if (sequence.sequenceFpus && sequence.sequenceFpus.length) {
      additionalCards.push(functionalProteinUnits);
    }
    if (sequence.sequenceFeatures && sequence.sequenceFeatures.length) {
      additionalCards.push(sequenceFeaturesCard);
    }
    if (sequence.parts && sequence.parts.length) {
      additionalCards.push(partsCard);
    }
    if (sequence.sequenceTypeCode === "GENOMIC_REGION") {
      additionalCards.push(
        <GenomeCard key="genomes" sequenceId={sequence.id} />
      );
    }

    if (
      sequence.polynucleotideMaterial
        ?.polynucleotideMaterialMicrobialMaterialPlasmids &&
      sequence.polynucleotideMaterial
        ?.polynucleotideMaterialMicrobialMaterialPlasmids.length
    ) {
      additionalCards.push(
        <MicrobialMaterialCard
          key="microbialMaterials"
          sequenceId={sequence.id}
        />
      );
    }
    if (sequence.sequenceTypeCode === "OLIGO") {
      additionalCards.push(
        <OligoBindingSitesCard
          key="oligoBindingSites"
          sequenceId={sequence.id}
        />
      );
    }
    const sequenceInfo = [
      ["Name", sequence.name],
      ["Size", sequence.size],
      //tnr: no longer needed since we improved the isOligo sequence preview handling
      // ...(sequence.sequenceTypeCode === "OLIGO"
      //   ? [
      //       [
      //         "Sequence",
      //         <SequenceBPS key="sequenceBPS" sequenceId={sequence.id} />
      //       ]
      //     ]
      //   : []),
      ["Sequence Type", get(sequence, "sequenceType.name")],
      ["Read Only", isBuild() || get(sequence, "isJ5Sequence") ? "Yes" : "No"],
      ["Molecular Weight", molecularWeightRender(sequence.molecularWeight)],
      sequence.description && ["Description", sequence.description],
      sequence.sequenceTypeCode === "RNA" &&
        sequence.rnaType && [
          "RNA Type",
          sequence.rnaType && sequence.rnaType.name
        ],
      sequence.plasmidInductionMethodPlasmids &&
        sequence.plasmidInductionMethodPlasmids.length > 0 && [
          "Induction Methods:",
          sequence.plasmidInductionMethodPlasmids
            .map(pim => pim.inductionMethod.name)
            .join(", ")
        ]
    ];
    if (isBuild()) {
      sequenceInfo.push([
        "DNA Material",
        sequence.polynucleotideMaterial ? (
          <Link
            key="dnaMaterialLink"
            to={modelNameToLink(sequence.polynucleotideMaterial)}
          >
            {sequence.polynucleotideMaterial.name}
          </Link>
        ) : (
          <a
            onClick={async () => {
              try {
                const [mat] = await safeUpsert("material", {
                  name: sequence.name,
                  ...getMaterialFields(false)
                });
                await safeUpsert(fragment, {
                  id: sequence.id,
                  polynucleotideMaterialId: mat.id
                });
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error creating material");
              }
            }}
          >
            Create DNA Material
          </a>
        )
      ]);
    }
    if (sequence.aminoAcidSequence && sequence.aminoAcidSequence.name) {
      sequenceInfo.push([
        "Amino Acid Sequence",
        this.renderLinkOrNotFound({
          sequence,
          path: "aminoAcidSequence",
          route: "amino-acid-sequences"
        })
      ]);
    }
    sequenceInfo.push([
      associatedDesignsToSeq.displayName,
      associatedDesignsToSeq.render(null, sequence)
    ]);

    return (
      <AbstractRecord
        {...this.props}
        recordInfo={sequenceInfo}
        recordName="sequence"
        updateShowFunction={this.updateShowFunction}
        additionalCards={additionalCards}
      >
        <TgSequenceEditor
          {...this.props}
          onPreviewModeFullscreenClose={() => {
            this.props.refetchRecord();
            window.refetchSequenceRecordBPS?.();
          }}
        />
      </AbstractRecord>
    );
  }
}

export default seqEdEnhancer(DNASequenceRecordView);

const CDSSchema = {
  model: "sequenceCodingSequence",
  fields: [
    {
      displayName: "Fragment Id",
      path: "codingDnaSequence.name"
    },
    {
      displayName: "Size",
      path: "codingDnaSequence.size",
      render: record => record + " bp"
    },
    tagColumnWithRenderNested("codingDnaSequence")
  ]
};

const FPUSchema = {
  model: "sequenceFpus",
  fields: [
    {
      displayName: "Name",
      path: "functionalProteinUnit.name"
    },
    {
      displayName: "Extinction Coefficient",
      path: "functionalProteinUnit.extinctionCoefficient"
    },
    {
      displayName: "Molecular Weight",
      path: "functionalProteinUnit.molecularWeight",
      render: value => {
        return value ? round(value, 2) : "";
      }
    },
    tagColumnWithRenderNested("functionalProteinUnit")
  ]
};

const plasmidSchema = {
  model: "codingDnaSequenceSequenceCodingSequence",
  fields: [
    {
      displayName: "Sequence Name",
      path: "sequence.name"
    },
    {
      displayName: "EOU Description",
      path: "eouDescription"
    },
    {
      displayName: "RBS Strength",
      path: "ribosomeBindingStrength"
    },
    tagColumnWithRenderNested("sequence")
  ]
};

const seqFeatureSchema = {
  model: "sequenceFeature",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Enclosing Features",
      render: (v, record) => {
        const enclosingFeatures = getEnclosingFeatures(record, record.sequence);
        return enclosingFeatures.map(f => f.name).join(", ");
      }
    },
    {
      displayName: "Type",
      path: "type"
    },
    ...annotationSizeStartEndColumns
  ]
};

const partSchema = {
  model: "part",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    ...annotationSizeStartEndColumns
  ]
};

export const associatedDesignsToSeq = {
  path: "parts.elements.design.name",
  type: "string",
  displayName: "Linked Designs",
  render: (undefinedArgument, seq) => {
    const toFilter = {};
    return (
      <>
        {flatMap(seq.parts, ({ elements }) => {
          return flatMap(elements, element => {
            if (!element?.design?.id) return [];
            if (toFilter[element.design.id]) return [];
            toFilter[element.design.id] = true;
            return [
              ", ",
              <span key={element.design.id}>
                <Link to={`/designs/${element.design.id}`}>
                  {element.design.name}
                </Link>
              </span>
            ];
          });
        }).slice(1)}
      </>
    );
  }
};
