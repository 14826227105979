/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { normalizeUserRecord } = require("./normalizeUserRecord");
const { getLoggedInFields } = require("./getLoggedInFields");
const {
  headers,
  userRoleCodes,
  labRoleCodes,
  BEARER_PREFIX,
  logDebug,
  getAllLabIdsFromContext,
  getLabIdFromContext,
  isSuperUser,
  isAdmin,
  isSystemUser,
  getUserFromContext,
  validateUserLabId,
  shouldIdBeNull,
  parseHeaders,
  getUserHighestRole
} = require("./ext-utils");

module.exports = {
  normalizeUserRecord,
  getLoggedInFields,
  headers,
  userRoleCodes,
  labRoleCodes,
  BEARER_PREFIX,
  logDebug,
  getAllLabIdsFromContext,
  getLabIdFromContext,
  isSuperUser,
  isAdmin,
  isSystemUser,
  getUserFromContext,
  validateUserLabId,
  shouldIdBeNull,
  parseHeaders,
  getUserHighestRole
};
