/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Redirect } from "react-router-dom";
import authRoutes from "../../authRoutes";
import RerouteToProperSequenceRecordView from "../../components/RerouteToProperSequenceRecordView";
import ToolLibrary from "../../ToolLibrary";
import { disabledFeaturesConfig } from "../../utils/generalUtils";
import { filterToolSchemasForLibrary } from "../toolUtils";
import makeLazy from "./makeLazy";

const initRoutes = (appRoutes, AppSettings, _toolSchemas) => {
  appRoutes.publicRoutes = {
    ...authRoutes,
    ...appRoutes.publicRoutes
  };

  let toolSchemas = _toolSchemas;
  if (toolSchemas) {
    toolSchemas = filterToolSchemasForLibrary(toolSchemas);
    appRoutes.toolSchemasMetadata = toolSchemas.reduce((acc, schema) => {
      const { title, toolSchema } = schema;
      acc.push({
        code: schema.code,
        title: title || toolSchema?.name,
        tabId: schema.tabId
      });
      return acc;
    }, []);
  }

  appRoutes.privateRoutes = {
    //common routes!
    ...((window.frontEndConfig.enableGraphiqlInspector ||
      window.Cypress?.enableGraphiqlInspector) && {
      "/graphiql": makeLazy(
        () => import("../../GraphIQL_Inspector/GraphIQL_Inspector")
      )
    }),
    "/notifications": makeLazy(
      () => import("../../NotificationUI/NotificationLibrary")
    ),
    "/settings": () => <Redirect to="/settings/profile" />,
    "/settings/:section/:subsection?": AppSettings,
    "/restriction-enzymes/:id": ({ match }) => (
      <Redirect
        to={`/settings/enzyme-management/enzymes?filters=id__equalTo__${match.params.id}`}
      />
    ),
    ...(!disabledFeaturesConfig.alignments
      ? {
          "/alignments": makeLazy(() => import("../../Library/Alignments")),
          "/alignments/new": makeLazy(
            () => import("../../AlignmentAddContainer")
          ),
          "/alignments/:id": makeLazy(
            () => import("../../AlignmentViewContainer")
          )
        }
      : {}),
    "/amino-acid-sequences": makeLazy(() => import("../../Library/AminoAcids")),
    "/import-collections": makeLazy(() => import("../../ImportCollections")),
    "/import-collections/:id": makeLazy(
      () =>
        import("../../TagAndWorkflowCollectionAndImportCollectionRecordView")
    ),
    "/tags": makeLazy(() => import("../../TagsLibrary")),
    "/tags/:id": makeLazy(
      () =>
        import("../../TagAndWorkflowCollectionAndImportCollectionRecordView")
    ),
    ...(toolSchemas && {
      "/tools": props => <ToolLibrary {...props} toolSchemas={toolSchemas} />,
      ...toolSchemas.reduce((acc, schema) => {
        const { Component, tabId } = schema;
        acc[`/tools/${tabId}`] = props => (
          <Component {...props} toolSchema={schema} />
        );
        return acc;
      }, {})
    }),
    "/sequences/:id": RerouteToProperSequenceRecordView,
    "/sequences": makeLazy(
      () => import("../../Library/Sequences/DNASequences")
    ),
    "/dna-sequences": makeLazy(
      () => import("../../Library/Sequences/DNASequences")
    ),
    "/protocols/:id": makeLazy(() => import("../../ProtocolRecordView")),
    "/protocols": makeLazy(() => import("../../Library/Protocols")),
    "/rna-sequences": makeLazy(
      () => import("../../Library/Sequences/RNASequences")
    ),
    "/restriction-enzymes": () => <Redirect to="/settings/enzyme-management" />,
    ...appRoutes.privateRoutes
  };
};

export { initRoutes };
