/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import QueryBuilder from "tg-client-query-builder";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "@teselagen/ui/style.css";
import "@teselagen/ove/style.css";
import store from "./redux/store";
import client from "../src-shared/apolloClient";
import Cookies from "js-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { FocusStyleManager } from "@blueprintjs/core";
import registerServiceWorker from "./registerServiceWorker";
import createIsoContext from "./createIsoContext";
import { download } from "./utils/downloadTest";
import { initLogger } from "./utils/initHelpers/initLogger";
// this will import trc styles
import "./bootstrap.css";
import "./flex-utils.css";
import "./sharedStyles.css";
import "./platform.css";
import { isAdmin, openInNewTab } from "./utils/generalUtils";
import shortid from "shortid";
import { DndProvider } from "react-dnd";
import MouseBackend from "react-dnd-mouse-backend";
import {
  safeUpsert,
  safeDelete,
  deleteWithQuery,
  safeQuery
} from "./apolloMethods";
import { setAuthToken } from "./auth/authTokenMethods";
import { initToolSchemas } from "./utils/toolUtils";
import { initRoutes } from "./utils/initHelpers/initRoutes";
import appGlobals from "./appGlobals";
import { initDependencyContainer } from "./utils/initHelpers/initDependencyContainer";
import { InitMobx } from "./utils/initHelpers/initMobx";

if (window.Cypress) {
  window.Cypress.downloadTest = download;
}
window.QueryBuilder = QueryBuilder;
initAuthToken();

createIsoContext();
FocusStyleManager.onlyShowFocusOnTabs();

const rootEl = document.getElementById("root");

if (window.location.search.match("azureAuthenticated=true")) {
  localStorage.setItem("authenticated", "true");
  localStorage.setItem("azureAuthenticated", "true");
}

if (window.location.search.match("samlAuthenticated=true")) {
  localStorage.setItem("authenticated", "true");
  localStorage.setItem("samlAuthenticated", "true");
}

if (window.location.search.match("ldapAuthenticated=true")) {
  localStorage.setItem("authenticated", "true");
  localStorage.setItem("ldapAuthenticated", "true");
}

registerServiceWorker();

// because we are switching to uuid's we need to fallback to nid for secondary table sorting
// trc query-params will use this
window.__sortId = "nid";

window.sessionStorage.setItem("uniqueTabId", shortid());

function initAuthToken() {
  const token = Cookies.get("auth-token");
  if (token) {
    Cookies.remove("appSession");
    setAuthToken(token);
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
      retryOnMount: true,
      // Default to no refetching automatically based on timers
      refetchInterval: false,
      refetchIntervalInBackground: false
    },
    mutations: {
      networkMode: "always"
    }
  }
});
/**
 *
 * @param {Object} options
 * @param {any=} options.mobXStore optional
 * @param {any=} options.modalComponents optional
 * @param {any=} options.mobXModalComponents optional
 * @param {any} options.Header required
 * @param {any} options.appRoutes required
 * @param {any} options.AppSettings required: lazy loaded component
 * @param {any=} options.appHotkeySets optional
 * @param {any=} options.className optional
 * @param {any=} options.Helper optional
 * @param {any=} options.toolSchemas optional
 * @param {any=} options.Alert optional
 */
const initApp = ({
  mobXStore,
  modalComponents,
  mobXModalComponents,
  Header,
  appRoutes,
  AppSettings,
  appHotkeySets,
  className,
  Helper,
  toolSchemas,
  Alert
}) => {
  initAuthToken();
  initDependencyContainer();
  if (toolSchemas) {
    initToolSchemas(toolSchemas);
  }
  initRoutes(appRoutes, AppSettings, toolSchemas);
  if (window.frontEndConfig.logToServer) {
    initLogger();
  }

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <DndProvider backend={MouseBackend}>
          <QueryClientProvider client={queryClient}>
            <InitMobx
              mobXStore={mobXStore}
              modalComponents={modalComponents}
              mobXModalComponents={mobXModalComponents}
              Header={Header}
              appRoutes={appRoutes}
              appHotkeySets={appHotkeySets}
              className={className}
              Helper={Helper}
              Alert={Alert}
            />
          </QueryClientProvider>
        </DndProvider>
      </Provider>
    </ApolloProvider>,
    rootEl
  );
};

export default initApp;

window.__showGenerateDefaultDot = () => {
  return isAdmin();
};

/**
 * This will make it easy to add link actions to our default toasts
 * @param {*} toastFn
 */
function addToastrLinkOptions(toastFn) {
  return (msg, options = {}, ...rest) => {
    const { link, linkText, target, ...passThroughOptions } = options;
    let action;
    if (link) {
      action = {
        text: linkText,
        onClick: e => {
          if (e.metaKey || target === "_blank") {
            openInNewTab(link);
          } else {
            appGlobals.history.push(link);
          }
        }
      };
    }
    return toastFn(
      msg,
      {
        action,
        ...passThroughOptions
      },
      ...rest
    );
  };
}

window.toastr.success = addToastrLinkOptions(window.toastr.success);
window.toastr.warning = addToastrLinkOptions(window.toastr.warning);
window.toastr.error = addToastrLinkOptions(window.toastr.error);
window.toastr.default = addToastrLinkOptions(window.toastr.default);
window.toastr.info = addToastrLinkOptions(window.toastr.info);

if (process.env.NODE_ENV !== "production") {
  window.safeUpsert = safeUpsert;
  window.safeDelete = safeDelete;
  window.deleteWithQuery = deleteWithQuery;
  window.safeQuery = safeQuery;
  window.deleteAll = async modelOrModels => {
    let models = modelOrModels;
    if (!Array.isArray(modelOrModels)) {
      models = [modelOrModels];
    }
    for (const model of models) {
      await deleteWithQuery(model, {});
    }
  };
}
