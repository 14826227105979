/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, get, forEach, difference, upperFirst } from "lodash";
import pluralize from "pluralize";
import QueryBuilder from "tg-client-query-builder";

import mergeUpdateRecord from "./mergeUpdateRecord";
import addNestedOldRecords from "./addNestedOldRecords";
import { extendedPropertyModels } from "../../../constants";
import { flatMap } from "lodash";
import {
  extendedPropertyUploadFragment,
  getBoundExtendedPropertyUploadHelpers
} from "../../utils/extendedPropertiesUtils";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import { isoContext } from "@teselagen/utils";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";

export async function upsertAddIds(
  { recordsToCreate, recordsToImport, modelOrFragment },
  ctx = isoContext
) {
  const { safeUpsert } = ctx;
  const fragment =
    typeof modelOrFragment === "string"
      ? [modelOrFragment, "id cid"]
      : modelOrFragment;

  const upsertResponse = await safeUpsert(fragment, recordsToCreate);
  const keyedByCid = keyBy(upsertResponse, "cid");
  recordsToImport.forEach(r => {
    if (r.__importFailed) return;
    if (!r.id) {
      r.id = keyedByCid[r.cid].id;
      if (!r.id) throw new Error("The cid should always exist!");
    }
  });
  return upsertResponse;
}

export async function removeJoins(
  {
    recordsToImport,
    model, //"strain"
    nested, //"plasmid"
    //these are necessary only if the join doesn't follow standard naming conventions
    joinName, //"strainPlasmid"
    modelId, //"strainId"
    nestedId, //"plasmidId"
    pluralNested //"plasmids"
  },
  ctx = isoContext
) {
  const _joinName = joinName || model + upperFirst(nested);
  const _modelId = modelId || model + "Id";
  const _nestedId = nestedId || nested + "Id";
  const _pluralNested = pluralNested || pluralize(nested);

  const removeFilters = [];
  recordsToImport.forEach(r => {
    if (!r[_pluralNested]) return;
    r[_pluralNested] = r[_pluralNested].filter(p => {
      if (p.__remove && p.id && r.id) {
        removeFilters.push({
          [_modelId]: r.id,
          [_nestedId]: p.id
        });
      }
      return !p.__remove;
    });
  });
  if (removeFilters.length) {
    const qb = new QueryBuilder(_joinName);
    const filter = qb.whereAny(...removeFilters).toJSON();

    await ctx.deleteWithQuery(_joinName, filter);
  }
}

export async function updateExtendedPropertiesOnExternalRecords(
  { records, model },
  ctx = isoContext
) {
  // on import/update/export from node-red, extendedProperties can be specified on a record and we should create the extendedProperties if necessary and update the record with the extendedProperties
  // grab all extendedProperty types (by extendedProperty name), and query for existing ones and create ones that don't yet exist

  if (!extendedPropertyModels.includes(model)) return;

  const removeAnyFilters = [];
  records.forEach(r => {
    const { __oldRecord, __newRecord } = r;
    if (!__oldRecord || !__newRecord) {
      return;
    }

    const cleanedProps = [];
    if (r.extendedProperties) {
      r.extendedProperties.forEach(p => {
        if (p.__remove) {
          if (p.id) {
            removeAnyFilters.push({
              extendedPropertyId: p.id,
              [model + "Id"]: r.id
            });
          }
        } else {
          cleanedProps.push(p);
        }
      });
    }

    r.extendedProperties = cleanedProps;
  });

  // deleting extendedPropertyItems where recordId is id and extendedPropertyId is extendedProperty.id
  if (removeAnyFilters.length) {
    const types = [
      "extendedValue",
      "extendedCategoryValue",
      "extendedMeasurementValue"
    ];
    for (const type of types) {
      const qb = new QueryBuilder(type);
      const filter = qb.whereAny(...removeAnyFilters);
      await ctx.deleteWithQuery(type, filter);
    }
  }

  const extendedPropertiesFromRecordsByLowerName = keyBy(
    flatMap(records, r => r.extendedProperties || []),
    t => t.name.toLowerCase()
  );

  if (Object.keys(extendedPropertiesFromRecordsByLowerName).length) {
    // we need to query for old properties
    const fakeCsvHeaders = [];
    const getHeader = propertyName => `ext-${model}-${propertyName}`;
    records.forEach(r => {
      const recordId = r.id || r.duplicate?.id;
      if (!recordId) {
        console.error(`Broken record with no id! 098127700:`, r);
        throw new Error(`Broken record with no id! name: ${r.name} 8912897711`);
      }
      r.extendedProperties.forEach(t => {
        fakeCsvHeaders.push(getHeader(t.name));
      });
    });

    fakeCsvHeaders.returnMissing = true;
    const { createUploadProperties, getCsvRowExtProps, missingProperties } =
      await getBoundExtendedPropertyUploadHelpers(fakeCsvHeaders, ctx);

    const recordIds = records.map(r => r.id || r.duplicate.id);

    const recordsWithProps = keyBy(
      await ctx.safeQuery([model, `id ${extendedPropertyUploadFragment}`], {
        variables: {
          filter: {
            id: recordIds
          }
        }
      }),
      "id"
    );

    records.forEach(r => {
      const recordId = r.id || r.duplicate.id;
      const recordWithProps = recordsWithProps[recordId];
      const fakeRow = {};
      let rowWarning = "";
      r.extendedProperties.forEach(p => {
        if (missingProperties.includes(p.name)) {
          rowWarning += `Extended property ${p.name} was not found. `;
        } else {
          const header = getHeader(p.name);
          fakeRow[header] = p.value;
        }
      });
      if (rowWarning) {
        r.__importWarning = concatWarningStrs(r.__importWarning, rowWarning);
      }
      getCsvRowExtProps({
        row: fakeRow,
        record: recordWithProps,
        recordId: recordWithProps.id,
        model,
        typeFilter: [model]
      });
    });

    await createUploadProperties();
  }
}

export { mergeUpdateRecord, addNestedOldRecords };

function getNewIds(val, subtype) {
  const oldIds = get(val, `__oldRecord.${subtype}`, []).map(v => v.id);
  const newIds = get(val, `__newRecord.${subtype}`, []).map(v => v.id);
  return difference(newIds, oldIds);
}

export function filterNestedFailures(records, nestedKey) {
  return records.filter(record => {
    if (record.__importFailed) return false;
    const nested = record[nestedKey];

    if (Array.isArray(nested)) {
      const failed = nested.find(n => n.__importFailed);
      if (failed) {
        record.__importFailed = failed.__importFailed;
        return false;
      } else {
        return true;
      }
    } else {
      const nestFail = record[nestedKey] && record[nestedKey].__importFailed;
      if (nestFail) {
        record.__importFailed = nestFail;
        return false;
      } else {
        // update the __newRecord of the nested item with the id of the update/create
        if (
          record[nestedKey] &&
          record[nestedKey].id &&
          record.__newRecord &&
          record.__newRecord[nestedKey]
        ) {
          record.__newRecord[nestedKey].id = record[nestedKey].id;
        }
        return true;
      }
    }
  });
}

export async function handleNestedRecords(records, nestedKey, handler) {
  const nestedRecords = addNestedOldRecords(records, nestedKey);
  if (nestedRecords) {
    await handler(nestedRecords);
  }
  return filterNestedFailures(records, nestedKey);
}

export function handleNewLinkagesOnUpdateRecords(records, subtype) {
  const toRet = [];
  forEach(records, record => {
    //we don't care about linkages to plasmids for brand new strains (because that's handled below)
    if (record.__oldRecord) {
      const idsToLink = getNewIds(record, subtype);
      idsToLink.forEach(id => {
        toRet.push([record.id, id]);
      });
    }
  });
  return toRet;
}

export const validateUnits = r => {
  if (
    r.volumetricUnitCode &&
    !unitGlobals.volumetricUnits[r.volumetricUnitCode]
  ) {
    return `Invalid volumetric unit ${r.volumetricUnitCode}.`;
  } else if (r.massUnitCode && !unitGlobals.massUnits[r.massUnitCode]) {
    return `Invalid mass unit ${r.massUnitCode}.`;
  } else if (
    r.concentrationUnitCode &&
    !unitGlobals.concentrationUnits[r.concentrationUnitCode]
  ) {
    return `Invalid mass unit ${r.concentrationUnitCode}.`;
  }
};
