/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Dialog, Callout } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import {
  InputField,
  TextareaField,
  NumericInputField,
  ReactSelectField,
  ReactColorField,
  DialogFooter
} from "@teselagen/ui";

import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { difference } from "lodash";
import shortid from "shortid";

// import "./style.css";

const AddOrEditReviewStepDialog = options => {
  const {
    handleSubmit,
    hideModal,
    refetch,
    isEdit,
    stageToEdit,
    otherStages = [],
    intitialStageApprovers = []
  } = options;
  const onSubmit = defaultAsyncWrap(
    async values => {
      const {
        name,
        description,
        howManyApprovers,
        color,
        stageApprovers: _stageApprovers,
        prerequisiteStagesstages = []
      } = values;

      //tnrtodo: once we have hasura come back here and improve this to not delete all stageApprover on every edit

      let stageApprovers = _stageApprovers;

      if (isEdit) {
        const newApproverUserIds = stageApprovers.map(({ id }) => id);
        const initialApproverUserIds = intitialStageApprovers.map(
          s => s.user.id
        );
        const added = difference(newApproverUserIds, initialApproverUserIds);
        const removed = difference(initialApproverUserIds, newApproverUserIds);
        stageApprovers = stageApprovers.filter(a => added.includes(a.id));
        await safeDelete(
          "stageApprover",
          intitialStageApprovers
            .filter(s => removed.includes(s.user.id))
            .map(s => s.id)
        );
        await safeDelete(
          "prerequisiteStage",
          (stageToEdit.prerequisiteStagesstages || []).map(({ id }) => id)
        );
      }

      const cid = shortid();
      const refCid = "&" + cid;
      await safeUpsert("stage", {
        ...(isEdit ? { id: stageToEdit.id } : { cid }),
        name,
        description,
        howManyApprovers,
        color
      });
      await safeUpsert(
        "stageApprover",
        stageApprovers.map(a => ({
          userId: a.id,
          stageId: isEdit ? stageToEdit.id : refCid
        }))
      );
      await safeUpsert(
        "prerequisiteStage",
        prerequisiteStagesstages.map(id => ({
          stageId: isEdit ? stageToEdit.id : refCid,
          prereqStageId: id
        }))
      );

      //refetch the stages
      await refetch();

      window.toastr.success(
        `Successfully ${isEdit ? "edited" : "added"} review step`
      );

      hideModal();
    },
    error => {
      console.error(error);
      window.toastr.error("Error adding review step");
    }
  );

  return (
    // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
    <Dialog
      canOutsideClickClose={false}
      isOpen
      onClose={hideModal}
      title={`${isEdit ? "Edit" : "Add"} Review Step`}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="user-form">
        <div className="bp3-dialog-body">
          {isEdit && (
            <Callout
              style={{ marginBottom: 10 }}
              intent="warning"
              children="By modifying this review step, any designs currently on this review step could require this step to be reconfigured."
            />
          )}
          <InputField
            autoFocus
            placeholder="Eg. Team Review or PI Review"
            isRequired
            label="Step Name*"
            name="name"
          />
          <TextareaField
            placeholder="Step description goes here..."
            label="Description"
            name="description"
          />
          <NumericInputField
            defaultValue={0}
            label="Number of required reviewers"
            name="howManyApprovers"
          />
          <ReactColorField
            defaultValue="lightblue"
            label="Color"
            name="color"
          />
          <GenericSelect
            asReactSelect
            isMultiSelect
            schema={["username"]}
            fragment={["user", "id username"]}
            label="Reviewers*"
            name="stageApprovers"
          />
          <ReactSelectField
            multi
            options={otherStages.map(s => ({ label: s.name, value: s.id }))}
            label="Prerequisite Steps"
            name="prerequisiteStagesstages"
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" />
      </form>
    </Dialog>
  );
};

const validate = ({ stageApprovers = [] } = {}) => {
  const errors = {};
  if (!stageApprovers.length) {
    errors.stageApprovers = "This field is Required.";
  }

  return errors;
};

// Decorate the form component
export default compose(
  reduxForm({
    form: "addOrEditReviewStep", // a unique name for this form
    validate
  })
  // tgFormValues({
  //   enteredPassword: "password",
  //   firstName: "firstName",
  //   lastName: "lastName",
  //   email: "email"
  // })
)(AddOrEditReviewStepDialog);
