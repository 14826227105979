/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import getModuleName from "../../../tg-iso-shared/src/utils/getModuleName";
import modelNameToLink from "./modelNameToLink";
import { pushHelper } from "./pushHelper";

export default function routeDoubleClick(row, rowIndex, history, e) {
  const modelName = row["__typename"] || "";
  const recordId = row["dbId"] || row["id"];
  const route = row.routeOverride || modelNameToLink(modelName, recordId);
  let clientBasePath;
  if (row.moduleSelector && row.moduleSelector !== getModuleName()) {
    clientBasePath = row.moduleSelector + "/client";
  }
  pushHelper(e, route, clientBasePath);
}
