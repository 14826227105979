/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import { checkDuplicateSequences } from "../../sequence-import-utils/checkDuplicateSequences";
import { oveSeqDataToGraphQL } from "../../sequence-import-utils/utils";
import { isBuild } from "../../utils/isModule";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import { upsertAddIds } from "./utils";

//this function should throw an error if something goes wrong with the import/update
export default async function ({ recordsToImport }, ctx = isoContext) {
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport,
      convertUserFacingToDbModel: r => {
        return oveSeqDataToGraphQL(
          { ...r, isProtein: true },
          { keepIds: true }
        );
      },
      precheckFn: r => {
        if (r.existingRecord) {
          return "This amino acid was already imported.";
        }
      },
      model: "aminoAcidSequence"
    },
    ctx
  );
  let recordsToCreate = [];
  let recordsToAddIdsTo = recordsToImport;
  if (newRecords.length) {
    if (isBuild()) {
      const duplicateSequences = await checkDuplicateSequences(
        newRecords,
        {
          isProtein: true,
          fragment: `id name hash`
        },
        ctx
      );
      const keyedDups = keyBy(duplicateSequences, "hash");
      recordsToAddIdsTo = [];
      newRecords.forEach((seq, i) => {
        const dup = keyedDups[seq.hash];
        if (dup) {
          recordsToImport[i].id = dup.id;
          recordsToImport[i].duplicate = true;
        } else {
          recordsToCreate.push(seq);
          recordsToAddIdsTo.push(recordsToImport[i]);
        }
      });
    } else {
      recordsToCreate = newRecords;
    }
    await upsertAddIds(
      {
        recordsToCreate,
        recordsToImport,
        modelOrFragment: "aminoAcidSequence"
      },
      ctx
    );
  }
}
