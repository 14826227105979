/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const {
  userRoleCodes: { ADMIN_ROLE, MEMBER_ROLE, SYSTEM_ROLE }
} = require("./constants");

function getUserHighestRole(user) {
  if (user.appRoleCodes[SYSTEM_ROLE]) return SYSTEM_ROLE;
  else if (user.appRoleCodes[ADMIN_ROLE]) return ADMIN_ROLE;
  else if (user.appRoleCodes[MEMBER_ROLE]) return MEMBER_ROLE;
  else {
    // There's also the special LAB_CREATOR role, but that is only used in specific scenarios
    // and should always come with the ADMIN role as well.
    throw new Error(`User with id='${user.id}' has no role.`);
  }
}

module.exports = {
  getUserHighestRole
};
