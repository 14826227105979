/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { startCase, lowerCase } from "lodash";
import pluralize from "pluralize";

const modelNameMap = {
  additiveMaterial: "reagent",
  additiveType: "reagent type",
  aliquotContainer: "tube",
  aliquotContainerType: "tube type",
  assaySubject: "subject",
  dnaMaterial: "DNA material",
  rnaMaterial: "RNA material",
  rnaPart: "RNA part",
  dnaPart: "DNA part",
  proteinMaterial: "protein material",
  microbialMaterial: "microbial material",
  cellCulture: "cell culture",
  j5Report: "DNA assembly report",
  j5PcrReaction: "DNA assembly PCR reaction",
  equipmentItem: "equipment",
  taskIo: "task input/output",
  ioItem: "input/output",
  ioItemType: "i/o item type",
  taskIoType: "task i/o type",
  containerArray: "plate",
  labProtocol: "protocol",
  lot: "reagent lot",
  containerArrayType: "plate type",
  customerRequest: "user request",
  dnaSequence: "DNA sequence",
  rnaSequence: "RNA sequence",
  rnaType: "RNA Type",
  DNA: "DNA",
  RNA: "RNA",
  MICROBIAL: "microbial",
  PROTEIN: "protein",
  cellLine: "cell line",
  vendorOrder: "order",
  microbialStrain: "strain",
  j5RunConstructView: "construct",
  assemblyPieceView: "assembly piece",
  partset: "DNA part set",
  ruleSet: "ruleset",
  targetOrganismClass: "organism group",
  plateMapGroup: "plate map",
  dataGrid: "data grid"
};

const upperModelNameMap = {
  additiveMaterial: "Reagent",
  additiveType: "Reagent Type",
  aliquotContainer: "Tube",
  aliquotContainerType: "Tube Type",
  assaySubject: "Subject",
  labProtocol: "Protocol",
  lot: "Reagent Lot",
  rnaPart: "RNA Part",
  dnaPart: "DNA Part",
  microbialStrain: "Microbial Strain",
  dnaMaterial: "DNA Material",
  rnaMaterial: "RNA Material",
  proteinMaterial: "Protein Material",
  microbialMaterial: "Microbial Material",
  cellCulture: "Cell Culture",
  cellLine: "Cell Line",
  equipmentItem: "Equipment",
  taskIo: "Task Input/Output",
  ioItem: "Input/Output",
  ioItemType: "I/O Item Type",
  taskIoType: "Task I/O Type",
  containerArray: "Plate",
  containerArrayType: "Plate Type",
  customerRequest: "User Request",
  dnaSequence: "DNA Sequence",
  rnaSequence: "RNA Sequence",
  rnaType: "RNA Type",
  grandDesign: "Design",
  part: "DNA Part",
  partset: "DNA Part Set",
  j5Report: "DNA Assembly Report",
  j5PcrReaction: "DNA Assembly PCR Reaction",
  j5RunConstructView: "Construct",
  assemblyPieceView: "Assembly Piece",
  vendorOrder: "Order",
  ruleSet: "Ruleset",
  targetOrganismClass: "Organism Group",
  plateMapGroup: "Plate Map",
  guideRnaScorePredictionRuns: "Guide RNA Score Prediction Report",
  CRISPRDesigns: "CRISPR Design",
  eln: "Electronic Lab Notebook"
};

export default function modelNameToReadableName(
  modelName,
  { upperCase, sanitize, plural } = {}
) {
  let modelNameToUse = modelName;
  if (upperCase) {
    modelNameToUse =
      upperModelNameMap[modelNameToUse] || startCase(modelNameToUse);
  } else {
    modelNameToUse = modelNameMap[modelNameToUse] || lowerCase(modelNameToUse);
  }
  if (sanitize) {
    modelNameToUse = sanitize(modelNameToUse);
  }
  if (/^j 5/i.test(modelNameToUse)) {
    modelNameToUse = modelNameToUse.replace(/^j 5/i, "j5");
  }
  modelNameToUse = modelNameToUse.replace(/pcr/gi, "PCR");
  if (plural) modelNameToUse = pluralize(modelNameToUse);
  return modelNameToUse;
}
