/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment taggedItemFragment on taggedItem {
    id
    tag {
      id
      name
      color
    }
    tagOption {
      id
      name
      color
    }
  }
`;
