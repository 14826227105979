/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const insertSequenceDialogFragment = gql`
  fragment insertSequenceDialogFragment on sequence {
    id
    moduleSelector
    name
    size
    description
    circular
    parts {
      id
      name
    }
    sequenceFeatures {
      id
      name
    }
    user {
      id
      username
      firstName
      lastName
    }
    designMaterialAvailabilityView {
      id
      isAvailable
    }
    sequenceTypeCode
    sequenceType {
      code
      name
    }
    isJ5Sequence
    isInLibrary
    lab {
      id
      name
    }
    createdAt
    updatedAt
  }
`;
