/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import AvatarIcon from "../AvatarIcon";
import { Button, Menu } from "@blueprintjs/core";
import { createMenu } from "@teselagen/ui";
import { isAdmin, openInNewTab } from "../utils/generalUtils";
import { Link } from "react-router-dom";
import { AssignDefaultsModeContext } from "@teselagen/ui";
import { handleIntegrationClick } from "./handleIntegrationClick";
import "./style.css";
import CurrentUserContext from "../context/CurrentUserContext";

const UserAccountButton = ({ logout, extraItems }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setAssignDefaultsMode } = useContext(AssignDefaultsModeContext);
  let conditionalItems = [];
  if (window.frontEndConfig.nodeRedEditorUrl && isAdmin()) {
    conditionalItems = [
      { divider: "" },
      {
        text: "Integration Server",
        icon: "send-to-graph",
        onClick: handleIntegrationClick
      }
    ];
  }
  if (process.env.NODE_ENV !== "production") {
    conditionalItems.push({
      text: "GraphIQL",
      icon: "locate",
      onClick: () => {
        window.open(
          `http://localhost:3021/${window.frontEndConfig.tgModuleName}/graphiql/`,
          "_blank"
        );
      }
    });
  }
  conditionalItems.push({
    text: "API Docs",
    icon: "layout-auto",
    onClick: () => {
      openInNewTab("/api-docs/", window.frontEndConfig.tgModuleName);
    }
  });

  const menu = [
    {
      divider: currentUser.firstName + " " + currentUser.lastName,
      tooltip: currentUser.email
    },
    { divider: "" },
    ...(extraItems || []),
    ...(isAdmin()
      ? [
          {
            text: <DefaultMode />,
            icon: "code",
            onClick: e => {
              const element = document.querySelector(
                ".tg-jump-to-settings-btn"
              );
              if (e.target !== element && !element.contains(e.target)) {
                setAssignDefaultsMode(prev => !prev);
              }
            }
          }
        ]
      : []),
    ...conditionalItems,
    { divider: "" },
    { text: "Sign out", icon: "log-out", onClick: logout }
  ];

  return (
    <div className="user-account-button">
      <AvatarIcon
        size={29}
        style={{ border: "2px solid white" }}
        user={currentUser}
        menu={<Menu>{createMenu(menu)}</Menu>}
      />
    </div>
  );
};

export default UserAccountButton;

const DefaultMode = () => {
  const { inAssignDefaultsMode } = useContext(AssignDefaultsModeContext);
  return (
    <>
      {inAssignDefaultsMode ? "Leave" : ""} Assign Default Mode{" "}
      <Link to="/settings/default-values">
        <Button
          className="tg-jump-to-settings-btn"
          // onClick={() => {
          //   history.push("/settings/default-values");
          //   // e.stopPropagation();
          // }}
          small
          minimal
          icon="settings"
        />
      </Link>
    </>
  );
};
