/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const {
  userRoleCodes: { ADMIN_ROLE, LAB_CREATOR_ROLE }
} = require("./ext-utils");

function createAppRoleCodeMap(user, isLabCreationEnableForMembers) {
  const { cid, userRoles = [] } = user;

  user.guid = cid;

  user.appRoleCodes = userRoles.reduce((acc, val) => {
    const { appRoleCode } = val;
    acc[appRoleCode] = true;
    return acc;
  }, {});

  if (user.appRoleCodes[ADMIN_ROLE] || isLabCreationEnableForMembers) {
    user.appRoleCodes[LAB_CREATOR_ROLE] = true;
  }

  return user;
}

module.exports = { createAppRoleCodeMap };
