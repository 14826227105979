/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import DesignFromDigestParts from "./DesignFromDigestParts";
import DesignFromPartitionTool from "./DesignFromPartition";

const toolSchemas = [
  {
    code: "partitionTool",
    Component: DesignFromPartitionTool,
    title: "Create Design From Partition Tool",
    // workflowOnly: true,
    description: <span>Creates a Design by Partitioning A DNA Sequence.</span>,
    input: "DNA Sequence",
    output: "Design",
    toolSchema: {
      input: {
        ioItems: {
          dnaSequence: {
            isList: false,
            label: "DNA Sequence",
            dataItemTypeCode: "DNA_SEQUENCE"
          }
        }
      },
      output: {
        ioItems: {
          design: {
            isList: false,
            label: "Design",
            dataItemTypeCode: "DESIGN"
          }
        }
      }
    }
  },
  {
    code: "digestDesignTool",
    Component: DesignFromDigestParts,
    title: "Create Design From Digest Parts",
    description: (
      <span>
        Creates a Design from a list of Digest Parts. Parts detected as vector
        backbones as placed in the first column, subsequent parts are ordered
        into columns based on matching 5' to 3' overhangs.{" "}
      </span>
    ),
    input: "Digest Parts",
    output: "Design",
    toolSchema: {
      input: {
        ioItems: {
          dnaSequence: {
            isList: true,
            label: "DNA Part",
            dataItemTypeCode: "DNA_PART"
          }
        }
      },
      output: {
        ioItems: {
          design: {
            isList: false,
            label: "Design",
            dataItemTypeCode: "DESIGN"
          }
        }
      }
    }
  }
];

toolSchemas.forEach(schema => {
  if (schema.toolSchema) {
    schema.toolSchema.name = schema.title;
  }
});

export default toolSchemas.sort((a, b) => a.title.localeCompare(b.title));
