/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequenceFragment from "./sequenceFragment";
import taggedItemFragment from "./taggedItemFragment";

export default gql`
  fragment aminoAcidRecordFragment on aminoAcidSequence {
    id
    name
    description
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    commonId
    extinctionCoefficient
    extinctionCoefficientUnit {
      code
      name
    }
    hisTagLoc
    isoPoint
    size
    molecularWeight
    codingDnaSequences {
      ...sequenceFragment
      taggedItems {
        ...taggedItemFragment
      }
    }
    regionAnnotations {
      id
      name
      start
      end
      type
      notes
    }
    aminoAcidParts {
      id
      name
      start
      end
      type
      notes
    }
    proteinSequence
    uniprotId
    cid
  }
  ${sequenceFragment}
  ${taggedItemFragment}
`;
