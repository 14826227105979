/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component, useState } from "react";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { Timeline, TimelineEvent } from "@teselagen/ui";
import { forEach } from "lodash";
import { Button, ButtonGroup, Card, Icon } from "@blueprintjs/core";
import modelNameToLink from "../utils/modelNameToLink";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { compose } from "recompose";
import withQuery from "../withQuery";
import queryBuilder from "tg-client-query-builder";
import "./style.css";
import { addActiveProjectFilter } from "../utils/projectUtils";
import { inDevelopment } from "../utils/inDevelopment";
import appGlobals from "../appGlobals";
import EditableTableWGenericSelect from "../components/EditableTableWGenericSelect";

const ToWrap = ({ toCountGroups, LinkEl = Link }) => {
  const itemsGroup = [];
  const generateLinks = modelList =>
    modelList.map(_model => {
      const model = _model.model || _model;
      const modelName = _model.name || model;

      const WrappedLink = withQuery([model, "id"], {
        isPlural: true,
        options: () => {
          const qb = new queryBuilder(model);
          if (_model.filter) {
            _model.filter(qb);
          }
          addActiveProjectFilter(qb, {
            model
          });
          const filter = qb.toJSON();
          return {
            variables: {
              pageSize: 1,
              filter
            }
          };
        }
      })(({ data }) => {
        const count = data.loading ? "..." : data.totalResults;
        return (
          <div>
            <LinkEl to={modelNameToLink(modelName)}>
              <b>{count}</b>{" "}
              {modelNameToReadableName(modelName, {
                plural: count !== 1,
                upperCase: true
              })}
            </LinkEl>
          </div>
        );
      });
      return <WrappedLink key={model}></WrappedLink>;
    });
  forEach(toCountGroups, ({ name, icon, items }) => {
    itemsGroup.push(
      <React.Fragment key={name}>
        <div>
          <h5 className="tg-flex align-center">
            <Icon icon={icon} />
            &nbsp; {name}
          </h5>
        </div>
        {generateLinks(items)}
      </React.Fragment>
    );
  });
  const toRet = (
    <>
      {itemsGroup.map((group, i) => {
        return <FlexCol key={i}>{group}</FlexCol>;
      })}
    </>
  );
  return toRet;
};
export const CountsSection = ({ ...rest }) => {
  const [countsVisible, showCounts] = useState(false);
  let Inner;
  if (countsVisible) {
    Inner = ToWrap;
  }
  return (
    <>
      <div className="flex-grid">
        <a onClick={() => showCounts(!countsVisible)}>
          {countsVisible ? "Hide Inventory Stats" : "Show Inventory Stats"}
        </a>
      </div>

      <div className="flex-grid">{Inner && <Inner {...rest} />}</div>
    </>
  );
};

export default class LandingPageTimelineInner extends Component {
  state = {
    showLocalOptions: true
  };

  renderLocalOptions() {
    if (!this.state.showLocalOptions || !inDevelopment) return null;
    const HasuraQueries = require("../components/__TestPages/HasuraQueries")
      .default;
    return (
      <div style={{ margin: 20, width: 800 }}>
        <h6>Developer Settings</h6>
        <ButtonGroup vertical>
          <Button
            text="test debugger sourcemaps"
            onClick={() => {
              // eslint-disable-next-line no-debugger
              debugger;
            }}
          />
          <Button
            text="Hide Local Options"
            onClick={() => this.setState({ showLocalOptions: false })}
          />

          <Button
            text="View Mock Workflow Report"
            onClick={() => {
              appGlobals.history.push("/workflow-report");
            }}
          />
          <Button
            text="View Plate Layout"
            onClick={() => {
              appGlobals.history.push("/test-plate-layout");
            }}
          />
        </ButtonGroup>
        <EditableTableWGenericSelect></EditableTableWGenericSelect>
        <HasuraQueries></HasuraQueries>
      </div>
    );
  }

  generateTimeline = model => {
    const LinkEl = this.props.LinkEl || Link;
    const items = this.props[pluralize(model)] || [];
    const timeline = items.map(({ name, id, __typename, updatedAt }) => {
      return (
        <TimelineEvent key={id} date={updatedAt}>
          <LinkEl
            to={modelNameToLink(__typename, id)}
            style={{
              overflow: "hidden",
              width: "15vw",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
          >
            {name}
          </LinkEl>
        </TimelineEvent>
      );
    });
    let innerContent;
    if (timeline.length) {
      innerContent = <Timeline>{timeline}</Timeline>;
    } else {
      innerContent = (
        <span
          style={{
            fontStyle: "italic",
            fontSize: 12,
            verticalAlign: "top"
          }}
        >
          No Recently Created{" "}
          {modelNameToReadableName(model, { plural: true, upperCase: true })}
        </span>
      );
    }
    return (
      <React.Fragment>
        <h5 className="tg-flex align-center">
          <Icon icon={this.props.timelineIcons[model]} />
          &nbsp; Recent{" "}
          {modelNameToReadableName(model, {
            upperCase: true,
            plural: true
          })}
        </h5>
        {innerContent}
      </React.Fragment>
    );
  };

  render() {
    const { timelineModels } = this.props;

    const timelineGroups = [];

    timelineModels.map(this.generateTimeline).forEach((timeline, i) => {
      timelineGroups.push(<div key={`timeline-${i}`}>{timeline}</div>);
    });

    return (
      <div className="landing-page">
        <div className="flex-grid" style={{ marginTop: 60 }}>
          {timelineGroups.map((group, i) => {
            return (
              <FlexCol style={{ maxWidth: 240 }} key={i}>
                {group}
              </FlexCol>
            );
          })}
        </div>

        <CountsSection {...this.props}></CountsSection>

        {this.renderLocalOptions()}
      </div>
    );
  }
}

function FlexCol({ children, style }) {
  return (
    <Card style={style} className="flex-col margin-collapsed">
      {children}
    </Card>
  );
  // return <div className="flex-col margin-collapsed">{children}</div>;
}

const timelineFields = "id name updatedAt";
const timelineQueryOptions = {
  isPlural: true,
  options: {
    variables: { pageSize: 5, sort: "-updatedAt" }
  }
};

export function timelineItemWrapper(timelineModels) {
  return compose(
    ...timelineModels.map(_model => {
      let model;
      if (typeof _model === "string") {
        model = _model;
      } else {
        model = _model.model;
      }

      const queryOptions = {
        ...timelineQueryOptions,
        options: () => {
          const qb = new queryBuilder(model);
          addActiveProjectFilter(qb, {
            model
          });
          if (_model.filter) {
            _model.filter(qb);
          }
          const filter = qb.toJSON();
          return {
            ...timelineQueryOptions.options,
            variables: {
              ...timelineQueryOptions.options.variables,
              filter
            }
          };
        },
        props: _model.props
      };
      return withQuery([model, timelineFields], queryOptions);
    })
  );
}
