/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { isBuild } from "../utils/isModule";

export default gql`
  fragment partFullFragment on part {
    id
    name
    start
    end
    strand
    sequence {
      id
      name
      description
      size
      circular
      hash
      rotationalHash
      fullSequence
      ${isBuild() ? `polynucleotideMaterial { id name }` : ``}
    }
  }
`;
