/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import appGlobals from "../appGlobals";
import { fullUrl, openInNewTab } from "./generalUtils";

export function pushHelper(event, newPathString, clientBasePath) {
  if (event && (event.metaKey || event.altKey)) {
    const win = openInNewTab(newPathString, clientBasePath);
    if (win) win.opener = null;
  } else {
    if (clientBasePath) {
      window.location = fullUrl(newPathString, clientBasePath);
    } else {
      appGlobals.history.push(newPathString);
    }
  }
}
