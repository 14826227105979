/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import Promise from "bluebird";
import { keyBy, isEmpty, noop, flatMap } from "lodash";
import { parseSequenceText } from "./utils";
import { checkDuplicateSequences } from "./checkDuplicateSequences";
import { isoContext } from "@teselagen/utils";
import createTaggedItems from "../tag-utils/createTaggedItems";
import { addTaggedItemsBeforeCreate } from "../tag-utils";
import upsertUniqueAliases from "./upsertUniqueAliases";
import { isBuild, isDesign } from "../utils/isModule";
import { getBoundExtendedPropertyUploadHelpers } from "../utils/extendedPropertiesUtils";
import { filterSequenceUploads } from "./utils";

const taggedItems = `taggedItems {
  id
  tagId
  tagOptionId
}`;

const extendedPropertyValues = `
  extendedValues {
    id
    extendedProperty {
      id
      name
      extendedTypeCode
    }
    value
  }
  extendedCategoryValues {
    id
    extendedCategory {
      id
      name
    }
    extendedProperty {
      id
      name
      extendedTypeCode
    }
  }
  extendedMeasurementValues {
    id
    value
    measurementUnitId
    measurementUnit {
      id
      abbreviation
    }
    extendedProperty {
      id
      name
      extendedTypeCode
    }
  }
`;

const BUILD_AA_FRAGMENT = `
id
name
hash
codingDnaSequences {
  id
  name
  inventoryItems {
    id
  }
}
inventoryItems {
  id
}
${taggedItems}
`;

/**
 * Uploads amino acid sequence files and creates materials, cds, and aliases
 * @param {object} params
 * @param {boolean} params.isFileUpload is file upload or paste
 * @param {any=} params.sequenceUpload sequence files
 * @param {(object[] | string[])=} params.sequenceTexts pasted sequence
 * @param {(undefined | string[])=} params.sequenceNames names for pasted sequences
 * @param {string=} params.labId lab to place sequences in
 * @param {object[]=} params.tags tags to apply to sequences and materials
 * @param {any[]=} params.extendedProperties
 * @param {function=} params.setActiveLab function to set the active lab appropriately
 * @param {boolean=} params.allowDuplicates whether to allow duplicate sequences
 * @param {object} ctx
 */
export default async function uploadAminoAcidSequences(
  {
    isFileUpload,
    sequenceUpload = [],
    sequenceTexts = [],
    sequenceNames = undefined,
    tags,
    extendedProperties = [],
    allowDuplicates
  },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert, startImportCollection = noop } = ctx;

  let sequences = [];
  const proteinMaterials = [];
  const sequencesToCreate = [];
  const fpusToCreate = [];
  const createdAminoAcidAliases = {};
  const inventoryItemsToCreate = [];
  const aliasesToCreate = [];
  const aaSequenceUpdates = [];
  const warnings = [];

  const isBuildModule = isBuild();

  if (isFileUpload) {
    sequences = await filterSequenceUploads(
      {
        allSequenceFiles: [...sequenceUpload],
        warnings,
        isProtein: true
      },
      ctx
    );
  } else {
    if (!sequenceTexts.length) {
      throw new Error("No sequence text provided.");
    }
    if (sequenceNames && sequenceTexts.length !== sequenceNames.length) {
      throw new Error(
        "In order to import multiple amino acid sequences, 'contents' and 'name' should both be arrays of the same length."
      );
    }

    sequences = await Promise.map(sequenceTexts, (sequenceText, idx) => {
      const seqName = sequenceNames && sequenceNames[idx];
      return parseSequenceText(sequenceText, seqName, {
        isProtein: true
      });
    });
    sequences = flatMap(sequences);
  }
  // const notAASequence = sequences.some(sequence =>
  //   guessIfSequenceIsDnaAndNotProtein(getSequence(sequence) || "")
  // );
  // if (notAASequence) {
  //   throw new Error("Invalid amino acid sequence.");
  // }

  // needs to happen before dup check
  const duplicateSequences = await checkDuplicateSequences(
    sequences,
    {
      isProtein: true,
      fragment: BUILD_AA_FRAGMENT
    },
    ctx
  );
  const keyedDups = keyBy(duplicateSequences, "hash");
  const newSequences =
    allowDuplicates && isDesign()
      ? sequences
      : sequences.filter(seq => !keyedDups[seq.hash]);

  // Only supported for new AA sequences and not for udating existing ones.
  let extendedPropertyHelpers;
  if (extendedProperties.length) {
    // This reduce allows for some sequences to come with missing custom fields.
    // This checks for all the different custom field names for the different provided sequences.
    const extendedPropertiesAsHeaders = extendedProperties.reduce(
      (_extendedPropertiesAsHeaders, extProp) => {
        Object.keys(extProp).forEach(extPropName => {
          if (!_extendedPropertiesAsHeaders.includes(extPropName)) {
            _extendedPropertiesAsHeaders.push(
              `ext-amino acid sequence-${extPropName}`
            );
          }
        });
        return _extendedPropertiesAsHeaders;
      },
      []
    );
    extendedPropertyHelpers = await getBoundExtendedPropertyUploadHelpers(
      extendedPropertiesAsHeaders,
      ctx
    );
  }
  newSequences.forEach((sequence, idx) => {
    const cid = shortid();
    sequence.cid = cid;
    if (isBuildModule) {
      fpusToCreate.push({
        cid,
        designId: sequence.designId,
        pdbId: sequence.pdbId,
        cofactor: sequence.cofactor,
        name: sequence.name,
        molecularWeight: sequence.molecularWeight,
        extinctionCoefficient: sequence.extinctionCoefficient,
        proteinSubUnits: [
          {
            aminoAcidSequenceId: `&${cid}`
          }
        ]
      });
      proteinMaterials.push({
        functionalProteinUnitId: `&${cid}`,
        name: sequence.name,
        materialTypeCode: "PROTEIN",
        provenanceType: "registered"
      });
    }
    delete sequence.designId;
    delete sequence.pdbId;
    delete sequence.cofactor;
    if (extendedProperties.length) {
      const customFieldsWithModelPrefix = Object.assign(
        {},
        ...Object.keys(extendedProperties[idx]).map(key => ({
          [`ext-amino acid sequence-${key}`]: extendedProperties[idx][key]
        }))
      );
      extendedPropertyHelpers.getCsvRowExtProps({
        row: customFieldsWithModelPrefix,
        recordId: `&${cid}`,
        modelTypeCode: "AMINO_ACID_SEQUENCE"
      });
    }
    sequencesToCreate.push(sequence);
  });

  sequences.forEach(sequence => {
    const existingSequence = keyedDups[sequence.hash];
    if (
      existingSequence &&
      sequence.name !== existingSequence.name &&
      !createdAminoAcidAliases[existingSequence.id] &&
      isBuildModule
    ) {
      const matchingCds = existingSequence.codingDnaSequences.find(
        cds => cds.name === existingSequence.name
      );
      createdAminoAcidAliases[existingSequence.id] = true;
      if (matchingCds) {
        let inventoryItemId;
        if (existingSequence.inventoryItems.length) {
          inventoryItemId = existingSequence.inventoryItems[0].id;
        } else {
          const cid = shortid();
          inventoryItemId = `&${cid}`;
          inventoryItemsToCreate.push({
            cid,
            inventoryItemTypeCode: "AMINO_ACID_SEQUENCE",
            aminoAcidSequenceId: existingSequence.id
          });
        }

        const cdsInventoryItemFields = [];
        if (matchingCds.inventoryItems.length) {
          const invItemId = matchingCds.inventoryItems[0].id;
          cdsInventoryItemFields.targetInventoryItemId = invItemId;
          aliasesToCreate.push({
            name: sequence.name,
            sequenceId: matchingCds.id,
            targetInventoryItemId: inventoryItemId
          });
        } else {
          cdsInventoryItemFields.targetInventoryItem = {
            inventoryItemTypeCode: "DNA_SEQUENCE",
            sequenceId: matchingCds.id,
            aliasesInventoryItems: [
              {
                name: sequence.name,
                targetInventoryItemId: inventoryItemId
              }
            ]
          };
        }

        aliasesToCreate.push({
          name: sequence.name,
          aminoAcidSequenceId: existingSequence.id,
          ...cdsInventoryItemFields
        });
      } else {
        aliasesToCreate.push({
          name: sequence.name,
          aminoAcidSequenceId: existingSequence.id
        });
      }
    }

    if (existingSequence) {
      const aaSequenceUpdate = {
        id: existingSequence.id,
        name: existingSequence.name
      };
      aaSequenceUpdates.push(aaSequenceUpdate);
      // existingSequences.push(existingSequence);
      if (isDesign()) {
        aliasesToCreate.push({
          name: sequence.name,
          aminoAcidSequenceId: existingSequence.id
        });
      }
    }
  });

  if (
    sequencesToCreate.length ||
    proteinMaterials.length ||
    fpusToCreate.length
  ) {
    await startImportCollection("Amino Acid Sequence Upload");
  }

  // Since customFields (possibly ext properties as well later on) is supported now, we query for the created AAs after upserting the custom fields.
  const createdAminoAcidSequencesRecords = await safeUpsert(
    ["aminoAcidSequence", `id name`],
    addTaggedItemsBeforeCreate(sequencesToCreate, tags)
  );

  if (extendedPropertyHelpers) {
    await extendedPropertyHelpers.createUploadProperties();
  }
  let createdFpus, createdMaterials;
  if (isBuildModule) {
    createdFpus = await safeUpsert(
      "functionalProteinUnit",
      addTaggedItemsBeforeCreate(fpusToCreate, tags)
    );
    createdMaterials = await safeUpsert(
      "material",
      addTaggedItemsBeforeCreate(proteinMaterials, tags)
    );
  }
  const existingAASequences = await safeUpsert(
    ["aminoAcidSequence", `id name ${taggedItems} ${extendedPropertyValues}`],
    aaSequenceUpdates
  );

  if (isBuildModule) {
    await safeUpsert("inventoryItem", inventoryItemsToCreate, {
      excludeResults: true
    });
  }
  await upsertUniqueAliases(aliasesToCreate, ctx);

  const createdAminoAcidSequences = await safeQuery(
    ["aminoAcidSequence", `id name ${taggedItems} ${extendedPropertyValues}`],
    {
      variables: {
        filter: {
          id: createdAminoAcidSequencesRecords.map(
            createdAminoAcidSequencesRecord =>
              createdAminoAcidSequencesRecord.id
          )
        }
      }
    }
  );

  if (!isEmpty(tags)) {
    await createTaggedItems(
      {
        selectedTags: tags,
        records: existingAASequences
      },
      ctx
    );
  }

  return {
    createdMaterials,
    createdAminoAcidSequences,
    createdFpus,
    existingAminoAcidSequences: existingAASequences,
    aminoAcidSequencesAliases: aliasesToCreate
  };
}
