/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Loading } from "@teselagen/ui";
import React, { Suspense, useRef } from "react";

export default function makeLazy(fn) {
  return props => {
    const LazyComponent = useRef(React.lazy(fn));
    return (
      <Suspense fallback={<Loading bounce></Loading>}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <LazyComponent.current {...props} />
      </Suspense>
    );
  };
}
