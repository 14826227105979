/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment projectItemFragment on projectItem {
    id
    project {
      id
      name
      color
      projectRoles {
        id
        userId
      }
    }
  }
`;
