/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const {
  userRoleCodes: { ADMIN_ROLE }
} = require("./constants");
const { isSystemUser } = require("./isSystemUser");

function isAdmin(context) {
  if (typeof context === "undefined") return;
  if (typeof context.req === "undefined") return;

  // need to check against the string because env vars get stringified
  if (
    process.env.LoadingFixtures === "true" ||
    process.env.LoadingFixtures === true
  ) {
    // if seed data is being populated then allow without an authenticated admin
    // LoadingFixtures should be unset after seed data is populated
    return true;
  }
  const user = context.req.user;
  if (
    isSystemUser(context) ||
    (user.appRoleCodes && user.appRoleCodes[ADMIN_ROLE]) ||
    context.req?.superuser
  ) {
    return true;
  }

  return false;
}

module.exports.isAdmin = isAdmin;
