/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import initApp from "../src-shared/initApp"; // keep first
import { appRoutes } from "./appRoutes";
import store from "../src-shared/redux/store";
import modalComponents from "./modalComponents";
import HdeHeader from "./components/HdeHeader";
import { appHotkeySets } from "./hotkeys";
import "./App.css";
import toolSchemas from "./components/Tools/toolSchemas";
import "./components/Library/initModelToLibrary";
import makeLazy from "../src-shared/utils/initHelpers/makeLazy";

initApp({
  store,
  modalComponents,
  Header: HdeHeader,
  appRoutes,
  appHotkeySets,
  toolSchemas,
  AppSettings: makeLazy(() => import("./containers/AppSettingsContainer"))
});

if (module.hot) {
  module.hot.accept();
}
