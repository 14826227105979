/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
if (window.Cypress?.__tgCypressFrontEndConfig) {
  window.frontEndConfig = {
    ...window.frontEndConfig,
    ...window.Cypress.__tgCypressFrontEndConfig
  };
}

if (
  window.frontEndConfig.notificationInterval &&
  Number(window.frontEndConfig.notificationInterval) > 0
) {
  window.frontEndConfig.notificationInterval = Number(
    window.frontEndConfig.notificationInterval
  );
} else {
  window.frontEndConfig.notificationInterval = 30000;
}
