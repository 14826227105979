/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getPositionFromAlphanumericLocation } from "../../../tg-iso-lims/src/utils/plateUtils";
import { getAllIndicesBetweenTwoLocations } from "./getAllIndicesBetweenTwoLocations";
import { cartesianProductOf } from "../../../tg-iso-design/utils/combinatorialUtils";
import { getAlphnumericWellLocation } from "./getAlphnumericWellLocation";

export const getAllLocationsBetweenCells = (active, location) => {
  active = getPositionFromAlphanumericLocation(active);
  location = getPositionFromAlphanumericLocation(location);
  return getAlphnumericWellLocation(
    cartesianProductOf([
      getAllIndicesBetweenTwoLocations(
        active.rowPosition,
        location.rowPosition
      ),
      getAllIndicesBetweenTwoLocations(
        active.columnPosition,
        location.columnPosition
      )
    ]).map(([rowPosition, columnPosition]) => ({
      rowPosition,
      columnPosition
    }))
  );
};
