/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";

import { Classes } from "@blueprintjs/core";

import { safeUpsert, useTgQuery } from "../apolloMethods";
import { DataTable } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { find, pick, startCase } from "lodash";
import integrationTypeSettingsMap from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { externalReferenceKeys } from "../../../tg-iso-shared/constants";
import { wrapDialog } from "@teselagen/ui";
import { useIntegrationQuery } from "./useIntegrationQuery";
import { SendRecordsPage } from "./SendRecordsPage";
import { updateTagsOnExternalRecords } from "../../../tg-iso-shared/src/tag-utils/utils";

const form = "ExportToExternalDb";

const {
  EXPORT: {
    endpoints: {
      EXPORT__FORMAT: { method: methodFormat },
      EXPORT__EXPORT: { method: methodExport }
    }
  }
} = integrationTypeSettingsMap;

const ExportToExternalDbDialog = ({
  submitting,
  refetch,
  model,
  hideModal,
  handleSubmit,
  records,
  integrationId
}) => {
  const [results, setResults] = useState();
  const { integration, ...rest } = useIntegrationQuery({ integrationId });

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  let inner;
  if (results) {
    inner = <ExportResultsPage results={results} />;
  } else {
    const handleExport = async (
      data,
      { entities, convertDbModelToUserFacing }
    ) => {
      try {
        const endpoint = find(
          integration.integrationEndpoints,
          ({ endpointTypeCode }) => endpointTypeCode === "EXPORT__EXPORT"
        );
        const res = [];
        for (const record of entities) {
          // NOTE: We might want to eventually clean the record before sending it to node-red
          // const recordToSend = cleanExternalRecord(
          //   {
          //     ...convertDbModelToUserFacing(record),
          //     ...pick(record, externalReferenceKeys)
          //   },
          //   integration.subtype
          // );
          const recordToSend = {
            ...convertDbModelToUserFacing(record),
            ...pick(record, externalReferenceKeys)
          };
          const result = await window.triggerIntegrationRequest({
            endpointId: endpoint.id,
            data: {
              record: recordToSend,
              additionalFields: data
            },
            method: methodExport
          });
          if (!result.data) {
            res.push({
              ...record,
              success: false,
              error: "Unknown Error with the integration response"
            });
          } else {
            res.push({ ...result.data, id: record.id });
          }
        }

        try {
          // r.tags: [{ name: "topSecret" }, { name: "High Value", color: "blue" }],

          await updateTagsOnExternalRecords({
            records: res,
            model
          });

          await safeUpsert(
            [model, `id ${externalReferenceKeys.join(" ")}`],
            res.map(r => {
              return pick(
                r,

                ["id", ...externalReferenceKeys]
              );
            })
          );
          refetch && (await refetch());
          setResults(res);
          window.toastr.success(
            `Successfully Exported ${entities.length} ${startCase(model)}(s).`
          );
        } catch (error) {
          console.error(`error:`, error);
          console.error(`res:`, res);
          window.toastr.error(
            "The external endpoint returned an invalid reponse. Please verify the endpoint is returning data in the expected format. Check the console for more details"
          );
        }
      } catch (error) {
        window.toastr.error(
          "An error occurred saving the data. Please verify that you've correctly set up the endpoint"
        );
        console.error(error);
        // eslint-disable-next-line no-debugger
      }
    };

    inner = (
      <SendRecordsPage
        integration={integration}
        hideModal={hideModal}
        records={records}
        submitting={submitting}
        handleSubmit={handleSubmit}
        methodFormat={methodFormat}
        endpointTypeCode="EXPORT__FORMAT"
        form={form}
        model={model}
        subtype={integration.subtype}
        onSend={handleExport}
      />
    );
  }
  return inner;
};

export default compose(
  wrapDialog({
    title: "Export to External Database"
  }),
  reduxForm({
    form
  })
)(ExportToExternalDbDialog);

const ExportResultsPage = ({ results }) => {
  return (
    <div className={Classes.DIALOG_BODY}>
      {" "}
      <h3>Export Results:</h3>{" "}
      <DataTable
        formName="exportResultsTable"
        isSimple
        schema={schema}
        entities={results}
      />
    </div>
  );
};

const schema = [
  "name",
  "externalReferenceId",
  { path: "success", type: "boolean" },
  "failureReason"
];

// export const noExternalSystemSetUp = (
//   <div style={{ padding: 20 }}>
//     No External Systems have been set up yet. Please have an admin set them up
//     in{" "}
//     <Link onClick={hideDialog} to="/settings/integrations-management">
//       Settings > Integrations
//     </Link>
//   </div>
// );
