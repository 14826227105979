/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import cn from "classnames";
import { Icon, Tooltip } from "@blueprintjs/core";
import "./style.css";
import { startCase } from "lodash";
import { Link } from "react-router-dom";
import urlUtil from "url";
import joinUrl from "url-join";

const platformModules = {
  Design: {
    moduleName: "Design",
    code: "hde",
    aliasCode: "design",
    icon: "draw",
    enabled: true
  },
  Build: {
    moduleName: "Build",
    code: "lims",
    aliasCode: "build",
    icon: "wrench",
    enabled: true
  },
  Test: {
    moduleName: "Test",
    code: "test",
    aliasCode: "test",
    icon: "dashboard",
    enabled: false
  },
  Evolve: {
    moduleName: "Discover",
    code: "evolve",
    aliasCode: "evolve",
    icon: "lightbulb",
    enabled: false
  }
};

// Apply module config overrides
Object.keys(window.frontEndConfig || {})
  .filter(k => k.endsWith("Module"))
  .forEach(k => {
    let obj;
    if (typeof window.frontEndConfig[k] === "string")
      obj = JSON.parse(window.frontEndConfig[k]);
    else obj = window.frontEndConfig[k];
    const name = startCase(k.replace("Module", ""));
    Object.assign(platformModules[name], obj);
  });

if (window.frontEndConfig) {
  if (
    window.frontEndConfig.designUrl ||
    window.frontEndConfig.buildUrl ||
    window.frontEndConfig.testUrl ||
    window.frontEndConfig.evolveUrl ||
    window.frontEndConfig.enabledModules
  ) {
    platformModules.Design.enabled = false;
    platformModules.Build.enabled = false;
    platformModules.Test.enabled = false;
    platformModules.Evolve.enabled = false;
  }

  if (window.frontEndConfig.enabledModules) {
    const enabledModules = window.frontEndConfig.enabledModules.split(",");
    enabledModules.forEach(modName => {
      const configName = startCase(modName);
      if (platformModules[configName]) {
        platformModules[configName].enabled = true;
      }
    });
  }

  if (window.frontEndConfig.designUrl) {
    platformModules.Design.href = window.frontEndConfig.designUrl;
    platformModules.Design.enabled = true;
  }

  if (window.frontEndConfig.buildUrl) {
    platformModules.Build.href = window.frontEndConfig.buildUrl;
    platformModules.Build.enabled = true;
  }

  if (window.frontEndConfig.testUrl) {
    platformModules.Test.href = window.frontEndConfig.testUrl;
    platformModules.Test.enabled = true;
  }

  if (window.frontEndConfig.evolveUrl) {
    platformModules.Evolve.href = window.frontEndConfig.evolveUrl;
    platformModules.Evolve.enabled = true;
  }
}

export const PlatformSidebar = ({ loggedIn }) => {
  if (!loggedIn) return null;
  const serverURI = window.frontEndConfig.serverBasePath || "";
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        width: "40px",
        backgroundColor: "#555",
        color: "white",
        display: "flex",
        flexFlow: "column",
        zIndex: "20 /* TODO */",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
      id="platform-sidebar"
    >
      <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
        <div className="sidebar-logo">
          <img
            src={joinUrl(serverURI, "/teselagen_square_logo.jpg")}
            alt="logo"
          />
        </div>
      </Link>
      <ModuleLinks />
    </div>
  );
};

export default PlatformSidebar;

export function ModuleLinks({ onMobile }) {
  return (
    <div className="module-links">
      {["Design", "Build", "Evolve"].map(moduleName => {
        const activeModule = startCase(window.frontEndConfig.tgModuleName);
        const _module = platformModules[moduleName];
        const curModule = platformModules[activeModule];
        const isActive = moduleName === activeModule;
        const feModuleName = platformModules[moduleName].moduleName;

        const moduleEnabled = _module.enabled;
        let moduleHref = "";
        const { url, href } = _module;
        if (!url && !href) {
          // moduleEnabled = false;
          // Not sure we still need this logic moving forward
          const curURL = window.location.href;
          moduleHref = curURL
            .split("/")
            .slice(0, 3)
            .join("/")
            .replace(curModule.code, _module.code)
            .replace(curModule.aliasCode, _module.aliasCode);
        } else if (url) {
          const curURL = window.location.href;
          const curUrlParts = urlUtil.parse(curURL);
          const urlParts = urlUtil.parse(url);
          moduleHref = urlUtil.format({
            protocol: curUrlParts.protocol,
            host: curUrlParts.host,
            port: curUrlParts.port,
            auth: curUrlParts.auth,
            pathname: urlParts.pathname,
            hash: urlParts.hash,
            search: urlParts.search
          });
        } else {
          // must be href
          moduleHref = href;
        }
        const mobileName = onMobile ? (
          <span style={{ marginLeft: 3 }}>{feModuleName}</span>
        ) : null;

        if (moduleEnabled) {
          return (
            <Tooltip
              position="right"
              key={moduleName}
              content={`${feModuleName} module`}
            >
              <a
                href={moduleHref}
                className={cn(_module.code, {
                  disabled: !_module.enabled,
                  active: isActive
                })}
                style={
                  onMobile
                    ? {
                        margin: "0px 5px",
                        display: "flex",
                        alignItems: "center"
                      }
                    : {}
                }
              >
                <Icon
                  iconSize={onMobile ? 12 : undefined}
                  icon={_module.icon}
                />
                {mobileName}
              </a>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              position="right"
              key={moduleName}
              content={`${feModuleName} module (not availabe in this edition)`}
            >
              <span
                className={cn(_module.code, {
                  disabled: !_module.enabled,
                  active: isActive
                })}
              >
                <Icon icon={_module.icon} /> {mobileName}
              </span>
            </Tooltip>
          );
        }
      })}
    </div>
  );
}
