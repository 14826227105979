/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import {
  DataTable,
  DialogFooter,
  Loading,
  wrapDialog,
  getSelectedEntities
} from "@teselagen/ui";
import { importSequenceFromBuildToDesign } from "../../../utils/sequenceUtils";

function ImportSequencesFromBuildDialog(props) {
  const { records: sequences, hideModal, refetch } = props;

  const [loading, setLoading] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [numImported, setNumImported] = useState(0);

  useEffect(() => {
    async function handleImport() {
      setLoading(true);
      try {
        const sequenceIds = sequences.map(sequence => sequence.id);
        const {
          newSequences,
          dupSequences
        } = await importSequenceFromBuildToDesign(sequenceIds);

        if (newSequences.length) {
          setNumImported(newSequences.length);
          await refetch();
        }
        if (dupSequences.length) {
          setDuplicates(dupSequences);
          setLoading(false);
        } else {
          window.toastr.success(`Imported ${newSequences.length} sequences.`);
          hideModal();
        }
      } catch (error) {
        setLoading(false);
        console.error(`error:`, error);
        window.toastr.error("Error loading sequences from build");
      }
    }
    handleImport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmitDuplicates() {
    try {
      setLoading(true);
      const dupsToImport = getSelectedEntities(
        window.teGlobalStore,
        "importSeqFromBuildDupTable"
      );
      const sequences = dupsToImport.map(d => d.original);
      const importResponse = await window.cliApi({
        method: "POST",
        moduleName: "design",
        url: `/import/sequence`,
        data: {
          allowDuplicates: true,
          sequences: sequences
        }
      });
      await refetch();
      window.toastr.success(
        `Imported ${importResponse.data.createdSequences.length} duplicate sequences.`
      );
      hideModal();
    } catch (error) {
      setLoading(false);
      console.error(`error:`, error);
      window.toastr.error("Error importing duplicates");
    }
  }

  if (duplicates.length) {
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {numImported > 0 && (
            <div style={{ marginBottom: 10 }}>
              Imported {numImported} sequences.
            </div>
          )}
          There were {duplicates.length} duplicate sequences. Select which ones
          you would like to import:
          <div
            style={{
              marginBottom: 10
            }}
          />
          <DataTable
            formName="importSeqFromBuildDupTable"
            isSimple
            schema={[
              "name",
              { displayName: "Size (bp)", path: "size", type: "integer" }
            ]}
            entities={duplicates}
            withCheckboxes
          />
        </div>
        <DialogFooter
          loading={loading}
          hideModal={hideModal}
          onClick={onSubmitDuplicates}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {loading && <Loading bounce inDialog />}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Import Sequences from Build"
  })
)(ImportSequencesFromBuildDialog);
