/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { IntentText, SelectField } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { get, keyBy, every } from "lodash";
import { updateEditor } from "@teselagen/ove";
import forcedAssemblyStrategies from "../../../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import store from "../../../../../src-shared/redux/store";
import RecordTags from "../../../../../src-shared/RecordInfoDisplay/RecordTags";
import "./style.css";
import {
  getItemOfType,
  getOperand1Elements,
  getOperand2Elements,
  getInputCardsOfReaction,
  getBinIdsInCard,
  getElementsInBin
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import PartsetSection from "./PartsetSection";
import SimpleElementSection from "./SimpleElementSection";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import VerticalTable from "../../../../../src-shared/components/VerticalTable";
import { renderInspectorSequenceWithQuery } from "../../../../utils/sequenceUtils";
import { pushHelper } from "../../../../../src-shared/utils/pushHelper";
import { SimpleAAView } from "../../../../../src-shared/AminoAcidRecordView";

const eugeneRuleSchema = [
  { path: "name", type: "string", displayName: "Name" },
  { path: "operand1", type: "string", displayName: "Operand 1" },
  {
    path: "negationOperator",
    type: "string",
    displayName: "NOT?",
    render: val => (!val ? "false" : "true")
  },
  { path: "compositionalOperator", type: "string", displayName: "Operator" },
  { path: "operand2", type: "string", displayName: "Operand 2" },
  { path: "operand2Number", type: "number", displayName: "MORETHAN #" }
];

const cellRenderer = {
  operand1: els => els.map(el => el.name).join(", "),
  operand2: els => els.map(el => el.name).join(", ")
};

function getOperandCardId(ruleId, isOperand1) {
  const state = store.getState();
  const { reactionId } = getItemOfType(state, "eugeneRule", ruleId);

  const elements = (isOperand1 ? getOperand1Elements : getOperand2Elements)(
    state,
    ruleId
  );
  if (!elements.length) return null;
  const elementIds = keyBy(elements, "id");

  const inputs = getInputCardsOfReaction(state, reactionId);
  for (const input of inputs) {
    const binIds = getBinIdsInCard(state, input.id);
    for (const binId of binIds) {
      const elements = getElementsInBin(state, binId);
      if (elements.some(el => elementIds[el.id])) {
        return input.id;
      }
    }
  }

  return null;
}

class ElementPanel extends React.Component {
  handleOverhangsChange = overhangName => overhangs => {
    const { updateElement, selectedElement } = this.props;

    if (
      !areOverhangsValid(overhangs) ||
      !overhangs ||
      !overhangs.trim() ||
      overhangs === selectedElement[overhangName]
    )
      return;

    updateElement({
      element: {
        id: selectedElement.id,
        [overhangName]: overhangs
      }
    });
  };

  handleNotesChange = notes => {
    const { updateElement, selectedElement } = this.props;

    updateElement({
      element: {
        id: selectedElement.id,
        notes
      }
    });
  };

  handleFasChange = fas => {
    const {
      selectedCardId,
      selectedElement,
      changeFas,
      inputCardId,
      isViewClassic
    } = this.props;

    changeFas({
      fas,
      cardId: isViewClassic ? inputCardId : selectedCardId,
      elementId: selectedElement.id
    });
  };

  handleAddRuleClick = () => {
    const { isViewClassic, inputCardId, selectedCardId } = this.props;
    showDialog({
      modalType: "ADD_EUGENE_RULE",
      modalProps: {
        cardId: isViewClassic ? inputCardId : selectedCardId
      }
    });
  };

  handleDeleteRuleClick = selectedRule => {
    const { removeEugeneRule } = this.props;

    removeEugeneRule(selectedRule.id);
    this.setState({
      selectedRule: null
    });
  };

  createEditEugeneRulesInitialValues = selectedRule => {
    const state = store.getState();

    const operand1Elements = getOperand1Elements(state, selectedRule.id).map(
      el => el.id
    );
    const operand2Elements = getOperand2Elements(state, selectedRule.id).map(
      el => el.id
    );

    return {
      id: selectedRule.id,
      name: selectedRule.name,
      negationOperator: selectedRule.negationOperator,
      compositionalOperator: selectedRule.compositionalOperator,
      operand1: get(selectedRule, "operand1[0]"),
      operand2Number: selectedRule.operand2Number,
      operand2: get(selectedRule, "operand2[0].id"),
      operand2CardId: getOperandCardId(selectedRule.id, false),
      operand1Elements,
      operand2Elements
    };
  };

  handleEditRuleClick = selectedRule => {
    const { isViewClassic, inputCardId, eugeneRules = [] } = this.props;
    const ruleToUse =
      eugeneRules.find(({ id }) => id === selectedRule.id) || selectedRule;
    showDialog({
      modalType: "ADD_EUGENE_RULE",
      modalProps: {
        isEdit: true,
        cardId: isViewClassic
          ? inputCardId
          : getOperandCardId(selectedRule.id, true),
        initialValues: this.createEditEugeneRulesInitialValues(ruleToUse)
      }
    });
  };

  handleViewPartClick = e => {
    const { part } = this.props;
    updateEditor(store, "SequenceEditor", {
      selectionLayer: {
        start: part.start,
        end: part.end
      }
    });
    pushHelper(e, `/sequences/${part.sequenceId}`);
  };

  handleViewAAPartClick = e => {
    const { aminoAcidPart } = this.props;
    updateEditor(store, "SequenceEditor", {
      selectionLayer: {
        start: aminoAcidPart.start,
        end: aminoAcidPart.end
      }
    });
    pushHelper(e, `/amino-acid-sequences/${aminoAcidPart.aminoAcidSequenceId}`);
  };

  render() {
    const {
      selectedElement,
      isVisualReport,
      isLocked,
      isSelectedCardRoot,
      isViewClassic,
      inspectorWidth,
      part,
      partsetParts = [],
      aminoAcidPart
    } = this.props;

    const tooltipMsg =
      "FAS and Eugene Rules are disabled because parts in the root card will never be inputs into an assembly reaction";

    if (!selectedElement)
      return (
        <div className="element-inspector-panel">
          <i>
            Double click a cell to insert a part, or select an existing part to
            view its details.
          </i>
        </div>
      );

    const { eugeneRules } = this.props;
    const isEditDisabled =
      (!isViewClassic && (isVisualReport || isSelectedCardRoot)) || isLocked;
    const isAAPart = selectedElement.aminoAcidPartId;

    const validForcedAssemblyStrategies = forcedAssemblyStrategies
      // Only digest parts are allowed to be set as FAS=DIGEST
      .filter(
        fas =>
          fas !== "DIGEST" ||
          part?.isDigestPart ||
          every(partsetParts, part => part.isDigestPart)
      );

    return (
      <div className="element-inspector-panel">
        <h5 className="inspector-panel-header">Part Details</h5>
        <form>
          {!!selectedElement.partId && (
            <React.Fragment>
              {renderInspectorSequenceWithQuery(
                { id: part.sequenceId },
                {
                  width: inspectorWidth - 50,
                  selectionLayer: part
                }
              )}
              <Button
                minimal
                style={{ marginBottom: 10, width: "100%" }}
                text="View Part"
                intent={Intent.PRIMARY}
                onClick={this.handleViewPartClick}
              />
              <RecordTags
                readOnly={isLocked}
                recordId={selectedElement.partId}
                model="part"
              />
              <div style={{ marginBottom: 10 }} />
            </React.Fragment>
          )}
          {!!aminoAcidPart?.aminoAcidSequenceId && (
            <React.Fragment>
              <SimpleAAView
                {...{ width: inspectorWidth - 50 }}
                selectionLayer={aminoAcidPart}
                recordIdOverride={aminoAcidPart?.aminoAcidSequenceId}
              ></SimpleAAView>
              <Button
                minimal
                style={{ marginBottom: 10, width: "100%" }}
                text="View Part"
                intent={Intent.PRIMARY}
                onClick={this.handleViewAAPartClick}
              />
              <RecordTags
                readOnly={isLocked}
                recordId={selectedElement.aminoAcidPartId}
                model="aminoAcidPart"
              />
              <div style={{ marginBottom: 10 }} />
            </React.Fragment>
          )}
          {selectedElement.partsetId ? (
            <PartsetSection {...this.props} />
          ) : (
            <SimpleElementSection
              {...this.props}
              handleOverhangsChange={this.handleOverhangsChange}
              handleNotesChange={this.handleNotesChange}
            />
          )}
          {!isViewClassic && isSelectedCardRoot && (
            <IntentText style={{ marginBottom: 12 }} intent="warning">
              {tooltipMsg}
            </IntentText>
          )}
          {!isAAPart && (
            <div>
              <div
                style={{
                  width: "18em"
                }}
              >
                <SelectField
                  label="Forced Assembly Strategy"
                  name="fas"
                  options={validForcedAssemblyStrategies.map(fas => ({
                    label: fas.replace(/_/g, " "),
                    value: fas
                  }))}
                  disabled={isEditDisabled}
                  onFieldSubmit={this.handleFasChange}
                />
              </div>
              <div>
                <h6 style={{ display: "flex", alignItems: "center" }}>
                  Eugene Rules{" "}
                  <Button
                    minimal
                    style={{ marginLeft: 6 }}
                    className="add-eugene-rule-button"
                    intent="success"
                    // text="Add Rule"
                    icon="add"
                    onClick={this.handleAddRuleClick}
                    disabled={isEditDisabled || selectedElement.isEmpty}
                  />
                </h6>
                <VerticalTable
                  maxHeight={185}
                  schema={eugeneRuleSchema}
                  entities={eugeneRules}
                  cellRenderer={cellRenderer}
                  onDelete={!isEditDisabled && this.handleDeleteRuleClick}
                  onEdit={!isEditDisabled && this.handleEditRuleClick}
                />

                <div
                  className="bp3-button-group"
                  style={{
                    marginTop: 5,
                    marginBottom: 15
                  }}
                ></div>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!areOverhangsValid(values.preferred5PrimeOverhangs))
    errors.preferred5PrimeOverhangs = "Invalid";
  if (!areOverhangsValid(values.preferred3PrimeOverhangs))
    errors.preferred3PrimeOverhangs = "Invalid";
  return errors;
};

// Note: This won't check that the overahngs are completely contained within
// the parts themselves. In other words, there can be some overhangs that
// pass this test but are still invalid.
const areOverhangsValid = str =>
  !str || /^(?:\((?:\+|-)?\d+,\d+\),)*$/m.test(str.replace(/\s/g, "") + ",");

export default reduxForm({
  form: "elementPanelForm", // a unique name for this form
  enableReinitialize: true,
  validate
})(ElementPanel);
