/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { createAppRoleCodeMap } = require("./createAppRoleCodeMap");
const { createUserLabsList } = require("./createUserLabsList");
const {
  filterLabRolesForSingleUserLabs
} = require("./filterLabRolesForSingleUserLabs");
const { logDebug } = require("./logDebug");
const { setDefaultLabRoleForUser } = require("./setDefaultLabRoleForUser");

function normalizeUserRecord(user, opts = {}) {
  let { isLabCreationEnableForMembers = false } = opts;
  if (isLabCreationEnableForMembers) {
    console.error(
      `[AUTH-UTILS] isLabCreationEnableForMembers is enabled but app is in SINGLE_LAB_MODE which prohibits this settings. Disabling setting.`
    );
    isLabCreationEnableForMembers = false;
  }
  logDebug(`Normalizing user ${user.username} with opts`, opts);
  user = setDefaultLabRoleForUser(user);
  user = createAppRoleCodeMap(user, isLabCreationEnableForMembers);
  user = createUserLabsList(user);
  user = filterLabRolesForSingleUserLabs(user);

  return user;
}

module.exports = { normalizeUserRecord };
