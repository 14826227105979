/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { map, debounce, isEmpty, isEqual } from "lodash";
import { withHotkeys, tgFormValues } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import {
  Menu,
  MenuItem,
  MenuDivider,
  InputGroup,
  Classes,
  Overlay,
  Tooltip,
  Button,
  Popover
} from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import classNames from "classnames";
import { stringify } from "qs";
import TagFilterMenu from "../TagFilterMenu";
import modelNameToLink from "../utils/modelNameToLink";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { tagModels } from "../../../tg-iso-shared/constants";
import { getFilteredRecords } from "./utils";
import { formatDateTime } from "../utils/dateUtils";
import { withProps } from "recompose";
import shortid from "shortid";
import { observable } from "mobx";
import { observer } from "mobx-react";
import "./style.css";
import { renderProjectItems } from "../utils/libraryColumns";

const universalSearchStore = observable({
  isOpen: false
});

export function showUniversalSearch() {
  universalSearchStore.isOpen = true;
}

export function hideUniversalSearch() {
  universalSearchStore.isOpen = false;
}

class UniversalSearch extends Component {
  state = {
    searchTerm: "",
    loading: false,
    modelMap: {}
  };

  static defaultProps = {
    timeField: "updatedAt"
  };

  constructor(props) {
    super(props);
    this.changeReduxFormSearchTerm = debounce(
      this.changeReduxFormSearchTerm,
      300
    );

    const { hotKey } = this.props;
    this.HotkeyEnabler = withHotkeys({
      searchHotkey: {
        allowInInput: true,
        global: true,
        combo: hotKey,
        label: "Show Universal Search",
        onKeyDown: this.handleToggle
      }
    });
  }

  activeSearch = { key: null };

  async UNSAFE_componentWillReceiveProps(newProps) {
    const { modelMap } = this.state;
    const {
      universalSearchTerm = "",
      tagFilterParams = {},
      noNameModels = []
    } = newProps;
    const {
      universalSearchTerm: oldSearchTerm,
      tagFilterParams: oldTagFilterParams = {},
      modelsToSearch,
      timeField
    } = this.props;
    const hasNewSearchTerm = universalSearchTerm !== oldSearchTerm;
    const hasNewTags = !isEqual(tagFilterParams.tags, oldTagFilterParams.tags);
    if (!this.hasQuery(universalSearchTerm, newProps)) {
      // cancel active queries
      this.cancelSearch();
      if (!isEmpty(modelMap)) {
        this.setState({
          modelMap: {}
        });
      }
    } else if (hasNewSearchTerm || hasNewTags) {
      this.setState({
        loading: true
      });
      const searchKey = shortid();
      this.activeSearch.key = searchKey;
      // if user started typing again after this search was fired we can use this to cancel
      // some of the outdated queries
      const isStaleSearch = () => searchKey !== this.activeSearch.key;
      try {
        this.setState({
          modelMap: {}
        });

        await getFilteredRecords({
          modelsToSearch,
          noNameModels,
          universalSearchTerm,
          tagFilterParams,
          timeField,
          isStaleSearch,
          setModelMap: modelMap => {
            if (!isStaleSearch()) {
              this.setState({
                modelMap
              });
            }
          }
        });
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error searching.");
      }
      if (!isStaleSearch()) {
        this.setState({
          loading: false
        });
      }
    }
  }

  cancelSearch = () => {
    this.activeSearch.key = shortid();
    this.setState({
      loading: false
    });
  };

  hasQuery = (searchTerm, props) => {
    const { tagFilterParams = {} } = props || this.props;
    return searchTerm !== "" || !!tagFilterParams.tags?.length;
  };

  changeReduxFormSearchTerm = searchTerm => {
    this.setState({
      startingSearch: false
    });
    this.props.change("universalSearchTerm", searchTerm);
  };

  onSearchChange = e => {
    const searchTerm = e.target.value;
    this.setState({
      searchTerm,
      startingSearch: true,
      modelMap: {}
    });
    this.changeReduxFormSearchTerm(searchTerm);
  };

  setTagFilterParams = newParams => {
    this.props.change("tagFilterParams", newParams);
  };

  onClose = () => {
    const { reset } = this.props;
    hideUniversalSearch();
    this.cancelSearch();
    this.setState({
      searchTerm: ""
    });
    reset();
  };

  handleToggle = () => {
    universalSearchStore.isOpen ? this.onClose() : showUniversalSearch();
  };

  render() {
    const { searchTerm, loading, modelMap, startingSearch } = this.state;
    const {
      history,
      universalSearchTerm,
      timeField,
      tagFilterParams = {},
      noNameModels = []
    } = this.props;

    let rightElement;
    if (tagModels.length) {
      rightElement = (
        <Popover
          content={
            <TagFilterMenu
              currentParams={tagFilterParams}
              setNewParams={this.setTagFilterParams}
            />
          }
        >
          <Button active={tagFilterParams.tags} minimal icon="tag" />
        </Popover>
      );
    }
    const hasQuery = this.hasQuery(searchTerm);
    const isLoading = hasQuery && (loading || startingSearch);

    const noResults = hasQuery && !isLoading && isEmpty(modelMap) && (
      <Menu>
        <MenuItem disabled text="No Results" />
      </Menu>
    );

    const searchComponent = (
      <div className="tg-universal-search">
        <InputGroup
          leftIcon="search"
          placeholder="Search..."
          autoFocus
          rightElement={
            <div style={{ display: "flex" }}>
              {isLoading && <Button minimal disabled loading />}
              {rightElement}
            </div>
          }
          onChange={this.onSearchChange}
          value={searchTerm}
          className={Classes.LARGE}
        />
        <div style={{ maxHeight: "75vh", overflow: "auto" }}>
          {hasQuery && (
            <React.Fragment>
              {map(
                modelMap,
                (
                  { displayName, entities = [], entityCount, route },
                  modelNameKey
                ) => {
                  let model = modelNameKey;
                  if (modelNameKey.includes("-"))
                    model = modelNameKey.split("-")[0];
                  const noName = noNameModels.includes(model);
                  if (!entities.length) return null;
                  return (
                    <div key={modelNameKey}>
                      <Menu>
                        <div
                          className={classNames(
                            "tg-flex",
                            "justify-space-between"
                          )}
                          style={{
                            paddingLeft: 10,
                            paddingRight: 20,
                            marginTop: 10
                          }}
                        >
                          <h6>{displayName}</h6>
                          {entityCount > 5 && (
                            <Tooltip
                              content={`View all ${entityCount} ${displayName} matching this filter in the library.`}
                            >
                              <Button
                                style={{
                                  height: 15
                                }}
                                className={classNames(
                                  Classes.MINIMAL,
                                  Classes.SMALL
                                )}
                                onClick={() => {
                                  this.onClose();
                                  const filter = {};
                                  if (!noName && universalSearchTerm) {
                                    filter.filters = `name__contains__${universalSearchTerm}`;
                                  }
                                  if (!isEmpty(tagFilterParams.tags)) {
                                    filter.tags = tagFilterParams.tags;
                                  }
                                  history.push(
                                    (route || modelNameToLink(model)) +
                                      "?" +
                                      stringify(filter)
                                  );
                                }}
                              >
                                Total: {entityCount}
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                        <MenuDivider />
                        <React.Fragment>
                          {entityCount > 5 && (
                            <MenuItem disabled text="Top 5 Results" />
                          )}
                          {entities.map(
                            ({
                              id,
                              name,
                              barcode,
                              aliases = [],
                              projectItems = [],
                              ...rest
                            }) => {
                              let displayName;
                              if (noName || !name) {
                                displayName =
                                  modelNameToReadableName(model, {
                                    upperCase: true
                                  }) + ` ${id}`;
                              } else {
                                displayName = name;
                              }
                              let barcodeEl;
                              if (barcode && barcode.barcodeString) {
                                barcodeEl = (
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginLeft: 3
                                    }}
                                    className={Classes.TEXT_MUTED}
                                  >
                                    ({barcode.barcodeString})
                                  </span>
                                );
                              }
                              let aliasComp;
                              if (aliases.length) {
                                aliasComp = (
                                  <div className={Classes.TEXT_MUTED}>
                                    {aliases.map(a => a.name).join(", ")}
                                  </div>
                                );
                              }
                              let projectsComp;
                              if (projectItems) {
                                projectsComp = (
                                  <div
                                    style={{
                                      transform: "scale(0.8)"
                                    }}
                                  >
                                    {renderProjectItems({ projectItems })}
                                  </div>
                                );
                              }
                              return (
                                <MenuItem
                                  key={id}
                                  text={
                                    <div>
                                      <span>
                                        {displayName}
                                        {barcodeEl}
                                      </span>
                                      {aliasComp}
                                      {projectsComp}
                                    </div>
                                  }
                                  label={formatDateTime(rest[timeField])}
                                  onClick={() => {
                                    this.onClose();
                                    history.push(
                                      route
                                        ? route + `/${id}`
                                        : modelNameToLink(model, id)
                                    );
                                  }}
                                />
                              );
                            }
                          )}
                        </React.Fragment>
                      </Menu>
                    </div>
                  );
                }
              )}
              {noResults}
            </React.Fragment>
          )}
        </div>
      </div>
    );
    const HotkeyEnabler = this.HotkeyEnabler;
    return (
      <React.Fragment>
        <HotkeyEnabler />
        <Overlay
          className="tg-universal-search-overlay"
          isOpen={universalSearchStore.isOpen}
          onClose={this.onClose}
        >
          {searchComponent}
        </Overlay>
      </React.Fragment>
    );
  }
}

const globalModels = ["restrictionEnzyme"];

export default compose(
  reduxForm({
    form: "universalSearchForm"
  }),
  tgFormValues("universalSearchTerm", "tagFilterParams"),
  withProps(props => {
    return {
      modelsToSearch: (props.modelsToSearch || []).concat(globalModels)
    };
  }),
  withRouter,
  observer
)(UniversalSearch);
