/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { invert } from "lodash";

export const inventoryItemTypeMap = {
  containerArray: "CONTAINER_ARRAY",
  aliquot: "ALIQUOT",
  aliquotContainer: "ALIQUOT_CONTAINER",
  material: "MATERIAL",
  sequence: "DNA_SEQUENCE",
  sequenceFeature: "SEQUENCE_FEATURE",
  additiveMaterial: "ADDITIVE_MATERIAL",
  reagent: "ADDITIVE_MATERIAL",
  functionalProteinUnit: "FUNCTIONAL_PROTEIN_UNIT",
  aminoAcidSequence: "AMINO_ACID_SEQUENCE",
  sample: "SAMPLE",
  lot: "LOT",
  strain: "STRAIN"
};

export const inventoryItemTypeCodeToModel = {
  ...invert(inventoryItemTypeMap),
  ADDITIVE_MATERIAL: "additiveMaterial"
};

export const dataItemTypeMap = {
  plateMapGroup: "PLATE_MAP_GROUP",
  plateMap: "PLATE_MAP",
  worklist: "WORKLIST",
  reactionMap: "REACTION_MAP",
  reactionDesign: "REACTION_DESIGN",
  dataTable: "DATA_TABLE",
  dataSet: "DATA_SET",
  dataMap: "DATA_MAP",
  vendorOrder: "VENDOR_ORDER",
  crisprDesign: "CRISPR_DESIGN",
  dataGrid: "DATA_GRID",
  assay: "ASSAY",
  j5Report: "J5_REPORT",
  alignment: "ALIGNMENT",
  sequencingQualityControlReport: "SEQUENCING_QUALITY_CONTROL_REPORT",
  dataFile: "DATA_FILE"
};
export const dataItemTypeCodeToModel = invert(dataItemTypeMap);
