/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {keyBy} from "lodash";

import * as apolloMethods from "../apolloMethods";
import {
  extendedPropertyDisplaySimpleValueFragment,
  transformSingleExtendedValue
} from "../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import {
  displayPropertyValue,
  getLinkValueItems
} from "../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "./modelNameToLink";

export * from "../../../tg-iso-shared/src/utils/extendedPropertiesUtils";

export function displayPropertyValueFrontEnd(v) {
  if (v.__typename === "extendedLinkValue") {
    const items = getLinkValueItems(v);
    return items.map((v, i) => (
      <span key={v.id}>
        <Link to={modelNameToLink(v)}>{v.display}</Link>
        {i !== items.length - 1 && ", "}
      </span>
    ));
  } else {
    return displayPropertyValue(v);
  }
}

export async function getExistingPropTemplateRows({
  model,
  itemIds,
  propsForTemplate
}) {
  const itemsWithProps = await apolloMethods.safeQuery(
    [
      model,
      /* GraphQL */ `
        {
          id
          ${extendedPropertyDisplaySimpleValueFragment}
        }
      `
    ],
    {
      variables: {
        filter: {
          id: itemIds
        }
      }
    }
  );
  const keyedItems = keyBy(itemsWithProps, "id");
  const keyedProps = keyBy(propsForTemplate, "id");
  const propsIdsForTemplate = propsForTemplate.map(p => p.id);
  const itemIdToRow = {};
  itemIds.forEach(id => {
    itemIdToRow[id] = [];
    const propIdToVal = {};

    const valKeys = [
      "extendedValues",
      "extendedCategoryValues",
      "extendedMeasurementValues"
    ];
    const keyedItem = keyedItems[id];
    if (!keyedItem) return;
    valKeys.forEach(key => {
      keyedItem[key].forEach(val => {
        if (propsIdsForTemplate.includes(val.extendedPropertyId)) {
          propIdToVal[val.extendedPropertyId] = transformSingleExtendedValue({
            ...val,
            extendedProperty: keyedProps[val.extendedPropertyId]
          }).value;
        }
      });
    });
    propsForTemplate.forEach(prop => {
      itemIdToRow[id].push(propIdToVal[prop.id] || "");
    });
  });
  return itemIdToRow;
}
