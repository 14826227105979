/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Icon, Menu, MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../AbstractLibrary";
import { showDialog } from "../../GlobalDialog";
import {
  MultiLineSearchMenuItem,
  sequenceSearchMenu
} from "../../components/SequenceSearch";
import { disableOligosInSeqLibHelper } from "../../utils/disableOligosInSeqLibHelper";
import DNASequenceRecordView from "../../DNASequenceRecordView";
import {
  isBuild,
  isDesign
} from "../../../../tg-iso-shared/src/utils/isModule";
import handlePartOrSequenceLibraryDelete from "../../utils/handlePartOrSequenceLibraryDelete";
import { contextMenu } from "../../TgSequenceEditor/ContextMenu";
import UploadDNASequenceDialog from "../../UploadDNASequenceDialog";
import ImportSequencesFromBuildDialog from "../../components/Dialogs/ImportSequencesFromBuildDialog";
import routeDoubleClick from "../../utils/routeDoubleClick";

const IncludeSequencesFromBuildMenuItem = props => {
  const { tableParams } = props;
  const curVal = tableParams.currentParams.showSequencesFromBuild;
  return (
    <MenuItem
      text="Show Sequences in Build"
      labelElement={curVal ? <Icon icon="tick" /> : null}
      onClick={() => {
        const newParams = {
          ...tableParams.currentParams
        };
        if (curVal) {
          // delete newParams.showSequencesFromBuild;
          newParams.showSequencesFromBuild = undefined;
        } else {
          newParams.showSequencesFromBuild = true;
        }
        tableParams.setNewParams(newParams);
      }}
    ></MenuItem>
  );
};

const deleteSequence = ({ records, refetch }) => {
  handlePartOrSequenceLibraryDelete({
    isPart: false,
    items: records,
    refetch: refetch
  })();
};

const GenericSequenceLibrary = props => {
  const { isRNA, history, refetchSequences } = props;

  const showSequenceUpload = () => {
    showDialog({
      ModalComponent: UploadDNASequenceDialog,
      modalProps: {
        isRNA,
        refetch: refetchSequences
      }
    });
  };

  const additionalContextMenu = selectedRecords => {
    if (isDesign()) {
      if (selectedRecords.every(r => r.moduleSelector === "build")) {
        // all selected records are from build so show ONLY import from build option
        return [
          <MenuItem
            key="importFromBuild"
            icon="import"
            text="Import from Build"
            onClick={() => {
              showDialog({
                ModalComponent: ImportSequencesFromBuildDialog,
                modalProps: {
                  records: selectedRecords,
                  refetch: refetchSequences
                }
              });
            }}
          />
        ];
      } else if (selectedRecords.some(r => r.moduleSelector === "build")) {
        // some selected records are from build and some from design so hide all additional options
        return [];
      }
    }
    return contextMenu({
      selectedRecords,
      ...props
    });
  };

  const onNewItemClick = () => {
    history.push(isRNA ? "/rna-sequences/new" : "/dna-sequences/new");
  };

  const onDoubleClick = (record, ...args) => {
    let routeOverride;
    if (isRNA) {
      if (record.rnaType?.name === "gRNA") {
        routeOverride = `/guide-rna-sequences/${record.id}`;
      } else {
        routeOverride = `/rna-sequences/${record.id}`;
      }
    }
    routeDoubleClick({ ...record, routeOverride }, ...args);
  };

  return (
    <AbstractLibrary
      {...props}
      onDoubleClick={onDoubleClick}
      libraryTitle={isRNA ? "RNA Sequences" : "DNA Sequences"}
      noNewItem={isBuild()}
      onNewItemClick={isDesign() ? onNewItemClick : undefined}
      model="sequence"
      sequenceType={isRNA ? "RNA" : undefined}
      libraryName={isRNA ? "rnaSequence" : "dnaSequence"}
      showUploadFunction={showSequenceUpload}
      generateExtraContextMenuItems={additionalContextMenu}
      isLibraryTable
      RecordViewInspector={DNASequenceRecordView}
      withExport
      onDelete={isDesign() ? deleteSequence : undefined}
      {...disableOligosInSeqLibHelper}
      searchMenu={
        <Menu>
          {sequenceSearchMenu({
            setSearchTerm: props.tableParams.setSearchTerm
          })}
          {isDesign() ? <IncludeSequencesFromBuildMenuItem {...props} /> : null}
          <MultiLineSearchMenuItem {...props}></MultiLineSearchMenuItem>
        </Menu>
      }
    />
  );
};

export default GenericSequenceLibrary;
