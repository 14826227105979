/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { difference, flatMap } from "lodash";
import Promise from "bluebird";
import b64ToBlob from "b64-to-blob";
import { queryForDuplicateTags } from "./importUtils";
import shortid from "shortid";
import { chunkSequenceToFragments } from "../../tg-iso-shared/src/sequence-import-utils/utils";
import findByKey from "../utils/findByKey";
import customJ5ParameterFragment from "../graphql/fragments/customJ5ParameterFragment";
import { isoContext } from "@teselagen/utils";

export default async (
  json,
  uploadImages = false,
  pDesignCid,
  ctx = isoContext
) => {
  const designCid = pDesignCid || shortid();

  const duplicatedJ5ParamMap = await queryForDuplicateJ5Parameters(json, ctx);
  const duplicatedIconMap = await queryForDuplicateIconsAndUploadImages(
    json,
    uploadImages,
    ctx
  );
  const duplicatedTagMap = await queryForDuplicateTags(json.tags);
  const duplicatedEnzymeMap = await queryForDuplicateEnzymes(json, ctx);

  // NOTE: The order of these is very important.
  return [
    { entity: "design", inputs: createDesignInput(json, designCid) },
    {
      entity: "icon",
      inputs: createIconInputs(json, designCid, duplicatedIconMap)
    }, // icons
    {
      entity: "tag",
      inputs: createTagInputs(json, designCid, duplicatedTagMap)
    }, // tags
    {
      entity: "restrictionEnzyme",
      inputs: createRestrictionEnzymeInputs(
        json,
        designCid,
        duplicatedEnzymeMap
      )
    }, // restrictionEnzymes
    { entity: "sequence", inputs: createSequenceInputs(json, designCid) }, // sequences
    {
      entity: "customJ5Parameter",
      inputs: createCustomJ5ParameterInputs(
        json,
        designCid,
        duplicatedJ5ParamMap
      )
    }, // customJ5Parameters
    {
      entity: "j5OutputNamingTemplate",
      inputs: createJ5OutputNamingTemplateInputs(json, designCid)
    }, // j5OutputNamingTemplates
    //
    {
      entity: "part",
      inputs: createPartInputs(json, designCid, duplicatedTagMap)
    }, // parts
    { entity: "element", inputs: createElementInputs(json, designCid) }, // elements
    //
    {
      entity: "operation",
      inputs: createOperationInputs(
        json,
        designCid,
        duplicatedJ5ParamMap,
        duplicatedEnzymeMap
      )
    }, // operations
    {
      entity: "set",
      inputs: createSetInputs(json, designCid, duplicatedIconMap),
      forceSequential: true
    }, // sets
    //
    { entity: "operationSet", inputs: createOpSetInputs(json, designCid) }, // cards
    { entity: "designSet", inputs: createDesignSets(json, designCid) }
  ];
};

// If uploadImages ==== true, this function might mutate `json`.
const queryForDuplicateIconsAndUploadImages = async (
  json,
  uploadImages,
  { safeQuery }
) => {
  const duplicatedIconMap = {};
  await Promise.map(json.icons, async icon => {
    if (icon.image && uploadImages) {
      const blob = b64ToBlob(icon.image.base64, icon.image.contentType);
      const file = new File([blob], shortid(), {
        type: icon.image.contentType
      });
      const data = new FormData();
      data.append("file", file);
      const {
        data: [{ path }]
      } = await window.api.request({
        url: "/uploadCustomIcon",
        data
      });

      icon.path = path;
    } else {
      const duplicates = await safeQuery(["icon", "id name isSbol path"], {
        variables: {
          filter: {
            name: icon.name,
            isSbol: icon.isSbol,
            path: icon.path
          }
        }
      });
      if (duplicates.length) duplicatedIconMap[icon.id] = duplicates[0].id;
    }
  });
  return duplicatedIconMap;
};

const queryForDuplicateJ5Parameters = async (json, ctx) => {
  const { safeQuery } = ctx;
  const arrayOfPossibleDuplicates = await Promise.map(
    json.customJ5Parameters,
    params => {
      const filter = { ...params };
      delete filter.id;
      return safeQuery(customJ5ParameterFragment, {
        variables: {
          filter
        }
      });
    }
  );
  const duplicatedJ5ParamMap = {};
  json.customJ5Parameters.forEach((params, i) => {
    const possibleDuplicates = arrayOfPossibleDuplicates[i];
    if (possibleDuplicates[0]) {
      duplicatedJ5ParamMap[params.id] = possibleDuplicates[0].id;
    }
  });
  return duplicatedJ5ParamMap;
};

const queryForDuplicateEnzymes = async (json, { safeQuery }) => {
  const arrayOfPossibleDuplicates = await Promise.map(
    json.restrictionEnzymes,
    enzyme =>
      safeQuery(
        [
          "restrictionEnzyme",
          `id name forwardSnipPosition recognitionLength recognitionRegex
       recognitionStart reverseRecognitionRegex reverseSnipPosition sequence`
        ],
        {
          variables: {
            filter: {
              name: enzyme.name,
              forwardSnipPosition: enzyme.forwardSnipPosition,
              recognitionLength: enzyme.recognitionLength,
              recognitionRegex: enzyme.recognitionRegex,
              recognitionStart: enzyme.recognitionStart,
              reverseRecognitionRegex: enzyme.reverseRecognitionRegex,
              reverseSnipPosition: enzyme.reverseSnipPosition,
              sequence: enzyme.sequence
            }
          }
        }
      )
  );
  const duplicatedEnzymeMap = {};
  json.restrictionEnzymes.forEach((enzyme, i) => {
    const possibleDuplicates = arrayOfPossibleDuplicates[i];
    if (possibleDuplicates[0]) {
      duplicatedEnzymeMap[enzyme.id] = possibleDuplicates[0].id;
    }
  });
  return duplicatedEnzymeMap;
};

const createTagInputs = (json, designCid, duplicatedTagMap) =>
  (json.tags || [])
    .filter(t => !duplicatedTagMap[t.id])
    .map(t => ({
      cid: designCid + "-" + t.id,
      name: t.name,
      description: t.description,
      color: t.color
    }));

const createDesignInput = (json, designCid) => ({
  cid: designCid,
  name: json.name,
  description: json.description,
  type: json.type,
  boundaryAnalysisType: json.boundaryAnalysisType
});

const createDesignSets = (json, designCid) => [
  {
    setId: "&" + designCid + "-" + json.rootCardId,
    designId: "&" + designCid
  }
];

const createOpSetInputs = (json, designCid) =>
  flatMap(json.operations, operation =>
    operation.inputIds.map((inputId, relativeIndex) => {
      const card = json.cards.find(({ id }) => inputId === id);
      return {
        designId: "&" + designCid,
        inputSetId: "&" + designCid + "-" + card.id,
        operationId: "&" + designCid + "-" + operation.id,
        opSetInputFas: card.fases.map(fas => ({
          name: fas.name,
          elementId: "&" + designCid + "-" + fas.elementId,
          designId: "&" + designCid
        })),
        opSetInputEugeneRules: card.eugeneRules.map(rule => ({
          name: rule.name,
          element1Id: "&" + designCid + "-" + rule.element1Id,
          element2Id: rule.element2Id
            ? "&" + designCid + "-" + rule.element2Id
            : null,
          operand2Number: rule.operand2Number,
          negationOperator: rule.negationOperator,
          compositionalOperator: rule.compositionalOperator,
          designId: "&" + designCid
        })),
        relativeIndex
      };
    })
  );

const createIconInputs = (json, designCid, duplicatedIconMap) =>
  json.icons
    .filter(({ id }) => !duplicatedIconMap[id])
    .map(icon => ({
      cid: designCid + "-" + icon.id,
      name: icon.name,
      isSbol: icon.isSbol,
      path: icon.path
    }));

const createRestrictionEnzymeInputs = (json, designCid, duplicatedEnzymeMap) =>
  json.restrictionEnzymes
    .filter(enzyme => !duplicatedEnzymeMap[enzyme.id])
    .map(enzyme => ({
      cid: designCid + "-" + enzyme.id,
      name: enzyme.name,
      description: enzyme.description,
      forwardSnipPosition: enzyme.forwardSnipPosition,
      recognitionLength: enzyme.recognitionLength,
      recognitionRegex: enzyme.recognitionRegex,
      recognitionStart: enzyme.recognitionStart,
      reverseRecognitionRegex: enzyme.reverseRecognitionRegex,
      reverseSnipPosition: enzyme.reverseSnipPosition,
      sequence: enzyme.sequence
    }));

const createSequenceInputs = (json, designCid) =>
  json.sequences.map(sequence => ({
    cid: designCid + "-" + sequence.id,
    name: sequence.name,
    description: sequence.description,
    circular: sequence.circular,
    sequenceTypeCode: sequence.circular ? "CIRCULAR_DNA" : "LINEAR_DNA",
    size: sequence.sequence.length || sequence.size,
    sequenceFeatures: !sequence.features
      ? []
      : sequence.features.map(f => ({
          name: f.name,
          start: f.start,
          end: f.end,
          strand: f.strand,
          type: f.type
        })),
    sequenceFragments: chunkSequenceToFragments(sequence.sequence)
  }));

const createCustomJ5ParameterInputs = (json, designCid, duplicatedJ5ParamMap) =>
  json.customJ5Parameters
    .filter(({ id }) => !duplicatedJ5ParamMap[id])
    .map(params => ({
      ...params,
      cid: designCid + "-" + params.id,
      id: undefined
    }));

const createJ5OutputNamingTemplateInputs = (json, designCid) =>
  json.j5OutputNamingTemplates.map(template => ({
    cid: designCid + "-" + template.id,
    incrementStart: template.incrementStart,
    numDigits: template.numDigits,
    outputTarget: template.outputTarget,
    template: template.template,
    designId: "&" + designCid
  }));

const createPartInputs = (json, designCid, duplicatedTagMap) =>
  json.parts.map(part => ({
    cid: designCid + "-" + part.id,
    name: part.name,
    start: part.start,
    end: part.end,
    strand: part.strand,
    preferred3PrimeOverhangs: part.preferred3PrimeOverhangs,
    preferred5PrimeOverhangs: part.preferred5PrimeOverhangs,
    sequenceId: "&" + designCid + "-" + part.sequenceId,
    taggedItems: (part.tagIds || []).map(tagId => ({
      tagId: duplicatedTagMap[tagId] || "&" + designCid + "-" + tagId
    }))
  }));

const createElementInputs = (json, designCid) =>
  json.elements.map(element => ({
    cid: designCid + "-" + element.id,
    name: element.name,
    direction: element.direction,
    isWildCard: element.isWildCard,
    leftScars: element.leftScars,
    rightScars: element.rightScars,
    regex: element.regex,
    preferred3PrimeOverhangs: element.preferred3PrimeOverhangs,
    preferred5PrimeOverhangs: element.preferred5PrimeOverhangs,
    partId: element.partId ? "&" + designCid + "-" + element.partId : null,
    designId: "&" + designCid
  }));

const createOperationInputs = (
  json,
  designCid,
  duplicatedJ5ParamMap,
  duplicatedEnzymeMap
) =>
  json.operations.map(op => ({
    cid: designCid + "-" + op.id,
    name: op.name,
    color: op.color,
    designId: "&" + designCid,
    assemblyMethodId: op.assemblyMethodId,
    isOutputCircular: op.isOutputCircular,
    restrictionEnzymeId: op.restrictionEnzymeId
      ? duplicatedEnzymeMap[op.restrictionEnzymeId] ||
        "&" + designCid + "-" + op.restrictionEnzymeId
      : null,
    customJ5ParameterId: op.customJ5ParameterId
      ? duplicatedJ5ParamMap[op.customJ5ParameterId] ||
        "&" + designCid + "-" + op.customJ5ParameterId
      : null,
    opJ5OutputNamingTemplates: op.j5OutputNamingTemplateIds.map(templateId => ({
      j5OutputNamingTemplateId: "&" + designCid + "-" + templateId,
      designId: "&" + designCid
    }))
  }));

const createSetInputs = (json, designCid, duplicatedIconMap) =>
  sortSets(json.sets).map(set => {
    const card = findByKey(json.cards, set.id, "id");
    return {
      cid: designCid + "-" + set.id,
      designId: "&" + designCid,
      name: set.name,
      direction: set.direction,
      iconId: set.iconId
        ? duplicatedIconMap[set.iconId] || "&" + designCid + "-" + set.iconId
        : null,
      secondaryIconId: set.secondaryIconId
        ? "&" + designCid + "-" + set.secondaryIconId
        : null,
      isCropped: set.isCropped,
      isFixed: set.isFixed,
      isPlaceholder: set.isPlaceholder,
      isValidationSet: set.isValidationSet,
      isFlankingValidator: set.isFlankingValidator,
      opSetInjecteds: set.injectingOperationId
        ? {
            injectingOpId: "&" + designCid + "-" + set.injectingOperationId,
            designId: "&" + designCid
          }
        : null,
      opConsumedSets: set.consumingOperationId
        ? {
            consumingOpId: "&" + designCid + "-" + set.consumingOperationId,
            designId: "&" + designCid
          }
        : null,
      setElements: set.elementIds.map((elementId, index) => ({
        elementId: "&" + designCid + "-" + elementId,
        designId: "&" + designCid,
        index
      })),
      parentSets: set.childSetIds.map((childSetId, index) => ({
        childSetId: "&" + designCid + "-" + childSetId,
        index,
        designId: "&" + designCid
      })),
      opSetOutputs:
        card && card.operationId
          ? {
              outputtingOpId: "&" + designCid + "-" + card.operationId,
              designId: "&" + designCid
            }
          : null
    };
  });

const sortSets = sets => {
  const idToRemainingChildren = {};
  sets.forEach(set => [(idToRemainingChildren[set.id] = [...set.childSetIds])]);

  const sorted = [];
  let setIdsLeft = Object.keys(idToRemainingChildren);
  while (setIdsLeft.length) {
    const addableSets = setIdsLeft.filter(
      id => !idToRemainingChildren[id].length
    );

    setIdsLeft.forEach(id => {
      idToRemainingChildren[id] = difference(
        idToRemainingChildren[id],
        addableSets
      );
    });

    addableSets.forEach(id => {
      sorted.push(findByKey(sets, id, "id"));
      delete idToRemainingChildren[id];
    });

    setIdsLeft = Object.keys(idToRemainingChildren);
  }

  return sorted;
};
