/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const appColors = {
  design: "#1585c5",
  build: "#57a773",
  test: "#a188c5",
  evolve: "#a66321"
};

const getAppColor = () =>
  window.frontEndConfig.appColorOverride ||
  appColors[window.frontEndConfig.tgModuleName];

export default getAppColor;
