/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  InputField,
  FileUploadField,
  wrapDialog,
  DialogFooter
} from "@teselagen/ui";
import { compose } from "recompose";
import iconFragment from "../../../../../tg-iso-design/graphql/fragments/iconFragment";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";

import "./style.css";

function UploadIconDialog(props) {
  const { handleSubmit, hideModal, submitting } = props;

  const onSubmit = async values => {
    try {
      const { onIconCreate, hideModal } = props;
      const { name, imageUpload } = values;
      const data = new FormData();
      data.append("file", imageUpload[0].originFileObj);
      const res = await window.api.request({
        method: "POST",
        url: "/uploadCustomIcon",
        data
      });
      const uploadedFile = res.data[0];
      const [{ id }] = await safeUpsert("icon", {
        name: name,
        isSbol: false,
        path: uploadedFile.path
      });

      const newIcon = await safeQuery(iconFragment, {
        variables: { id }
      });
      hideModal();
      onIconCreate && onIconCreate(newIcon);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error uploading custom icon");
    }
  };

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <FileUploadField
          accept={[".svg", ".png", ".jpg", ".jpeg", ".gif"]}
          name="imageUpload"
          isRequired
          label="Upload Image"
          fileLimit={1}
          beforeUpload={(fileList, onChange) => {
            onChange([
              {
                ...fileList[0],
                loading: false
              }
            ]);
          }}
        />
      </div>
      <DialogFooter
        onClick={handleSubmit(onSubmit)}
        hideModal={hideModal}
        submitting={submitting}
      />
    </div>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Icon",
    style: {
      width: 550
    }
  }),
  reduxForm({
    form: "uploadIconDialogForm",
    enableReinitialize: true
  })
)(UploadIconDialog);
