/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { camelCase, noop } from "lodash";
import React, { useEffect } from "react";
import { Loading } from "@teselagen/ui";
import classNames from "classnames";
import RecordButtonGroup from "../RecordButtonGroup";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import RecordInfoDisplay from "../RecordInfoDisplay";
import "./style.css";
import { Icon } from "@blueprintjs/core";

const RecordTitle = props => {
  const {
    title,
    record = {},
    recordName,
    noPadding,
    readOnly,
    isInsideSidePanel
  } = props;
  if (!title && !record.id) return null;
  const inner =
    title ||
    record.name ||
    modelNameToReadableName(recordName, { upperCase: true }) + " " + record.id;
  const header = (
    <h2
      className={classNames("record-title-name", { "no-padding": noPadding })}
    >
      {inner}
    </h2>
  );

  if (readOnly && !isInsideSidePanel) {
    return (
      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
        <Icon icon="lock" style={{ marginRight: 10 }} />
        {header}
      </div>
    );
  }
  return header;
};

const AbstractRecord = props => {
  const recordName = props.recordName || props.model;
  const { match, onNewRecord = noop, ...rest } = props;
  const {
    additionalButtons,
    additionalCards = [],
    additionalUpdateMenuItems,
    afterDelete,
    afterTag,
    beforeDelete = noop,
    children,
    containerStyle,
    data = {},
    handleDelete,
    headerInfo,
    history,
    inCard,
    isInsideSidePanel,
    noDelete,
    noDeleteAlert,
    noPadding,
    noTitle,
    onDelete,
    onExport,
    readOnly,
    recordInfo = [],
    refetchRecord,
    title,
    updateNameOverwrite,
    updateShowFunction,
    withRecordInfo
  } = props;

  useEffect(() => {
    const routeId = match && match.params && match.params.id;
    if (routeId === "new") {
      onNewRecord(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecord = () => {
    const { data } = props;
    return props[camelCase(recordName)] || data[camelCase(recordName)];
  };
  const loading = props[camelCase(recordName) + "Loading"] || data.loading;
  let record;
  if (!loading) {
    record = getRecord();
  }

  return (
    <Loading loading={loading}>
      <div
        className={classNames("record-container", {
          "no-padding": noPadding
        })}
        style={containerStyle}
      >
        <div
          className={classNames({
            "tg-card": inCard
          })}
        >
          <div className="record-title-and-buttons">
            {!noTitle && (
              <RecordTitle
                noPadding={noPadding}
                record={record}
                recordName={recordName}
                title={title}
                readOnly={readOnly}
                isInsideSidePanel={isInsideSidePanel}
              />
            )}
            <RecordButtonGroup
              noDelete={noDelete}
              noDeleteAlert={noDeleteAlert}
              history={history}
              onDelete={onDelete}
              handleDelete={handleDelete}
              beforeDelete={beforeDelete}
              afterDelete={afterDelete}
              additionalButtons={additionalButtons}
              updateNameOverwrite={updateNameOverwrite}
              updateShowFunction={updateShowFunction}
              onExport={onExport}
              record={record}
              additionalUpdateMenuItems={additionalUpdateMenuItems}
              readOnly={readOnly}
              refetch={refetchRecord}
            />
          </div>
          {headerInfo && (
            <div className="record-header-info-section">{headerInfo}</div>
          )}
          {(withRecordInfo || !!recordInfo.length) && (
            <RecordInfoDisplay
              recordInfo={recordInfo}
              record={record}
              afterTag={afterTag}
              readOnly={readOnly}
            />
          )}
        </div>
        {children}
        {!!additionalCards.length && <hr className="tg-section-break" />}
        {additionalCards}
      </div>
    </Loading>
  );
};

export default AbstractRecord;
