/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import download from "downloadjs";
import { EXPORT_VERSION } from "../../../../../tg-iso-design/redux/sagas/exportDesign/constants";

export { EXPORT_VERSION };

export default async function designToJson(design) {
  try {
    const isArray = Array.isArray(design);
    const res = await window.api.request({
      method: "POST",
      url: "/exportDesigns",
      data: {
        zipped: isArray,
        designIds: isArray ? design.map(design => design.id) : [design.id]
      }
    });
    const {
      data: { success, err, designs }
    } = res;

    if (!success) {
      console.error(
        "designToJsonError 51662651. There was an error communicating with the /exportDesigns route: ",
        err,
        res
      );
      throw new Error(err);
    }
    const json = designs[0];

    let content = null;
    let type = null;
    let name = null;

    if (isArray) {
      type = "application/zip, application/octet-stream";
      content = designs;
      name = "Designs.zip";
    } else {
      content = JSON.stringify(json);
      type = "application/json";
      name = design.name + ".json";
    }
    if (!window.Cypress) {
      download(content, name, type);
    } else {
      window.cypressDownloadData = content;
    }
    window.toastr.success("File downloaded.");
    return true;
  } catch (error) {
    let message = error.message;
    if (error.response?.data) {
      message = error.response.data;
    }
    window.toastr.error("Error exporting design: " + message);
    console.error(`error:`, error);
    return false;
  }
}
