/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import ioItemWithLinkedItemFragment from "./ioItemWithLinkedItemFragment.gql";
import microserviceTaskLibraryDesignFragment from "./microserviceTaskLibraryDesignFragment.gql";

export default gql`
  fragment microserviceTaskLibraryBuildFragment on microserviceQueue {
    ...microserviceTaskLibraryDesignFragment
    ioItem {
      ...ioItemWithLinkedItemFragment
    }
  }
  ${ioItemWithLinkedItemFragment}
  ${microserviceTaskLibraryDesignFragment}
`;
