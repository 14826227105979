/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5RunConstructFragment on j5RunConstruct {
    id
    cid
    name
    isPrebuilt
    numWarnings
    partNames
    sequence {
      id
      cid
      name
      name
      isInLibrary
      size
      circular
      hash
      polynucleotideMaterialId
    }
    j5ConstructAssemblyPieces {
      id
      index
      assemblyPiece {
        id
        name
        type
        overhangWithNext
        overhangWithPrevious
        relativeOverhang
        j5AssemblyPieceParts {
          id
          index
          j5InputPart {
            id
            part {
              id
              name
            }
          }
        }
        sequence {
          id
          size
        }
      }
    }
  }
`;
