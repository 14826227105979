/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap } from "lodash";
import getActiveLabId, {
  COMMON_LAB_ID
} from "../../../tg-iso-shared/src/utils/getActiveLabId";
import appGlobals from "../appGlobals";
import { isAdmin } from "./generalUtils";

export const lastSelectedLabKey = "lastSelectedLabId";
export const labStorageKey = "activeLabId";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const getLabAdmins = lab =>
  lab.labRoles.filter(({ roleCode }) => roleCode === "ADMIN");

export const getLabMembers = lab =>
  lab.labRoles.filter(({ roleCode }) => roleCode === "MEMBER");

export const setActiveLab = labId => {
  if (!isAdmin()) {
    return;
  } else {
    if (!labId) {
      labId = getActiveLabId();
    }
    if (!labId) {
      throw new Error("No lab selected. Cannot proceed.");
    }
  }
  if (!appGlobals.setActiveLabId) {
    console.error("No function for setting active lab. Shouldn't be here.");
  } else {
    appGlobals.setActiveLabId(labId);
  }
};

export function isSingleLabLocked(record = { lab: null }) {
  return !isAdmin() && (record.lab === null || record.labId === null);
}

export function isSingleLabCommon() {
  return getActiveLabId() === null;
}

export function changeActiveLab(activeLabId) {
  localStorage.setItem(lastSelectedLabKey, activeLabId);
  sessionStorage.setItem(labStorageKey, activeLabId);
  window.allowLabChange = true;
  // do a full page refresh to stop weirdness from having mismatched labs.
  // The sessionStorage lab will be automatically set to this selected lab when the app is reloaded
  if (appGlobals.activeRecordId) {
    let newUrl = "/";
    if (window.frontEndConfig.tgModuleName) {
      newUrl = `/${window.frontEndConfig.tgModuleName}/client`;
    }
    // if there is an active record open then go to the home page so it does not auto switch lab on reload
    window.location.href = newUrl;
  } else {
    window.location.reload();
  }
}

export const getUserLabs = currentUser => {
  const { labRoles = [] } = currentUser;
  let options = [];
  options = options.concat(
    ...labRoles
      .map(({ lab }) => lab)
      .filter(l => !!l)
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
  );
  return options;
};

export const addLabFilterToQuery = (labFilter, qb) => {
  if (labFilter) {
    if (labFilter === COMMON_LAB_ID) {
      qb.whereAll({
        labId: null
      });
    } else {
      qb.whereAll({
        labId: labFilter
      });
    }
  }
};

export const removeLabMembers = async (labs, safeDelete) => {
  const labRoleIdsToDelete = flatMap(
    labs.map(lab => {
      return lab.labRoles
        .filter(labRole => labRole.roleCode === "MEMBER")
        .map(labRole => labRole.id);
    })
  );
  await safeDelete("labRole", labRoleIdsToDelete);
};

/**
 * This function should be called whenever we need to initialize the
 * session and/or local storage lab id info (e.g., on login, on window reload, on new tab, etc.)
 */
export const initActiveLabId = userData => {
  const activeLabId = getActiveLabId();

  const userLabs = userData.labRoles.map(labRole => labRole.labId);

  const hasActiveLabId = userLabs.some(labId => labId === activeLabId);
  if (!hasActiveLabId) {
    localStorage.setItem(lastSelectedLabKey, userData.defaultLabId);
    sessionStorage.setItem(labStorageKey, userData.defaultLabId);
  }
  // Its possible that either the sessionStorage or the localStorage keys isn't set
  else {
    localStorage.setItem(lastSelectedLabKey, activeLabId);
    sessionStorage.setItem(labStorageKey, activeLabId);
  }
};
