/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createActions } from "redux-actions";
import shortid from "shortid";
import injectPlatformActions from "../../injectPlatformActions";

const identity = payload => payload;

// const addGuids = nGuids => payload => payload
const actions = {
  MASSIVELY_PARALLEL_PART_CREATION: {
    ADD_DATA: identity
  },
  APP_CONFIGURATION: {
    SET_DATA: identity
  },
  UI: {
    RECORDS: {
      CONTAINER_ARRAY: {
        SET_SELECTED_ALIQUOT_CONTAINER_LOCATION: identity,
        SET_SELECTED_ALIQUOT_CONTAINER_LOCATIONS: identity
      }
    },
    LIBRARY: {
      INSERT_SEQUENCE: {
        SET_PAGE_SIZE: identity
      },
      AMINO_ACID_PART_LIBRARY_DIALOG: {
        SET_PAGE_SIZE: identity
      }
    },
    DESIGN_EDITOR: {
      UNDO: {
        ADD_TO_UNDO_STACK: identity,
        UNDO: identity,
        REDO: identity,
        POP_UNDO_STACK: identity,
        POP_REDO_STACK: identity,
        SET_DESIGN_FROM_UNDO_REDO_STACK: identity
      },
      INSPECTOR: {
        TOGGLE: identity,
        OPEN: identity,
        SET_ACTIVE_PANEL: identity,
        SET_WIDTH: identity,
        TOGGLE_REMOVE_INTERRUPTED_FEATURES: identity,
        TOGGLE_COMPATIBLE_PARTS_VALIDATION: identity
      },
      TREE: {
        ZOOM_IN: identity,
        ZOOM_OUT: identity,
        UPDATE_CARD_VIEW_OPTIONS: identity,
        UPDATE_CARD_SET_EXPANSION: identity,
        CLEAR_CARD_SET_EXPANSIONS: identity,
        UPDATE_CARD_WIDTH: identity,
        UPDATE_CARD_MATERIAL_AVAILABILITY_INFORMATION: identity,
        TOGGLE_MINIMAP: identity
      },
      GENERAL: {
        UPDATE_VIEW_OPTIONS: identity,
        UPDATE_SELECTION: identity,
        SET_DESIGN_LAST_UPDATED: identity,
        SET_LAYOUT_FROZEN: identity,
        UNFREEZE_LAYOUT_AND_SET_DESIGN_LAST_UPDATED: identity,
        SELECT_REACTION: identity,
        TOGGLE_MATERIAL_AVAILABILITY_TRAY: identity,
        TOGGLE_SBOL_RIBBON: identity,
        SELECT_CARD: identity,
        SELECT_BIN: identity,
        SELECT_CELL: identity,
        SELECT_VALIDATION_SET: identity,
        UPDATE_SELECTION_AFTER_DESIGN_EDIT: identity,
        MATERIAL_AVAILABILITY_UPDATE_STARTED: identity,
        MATERIAL_AVAILABILITY_UPDATE_ENDED: identity,
        COPY_CELLS: identity,
        PASTE_CELLS: identity,
        SELECT_VALIDATED_JUNCTION: identity,
        MOVE_ACTIVE_CELL: identity,
        SELECT_ROW: identity,
        SELECT_ALL_ROWS: identity,
        TOGGLE_ROWS_INDEX: identity,
        TOGGLE_ROW_NAMES: identity,
        TOGGLE_SHOW_ROW_DISABLE: identity,
        SYNC_DESIGN_STATE: identity
      }
    },
    SEQUENCE_EDITOR: {
      SET_NEEDS_TO_SAVE: identity,
      SEQUENCE_OPENED: identity,
      SAVE_SEQUENCE: identity,
      SEQUENCE_CLOSED: identity,
      SAVE_STARTED: identity,
      SET_SAVE_PENDING: identity,
      SAVE_COMPLETED: identity,
      SAVE_FAILED: identity
    },
    NAV_PANEL: {
      TOGGLE: identity
    }
  },
  TASK_MANAGER: {
    ADD_TASK: identity,
    UPDATE_TASK: identity,
    TICK_TIMERS: identity,
    SET_ALL_TICKS: identity,
    CLEAR: identity,
    TOGGLE: identity
  },
  J5: {
    SUBMIT_DESIGN_FOR_ASSEMBLY: identity,
    SUBMIT_DESIGN_FOR_ASSEMBLY_STARTED: identity,
    SUBMIT_DESIGN_FOR_ASSEMBLY_COMPLETED: identity,
    SUBMIT_DESIGN_FOR_ASSEMBLY_FAILED: identity,
    SUBMITTING_TO_J5_START: identity,
    SUBMITTING_TO_J5_FINISH: identity
  },
  CONTEXT: {
    USER: {
      SET_DATA: identity,
      RESET_DATA: identity,
      LOGIN: identity,
      LOGOUT: identity,
      REGISTER: identity,
      SET_AUTH: identity,
      SENDING_REQUEST: identity,
      REQUEST_ERROR: identity,
      CLEAR_ERROR: identity,
      REDIRECT_TO_REFERRER: identity
    }
  },
  DESIGN_IO: {
    ADD_ITEMS: identity,
    SAVE: identity,
    SAVE_STARTED: identity,
    SAVE_COMPLETED: identity,
    SAVE_FAILED: identity,
    SET_LAST_SAVED_STATE: identity,
    FETCH_FROM_SERVER: identity,
    FETCH_STARTED: identity,
    SET_DESIGN: identity,
    EXPORT_TO_JSON: identity,
    EXPORT_TO_CSV_GENBANK: identity
  },
  DESIGN_MATERIAL_AVAILABILITY: {
    UPDATE_MATERIAL_AVAILABILITY: identity
  },
  /**
   * !!!!! Within the app we should only be using actions.design.XXXXX,
   * never actions.designEdit.XXXXX !!!!!!
   */
  DESIGN: {
    SET_DSF: identity,
    INSERT_BIN: payload => ({ ...payload, newBinId: shortid() }),
    REMOVE_BINS: identity,
    FLIP_BIN: identity,
    REMOVE_REACTION: identity,
    CHANGE_FAS: identity,
    UPDATE_DESIGN: identity, //we need a meta for this one!
    UPDATE_BIN: identity,
    UPDATE_BIN_CARD: identity,
    UPDATE_CARD: identity,
    UPDATE_ELEMENT: identity,
    UPDATE_PART: identity,
    CREATE_PART: identity,
    UPDATE_REACTION: identity,
    UPDATE_REACTION_RESTRICTION_ENZYME: identity,
    ADD_OR_CHANGE_REACTION: identity,
    REMOVE_ELEMENTS: identity,
    REMOVE_CONSTRUCT_ANNOTATION: identity,
    UPSERT_CONSTRUCT_ANNOTATION: identity,
    CREATE_ELEMENTS: identity,
    MAP_ELEMENT_TO_PART: identity,
    CHANGE_ELEMENT_INDEX: identity,
    CHANGE_PARTSET_PART: identity,
    TOGGLE_VALIDATION_END: identity,
    INSERT_FIXED_SET: identity,
    ASSIGN_OVERHANGS: identity,
    INTERNALIZE_OVERHANG_ON_PART: identity,
    INTERNALIZE_OVERHANG_ON_FLANKING_SEQUENCE: identity,
    INTERNALIZE_RECOGNITION_SITE_ON_PART: identity,
    INTERNALIZE_RECOGNITION_SITE_ON_FLANKING_SEQUENCE: identity,
    MAKE_ERDAM_CIRCULAR: identity,
    MAKE_ERDAM_LINEAR: identity,
    LOCK_BINS: identity,
    CONVERT_BINS_TO_PLACEHOLDERS: identity,
    CONVERT_TO_LIST_LAYOUT: identity,
    TOGGLE_DISABLE_SAME_BIN_DIGEST_VALIDATION: identity,
    APPLY_DESIGN_TEMPLATE: identity,
    SET_SELECTED_BINS_ICON: identity,
    SET_BIN_ICON: identity,
    CLEAR_DESIGN: identity,
    APPLY_RULE_SET: identity,
    REMOVE_RULE_SET: identity,
    UPSERT_EUGENE_RULE: identity,
    REMOVE_ELEMENT_EUGENE_RULES: identity,
    REMOVE_EUGENE_RULE: identity,
    UPDATE_MATERIAL_AVAILABILITY_IN_DESIGN: payload => ({
      ...payload,
      lastCheckedAvailability: new Date()
    }),
    INSERT_ROW: identity,
    REMOVE_ROWS: identity,
    TOGGLE_ROW_DISABLED: identity,
    CHANGE_INTERNALIZATION_PREFERENCES: identity,
    SWAP_CONSTRUCT: identity,
    ADD_AUTO_DIGEST_ELEMENT_COMBO: identity,
    REMOVE_AUTO_DIGEST_ELEMENT_COMBO: identity,
    ELIMINATE_COMBINATIONS: identity,
    REMOVE_ELIMINATED_COMBINATION: identity,
    CUT_CELLS: identity,
    PASTE_CELLS: identity,
    UPDATE_JUNCTION: identity,
    ALPHABETIZE_PARTS: identity
  }
};

/**
 * !!!!! Within the app we should only be using actions.design.XXXXX,
 * never actions.designEdit.XXXXX !!!!!!
 */
actions.DESIGN_EDIT = actions.DESIGN;
// actions.designEdit (DO NOT USE THESE IN GENERAL CODEBASE)
//  - actually triggers reducer (immediately)

//  vs

// actions.design (USE THESE)
// - does not trigger reducer
// - trigger handleEditDesignAction to be hit
// - "puts" a second action (a designEdit action) that does trigger reducer
// - has options overrideLock and doNotAddToUndoStack
// overrideLock allows an action to still occur despite the design being locked
// doNotAddToUndoStack prevents the action from being added to the undo redo stack

export default injectPlatformActions(createActions(actions));
