/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { FileUploadField } from "@teselagen/ui";
import { getDownloadTemplateFileHelpers } from "../components/DownloadTemplateFileButton";
import {
  guideRNAFileTypesAndDescriptions,
  aminoAcidFileTypesAndDescriptions,
  rnaFileTypesAndDescriptions,
  dnaFileTypesAndDescriptions,
  dnaMaterialFileTypesAndDescriptions,
  rnaMaterialFileTypesAndDescriptions
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";

function SequenceFileUpload(props) {
  return (
    <FileUploadField
      accept={optionallyAddMaterialFieldsToCsvSchema(
        props.isMaterial,
        props.isGuideRNA
          ? guideRNAFileTypesAndDescriptions
          : props.isProtein
          ? aminoAcidFileTypesAndDescriptions
          : props.isRNA
          ? props.isMaterial
            ? rnaMaterialFileTypesAndDescriptions
            : rnaFileTypesAndDescriptions
          : props.isMaterial
          ? dnaMaterialFileTypesAndDescriptions
          : dnaFileTypesAndDescriptions
      ).map(f => {
        if (f.validateAgainstSchema) {
          return getDownloadTemplateFileHelpers(f);
        }
        return f;
      })}
      {...props}
    />
  );
}

export default SequenceFileUpload;

function optionallyAddMaterialFieldsToCsvSchema(
  isMaterial,
  fileTypesAndDescriptions
) {
  if (isMaterial) {
    return fileTypesAndDescriptions.map(f => {
      if (f.validateAgainstSchema) {
        return {
          ...f,
          extendedPropTypes: [...f.extendedPropTypes, "material"],
          validateAgainstSchema: {
            ...f.validateAgainstSchema,
            fields: [
              {
                path: "MaterialName",
                description: "The name of the material",
                example: "Neurogen1"
              },
              {
                path: "ProvenanceType",
                example: "Registered",
                isRequired: true,
                type: "dropdown",
                values: ["Registered", "Target"]
              },
              {
                path: "ExternallyAvailable",
                type: "boolean",
                defaultValue: false
              },
              ...f.validateAgainstSchema.fields
            ]
          }
        };
      }
      return f;
    });
  }
  return fileTypesAndDescriptions;
}
