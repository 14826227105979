/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
import gql from "graphql-tag";
import libraryEnhancer from "../../libraryEnhancer";
import AbstractLibrary from "../../AbstractLibrary";
import modelNameToLink from "../../utils/modelNameToLink";
import { pushHelper } from "../../utils/pushHelper";
import { getEnclosingFeatures } from "../../utils/sequenceUtils";
import { isDesign } from "../../../../tg-iso-shared/src/utils/isModule";
import ChangeFeatureTypeMenuItem from "../../ChangeFeatureTypeMenuItem";
import { Link } from "react-router-dom";
import SequenceFeatureRecordView from "../../SequenceFeatureRecordView";
import { annotationSizeStartEndColumns } from "../../utils/libraryColumns";

const schema = {
  model: "sequenceFeature",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "sequence.name",
      type: "string",
      displayName: "Source Sequence",
      render: (v, r) => {
        const NonDisplayableTypes = ["GENOME", "ALIGNMENT_SEQ"];
        if (NonDisplayableTypes.includes(r.sequence?.sequenceType?.code))
          // TODO: put in an appropriate link for these sequence types
          return v;
        else return <Link to={modelNameToLink(r.sequence)}>{v}</Link>;
      }
    },
    {
      filterDisabled: true,
      sortDisabled: true,
      type: "string",
      displayName: "Enclosing Features",
      render: (_, record) => {
        if (!record.sequence) return;
        const enclosingFeatures = getEnclosingFeatures(record, record.sequence);
        return enclosingFeatures.map((f, i) => {
          return (
            <React.Fragment key={f.id}>
              <Link to={modelNameToLink(f)}>{f.name}</Link>
              {i !== enclosingFeatures.length - 1 && ", "}
            </React.Fragment>
          );
        });
      }
    },
    { path: "type", type: "string", displayName: "Type" },
    {
      path: "sequence.sequenceType.name",
      type: "string",
      displayName: "Sequence Type"
    },
    ...annotationSizeStartEndColumns
  ]
};

class SequenceFeatureLibrary extends Component {
  additionalContextMenu = selectedRecords => {
    const extraItems = [];
    if (selectedRecords.length === 1) {
      const { sequence } = selectedRecords[0];
      extraItems.push(
        <MenuItem
          key="viewSourceSequence"
          icon="circle"
          text="View Source Sequence"
          onClick={e =>
            pushHelper(e, modelNameToLink(sequence.__typename, sequence.id))
          }
        />
      );
    }
    if (isDesign()) {
      extraItems.push(
        <ChangeFeatureTypeMenuItem key="editType" records={selectedRecords} />
      );
    }
    return extraItems;
  };
  render() {
    return (
      <AbstractLibrary
        {...this.props}
        noNewItem
        RecordViewInspector={SequenceFeatureRecordView}
        model="sequenceFeature"
        libraryName="sequenceFeature"
        libraryTitle="Sequence Features"
        generateExtraContextMenuItems={this.additionalContextMenu}
        isLibraryTable
        withExport
      />
    );
  }
}

const fragment = gql`
  fragment sequenceFeatureLibraryFragment on sequenceFeature {
    id
    name
    type
    strand
    start
    end
    sequence {
      id
      name
      circular
      size
      sequenceType {
        code
        name
      }
      sequenceFeatures {
        id
        name
        start
        end
        strand
      }
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  noUser: isDesign(),
  noAddedBy: isDesign()
})(SequenceFeatureLibrary);
