/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { remove } from "lodash";
import { size, filter, groupBy, cloneDeep } from "lodash";

function addDesignColumn({ designColumns, newColumnParts }) {
  const numberOfColumns = designColumns.length;
  designColumns.push({
    index: numberOfColumns,
    name: `Column ${numberOfColumns}`,
    icon: "USER-DEFINED",
    direction: "forward",
    parts: newColumnParts.map(part => ({
      index: 0,
      id: part.id,
      forced_assembly_strategy: "DIGEST"
    }))
  });
}

/**
 * NOTE: this functions finds all the candidate (overhang matching) columns
 * at the 5' and 3' ends of the construct, and returns uses the first one.
 *
 * In theory, there shouldn't be more than one candidate since overhangs should be
 * unique, otherwise the assembly may fail.
 */
function findBestSurroundingColumns({ overhangs, columns }) {
  const [fivePrimeOverhang, threePrimeOverhang] = overhangs;

  const fivePrimeCandidateColumns = filter(columns, key =>
    key.endsWith(fivePrimeOverhang)
  );
  const best5PrimeColumn = fivePrimeCandidateColumns[0];

  const threePrimeCandidateColumns = filter(
    columns,
    key => key.startsWith(threePrimeOverhang) && key !== best5PrimeColumn
  );
  const best3PrimeColumn = threePrimeCandidateColumns[0];

  return {
    best5PrimeColumn,
    best3PrimeColumn
  };
}

// Columns are represented by a string formatted like this: '5primeOverhang-3primeOverhang'.
function findBestColumnOrder({ columns }) {
  // We clone the columns into an array that we'll be mutating,
  // so we can keep the original columns array intact.
  const remainingColumns = cloneDeep(columns);

  const numberOfColumns = remainingColumns.length;

  // We need a starting point, just select the first column in the array.
  const firstColumn = remainingColumns.shift();

  // 'constructThreePrimeOverhang' and 'constructFivePrimeOverhang' are aux variables
  // to store the current 5' and 3' prime overhangs of the circular construct in each iteration.
  let [
    constructFivePrimeOverhang,
    constructThreePrimeOverhang
  ] = firstColumn.split("-");

  // These will store the ordered columns.
  const orderedColumns = [firstColumn];

  for (let idx = 0; idx < numberOfColumns; idx++) {
    // break if there are no more remaining columns left.
    if (!remainingColumns.length) break;

    const { best5PrimeColumn, best3PrimeColumn } = findBestSurroundingColumns({
      overhangs: [constructFivePrimeOverhang, constructThreePrimeOverhang],
      columns: remainingColumns
    });

    if (best5PrimeColumn) {
      // Take the 'best5PrimeColumn out of the 'remainingColumns' and into the beginning of
      // the 'orderedColumns' array.
      remove(remainingColumns, col => col === best5PrimeColumn);
      orderedColumns.unshift(best5PrimeColumn);
      // Update the construct's 5' overhang
      constructFivePrimeOverhang = best5PrimeColumn.split("-")[0];
    }

    if (best3PrimeColumn) {
      // Take the 'best5PrimeColumn out of the 'remainingColumns' and into the end of
      // the 'orderedColumns' array.
      remove(remainingColumns, col => col === best3PrimeColumn);
      orderedColumns.push(best3PrimeColumn);
      // Update the construct's 3' overhang
      constructThreePrimeOverhang = best3PrimeColumn.split("-")[1];
    }
  }

  if (size(remainingColumns)) {
    orderedColumns.push(...remainingColumns);
  }

  return orderedColumns;
}

export function getSimpleDesign({ parts }) {
  const designColumns = [];

  // Group parts into columns based on their 5' and 3' overhangs.
  const partsByColumn = groupBy(
    parts,
    part => `${part.re5PrimeOverhang}-${part.re3PrimeOverhang}`
  );

  const columns = Object.keys(partsByColumn);

  // Find the best order for the columns.
  const orderedColumns = findBestColumnOrder({ columns });

  orderedColumns.forEach(column =>
    addDesignColumn({
      designColumns,
      newColumnParts: partsByColumn[column]
    })
  );

  const simpleDesignJson = {
    columns: designColumns,
    assembly_method: "Golden Gate"
  };

  return simpleDesignJson;
}
