/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { digestPartFragment } from "../../../tg-iso-design/graphql/fragments/partFragment";

export default gql`
  fragment sequenceRecordFragment on sequence {
    id
    name
    circular
    description
    size
    parentSequenceId
    parentSequenceSequences {
      id
      name
    }
    translations {
      id
      start
      end
      strand
    }
    # molecularWeight
    hash
    createdAt
    updatedAt
    # rnaType {
    #   id
    #   name
    # }
    chromatogramData
    # aminoAcidSequence {
    #   id
    #   name
    # }
    # plasmidInductionMethodPlasmids {
    #   id
    #   inductionMethod {
    #     id
    #     name
    #   }
    # }
    # sequenceFpus {
    #   id
    #   functionalProteinUnit {
    #     id
    #     name
    #     extinctionCoefficient
    #     molecularWeight
    #     taggedItems {
    #       ...taggedItemFragment
    #     }
    #   }
    # }
    # sequenceCodingSequences {
    #   id
    #   codingDnaSequence {
    #     id
    #     name
    #     size
    #     taggedItems {
    #       ...taggedItemFragment
    #     }
    #   }
    # }
    # codingDnaSequenceSequenceCodingSequences {
    #   id
    #   eouDescription
    #   ribosomeBindingStrength
    #   sequence {
    #     id
    #     name
    #     size
    #     taggedItems {
    #       ...taggedItemFragment
    #     }
    #   }
    # }
    # polynucleotideMaterial {
    #   id
    #   name
    #   polynucleotideMaterialMicrobialMaterialPlasmids {
    #     id
    #     microbialMaterialId
    #   }
    # }
    parts {
      id
      name
      # size
      start
      end
      strand
      overlapsSelf
      elements {
        id
        design {
          id
          name
        }
      }
      notes
      type
      ...digestPartFragment
      taggedItems {
        id
        tagId
        tagOptionId
      }
    }
    # sequenceSegments {
    #   id
    #   start
    #   end
    #   strand
    # }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
      locations
      notes
      arrowheadType
      primerBindsOn
      oligoWithBindingSiteId
      oligoWithBindingSite {
        id
        name
        fullSequenceRaw
      }
    }
    sequenceFragments {
      id
      cid
      index
      fragment
    }
    sequenceTypeCode
    sequenceType {
      code
      name
    }
    # user {
    #   id
    #   username
    # }
    lockId
    childLockId
    userId
    sequence
    isJ5Sequence
    isInLibrary
  }
  ${digestPartFragment}
`;
