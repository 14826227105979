/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { query } from "../apolloMethods";

export const getEnzymeOverrides = async setState => {
  //tnrtodo! come back here and fill this out
  const enzymes = await query(
    gql`
      fragment restrictionEnzymeFragment on restrictionEnzyme {
        id
        name
        sequence
        forwardSnipPosition
        recognitionRegex
        reverseRecognitionRegex
        reverseSnipPosition
      }
    `,
    {
      variables: {
        filter: {
          isSystemType: false
        },
        pageSize: 15000
      }
    }
  );

  const additionalEnzymes = {}; //these are the user added enzymes
  enzymes.forEach(e => {
    additionalEnzymes[e.name.toLowerCase()] = {
      name: e.name,
      site: e.sequence,
      forwardRegex: e.recognitionRegex,
      reverseRegex: e.reverseRecognitionRegex,
      topSnipOffset: e.forwardSnipPosition,
      bottomSnipOffset: e.reverseSnipPosition
    };
  });

  const enzymeGroups = await query(
    gql`
      fragment enzymeGroupFragment on enzymeGroup {
        isSystemType
        name
        description
        id
        enzymeToGroups {
          id
          restrictionEnzyme {
            id
            name
          }
          restrictionEnzymeId
        }
      }
    `,
    {
      variables: {
        // filter: {
        //   isSystemType: true
        // },
        pageSize: 15000
      }
    }
  );

  const enzymeGroupsOverride = {};
  enzymeGroups.forEach(g => {
    enzymeGroupsOverride[g.name] = g.enzymeToGroups.map(
      ({ restrictionEnzyme }) => {
        return restrictionEnzyme.name;
      }
    );
  });
  setState({
    enzymeGroupsOverride,
    additionalEnzymes
  });
};

export const enzymeManageOverride = history => {
  history.push("/settings/enzyme-management");
};
