/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { DialogFooter, NumericInputField, wrapDialog } from "@teselagen/ui";
import { inRange } from "../../../../src-shared/utils/formUtils";

export default compose(
  wrapDialog({
    title: "Partition Constraint Parameters",
    style: { width: "50%" }
  }),
  reduxForm({ form: "algorithm-validation-parameters" })
)(props => {
  const {
    hideModal,
    handleSubmit,
    handleAlgorithmParams,
    label,
    inputs
  } = props;

  const onSubmitAlgorithmParams = values => {
    for (const [inputName, inputValue] of Object.entries(values)) {
      handleAlgorithmParams(inputName, inputValue);
    }
    hideModal();
  };

  return (
    <div className={Classes.DIALOG_BODY}>
      <h3>{label}</h3>
      {inputs.map(inputParams => {
        const { name, label, value, required, min, max } = inputParams;
        const toShow = value ? value : inputParams.default;
        return (
          <NumericInputField
            key={name}
            name={name}
            label={label}
            min={min}
            max={max}
            normalize={inRange([min, max], { integer: true })}
            defaultValue={toShow}
            placeholder={`${toShow}`}
            isRequired={required}
          />
        );
      })}
      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(onSubmitAlgorithmParams)}
      />
    </div>
  );
});
