/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { bteModuleName } from "@teselagen/utils";
import getActiveLabId, { COMMON_LAB_ID } from "./getActiveLabId";
import getActiveProjectId from "./getActiveProjectId";

export { COMMON_LAB_ID };
/// The following constants are duplicate in auth-utils/src/ext-utils/constants.js
/// they need to be refactored to a common DRY source
export const impersonatedUserIdHeaderKey = "tg-lims-user-id"; // this is only used by super user requests (from backend)
export const activeLabHeaderKey = "tg-active-lab-id";
export const activeProjectHeaderKey = "tg-project-id";
export const activeBatchHeaderKey = "tg-active-batch-id";
export const importCollectionHeaderKey = "tg-active-import-collection-id";
export const moduleSelectorHeaderKey = "module-selector";

function getModuleSelectorHeader(moduleName) {
  const moduleSelector = bteModuleName(
    moduleName ||
      window?.frontEndConfig?.tgModuleName ||
      process?.env?.TG_MODULE_NAME
  );

  if (!moduleSelector) {
    throw new Error("This has to be properly set in here.");
  }

  return moduleSelector;
}

export default function getRequestHeaderKeys(moduleName) {
  // get the authentication activeBatchToken from local storage if it exists
  // const activeBatchId = localStorage.getItem("activeBatchId");
  const token = localStorage.getItem("authToken-teselagen");
  const importCollectionId = sessionStorage.getItem("importCollectionId");

  const activeProjectId = getActiveProjectId();
  const activeLabId = getActiveLabId();

  const headers = {
    // [activeBatchHeaderKey]: activeBatchId || "",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    // We need to specify the moduleSelector so that the graphql server knows
    // if the request is coming from a design/build moduleSelector model.
    [moduleSelectorHeaderKey]: getModuleSelectorHeader(moduleName)
  };

  // NOTE: we should always send the active lab id header key.
  // Even if its 'null', bc that will tell us its the common lab.
  headers[activeLabHeaderKey] = activeLabId;

  if (activeProjectId) {
    headers[activeProjectHeaderKey] = activeProjectId;
  }
  if (importCollectionId) {
    headers[importCollectionHeaderKey] = importCollectionId;
  }

  return headers;
}
