/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty } from "lodash";
import { InfoHelper } from "@teselagen/ui";
import { unparse } from "papaparse";
import pluralize from "pluralize";
import { download } from "../../utils/downloadTest";
import GenericSelect from "../../GenericSelect";
import {
  Classes,
  Intent,
  MenuItem,
  AnchorButton,
  Button,
  ButtonGroup
} from "@blueprintjs/core";
import {
  SelectField,
  wrapDialog,
  useDialog,
  tgFormValues,
  DialogFooter
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { modelTypeMap } from "../../../../tg-iso-shared/src/utils/modelTypeMap";
import { extPropTypeFilterAdditionalItems } from "../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { camelCase } from "lodash";
import { observer } from "mobx-react";
import { some } from "lodash";
import { Link } from "react-router-dom";
import { isFunction } from "lodash";

const DEFAULT_BUTTON_TEXT = "Download Template";

function DownloadTemplateFileButton(props) {
  const { exampleFile, helperToUse } = getDownloadTemplateFileHelpers(props);
  return (
    <MenuItem
      text={
        <div className="tg-flex align-center">
          {props.buttonText || DEFAULT_BUTTON_TEXT}
          {helperToUse && (
            <React.Fragment>
              &nbsp; &nbsp;
              <InfoHelper icon="help" content={helperToUse} />
            </React.Fragment>
          )}
        </div>
      }
      onClick={exampleFile}
      intent={Intent.PRIMARY}
      className={Classes.MINIMAL}
    />
  );
}

export default DownloadTemplateFileButton;

export const getDownloadTemplateFileHelpers = ({
  headers: _headers = [],
  validateAgainstSchema,
  requiredHeaders = [],
  headerMessages: _headerMessages = {},
  conditionalHeaderMessages = {},
  extendedPropTypes = [], // types of extended properties that this upload accepts
  fileName,
  helper,
  fileContents,
  type,
  tableWideValidation
}) => {
  let headerMessages = _headerMessages;
  let headers = _headers.length ? _headers : Object.keys(headerMessages);
  let helperToUse = helper;
  const fields = [...(validateAgainstSchema?.fields || [])];
  if (extendedPropTypes.length) {
    headerMessages = { ...headerMessages };
    const extHeaders = [];
    extendedPropTypes.forEach(pType => {
      const header = `ext-${pType}-`;
      extHeaders.push(header);
      headerMessages[
        header
      ] = `This upload accepts extended properties for the ${pluralize(
        pType
      )}. Add a property name to this header (ex. '${header}Date of Purchase') and values to the rows. To add additional ${type} properties just duplicate the column header.`;
    });
    headers = [...headers, ...extHeaders];
    const helperMessage = validateAgainstSchema
      ? ""
      : `Please download the template file for help with adding extended properties. All extended properties are set in 'Settings > Extended Properties' before this upload.`;
    helperToUse = helper ? helper + "\n" + helperMessage : helperMessage;
    if (validateAgainstSchema) {
      const paths = fields.map(f => f.path);
      extHeaders.forEach(header => {
        if (!paths.includes(header)) {
          fields.push({
            path: header,
            displayName: header,
            description: headerMessages[header],
            example: headerMessages[header]
          });
        }
      });
    }
  }

  const toRet = {
    ...(type
      ? { type }
      : {
          type: [".csv", ".xlsx"] //by default allow csv and xlsx
        }),
    callout: helperToUse || undefined,
    description: helperToUse || undefined,
    ...(validateAgainstSchema
      ? {
          validateAgainstSchema: {
            ...(extendedPropTypes?.length && {
              coerceUserSchema: ({ userSchema, officialSchema }) => {
                //first remove any extended properties that may have been added to the officialSchema from a previous upload
                officialSchema.fields = officialSchema.fields.filter(
                  ({ path }) => {
                    return !path.startsWith("ext-");
                  }
                );
                //if userSchema comes in with extended properties, we need to add them to the officialSchema
                userSchema.fields.forEach(f => {
                  if (
                    f.path.startsWith("ext-") &&
                    !officialSchema.fields.some(({ path }) => {
                      return path === f.path;
                    })
                  ) {
                    officialSchema.fields.push({
                      path: f.path,
                      displayName: f.path,
                      hasMatch: true,
                      matches: [
                        {
                          item: {
                            displayName: f.path,
                            path: f.path
                          }
                        }
                      ]
                    });
                  }
                });
              },
              HeaderComp: ({
                validateAgainstSchema
                // props from TRC upload component
              }) => {
                const { showDialogPromise, comp } = useDialog({
                  ModalComponent: compose(
                    wrapDialog({ title: "Add Extended Property" }),
                    reduxForm({ form: "addExtendedPropertyCsvWizard" }),
                    tgFormValues("propType"),
                    observer
                  )(({ propType, hideDialog, handleSubmit, change }) => {
                    return (
                      <>
                        <div className={Classes.DIALOG_BODY}>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to="/settings/extended-properties"
                          >
                            <AnchorButton
                              style={{ marginBottom: 10 }}
                              data-tip="Extended Properties Settings"
                              icon="cog"
                            >
                              Extended Properties Settings
                            </AnchorButton>
                          </Link>

                          <SelectField
                            label="Extended Property Type"
                            defaultValue={extendedPropTypes[0]}
                            name="propType"
                            options={extendedPropTypes}
                            onFieldSubmit={() => {
                              change("extendedProperty", null);
                            }}
                          ></SelectField>
                          <GenericSelect
                            {...{
                              name: "extendedProperty",
                              isRequired: true,
                              asReactSelect: true,
                              label: "Extended Property",
                              fragment: ["extendedProperty", "id name"],
                              additionalFilter: (props, qb) => {
                                const modelToUse =
                                  extPropTypeFilterAdditionalItems[propType] ||
                                  camelCase(propType);
                                const filter = {
                                  modelTypeCode: modelTypeMap[modelToUse]
                                };
                                filter.extendedPropertyClassCode = qb.notEquals(
                                  "LINK"
                                );
                                return filter;
                              }
                            }}
                          ></GenericSelect>
                        </div>
                        <DialogFooter
                          onClick={handleSubmit(
                            ({ extendedProperty, propType }) => {
                              const path = `ext-${propType}-${extendedProperty.name}`;
                              if (some(validateAgainstSchema.fields, { path }))
                                return window.toastr.warning(
                                  "This extended property column has already been added"
                                );
                              validateAgainstSchema.fields.push({
                                path,
                                displayName: path
                              });
                              window.toastr.success(
                                "Extended Property Column Added"
                              );
                              hideDialog();
                            }
                          )}
                        ></DialogFooter>
                      </>
                    );
                  })
                });
                return (
                  <>
                    {comp}
                    <ButtonGroup>
                      <Button
                        style={{ marginBottom: 5 }}
                        icon="add"
                        intent="primary"
                        onClick={async () => {
                          showDialogPromise();
                        }}
                      >
                        Add Extended Property Column
                      </Button>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to="/settings/extended-properties"
                      >
                        <AnchorButton
                          data-tip="Extended Properties Settings"
                          icon="cog"
                        ></AnchorButton>
                      </Link>
                    </ButtonGroup>
                  </>
                );
              }
            }),
            ...tableWideValidation,
            ...validateAgainstSchema,
            ...(fileName && { fileName }),
            fields
          }
        }
      : {
          exampleFile: () => {
            let contents;
            if (fileContents) {
              if (isFunction(fileContents)) {
                contents = fileContents(headers, headerMessages);
              } else {
                contents = fileContents;
              }
            } else {
              const csvData = {
                fields: headers,
                data: []
              };

              if (
                requiredHeaders.length ||
                !isEmpty(conditionalHeaderMessages) ||
                !isEmpty(headerMessages)
              ) {
                const requiredRow = Array(headers.length);
                requiredHeaders.forEach(header => {
                  requiredRow[headers.indexOf(header)] = "required";
                });
                Object.keys(conditionalHeaderMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] =
                    conditionalHeaderMessages[header];
                });
                Object.keys(headerMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] = headerMessages[header];
                });
                csvData.data.push(requiredRow);
              }
              const csvString = unparse(csvData);
              contents = csvString;
            }
            download(
              contents,
              fileName.includes(".") ? fileName : fileName + ".csv",
              "text/plain"
            );
          }
        })
  };
  return toRet;
};
