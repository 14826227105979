/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { showConfirmationDialog, showProgressToast } from "@teselagen/ui";
import { inDevelopment } from "./utils/inDevelopment";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
interface AppGlobals {
  // refetch current app user
  refetchCurrentUser?: () => void;
  tgApolloClient?: Object;
  logout?: () => void;
  currentUser?: {
    username: String;
    id: Number;
    projectRoles: [];
    labRoles: [];
  };
  // The id of the active record view that is open
  activeRecordId?: String;
  // react router history
  history?: () => void;
}

interface triggerIntegrationRequestObj {
  endpointId: number;
  data: any;
  params: any;
  method: string;
}

type WindowApi = {
  request: (options: any) => Promise<any>;
  (options: any): Promise<any>;
};

interface cliApiRequestObj {
  moduleName: string;
  method: "POST" | "PUT" | "GET";
  url: string;
  data: object;
  headers: object;
}

declare global {
  interface Window {
    __appGlobals: any;
    showConfirmationDialog: (options: any) => Promise<boolean>;
    showProgressToast: (
      message: string,
      value: number,
      key: string,
      opts: { [key: string]: string | number }
    ) => void;
    teGlobalStore: {};
    toastr: {
      error: (message: any, opts?: { timeout?: number }) => void;
      warning: (message: any, opts?: { timeout?: number }) => void;
    };

    /**
     * Custom wrapper around axios request that adds appropriate headers
     *
     * @example
     * await window.api.request({
        method: "POST",
        baseURL: window.frontEndConfig.serverBasePath || "",
        url: "/execute-worklist",
        data: {
          worklistId
        }
      });
     */
    api: WindowApi;

    /**
     * Api request to teselagen cli. Adds appropriate cli headers.
     *
     * @example
     * await window.api.request({
        method: "POST",
        baseURL: window.frontEndConfig.serverBasePath || "",
        url: "/execute-worklist",
        data: {
          worklistId
        }
      });
     */
    cliApi(options: cliApiRequestObj): Promise<any>;
    /**
     * This triggers the integration's request to be run on the server.
     * The server hits the url set up for that endpoint
     * The server needs to run the actual request to avoid any CORS issues
     *
     * @example
     * const res = await window.triggerIntegrationRequest({
          endpointId: endpoint.id
          //for a POST/DELETE/PUT
          data: {
            myData: "here"
          },
          //for a GET request
          params: {
            id: 5
          } ,
          method: methodSearch,
        });

        defined in src-shared/api.js
     */
    triggerIntegrationRequest(
      triggerIntegrationRequestObj: triggerIntegrationRequestObj
    ): Promise<any>;
    /**
     * @example
     * window.frontEndConfig.atLanzaTech
     * @example
     * window.frontEndConfig.appSettings.ALLOW_REGULAR_USERS_TO_CREATE_LABS
     */
    frontEndConfig: {
      allProjectsTagId: string;
      appColorOverride: string;
      /**
       * @example
       * window.frontEndConfig.appSettings.ALLOW_REGULAR_USERS_TO_CREATE_LABS
       *
       * you can use this together with upsertAppSetting like so:
       * await upsertAppSetting({
       *   code: "ALLOW_REGULAR_USERS_TO_CREATE_LABS",
       *   value: !checked
       * });
       * this.forceUpdate();
       */
      appSettings: {
        ALLOW_REGULAR_USERS_TO_CREATE_LABS: boolean;
        MIN_AUTO_ANNOTATED_FEATURE_LENGTH: number;
        MIN_AUTO_ANNOTATED_PART_LENGTH: number;
        MIN_AUTO_ANNOTATED_OLIGO_LENGTH: number;
        DEFAULT_PHONE_FORMAT_CODE: string;
      };
      atLanzatech: boolean;
      azureADLogin: boolean;
      buildUrl: string;
      clientBasePath: string;
      experimentalFeaturesEln: boolean; // TODO: Remove once react 18 is in
      defaultModuleName: string;
      designUrl: string;
      debugEln: boolean;
      enabledModules: any;
      enabledTools: string[];
      enableGraphiqlInspector: boolean;
      enableNonSequenceDnaMaterials: boolean;
      segmentAnalyticsKey?: string;
      enableVendorOrderingTool: boolean;
      evolveUrl: string;
      federatedSso: boolean;
      googleLogin: boolean;
      hiddenNotificationSettings: object;
      hideTaskMenu: boolean;
      iubenda: {
        version: number;
      };
      nodeRedEditorUrl: string;
      projectTags: boolean;
      reactions: boolean;
      recoverPassword: boolean;
      samlLogin: boolean;
      /**
       * * @example
       * window.frontEndConfig.serverBasePath ==="/design"
       */
      serverBasePath: string;
      showDocumentation: boolean;
      simulateLatency: boolean;
      simulateInitialLatency: boolean;
      singleUserLabs: boolean;
      testUrl: string;
      /**
       * @example
       * window.frontEndConfig.tgModuleName === "build"
       */
      tgModuleName: string;
      trackUserNavigation: boolean;
      userRecordLocking: boolean;

      hasuraEnabled: boolean;
    };

    Cypress?: {
      tgApolloClient?: ApolloClient<NormalizedCacheObject> & {
        removeFromCache?: (records: Object[]) => void;
      };
    };
    _iub: {
      cons_instructions: any[];
    };
  }
}

window.showConfirmationDialog = showConfirmationDialog;
window.showProgressToast = showProgressToast;

const appGlobals: AppGlobals = {};
export default appGlobals;

if (inDevelopment) {
  window.__appGlobals = appGlobals;
}
