/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useMemo } from "react";
import { kebabCase, noop, pick, isString, isFunction } from "lodash";
import Helmet from "react-helmet";
import {
  Classes,
  Button,
  Intent,
  Popover,
  Position,
  Menu,
  MenuItem,
  Icon,
  Tooltip,
  Checkbox,
  AnchorButton,
  Callout
} from "@blueprintjs/core";
import {
  DataTable,
  getTextFromEl,
  DropdownButton,
  MenuItemWithTooltip,
  CollapsibleCard
} from "@teselagen/ui";
import classNames from "classnames";
import isMobile from "is-mobile";
import tgUseLocalStorageState from "tg-use-local-storage-state";
import modelNameToLink from "../utils/modelNameToLink";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import ExportTableAsCsvButton from "../ExportTableAsCsvButton";
import {
  labModels,
  tagModels,
  labModelsNoEdit,
  extendedPropertyModels,
  externalRecordIdentifierModels,
  isDeprecatedModels,
  commonLabModels,
  lockableModels,
  aliasModels
} from "../../../tg-iso-shared/constants";
import TagTableColumn from "../TagTableColumn";
import routeDoubleClick from "../utils/routeDoubleClick";
import TagMenuItem from "../components/TagMenuItem";
import EditExtendedPropertiesMenuItem from "../components/EditExtendedPropertiesMenuItem";
import RenameRecordDialog from "../RenameRecordDialog";

import { showDialog } from "../GlobalDialog";
import { disabledFeaturesConfig, openInNewTab } from "../utils/generalUtils";
import { deleteRecordsHelper } from "../utils/deleteUtils";
import { has } from "lodash";
import { ImportFromExternalDbDialogButton } from "../ExternalIntegrations/ImportFromExternalDbDialogButton";
import EditExternalIdentifiersDialog from "../ExternalIntegrations/EditExternalIdentifiersDialog";
import GetCustomExternalInfoDialog from "../ExternalIntegrations/GetCustomExternalInfoDialog";
import UploadTagsCsvDialog from "../UploadTagsCsvDialog";
import { safeUpsert } from "../apolloMethods";
import CustomTableSearchDialog from "../CustomTableSearchDialog";
import UploadAliasesCsvDialog from "../UploadAliasesCsvDialog";

import "./style.css";
import { isSingleLabLocked, isSingleLabCommon } from "../utils/labUtils";
import ChangeProjectDialog from "../ChangeProjectDialog";
import UpdateExternalDialog from "../ExternalIntegrations/UpdateExternalDialog";
import { pushHelper } from "../utils/pushHelper";
import getModuleName from "../../../tg-iso-shared/src/utils/getModuleName";
import EditAliasesMenuItem from "../components/EditAliasesMenuItem";
import { shouldShowProjects } from "../utils/projectUtils";
import RecordLockingMenuItem from "../components/RecordLockingMenuItem";
import LibrarySidePanelWrapper from "./LibrarySidePanelWrapper";

function getRecordForViewerComponent(selectedEntities, tableParams) {
  return (
    selectedEntities[0] &&
    (tableParams.entities || []).find(e => e.id === selectedEntities[0].id)
  );
}

const InspectorPanel = ({
  ViewerComponent,
  selectedEntities,
  libraryFragment,
  tableParams,
  history,
  model,
  defaultOpen,
  alwaysShowInspector,
  inspectorWidth,
  onDoubleClick
}) => {
  let [open, setOpen] = tgUseLocalStorageState("tgInspectorOpenByDefault", {
    defaultValue: defaultOpen || false
  });
  if (alwaysShowInspector) {
    open = true;
    setOpen = () => {};
  }
  if (isMobile()) return null;
  let recordForViewerComponent;
  if (open) {
    recordForViewerComponent = getRecordForViewerComponent(
      selectedEntities,
      tableParams
    );
  }

  let inner;
  if (recordForViewerComponent) {
    inner = (
      <ViewerComponent
        libraryFragment={libraryFragment}
        record={recordForViewerComponent}
        dataType={model}
        readOnly={true}
        recordIdOverride={recordForViewerComponent?.id}
        onDoubleClick={onDoubleClick}
        refetch={tableParams.onRefresh}
      />
    );
  }

  return (
    <LibrarySidePanelWrapper
      open={open}
      extraButtons={
        alwaysShowInspector
          ? null
          : open &&
            recordForViewerComponent && (
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  left: 10,
                  top: 7
                }} //3
              >
                <Icon
                  intent="primary"
                  icon="maximize"
                  // iconSize={IconSize.LARGE}
                  onClick={() =>
                    onDoubleClick(recordForViewerComponent, undefined, history)
                  }
                />
              </div>
            )
      }
      setOpen={setOpen}
      alwaysShowInspector={alwaysShowInspector}
      inspectorWidth={inspectorWidth}
    >
      {inner}
    </LibrarySidePanelWrapper>
  );
};

const disabledForCommonSingleLabTooltip =
  "Cannot add to common lab. Please change lab before continuing.";

const AbstractLibrary = props => {
  const {
    additionalButtons = [],
    additionalHeaderItems,
    afterDelete,
    afterTag,
    alwaysShowInspector,
    batchUploadButton: maybeBatchUploadButton,
    beforeDelete = noop,
    checkIfIntegrationsDisabled,
    className,
    compact,
    contextMenu,
    defaultViewerOpen,
    extraTableParams = {},
    generateExtraContextMenuItems,
    getRoute,
    helpArticleLink: _helpArticleLink,
    hideInspector,
    history,
    isCard,
    isEmbedded,
    isLibraryTable,
    inspectorWidth,
    integrations,
    libraryFilter,
    libraryFragment,
    libraryHelperText,
    libraryName,
    libraryTitle,
    model,
    newItemMenu,
    newItemText,
    noAliasUpload,
    noContextMenu,
    noDelete,
    noHeaderBtns,
    noNewItem,
    noOpen,
    noRename,
    noRoute,
    noTagUpload,
    onEdit,
    onDelete,
    onDoubleClick: propsOnDoubleClick,
    onNewItemClick,
    RecordViewInspector,
    readOnly,
    refetch: propsRefetch,
    rnaType,
    searchMenu: _searchMenu,
    selectedEntities,
    selectTableRecords = noop,
    sequenceType,
    show,
    showUploadFunction,
    tableParams,
    topItems,
    uploadMenuItems = [],
    ViewerComponent: _ViewerComponent,
    withExport,
    withExternalImportIntegration,
    wrapNewItemButton
  } = props;

  const titleCase = useMemo(() => {
    return modelNameToReadableName(libraryName || model, {
      plural: true,
      upperCase: true
    });
  }, [libraryName, model]);

  useEffect(() => {
    window.activeTableRefetch = tableParams.onRefresh;
  }, [tableParams?.onRefresh]);

  const handleNewItemClick = e => {
    if (onNewItemClick) {
      onNewItemClick({ event: e, history, refetch: tableParams.onRefresh });
    } else {
      pushHelper(e, `${modelNameToLink(libraryName || model)}/new`);
    }
  };

  const renderContextMenu = options => {
    const { selectedRecords, history } = options;
    const plural = selectedRecords.length > 1;
    const record = selectedRecords[0];
    if (!record) return;
    let integrationsDisabledMsg;
    if (checkIfIntegrationsDisabled) {
      integrationsDisabledMsg = checkIfIntegrationsDisabled({
        selectedRecords
      });
    }
    const refetch = async () => {
      await tableParams.onRefresh();
      // deselect and reselect so that the inspector is forced to refresh
      selectTableRecords([]);
      selectTableRecords(selectedRecords.map(r => r.id));
    };

    const model = record.__typename;
    let route;
    if (getRoute) {
      route = getRoute(record);
    } else {
      route = modelNameToLink(model, record.id);
    }
    const libExtraItems = generateExtraContextMenuItems
      ? generateExtraContextMenuItems(selectedRecords, { show }, props)
      : [];
    let extraContextItems = [
      ...(Array.isArray(libExtraItems) ? libExtraItems : [libExtraItems])
    ];
    if (!Array.isArray(extraContextItems)) {
      extraContextItems = [extraContextItems]; // assume they only returned a single menu item
    }
    const withExternalReference =
      externalRecordIdentifierModels.includes(model);
    const customInfoIntegrations =
      (!plural &&
        integrations &&
        integrations.filter(i => i.integrationTypeCode === "CUSTOM_INFO")) ||
      [];

    if (customInfoIntegrations.length) {
      extraContextItems.push(
        <MenuItemWithTooltip
          disabled={integrationsDisabledMsg}
          tooltip={integrationsDisabledMsg}
          key="viewCustomInfo"
          text="View Custom Info"
          icon="eye-open"
        >
          {customInfoIntegrations.map((integration, i) => {
            return (
              <MenuItem
                key={`viewCustomInfoSub-${i}`}
                text={integration.name}
                onClick={() => {
                  showDialog({
                    modalProps: {
                      record: {
                        ...record,
                        internalType: integration.subtype,
                        id: record.id
                      },
                      endpointId: integration.integrationEndpoints[0].id,
                      dialogProps: {
                        title: integration.name
                      }
                    },
                    ModalComponent: GetCustomExternalInfoDialog
                  });
                }}
              />
            );
          })}
        </MenuItemWithTooltip>
      );
    }
    const updateIntegrations =
      (integrations &&
        integrations.filter(i => i.integrationTypeCode === "UPDATE")) ||
      [];
    if (updateIntegrations.length) {
      extraContextItems.push(
        <MenuItemWithTooltip
          disabled={integrationsDisabledMsg}
          tooltip={integrationsDisabledMsg}
          key="updateExternal"
          className="tg-integration-update-menu-item"
          icon="automatic-updates"
          text="Update"
        >
          {updateIntegrations.map((integration, i) => {
            return (
              <MenuItem
                key={`updateExternal-${i}`}
                text={integration.name}
                onClick={() => {
                  showDialog({
                    modalProps: {
                      model,
                      records: selectedRecords,
                      integrationId: integration.id,
                      dialogProps: {
                        title: integration.name
                      },
                      refetch
                    },
                    ModalComponent: UpdateExternalDialog
                  });
                }}
              />
            );
          })}
        </MenuItemWithTooltip>
      );
    }
    const exportIntegrations =
      (integrations &&
        integrations.filter(i => i.integrationTypeCode === "EXPORT")) ||
      [];
    if (exportIntegrations.length) {
      extraContextItems.push(
        <MenuItemWithTooltip
          disabled={integrationsDisabledMsg}
          tooltip={integrationsDisabledMsg}
          key="exportExternal"
          icon="export"
          text="Export to External DB"
        >
          {exportIntegrations.map((integration, i) => {
            return (
              <MenuItem
                key={`exportExternal-${i}`}
                text={integration.name}
                onClick={() => {
                  showDialog({
                    modalType: "ExportToExternalDbDialog",
                    modalProps: {
                      model,
                      refetch: propsRefetch,
                      records: selectedRecords,
                      integrationId: integration.id,
                      dialogProps: {
                        title: `Export - ${integration.name}`
                      }
                    }
                  });
                }}
              />
            );
          })}
        </MenuItemWithTooltip>
      );
    }
    if (withExternalReference) {
      selectedRecords.length === 1 &&
        extraContextItems.push(
          <MenuItem
            key="EditExternalIdentifiersDialog"
            text="Edit External Identifiers"
            icon="edit"
            onClick={() => {
              showDialog({
                ModalComponent: EditExternalIdentifiersDialog,
                modalProps: {
                  initialValues: selectedRecords[0]
                }
              });
            }}
          />
        );
    }

    if (isLibraryTable && labModels.includes(model)) {
      if (!labModelsNoEdit.includes(model)) {
        // TODO tgreen: we are disabling moving items between labs because it does not propagate to nested entities and can lead to a lot of problems currently
        // extraContextItems.push(
        //   <MenuItem
        //     key="MoveItemToLabGroupDialog"
        //     icon="move"
        //     text={`Move Item${
        //       selectedRecords.length > 1 ? "s" : ""
        //     } To A Different Lab Group`}
        //     onClick={() => {
        //       showDialog({
        //         modalType: "MOVE_ITEM_TO_LAB_GROUP",
        //         modalProps: {
        //           items: selectedRecords,
        //           model,
        //           refetch
        //         }
        //       });
        //     }}
        //   />
        // );
      }
    }

    const openRecordInNewTab = () => {
      let routeToOpen = route;
      if (routeToOpen.hash) {
        routeToOpen = route.pathname + `#${routeToOpen.hash}`;
      }
      openInNewTab(routeToOpen);
    };

    const openRecord = e => {
      if (e.metaKey) {
        openRecordInNewTab();
      } else {
        history.push(route);
      }
    };

    const deleteRecords = () => {
      if (onDelete) {
        return onDelete({
          records: selectedRecords,
          refetch,
          deleteRecordsHelper: _records =>
            deleteRecordsHelper({
              records: _records,
              beforeDelete,
              afterDelete,
              history,
              refetch
            }),
          history
        });
      } else {
        return deleteRecordsHelper({
          records: selectedRecords,
          beforeDelete,
          afterDelete,
          history,
          refetch
        });
      }
    };

    const renameMenuItem =
      has(record, "name") && !plural && !noRename ? (
        <MenuItem
          key="rename"
          icon="edit"
          onClick={() => {
            showDialog({
              ModalComponent: RenameRecordDialog,
              modalProps: {
                libraryName,
                record
              }
            });
          }}
          text="Rename"
        />
      ) : undefined;

    let editMenuItem;
    if (onEdit && !plural) {
      editMenuItem = (
        <MenuItem
          key="editRecord"
          icon="edit"
          onClick={() => onEdit(record)}
          text="Edit"
        />
      );
    }

    let deprecateMenuItem, reactivateMenuItem;
    if (isDeprecatedModels.includes(model)) {
      const undeprecatedRecords = selectedRecords.filter(r => !r.isDeprecated);
      const deprecatedRecords = selectedRecords.filter(r => r.isDeprecated);
      if (undeprecatedRecords.length) {
        deprecateMenuItem = (
          <MenuItem
            key="deprecate"
            icon="disable"
            text="Deprecate"
            onClick={async () => {
              try {
                await safeUpsert(
                  model,
                  selectedRecords.map(r => ({ id: r.id, isDeprecated: true }))
                );
                await refetch();
              } catch (error) {
                console.error("error:", error);
                window.toastr.error("Error deprecating");
              }
            }}
          />
        );
      }
      if (deprecatedRecords.length) {
        reactivateMenuItem = (
          <MenuItem
            key="reactivate"
            icon="confirm"
            text="Reactivate"
            onClick={async () => {
              try {
                await safeUpsert(
                  model,
                  deprecatedRecords.map(r => ({
                    id: r.id,
                    isDeprecated: false
                  }))
                );
                await refetch();
              } catch (error) {
                console.error("error:", error);
                window.toastr.error("Error deprecating");
              }
            }}
          />
        );
      }
    }

    const deleteMenuItem = (
      <MenuItem
        key="delete"
        icon="trash"
        onClick={deleteRecords}
        text="Delete"
      />
    );
    const openMenuItem =
      plural || noOpen ? undefined : (
        <React.Fragment key="open-menu-items">
          <MenuItem icon="eye-open" onClick={openRecord} text="View" />
          <MenuItem
            icon="applications"
            onClick={openRecordInNewTab}
            text="Open in New Tab"
          />
        </React.Fragment>
      );

    const withTagging = isLibraryTable && tagModels.includes(model);
    const withAliases = isLibraryTable && aliasModels.includes(model);
    const withProjects = isLibraryTable && shouldShowProjects(model);
    const withRecordLocking =
      isLibraryTable &&
      window.frontEndConfig.userRecordLocking &&
      lockableModels.includes(model);

    const withExtendedProperties =
      isLibraryTable && extendedPropertyModels.includes(model);
    const tagMenuItem = withTagging ? (
      <TagMenuItem
        key="tag"
        records={selectedRecords}
        refetch={refetch}
        afterTag={afterTag}
      />
    ) : undefined;

    const projectMenuItem = withProjects ? (
      <MenuItem
        icon="projects"
        text="Project"
        key="changeProject"
        onClick={() => {
          showDialog({
            ModalComponent: ChangeProjectDialog,
            modalProps: {
              records: selectedRecords,
              refetch
            }
          });
        }}
      />
    ) : undefined;

    const lockingMenuItem = withRecordLocking ? (
      <RecordLockingMenuItem
        key="recordLocking"
        records={selectedRecords}
        refetch={propsRefetch}
      />
    ) : undefined;

    const editExtendedPropertiesMenuItem = withExtendedProperties ? (
      <EditExtendedPropertiesMenuItem
        key="extendedProps"
        records={selectedRecords}
        libraryFragment={libraryFragment}
      />
    ) : undefined;

    const editAliasesMenuItem =
      withAliases && !plural ? (
        <EditAliasesMenuItem
          key="EditAliasesMenuItem"
          record={record}
          fromLibrary
        />
      ) : undefined;

    const hasSingleLabLockedRecords = selectedRecords.some(r =>
      isSingleLabLocked(r)
    );

    const hasRecordFromOtherModule = selectedRecords.some(
      r => r.moduleSelector && r.moduleSelector !== getModuleName()
    );

    if (hasRecordFromOtherModule) {
      return libExtraItems || [];
    }

    // don't allow all the edit actions if locked
    if (hasSingleLabLockedRecords) {
      return [openMenuItem];
    }

    if (contextMenu)
      return contextMenu({
        ...props,
        ...options,
        refetch,
        deleteRecords,
        openRecord,
        renameMenuItem,
        openMenuItem,
        deprecateMenuItem,
        reactivateMenuItem,
        tagMenuItem,
        lockingMenuItem,
        projectMenuItem,
        editExtendedPropertiesMenuItem,
        editAliasesMenuItem,
        deleteMenuItem,
        editMenuItem,
        extraContextItems
      });
    return [
      openMenuItem,
      editMenuItem,
      renameMenuItem,
      !noDelete && deleteMenuItem,
      deprecateMenuItem,
      reactivateMenuItem,
      lockingMenuItem,
      tagMenuItem,
      projectMenuItem,
      editExtendedPropertiesMenuItem,
      editAliasesMenuItem,
      ...extraContextItems
    ];
  };

  const isCommonLabDisabled = () => {
    return isSingleLabCommon() && commonLabModels.includes(model);
  };

  const newItemButton = () => {
    if (noNewItem) return null;
    let buttonText;
    if (!compact) {
      if (newItemText) {
        buttonText = newItemText;
      } else {
        buttonText = `New ${modelNameToReadableName(libraryName || model, {
          upperCase: true
        })}`;
      }
    }

    let tooltip;
    let disabled = false;
    if (isCommonLabDisabled()) {
      tooltip = disabledForCommonSingleLabTooltip;
      disabled = true;
    }
    const buttonProps = {
      disabled,
      key: "newItemButton",
      onClick: wrapNewItemButton ? noop : handleNewItemClick,
      text: isMobile() ? "" : buttonText,
      icon: "add",
      intent: Intent.SUCCESS,
      className: Classes.MINIMAL
    };
    let newItemButton;
    if (newItemMenu) {
      newItemButton = (
        <DropdownButton {...buttonProps} menu={newItemMenu} onClick={noop} />
      );
    } else {
      newItemButton = <Button {...buttonProps} />;
      if (wrapNewItemButton) {
        newItemButton = wrapNewItemButton(newItemButton, {
          refetch: tableParams.onRefresh
        });
      }
    }
    if (disabled) {
      return (
        <Tooltip content={tooltip}>
          <AnchorButton {...buttonProps} />
        </Tooltip>
      );
    }
    return newItemButton;
  };

  const showAliasUpload = () => {
    showDialog({
      ModalComponent: UploadAliasesCsvDialog,
      modalProps: {
        refetch: tableParams.onRefresh,
        additionalFilter: libraryFilter,
        model
      }
    });
  };

  const showTagUpload = () => {
    showDialog({
      ModalComponent: UploadTagsCsvDialog,
      modalProps: {
        refetch: tableParams.onRefresh,
        sequenceType,
        rnaType,
        model
      }
    });
  };

  const uploadButton = () => {
    let batchUploadButton = maybeBatchUploadButton;
    const withTagging = isLibraryTable && tagModels.includes(model);
    const withAliases = isLibraryTable && aliasModels.includes(model);
    if (
      showUploadFunction ||
      uploadMenuItems.length ||
      (withTagging && !noTagUpload)
    ) {
      let disablePopover = false;
      let uploadOnClick;
      const allUploadMenuItems = [];
      if (showUploadFunction && !uploadMenuItems.length && !withTagging) {
        uploadOnClick = showUploadFunction;
        disablePopover = true;
      } else {
        if (showUploadFunction) {
          allUploadMenuItems.push(
            <MenuItem
              key="libraryUpload"
              text={titleCase}
              onClick={showUploadFunction}
            />
          );
        }
        if (uploadMenuItems.length) {
          allUploadMenuItems.push(...uploadMenuItems);
        }
        if (withTagging && !noTagUpload) {
          allUploadMenuItems.push(
            <MenuItem
              key="tagUpload"
              text={`Tags on ${titleCase}`}
              onClick={showTagUpload}
            />
          );
        }
        if (withAliases && !noAliasUpload) {
          allUploadMenuItems.push(
            <MenuItem
              key="aliasUpload"
              text="Aliases"
              onClick={showAliasUpload}
            />
          );
        }
      }
      const disabledBtn = isCommonLabDisabled();
      const buttonProps = {
        disabled: disabledBtn,
        icon: "upload",
        onClick: uploadOnClick,
        className: Classes.MINIMAL,
        intent: Intent.PRIMARY,
        text: compact ? undefined : isMobile() ? "" : "Upload",
        rightIcon: disablePopover ? null : "caret-down"
      };
      if (disabledBtn) {
        batchUploadButton = (
          <Tooltip content={disabledForCommonSingleLabTooltip}>
            <AnchorButton {...buttonProps} />
          </Tooltip>
        );
      } else {
        batchUploadButton = (
          <Popover
            key="upload"
            minimal
            disabled={disablePopover}
            content={<Menu>{allUploadMenuItems}</Menu>}
            position={Position.BOTTOM_LEFT}
          >
            <Button {...buttonProps} />
          </Popover>
        );
      }
    }
    return batchUploadButton;
  };

  const showCustomTableSearch = () => {
    showDialog({
      ModalComponent: CustomTableSearchDialog,
      modalProps: {
        tableParams
      }
    });
  };

  const onDoubleClick = (row, rowIndex, history, e) => {
    if (getRoute) {
      pushHelper(e, getRoute(row));
    } else {
      routeDoubleClick(row, rowIndex, history, e);
    }
  };

  let ViewerComponent = _ViewerComponent;
  if (RecordViewInspector) {
    ViewerComponent = RecordViewInspector;
  }
  if (ViewerComponent && !selectedEntities) {
    throw new Error(
      `Need to wrap ${titleCase} library with withSelectedEntities if using ViewerComponent`
    );
  }
  const withTagging = isLibraryTable && tagModels.includes(model);

  const cellRenderer = extraTableParams.cellRenderer || {};

  if (withTagging) {
    const paramProps = pick(tableParams, ["currentParams", "setNewParams"]);
    cellRenderer["tags"] = (v, record) => (
      <TagTableColumn record={record} paramProps={paramProps} />
    );
  }
  if (
    withExternalImportIntegration &&
    !disabledFeaturesConfig.integrations &&
    !disabledFeaturesConfig.importFromExternal
  ) {
    additionalButtons.push(
      <ImportFromExternalDbDialogButton
        key="ImportFromExternalDbDialogButton"
        subtype={withExternalImportIntegration.key}
        refetch={propsRefetch}
      />
    );
  }

  let searchMenuButton;
  let searchMenu = _searchMenu;

  if (!searchMenu && isDeprecatedModels.includes(model)) {
    searchMenu = searchMenu = (
      <div style={{ padding: "10px 20px", paddingTop: 18 }}>
        <Checkbox
          label="Show deprecated"
          checked={tableParams.currentParams.showDeprecated || false}
          onChange={e => {
            tableParams.setNewParams({
              ...tableParams.currentParams,
              showDeprecated: e.target.checked
            });
          }}
        />
      </div>
    );
  }

  if (searchMenu) {
    searchMenuButton = (
      <Popover content={searchMenu} position="bottom-right" minimal>
        <Button
          icon="caret-down"
          className="tg-table-search-menu-button"
          minimal
          style={{
            borderRadius: "50%"
          }}
        />
      </Popover>
    );
  } else if (isLibraryTable) {
    searchMenuButton = (
      <Button
        icon="menu"
        className="tg-custom-table-search-button"
        minimal
        onClick={showCustomTableSearch}
        style={{
          borderRadius: "50%"
        }}
      />
    );
  }

  const _onDoubleClick =
    noRoute || readOnly ? undefined : propsOnDoubleClick || onDoubleClick;

  const title = libraryTitle || titleCase;
  let helmetTitle = title;
  if (!(typeof helmetTitle === "string")) {
    helmetTitle = getTextFromEl(helmetTitle);
  }

  const helpArticleLink = () => {
    if (!_helpArticleLink) return;
    let customProps = {};
    if (isFunction(_helpArticleLink)) {
      customProps = { onClick: _helpArticleLink };
    }
    if (isString(_helpArticleLink)) {
      customProps = { href: _helpArticleLink, target: "_blank" };
    }
    return (
      <a
        style={{ marginLeft: 7, marginBottom: 7 }}
        rel="noopener noreferrer"
        {...customProps}
      >
        <Icon icon="help" />
      </a>
    );
  };

  const libraryComponent = (
    <div
      className={className}
      style={
        isEmbedded
          ? {}
          : {
              minWidth: 0,
              width: "100%",
              height: "100%",
              padding: 10,
              flex: 1
            }
      }
    >
      {helmetTitle && <Helmet title={helmetTitle} />}
      {libraryHelperText && (
        <Callout intent="primary">{libraryHelperText}</Callout>
      )}
      {topItems}
      <DataTable
        {...tableParams}
        autoFocusSearch
        maxHeight={null}
        compact={compact}
        searchMenuButton={searchMenuButton}
        showCount
        onDoubleClick={_onDoubleClick}
        integrations={integrations}
        contextMenu={noContextMenu || readOnly ? undefined : renderContextMenu}
        tableName={
          <span style={{ display: "flex", alignItems: "center" }}>
            <div>{title}</div>
            {helpArticleLink()}
          </span>
        }
        className={classNames(kebabCase(`${libraryName || model}Table`), {
          "tg-library-table": isLibraryTable
        })}
        {...extraTableParams}
        // needs to come last to override extraTableParams
        cellRenderer={cellRenderer}
        additionalFooterButtons={
          withExport && (
            <Tooltip content="Export as CSV">
              <ExportTableAsCsvButton {...props} {...extraTableParams} />
            </Tooltip>
          )
        }
      >
        {!noHeaderBtns && !readOnly && (
          <>
            {newItemButton()}
            {uploadButton()}
            {additionalButtons}
            {additionalHeaderItems}
          </>
        )}
      </DataTable>
    </div>
  );

  let inspectorPanel;
  if (ViewerComponent && !hideInspector) {
    inspectorPanel = (
      <InspectorPanel
        history={history}
        ViewerComponent={ViewerComponent}
        libraryFragment={libraryFragment}
        selectedEntities={selectedEntities}
        tableParams={tableParams}
        model={model}
        onDoubleClick={_onDoubleClick}
        defaultOpen={defaultViewerOpen}
        inspectorWidth={inspectorWidth}
        alwaysShowInspector={alwaysShowInspector}
      />
    );
  }

  let toRet;
  if (inspectorPanel) {
    toRet = (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          // max width full width - sidebar width
          maxWidth: `calc(100vw - 40px)`
        }}
      >
        {libraryComponent}
        {inspectorPanel}
      </div>
    );
  } else {
    toRet = libraryComponent;
  }
  if (isCard) return <CollapsibleCard>{toRet}</CollapsibleCard>;
  return toRet;
};

export default AbstractLibrary;
