/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { range } from "lodash";

export const getAllIndicesBetweenTwoLocations = (activeIndex, clickedIndex) => {
  let rowMin, rowMax;
  if (activeIndex < clickedIndex) {
    rowMin = activeIndex;
    rowMax = clickedIndex;
  } else {
    rowMin = clickedIndex;
    rowMax = activeIndex;
  }
  return range(rowMin, rowMax + 1);
};
