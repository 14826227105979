/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm, Field } from "redux-form";
import { InputField, RadioGroupField, DataTable } from "@teselagen/ui";
import { get, set } from "lodash";
import { compose } from "redux";

import IconFieldComponent from "./IconFieldComponent";
import { tgFormValues } from "@teselagen/ui";
import { CheckboxField } from "@teselagen/ui";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { Tooltip, Icon, Intent, Position, Button } from "@blueprintjs/core";
import ruleSetFragment from "../../../../../../tg-iso-design/graphql/fragments/ruleSetFragment";
import "./style.css";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import VerticalTable from "../../../../../src-shared/components/VerticalTable";
import { disabledFeaturesConfig } from "../../../../../src-shared/utils/generalUtils";

const elementsSchema = {
  model: "element",
  fields: [
    { path: "id", type: "string", displayName: "Id", isHidden: true },
    { path: "name", type: "string", displayName: "Name" },
    { path: "size", type: "number", displayName: "Size" }
  ]
};

const ruleSetsSchema = {
  model: "ruleSet",
  fields: [{ path: "name", type: "string", displayName: "Name" }]
};

class BinPanel extends React.Component {
  handleFieldSubmit = fieldName => newValue => {
    const {
      updateBin,
      selectedBin,
      valid,
      leftJunction,
      rightJunction,
      updateJunction
    } = this.props;

    if (!valid) return;

    if (fieldName === "direction") {
      newValue = Number(newValue);
    }

    if (fieldName === "leftJunctionBps") {
      updateJunction({
        id: leftJunction.id,
        bps: newValue.toUpperCase()
      });
    } else if (fieldName === "rightJunctionBps") {
      updateJunction({
        id: rightJunction.id,
        bps: newValue.toUpperCase()
      });
    } else {
      updateBin({
        id: selectedBin.id,
        [fieldName]: newValue
      });
    }
  };

  handleDsfChange = (e, newValue, oldValue) => {
    const {
      setDsf,
      selectedBin,
      isClassicView,
      selectedInputCard
    } = this.props;
    setDsf({
      cardId: isClassicView ? selectedInputCard.id : selectedBin.id,
      // We can't simply set dsf to newValue because of a bug.
      dsf: !oldValue
    });
  };

  // Needed to prevent refersh upon pressing enter.
  onSubmit = () => {};

  handleApplyRuleSetClick = () => {
    const { applyRuleSet, selectedBin, ruleSets } = this.props;

    showDialog({
      modalType: "DESIGN_RULE_SET_LIBRARY",
      modalProps: {
        idsToIgnore: ruleSets.map(rs => rs.id),
        onSubmit: async ruleSets => {
          try {
            const fullRuleSets = await safeQuery(ruleSetFragment, {
              isPlural: true,
              variables: { filter: { id: ruleSets.map(rs => rs.id) } }
            });
            applyRuleSet({
              binIds: [selectedBin.id],
              ruleSets: fullRuleSets
            });
          } catch (e) {
            console.error(e);
            window.toastr.error("Error applying rule set.");
          }
        }
      }
    });
  };

  handleLeftJunctionIsOverhangChange = (e, newValue) => {
    const { updateJunction, leftJunction } = this.props;

    updateJunction({
      id: leftJunction.id,
      fivePrimeCardFormsOverhang: !newValue
    });
  };

  handleRightJunctionIsOverhangChange = (e, newValue) => {
    const { updateJunction, rightJunction } = this.props;

    updateJunction({
      id: rightJunction.id,
      fivePrimeCardFormsOverhang: newValue
    });
  };

  goToEditRuleSetView = ruleSet => {
    const { history } = this.props;
    history.push("/design-rulesets/" + ruleSet.id);
  };

  handleDeleteRuleSetClick = selectedRuleSet => {
    const { selectedBin, removeRuleSet } = this.props;
    removeRuleSet({
      binIds: [selectedBin.id],
      ruleSetIds: [selectedRuleSet.id]
    });
  };

  handleValidateDesignClick = () => {
    showDialog({
      modalType: "DESIGN_RULE_VIOLATIONS"
    });
  };
  renderRuleSetsTable() {
    const { ruleSets, isLocked } = this.props;
    return (
      <React.Fragment>
        <h6 style={{ marginTop: 15, display: "flex", alignItems: "center" }}>
          Design Rulesets{" "}
          <Button
            minimal
            className="add-design-ruleset-button"
            style={{ marginLeft: 6 }}
            intent="success"
            icon="add"
            onClick={this.handleApplyRuleSetClick}
            disabled={isLocked}
          />
        </h6>
        <div>
          <VerticalTable
            schema={ruleSetsSchema}
            entities={ruleSets}
            onEdit={!isLocked && this.goToEditRuleSetView}
            onDelete={!isLocked && this.handleDeleteRuleSetClick}
          />
          <Button
            intent="primary"
            text="Validate Rulesets"
            disabled={isLocked}
            onClick={this.handleValidateDesignClick}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      icons,
      selectedBin,
      selectedBinCard,
      selectedBinNumber,
      selectedBinCardLevel,
      elements,
      direction,
      cannotChangeIcon,
      isClassicView,
      handleSubmit,
      isLocked,
      leftJunction,
      rightJunction,
      isUSERInput
    } = this.props;

    if (!selectedBin)
      return (
        <div className="bin-inspector-panel">
          <i>Please select a bin.</i>
        </div>
      );

    return (
      <div className="bin-inspector-panel">
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <h5 className="inspector-panel-header">Bin Details</h5>
          <Field
            name="icon"
            icons={icons}
            forward={direction === "1"}
            disabled={cannotChangeIcon || isLocked}
            onFieldSubmit={this.handleFieldSubmit("icon")}
            component={IconFieldComponent}
          />
          <br></br>
          <span>{`Bin ${selectedBinNumber} of Card ${
            get(selectedBinCard, "name")
              ? selectedBinCardLevel + " - " + get(selectedBinCard, "name")
              : selectedBinCardLevel
          }`}</span>
          <br />
          <br />
          <InputField
            name="name"
            label="Name"
            onFieldSubmit={this.handleFieldSubmit("name")}
            disabled={isLocked}
          />
          {!!isClassicView && (
            <CheckboxField
              name="dsf"
              label="Direct Synthesis Firewall"
              onChange={this.handleDsfChange}
              disabled={isLocked}
            />
          )}
          <RadioGroupField
            name="direction"
            label="Direction"
            disabled={set.isInjected || isLocked}
            defaultValue={set.direction + ""}
            onFieldSubmit={this.handleFieldSubmit("direction")}
            options={[
              {
                label: "Forward",
                value: "1"
              },
              {
                label: "Reverse",
                value: "0"
              }
            ]}
          />
          <InputField
            name="fro"
            label={
              <div style={{ display: "inline-flex" }}>
                Forced Relative Overhang Position
                <div style={{ marginTop: "-.5em" }}>
                  <Tooltip
                    position={Position.TOP}
                    content={
                      <div style={{ width: 325 }}>
                        <strong>Function:</strong> Values in this field will
                        forcibly set a specific relative overlap/overhang
                        position between assembly pieces at junctions that occur
                        at the 3' end of the highlighted bin.
                        <br />
                        <strong>Format:</strong> Entries should either be empty
                        or an integer (e.g. 2, -2, or blank) of bps and only
                        have an effect if the bin is a 3' terminal end of an
                        assembly piece.
                      </div>
                    }
                  >
                    <Icon
                      icon="help"
                      intent={Intent.PRIMARY}
                      style={{ marginLeft: "1em" }}
                    />
                  </Tooltip>
                </div>
              </div>
            }
            readOnly={isLocked}
            onFieldSubmit={this.handleFieldSubmit("fro")}
          />
          <InputField
            name="extra5PrimeBps"
            label={
              <div style={{ display: "inline-flex" }}>
                Extra 5' Overlap Bps
                <div style={{ marginTop: "-.5em" }}>
                  <Tooltip
                    content={
                      <div style={{ width: 325 }}>
                        <strong>Function:</strong> Values in this field will add
                        or remove a specified number of base pairs from the 5'
                        side of the Gibson/SLIC/CPEC overlap for the junction
                        that would occur on the 5' side of this bin (the default
                        size of the overlaps are controlled by the "Gibson
                        Overlap Bps" assembly parameter).
                        <br />
                        <strong>Format:</strong> Entries should either be empty
                        or an integer (e.g. 10, -2, or blank) of bps and only
                        have an effect in Gibson/SLIC/CPEC assemblies where the
                        bin is a 5' terminal end of an assembly piece.
                      </div>
                    }
                  >
                    <Icon
                      icon="help"
                      intent={Intent.PRIMARY}
                      style={{ marginLeft: "1em" }}
                    />
                  </Tooltip>
                </div>
              </div>
            }
            readOnly={isLocked}
            onFieldSubmit={this.handleFieldSubmit("extra5PrimeBps")}
          />
          <InputField
            name="extra3PrimeBps"
            label={
              <div style={{ display: "inline-flex" }}>
                Extra 3' Overlap Bps
                <div style={{ marginTop: "-.5em" }}>
                  <Tooltip
                    content={
                      <div style={{ width: 325 }}>
                        <strong>Function:</strong> Values in this field will add
                        or remove a specified number of base pairs from the 3'
                        side of the Gibson/SLIC/CPEC overlap for the junction
                        that would occur on the 3' side of this bin (the default
                        size of the overlaps are controlled by the "Gibson
                        Overlap Bps" assembly parameter).
                        <br />
                        <strong>Format:</strong> Entries should either be empty
                        or an integer (e.g. 10, -2, or blank) of bps and only
                        have an effect in Gibson/SLIC/CPEC assemblies where the
                        bin is a 3' terminal end of an assembly piece.
                      </div>
                    }
                  >
                    <Icon
                      icon="help"
                      intent={Intent.PRIMARY}
                      style={{ marginLeft: "1em" }}
                    />
                  </Tooltip>
                </div>
              </div>
            }
            readOnly={isLocked}
            onFieldSubmit={this.handleFieldSubmit("extra3PrimeBps")}
          />
          Parts
          <DataTable
            formName="BinPanelElements"
            compact
            isSimple
            isInfinite
            urlConnected={false}
            schema={elementsSchema}
            entities={elements}
          />
          {!disabledFeaturesConfig.designRulesets && this.renderRuleSetsTable()}
          {isUSERInput && isClassicView ? (
            <div className="user-junction-container">
              <div style={{ display: "inline-flex" }}>
                <h6>USER Junctions</h6>
                <div style={{ marginTop: "-.5em" }}>
                  <Tooltip
                    position={Position.TOP}
                    content={
                      <div style={{ width: 220 }}>
                        Here you can specify the exact bps that will be at the
                        end of USER parts that result from this bin.
                        <br />
                        <b>Note:</b> This section will appear in the Card
                        Details inspector if in non-standard View Mode
                      </div>
                    }
                  >
                    <Icon
                      icon="help"
                      intent={Intent.PRIMARY}
                      style={{ marginLeft: "1em", marginTop: "0.5em" }}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="card-visualization">
                <div className="strand-visualization top">
                  <div
                    className={
                      leftJunction.fivePrimeCardFormsOverhang ? "blank" : ""
                    }
                  >
                    {leftJunction.fivePrimeCardFormsOverhang ? "USER tail" : ""}
                  </div>
                  <div className="middle" />
                  <div
                    className={
                      rightJunction.fivePrimeCardFormsOverhang ? "" : "blank"
                    }
                  >
                    {rightJunction.fivePrimeCardFormsOverhang
                      ? ""
                      : "USER tail"}
                  </div>
                </div>
                <div className="strand-visualization bottom">
                  <div
                    className={
                      leftJunction.fivePrimeCardFormsOverhang ? "" : "blank"
                    }
                  >
                    {leftJunction.fivePrimeCardFormsOverhang ? "" : "USER tail"}
                  </div>
                  <div className="middle" />
                  <div
                    className={
                      rightJunction.fivePrimeCardFormsOverhang ? "blank" : ""
                    }
                  >
                    {rightJunction.fivePrimeCardFormsOverhang
                      ? "USER tail"
                      : ""}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CheckboxField
                  name="leftJunctionIsUnderhang"
                  label="Overhang Left"
                  onChange={this.handleLeftJunctionIsOverhangChange}
                  disabled={isLocked}
                />
                <div style={{ width: "1.5em" }}></div>
                <CheckboxField
                  name="rightJunctionIsOverhang"
                  label="Overhang Right"
                  onChange={this.handleRightJunctionIsOverhangChange}
                  disabled={isLocked}
                />
              </div>
              <InputField
                name="leftJunctionBps"
                label="Left Junction Bps (forward top strand)"
                readOnly={isLocked}
                onFieldSubmit={this.handleFieldSubmit("leftJunctionBps")}
              />
              <InputField
                name="rightJunctionBps"
                label="Right Junction Bps (forward top strand)"
                readOnly={isLocked}
                onFieldSubmit={this.handleFieldSubmit("rightJunctionBps")}
              />
              {/* <SelectField
              label={
                <div style={{ display: "inline-flex" }}>
                  <h6>Adding Missing Specified Ends</h6>
                  <div style={{ marginTop: "-.5em" }}>
                    <Tooltip
                      position={Position.TOP}
                      content={
                        <div style={{ width: 220 }}>
                          If end bps are specified and not found, you can add a
                          sourcing reaction to add the end bps onto your parts
                          via the selected method that'll be generated when you
                          submit the design for assembly.
                        </div>
                      }
                    >
                      <Icon
                        icon="help"
                        intent={Intent.PRIMARY}
                        style={{ marginLeft: "1em", marginTop: "0.5em" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              name="howToSourceJunctionBps"
              options={[
                {
                  label: "None",
                  value: "none"
                },
                {
                  label: "PCR",
                  value: "pcr"
                },
                {
                  label: "Direct Synthesis",
                  value: "directSynthesis"
                },
                {
                  label: "Unspecified",
                  value: "unspecified"
                }
              ]}
              disabled={isLocked}
              onFieldSubmit={() => "kc_todo"}
            /> */}
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  const requiredProperties = ["name", "icon"];

  requiredProperties.forEach(property => {
    if (!get(values, property)) set(errors, property, "Required");
  });
  if (!isFroValid(values.fro)) errors.fro = "Invalid fro.";
  if (!isExtraBpsValid(values.extra5PrimeBps))
    errors.extra5PrimeBps = "Must be empty or integer.";
  if (!isExtraBpsValid(values.extra3PrimeBps))
    errors.extra3PrimeBps = "Must be empty or integer.";
  if (!areJunctionBpsValid(values.leftJunctionBps))
    errors.leftJunctionBps = "Must be valid DNA characters (ATGC).";
  if (!areJunctionBpsValid(values.rightJunctionBps))
    errors.rightJunctionBps = "Must be valid DNA characters (ATGC).";
  return errors;
};

export default compose(
  reduxForm({
    form: "binPanelForm", // a unique name for this form
    enableReinitialize: true,
    validate
  }),
  tgFormValues("name", "direction", "icon")
)(BinPanel);

function isExtraBpsValid(bps) {
  return !bps || /-?\d+/.test(bps);
}

function isFroValid(fro) {
  if (!fro) return true;
  return /^(?:=|<|>)?-?\d+$/.test(fro);
}

function areJunctionBpsValid(bps) {
  return !bps || !/[^ATGC]/.test(bps.toUpperCase());
}
