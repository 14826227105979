/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5InputSequenceFragment on j5InputSequence {
    id
    isStock
    sequence {
      id
      name
      size
      description
      circular
    }
  }
`;
