/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { initialize } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StepForm from "../../../../src-shared/StepForm";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import ConfigurationStep from "./ConfigurationStep";
import SelectPartStep from "./SelectPartStep";
import {
  InventoryParameters,
  PartitionParameters,
  ValidationAlgorithms
} from "./ValidationAlgorithms";

const initialParameters = {
  validationAlgorithms: Object.values(ValidationAlgorithms),
  ...PartitionParameters.default,
  ...InventoryParameters.default,
  usingInventoryParts: false,
  designNameTemplate: "{{targetName}} - partitioned in {{totalParts}}",
  partNameTemplate: "{{designName}} - part {{incrementingNumber}}",
  isSingleDesignFlag: false
};

const SuccessPageInnerContent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>
        The partition tool has been submitted. Head to{" "}
        <Link to={modelNameToLink("microserviceTask")}>Tasks</Link> to view the
        status.
        <br />
      </h4>
      <h5>After successful completion a design will be generated.</h5>
    </div>
  );
};

const DesignFromPartitionTool = props => {
  const { toolIntegrationProps, toolSchema, isToolIntegrated } = props;

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async values => {
    setSubmitting(true);

    const {
      selectedParts,
      selectedSequences,
      validationAlgorithms,
      partitionSizeMin,
      partitionSizeMax,
      assemblyStrategy,
      overlapSizeMin,
      overlapSizeOptimal,
      overlapSizeMax,
      overlapGcContentMin,
      overlapGcContentOptimal,
      overlapGcContentMax,
      min_match_length,
      usingInventoryParts,
      designNameTemplate,
      partNameTemplate,
      isSingleDesignFlag,
      selectedRestrictionEnzymes
    } = values;

    let urlParams = "?useInventoryParts=";

    const dataParameters = {
      ...PartitionParameters.inputsToApi({
        partitionSizeMin,
        partitionSizeMax,
        isSingleDesign: isSingleDesignFlag,
        assemblyStrategy,
        overlapSizeMin,
        overlapSizeOptimal,
        overlapSizeMax,
        overlapGcContentMin,
        overlapGcContentOptimal,
        overlapGcContentMax,
        selectedRestrictionEnzymes,
        overlap_validator_config: {
          methods: validationAlgorithms.map(alg => {
            return { name: alg.name, config: alg.inputsToApi() };
          })
        }
      })
    };

    dataParameters[
      "inventory_partitions_config"
    ] = InventoryParameters.inputsToApi({
      min_match_length,
      terminate_on_full_sequence_inventory_match: false
    });

    urlParams += usingInventoryParts ? "true" : "false";

    const data = {
      partIds: selectedParts ? selectedParts.map(el => el.id) : undefined,
      sequenceIds: selectedSequences
        ? selectedSequences.map(el => el.id)
        : undefined,
      parameters: dataParameters,
      designNameTemplate,
      partNameTemplate
    };

    const results = await window.cliApi({
      url: `/partition-tool${urlParams}`,
      method: "POST",
      moduleName: "design",
      data
    });
    setSubmitting(false);
    return results;
  };

  const steps = [
    {
      title: "Select DNA",
      Component: SelectPartStep,
      withCustomFooter: true,
      props: { submitting }
    },
    {
      title: "Configuration",
      Component: ConfigurationStep,
      withCustomFooter: true,
      props: { submitting }
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      successPageInnerContent={SuccessPageInnerContent}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      afterReset={() => {
        props.reinitialize(toolSchema.code, { ...initialParameters });
      }}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={{ ...initialParameters }}
    />
  );
};

export default compose(
  connect(null, {
    reinitialize: initialize
  })
)(DesignFromPartitionTool);
