/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState, useContext, useMemo } from "react";
import { remove } from "lodash";
import { Classes, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import determineBlackOrWhiteTextColor from "../utils/determineBlackOrWhiteTextColor";
import appGlobals from "../appGlobals";
import { UNASSIGNED_PROJECT_ID } from "../../../tg-iso-shared/src/utils/getActiveProjectId";
import {
  projectStorageKey,
  lastSelectedProjectKey,
  getUserProjects
} from "../utils/projectUtils";
import { TgSelect } from "@teselagen/ui";
import useDisabledProjects from "../utils/useDisabledProjects";
import CurrentUserContext from "../context/CurrentUserContext";
import "./style.css";

const getProjectOptions = (currentUser, asId, withActiveProject) => {
  const activeProjectId = sessionStorage.getItem(projectStorageKey);
  let projects = getUserProjects(currentUser).map(project => {
    let label = project.name;

    if (withActiveProject && project.id === activeProjectId) {
      label = (
        <span>
          <Icon icon="dot" /> {label}
        </span>
      );
    }

    return {
      label,
      value: asId ? project.id : project
    };
  });

  if (activeProjectId) {
    const activeItem = remove(projects, projectOrProjectId => {
      return asId
        ? projectOrProjectId.value === activeProjectId
        : projectOrProjectId.value.id === activeProjectId;
    });
    projects = [...activeItem, ...projects];
  }

  return projects;
};

const ActiveProjectSelectField = ({ label = "Project" }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const options = getProjectOptions(currentUser);
  const [activeProjectId, setActiveProjectId] = useState();

  const activeProject = useMemo(() => {
    const activeProjectIdToUse = activeProjectId && activeProjectId.toString();
    return options.find(op => op.value.id === activeProjectIdToUse);
  }, [activeProjectId, options]);

  /**
   * This can be called from other places in the app (dialogs on submit)
   * @param {*} activeProjectId
   */
  const globalSetActiveProjectId = activeProjectId => {
    localStorage.setItem(lastSelectedProjectKey, activeProjectId);
    sessionStorage.setItem(projectStorageKey, activeProjectId);
    setActiveProjectId(activeProjectId);
  };

  useEffect(() => {
    let newActiveProjectId = activeProjectId;
    if (!newActiveProjectId || !activeProject) {
      if (!newActiveProjectId) {
        if (localStorage.getItem(lastSelectedProjectKey)) {
          newActiveProjectId = localStorage.getItem(lastSelectedProjectKey);
        }
        if (!options.find(op => op.value.id === newActiveProjectId))
          newActiveProjectId = UNASSIGNED_PROJECT_ID;
      }
      appGlobals.setActiveProjectId = globalSetActiveProjectId;
      sessionStorage.setItem(projectStorageKey, newActiveProjectId);
      setActiveProjectId(newActiveProjectId);
    }
  }, [activeProject, activeProjectId, options]);

  const onChange = newActiveProjectId => {
    if (activeProjectId === newActiveProjectId) return;
    globalSetActiveProjectId(newActiveProjectId);
    // do a full page refresh to stop weirdness from having mismatched projects.
    // The sessionStorage project will be automatically set to this selected project when the app is reloaded
    if (appGlobals.activeRecordId) {
      let newUrl = "/";
      if (window.frontEndConfig.tgModuleName) {
        newUrl = `/${window.frontEndConfig.tgModuleName}/client`;
      }
      // if there is an active record open then go to the home page so it does not auto switch lab on reload
      window.location.href = newUrl;
    } else {
      window.location.reload();
    }
  };

  const color =
    activeProject?.value?.color || activeProject?.value?.project?.color;
  if (!options.length) return null;

  return (
    <div
      data-cy="active-project-select-field"
      style={{
        marginLeft: 20,
        borderRadius: 5,
        flexShrink: 1,
        maxWidth: "18em",
        // width: "18em", //tnr: I don't think we should have a fixed width here -- better to let it expand and shrink as needed ?
        border: "2px solid white"
      }}
      className={classNames(
        "tg-active-project-select",
        "tg-no-form-group-margin tg-flex align-center",
        Classes.DARK
      )}
    >
      <span
        style={{
          paddingRight: 8,
          paddingLeft: 8
        }}
      >
        {label}
      </span>
      <div
        style={{
          borderLeft: "2px solid white",
          background: color,
          borderRadius: "0 3px 3px 0",
          overflow: "hidden"
        }}
      >
        <TgSelect
          options={options.map(p => ({
            label: p.label,
            value: p.value.id
          }))}
          disallowClear
          onChange={val => {
            const projectId = val && val.value;
            if (projectId) {
              onChange(projectId);
            } else {
              onChange("");
            }
          }}
          value={activeProjectId}
          tagInputProps={{
            placeholder: null,
            tagProps: {
              onRemove: null,
              style: {
                color: color ? determineBlackOrWhiteTextColor(color) : "white"
              }
            }
          }}
        />
      </div>
    </div>
  );
};

function ConditionalComponent({ label }) {
  const [projectsDisabled] = useDisabledProjects();
  if (projectsDisabled) return null;
  return <ActiveProjectSelectField label={label} />;
}

export default ConditionalComponent;
