/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import joinUrl from "url-join";
import { get } from "lodash";
import { customAlphabet } from "nanoid";
import appGlobals from "../appGlobals";
import getActiveLabId from "../../../tg-iso-shared/src/utils/getActiveLabId";
import { lastSelectedLabKey } from "./labUtils";

export function fullUrl(url, clientBasePath) {
  const basePathToUse = clientBasePath || window.frontEndConfig.clientBasePath;
  if (basePathToUse) {
    const newWinUrl = joinUrl(window.location.origin, basePathToUse, url);
    return newWinUrl;
  } else {
    return window.location.origin + url;
  }
}

export function getHelpArticleLink(article) {
  return `/${window.frontEndConfig.tgModuleName}/html_files/articles/${article}.html`;
}
export function openInNewTab(url, clientBasePath) {
  const activeLabId = getActiveLabId();
  if (activeLabId && localStorage.getItem(lastSelectedLabKey) !== activeLabId) {
    // will make new tab's lab match current tabs
    localStorage.setItem(lastSelectedLabKey, activeLabId);
  }
  const toRet = window.open(fullUrl(url, clientBasePath), "_blank");
  toRet?.focus();
  return toRet;
}

export const includesMultiple = (array, items, { caseInsensitive } = {}) => {
  const arrayToSearch = caseInsensitive
    ? array.map(s => s.toLowerCase())
    : array;
  return items.every(item => arrayToSearch.includes(item));
};

export function isAdmin() {
  const isAdmin = get(appGlobals.currentUser, "userRoles", []).some(
    ({ appRoleCode }) => appRoleCode === "ADMIN"
  );
  return isAdmin;
}

export function isLabAdmin() {
  const activeLabId = getActiveLabId();
  return (
    activeLabId &&
    activeLabId !== null &&
    (
      get(appGlobals.currentUser, "labRoles", []).find(
        lr => lr.labId === activeLabId
      ) || {}
    ).roleCode === "ADMIN"
  );
}

export function isLabMember() {
  const activeLabId = getActiveLabId();
  const userLabRole =
    get(appGlobals.currentUser, "labRoles", []).find(
      lr => lr.labId === activeLabId
    ) || {};
  return (
    activeLabId &&
    activeLabId !== null &&
    ["ADMIN", "MEMBER"].includes(userLabRole.roleCode)
  );
}

export const popoverOverflowModifiers = {
  preventOverflow: { enabled: false },
  hide: {
    enabled: false
  },
  flip: {
    boundariesElement: "viewport"
  }
};

export const anOrA = word => {
  return /^[aeiou]/i.test(word) ? "an" : "a";
};

/**
 * Use this to sort strings in ascending order.
 *
 * @param {String} a
 * @param {String} b
 */
export function strcmp(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}

export const isMac = navigator.userAgent.includes("Mac OS X");

export const disabledFeaturesConfig =
  (window.frontEndConfig && window.frontEndConfig.disabledFeaturesConfig) || {};

if (disabledFeaturesConfig.equipment) {
  disabledFeaturesConfig.placementStrategy = true;
}

// disable design templates by default for now. they are not in a great working state.
if (disabledFeaturesConfig.designTemplates !== false) {
  disabledFeaturesConfig.designTemplates = true;
}

// Unless explicitly enabled, disable ELN
if (disabledFeaturesConfig.eln !== false) {
  disabledFeaturesConfig.eln = true;
}

// Enable ELN for Cypress tests
if (window.Cypress) {
  disabledFeaturesConfig.eln = false;
}

const customNanoId = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
export const getUniqueFragmentKey = () => customNanoId();

export function inSSO() {
  return !get(window, "frontEndConfig.localLogin");
}
