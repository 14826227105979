/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import getActiveLabId, {
  COMMON_LAB_ID
} from "../../../tg-iso-shared/src/utils/getActiveLabId";
import dataTableSelectFilterMenu from "../dataTableSelectFilterMenu";
import appGlobals from "../appGlobals";
import { getUserLabs } from "./labUtils";

export const labGroupColumn = {
  path: "lab.name",
  type: "string",
  displayName: "Lab Group",
  render: v => v || "Common",
  filterIsActive: currentParams => currentParams.labFilter,
  FilterMenu: dataTableSelectFilterMenu({
    getOptions: () => {
      const allLabs = getUserLabs(appGlobals.currentUser);
      const activeLabId = getActiveLabId();
      const labOptions = allLabs
        .filter(l => l.id === COMMON_LAB_ID || l.id === activeLabId)
        .map(l => ({
          value: l.id,
          label: l.name
        }));
      return labOptions;
    },
    paramKey: "labFilter",
    label: "Lab"
  })
};
