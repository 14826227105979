/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const authTokenName = "authToken-teselagen";

export const setAuthToken = token => {
  localStorage.setItem(authTokenName, token);
  window.dispatchEvent(new Event("authToken"));
  return token;
};

export const getAuthToken = () => {
  const token = localStorage.getItem(authTokenName);
  return token;
};

export const clearLocalStorageItems = () => {
  localStorage.removeItem("authenticated");
  localStorage.removeItem("azureAuthenticated");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("samlAuthenticated");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("ldapAuthenticated");
  localStorage.removeItem("authToken");
  localStorage.removeItem("authToken-teselagen");
  localStorage.removeItem("auth-error");
  localStorage.removeItem("darkMode");
  localStorage.removeItem("lastSelectedLabId");
  localStorage.removeItem("activeLabId");
  localStorage.removeItem("lastSelectedProjectId");
  localStorage.removeItem("activeProjectId");
  localStorage.removeItem("networkRequestTracker");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("username");
  localStorage.removeItem("userId");
};
