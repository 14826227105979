/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import path from "path";
import dirname from "es-dirname";

const __dirname = dirname();

// These shouldnt be needed by the server.
// But the initAppDatabase logic is embeded in the server files.
const dbConfigs = {
  datamodelCSVPath: "../datamodel-csv-export-lims",
  resolversPath: "../graphql-lims/resolvers",
  schemaPath: "../graphql-lims/schema",
  mockResolversPath: "../graphql-lims/mock-resolvers",
  dataLibPath: "../data-lib-lims",
  extendTableMap: "./extendTableMapLims",
  gqlSchemaPath: "../schema-lims.json"
};

let exampleDesignTasks = ",xlsx-to-csv,callback";

if (
  !process.env.FE_SHOW_EXAMPLE_MICROSERVICE_TASK &&
  !process.env.CI_DOCKER_COMPOSE
) {
  exampleDesignTasks = "";
} else {
  console.info(
    `[MS-EX] Adding example microservice tasks to design: ${exampleDesignTasks}`
  );
}

const products = {
  design: {
    //INIT CUSTOM ENDPOINT PATH
    initCustomEndPointsPath: "./initCustomEndpoints-design",
    initApiPath: "./init/initApi-design",
    exampleFilesFolder: "design",
    //CLIENT PATH
    clientPath: "../../../client/",
    clientBasePath: "/design/client",
    serverBasePath: "/design",
    devPort: 4000,
    frontEndConfigPath: path.resolve(__dirname, "frontEndConfig-design.json"),
    enabledTaskServicesForQueueManager:
      "j5,crickit-manager,standard-alignment,next-gen-alignment,partition-tool,blast" +
      exampleDesignTasks,
    ...dbConfigs
  },
  build: {
    //INIT CUSTOM ENDPOINT PATH
    initCustomEndPointsPath: "./initCustomEndpoints-lims",
    exampleFilesFolder: "build",
    //CLIENT PATH
    clientPath: "../../../client/",
    clientBasePath: "/build/client",
    serverBasePath: "/build",
    devPort: 4001,
    frontEndConfigPath: path.resolve(__dirname, "frontEndConfig-lims.json"),
    enabledTaskServicesForQueueManager:
      "vendor-scoring-and-pricing,vendor-vector,standard-alignment,next-gen-alignment,ds-tools,ds-tools-gpu,homology-path",
    ...dbConfigs
  },
  test: {
    //INIT CUSTOM ENDPOINT PATH
    initCustomEndPointsPath: "./initCustomEndpoints-lims",
    //CLIENT PATH
    clientPath: "../../../client/",
    clientBasePath: "/build/client",
    serverBasePath: "/build",
    devPort: 4002,
    frontEndConfigPath: path.resolve(__dirname, "frontEndConfig-test.json"),
    // gqlSchemaPath: "../schema-lims.json",
    enabledTaskServicesForQueueManager: "",
    ...dbConfigs
  },
  evolve: {
    //INIT CUSTOM ENDPOINT PATH
    initCustomEndPointsPath: "./initCustomEndpoints-lims",
    //CLIENT PATH
    clientPath: "../../../client/",
    clientBasePath: "/evolve/client",
    serverBasePath: "/evolve",
    devPort: 4003,
    frontEndConfigPath: path.resolve(__dirname, "frontEndConfig-evolve.json"),
    // gqlSchemaPath: "../schema-lims.json",
    enabledTaskServicesForQueueManager: "",
    ...dbConfigs
  }
};

export { products };
