/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Colors, Menu, MenuItem } from "@blueprintjs/core";
import { camelCase, noop, identity, get } from "lodash";
import { compose, withProps } from "recompose";
import { Link } from "react-router-dom";
import {
  CollapsibleCard,
  InfoHelper,
  DataTable,
  withTableParams,
  DropdownButton,
  MenuItemWithTooltip
} from "@teselagen/ui";
import withQueryDynamic from "../withQueryDynamic";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { isBuild, isDesign } from "../../../tg-iso-shared/src/utils/isModule";
import { showDialog } from "../GlobalDialog";
import CreateSequenceOrderDialog from "./CreateSequenceOrderDialog";
import { safeQuery } from "../apolloMethods";
import { stringify } from "qs";
import { isFunction } from "lodash";
import {
  combineGqlFragments,
  pathToNestedString
} from "../../../tg-iso-shared/utils/gqlUtils";
import { externalReferenceKeys } from "../../../tg-iso-shared/constants";
import { getExternalReferenceColumnsWithRender } from "../../../tg-iso-shared/utils/getExternalReferenceColumnsWithRender";
import withLibraryExtendedPropertyColumns from "../enhancers/withLibraryExtendedPropertyColumns";
import { libraryExtendedStringValues } from "../libraryEnhancer";
import { disabledFeaturesConfig } from "../utils/generalUtils";

const collapsedByDefaultTables = {
  "Input Parts": true,
  "Input Sequences": true
};

function useIsOpen() {
  const [isOpen, setOpen] = useState(false);
  const hasOpened = useRef(false);
  const toggle = () => {
    hasOpened.current = true;
    setOpen(!isOpen);
  };
  return {
    isOpen,
    hasOpened: hasOpened.current,
    toggle
  };
}

const tableRowHeight = 24;

const generateFragmentForPath = remainingPath => {
  if (!remainingPath) return "";
  const [first, ...rest] = remainingPath.split(".");
  return `${first} { id ${generateFragmentForPath(rest.join("."))} }`;
};

function J5TableCard(props) {
  const {
    title,
    formName,
    helperMessage,
    tableProps,
    schema,
    onDoubleClick,
    cellRenderer,
    openTitleElements,
    children,
    tableParams = {},
    createSchema = noop,
    processData = identity,
    fragment,
    SubComponent,
    model,
    createOrderModels = [],
    pathToJ5Sequence,
    j5ReportId,
    history
  } = props;
  let openTitleElementsToUse = openTitleElements;
  if (isFunction(openTitleElements)) {
    openTitleElementsToUse = openTitleElements(props);
  }

  const [entities, entityCount, filteredEntities] = useMemo(() => {
    let tmpEntities;
    let tmpEntityCount;
    // using remote paging
    if (fragment) {
      tmpEntities = tableParams.entities;
      tmpEntityCount = tableParams.entityCount;
    }
    tmpEntities = tmpEntities || [];
    const tmpFilteredEntities = processData(tmpEntities);
    if (
      tmpFilteredEntities.length !== tmpEntities.length &&
      tmpFilteredEntities.length !== tmpEntityCount
    ) {
      tmpEntityCount = tmpFilteredEntities.length;
    }
    return [tmpEntities, tmpEntityCount, tmpFilteredEntities];
  }, [tableParams, fragment, processData]);

  const { isOpen, toggle, hasOpened } = useIsOpen();

  useEffect(() => {
    // this will handle opening cards once data comes in (if there is data).
    // if the user has already open and closed the table (before finishing load) we don't want to reopen.
    // that is what the hasOpened variable is tracking.
    if (
      filteredEntities.length > 0 &&
      !collapsedByDefaultTables[title] &&
      !hasOpened
    ) {
      toggle();
    }
  }, [filteredEntities, toggle, hasOpened, title]);

  const currentNumOfEntities = entities.length;
  const totalHeightNeededToShowAll = tableRowHeight * currentNumOfEntities + 28; // height of header
  let maxHeightOfTable =
    totalHeightNeededToShowAll < 400 ? 400 : totalHeightNeededToShowAll;
  if (maxHeightOfTable > 400) {
    // only show 20% so the table doesn't get too tall
    maxHeightOfTable = maxHeightOfTable * 0.2;
    if (maxHeightOfTable < 400) {
      maxHeightOfTable = 400;
    }
  }

  let createOrderButton;

  function getSequenceFragmentAndHelper() {
    const seqFrag = generateFragmentForPath(pathToJ5Sequence);
    const lastItemInPath = pathToJ5Sequence.split(".").pop();
    // add name to sequence
    const fragmentWithSequenceName = `id ${seqFrag.replace(
      lastItemInPath + " { id",
      lastItemInPath + " { id name"
    )}`;

    const fragment = [model, fragmentWithSequenceName];
    const getSequenceForRecord = r => {
      if (pathToJ5Sequence && pathToJ5Sequence !== "sequence") {
        return get(r, pathToJ5Sequence);
      } else {
        return r.sequence;
      }
    };

    return {
      pathToSeqFragment: fragment,
      getSequenceForRecord
    };
  }
  if (
    !disabledFeaturesConfig.bioshop &&
    createOrderModels.includes(model) &&
    pathToJ5Sequence
  ) {
    createOrderButton = (
      <DropdownButton
        text="Order Sequences"
        menu={
          <Menu>
            <MenuItemWithTooltip
              tooltip="Creates a table of sequences for ordering"
              text="Create Order Table"
              onClick={() => {
                const {
                  pathToSeqFragment,
                  getSequenceForRecord
                } = getSequenceFragmentAndHelper();
                showDialog({
                  ModalComponent: CreateSequenceOrderDialog,
                  modalProps: {
                    fragment: pathToSeqFragment,
                    getSequenceForRecord,
                    j5ReportId
                  }
                });
              }}
            />
            {["Twist", "IDT"].map(vendor => {
              return (
                <MenuItem
                  key={vendor}
                  text={`Order from ${vendor}`}
                  onClick={async () => {
                    try {
                      const {
                        pathToSeqFragment,
                        getSequenceForRecord
                      } = getSequenceFragmentAndHelper();
                      const itemsWithSeqs = await safeQuery(pathToSeqFragment, {
                        variables: {
                          filter: {
                            j5ReportId
                          }
                        }
                      });
                      const seqIds = itemsWithSeqs
                        .map(getSequenceForRecord)
                        .filter(identity)
                        .map(s => s.id);
                      history.push({
                        pathname: `/tools/${vendor.toLowerCase()}-ordering`,
                        hash: stringify({
                          sequenceIds: seqIds
                        })
                      });
                    } catch (error) {
                      console.error(`error:`, error);
                      window.toastr.error("Error loading sequences");
                    }
                  }}
                />
              );
            })}
          </Menu>
        }
        onClick={() => {}}
      />
    );
  }

  return (
    <CollapsibleCard
      isOpen={isOpen}
      toggle={toggle}
      icon={
        helperMessage && (
          <InfoHelper icon="help" color={Colors.BLUE3}>
            {helperMessage}
          </InfoHelper>
        )
      }
      title={title}
      initialClosed
      openTitleElements={
        <div>
          {createOrderButton}
          {openTitleElementsToUse}
        </div>
      }
    >
      <DataTable
        {...tableProps}
        SubComponent={SubComponent || null}
        onDoubleClick={onDoubleClick}
        isOpenable={onDoubleClick}
        formName={formName} //because these tables are currently not connected to table params, we need to manually pass a formName here
        cellRenderer={cellRenderer}
        noHeader
        {...tableParams}
        maxHeight={maxHeightOfTable}
        entityCount={entityCount}
        entities={filteredEntities}
        // schema is weird because we are sometimes generating schema off of the entities
        schema={
          createSchema(entities, tableParams.schema) ||
          tableParams.schema ||
          schema
        }
      />
      {children}
    </CollapsibleCard>
  );
}

export default compose(
  WithExtPropsHoc,
  withProps(props => {
    const {
      fragment: _fragment,
      title,
      schema: maybeSchema,
      columnToSortBy,
      pathToJ5Sequence,
      withExtendedProperites
    } = props;

    const formName =
      (window.frontEndConfig.tgModuleName || "") + "-" + camelCase(title);

    let fragment = _fragment;
    if (!fragment) {
      return {
        formName
      };
    }
    const modelName = getModelNameFromFragment(fragment);

    let schema = maybeSchema;
    if (schema && !schema.model) {
      if (Array.isArray(schema))
        schema = {
          fields: schema
        };
      schema.model = modelName;
    }
    // this happens when we are creating the schema based on the entities
    if (!schema) {
      schema = {
        model: modelName,
        fields: []
      };
    }
    schema = {
      ...schema
    };
    if (pathToJ5Sequence && isBuild()) {
      schema.fields = [
        ...schema.fields,
        {
          displayName: "Aliquots",
          path:
            pathToJ5Sequence + ".sequenceAvailableInventoryView.containerCount",
          isHidden: true,
          filterDisabled: true,
          sortDisabled: true,
          render: (v, r) => {
            const sequence = get(r, pathToJ5Sequence);
            if (!sequence || Number(v) === 0) {
              return "No Aliquots";
            }
            return (
              <Link to={`/aliquots?j5SequenceIdFilter=${sequence.id}`}>
                {v}
              </Link>
            );
          }
        },
        {
          displayName: "Samples",
          path:
            pathToJ5Sequence + ".sequenceAvailableInventoryView.sampleCount",
          isHidden: true,
          filterDisabled: true,
          sortDisabled: true,
          render: (v, r) => {
            const sequence = get(r, pathToJ5Sequence);
            if (!sequence || Number(v) === 0) {
              return "No Samples";
            }
            return (
              <Link to={`/samples?j5SequenceIdFilter=${sequence.id}`}>{v}</Link>
            );
          }
        }
      ];
    }

    if (pathToJ5Sequence) {
      let pathToSeqWithExtInfo = pathToJ5Sequence;
      if (modelName === "j5InputSequence" && isDesign()) {
        pathToSeqWithExtInfo = "sourceSequence";
      }
      const frag = pathToNestedString(
        pathToSeqWithExtInfo,
        externalReferenceKeys.join(" ")
      );

      fragment = combineGqlFragments([fragment, [modelName, `{ ${frag} }`]]);
      schema.fields = [
        ...schema.fields,
        ...getExternalReferenceColumnsWithRender({
          prefix: pathToSeqWithExtInfo
        }).map(f => ({
          ...f,
          isHidden: true
        }))
      ];
    }

    if (withExtendedProperites) {
      const { path } = withExtendedProperites;
      const frag = pathToNestedString(path, libraryExtendedStringValues);
      fragment = combineGqlFragments([fragment, [modelName, `{ ${frag} }`]]);
    }

    return {
      model: modelName,
      schema,
      formName,
      defaults: columnToSortBy
        ? {
            order: [columnToSortBy]
          }
        : {},
      runTimeQueryOptions: {
        fragment
      }
    };
  }),
  withTableParams({
    urlConnected: false,
    withDisplayOptions: true,
    additionalFilter: (props, qb, ...rest) => {
      if (!props.noJ5ReportId) {
        qb.whereAll({
          j5ReportId: props.j5ReportId
        });
      }
      if (props.additionalTableFilter) {
        props.additionalTableFilter(props, qb, ...rest);
      }
    }
  }),
  withQueryDynamic({
    isPlural: true
  })
)(J5TableCard);

function WithExtPropsHoc(Component) {
  return props => {
    let Wrapped = Component;
    if (props.withExtendedProperites) {
      const { path, model } = props.withExtendedProperites;
      Wrapped = compose(
        withLibraryExtendedPropertyColumns({
          schema: props.schema,
          recordPath: path,
          model: model
        }),
        withProps(({ schema }) => {
          return {
            ...props,
            schema
          };
        })
      )(Component);
    }
    return <Wrapped {...props} />;
  };
}
