/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const COMMON_LAB_ID = "COMMON_LAB_ID";
export const lastSelectedLabKey = "lastSelectedLabId";
export const labStorageKey = "activeLabId";

/**
 * First, it looks for an active lab id in the browser's sessionStorage
 * which is preserved for tab duplication and tab reloading but not for new tabs.
 *
 * Then it looks for the lastSelectedLabId but now in the local storage, which
 * is preserved for the whole browser window.
 *
 */
export default () => {
  const activeLabId =
    sessionStorage.getItem(labStorageKey) ||
    localStorage.getItem(lastSelectedLabKey);
  if (activeLabId && activeLabId !== COMMON_LAB_ID) {
    return activeLabId;
  } else {
    // tgreen: this needs to be null because it will be applied to filters in graphql
    // if it is an empty string "" it will break the query
    return null;
  }
};
