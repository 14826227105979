/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import QueryBuilder from "tg-client-query-builder";
import makeMaterialSearchObject from "./makeMaterialSearchObject";

export type UniversalSearchModel = {
  model: string;
  displayName: string;
  additionalFilter: (qb: QueryBuilder) => QueryBuilder;
  route: string;
};

const universalSearchSettings: UniversalSearchModel[] = [
  {
    model: "sequence",
    displayName: "Sequence",
    additionalFilter: (qb: QueryBuilder) => {
      return qb.whereAll({
        sequenceTypeCode: qb.notEquals("OLIGO")
      });
    },
    route: `/sequences`
  },
  {
    model: "sequence",
    displayName: "Oligo",
    additionalFilter: (qb: QueryBuilder) => {
      return qb.whereAll({
        sequenceTypeCode: "OLIGO"
      });
    },
    route: `/oligos`
  }
];

export const designSearchSettings: {
  models: (string | UniversalSearchModel)[];
} = {
  models: [
    "design",
    "schematic",
    ...universalSearchSettings,
    "part",
    "partset",
    "ruleSet",
    "j5Report",
    "alignment",
    "aminoAcidSequence",
    "aminoAcidPart",
    "codonMap"
  ]
};

export const buildSearchSettings: {
  models: (string | UniversalSearchModel)[];
  noNameModels: string[];
} = {
  models: [
    "additiveMaterial",
    "aliquot",
    "aliquotContainer",
    "aminoAcidSequence",
    "container",
    "containerArray",
    "customerRequest",
    "dataSet",
    "dataTable",
    "equipmentItem",
    "functionalProteinUnit",
    "j5Report",
    "job",
    "location",
    "lot",
    "plateMapGroup",
    "labProtocol",
    "reactionMap",
    "reactionDesign",
    "sample",
    "strain",
    "workflowRun",
    "worklist",
    ...universalSearchSettings,
    makeMaterialSearchObject("DNA"),
    makeMaterialSearchObject("PROTEIN"),
    makeMaterialSearchObject("MICROBIAL")
  ],
  noNameModels: ["aliquot"]
};
