/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import gql from "graphql-tag";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import LabelWithTooltip from "../../../../src-shared/LabelWithTooltip";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { CheckboxField, InputField } from "@teselagen/ui";
import partLibraryFilter from "../../../utils/partLibraryFilter";
import { Button, Intent } from "@blueprintjs/core";
import { size } from "lodash";

export const MINIMUM_TARGET_PART_SIZE = 100;

const DNA_PART_URL_PREFIX = "/design/client/parts";
const DNA_SEQUENCE_URL_PREFIX = "/design/client/sequences";

const partSchema = [
  { path: "name", type: "string", displayName: "Name" },
  { path: "size", type: "number", displayName: "Size" },
  { path: "circular", type: "boolean", displayName: "Circular Part" },
  { path: "sequence.name", type: "string", displayName: "Sequence" },
  { path: "sequence.size", type: "number", displayName: "Sequence Size" },
  { path: "strand", type: "number", displayName: "Strand" },
  { path: "start", type: "number", displayName: "Start" },
  { path: "end", type: "number", displayName: "End" }
];

const partsFragment = gql`
  fragment partsFragment on partitionPartView {
    id
    name
    size
    circular
    sequence {
      id
      name
      circular
      size
      designMaterialAvailabilityView {
        id
        isAvailable
      }
    }
    strand
    start
    end
  }
`;

const sequenceSchema = [
  { path: "name", type: "string", displayName: "Name" },
  { path: "size", type: "number", displayName: "Size" },
  {
    path: "designMaterialAvailabilityView.isAvailable",
    type: "boolean",
    displayName: "Available"
  },
  { path: "circular", type: "boolean", displayName: "Circular" }
];

const sequenceFragment = gql`
  fragment sequenceFragment on sequence {
    id
    name
    circular
    size
    designMaterialAvailabilityView {
      id
      isAvailable
    }
  }
`;

const partsFilter = (props, qb) => {
  partLibraryFilter(props, qb);
  qb.whereAll({
    size: qb.greaterThan(MINIMUM_TARGET_PART_SIZE),
    "sequence.size": qb.notNull()
  });
};

const sequenceFilter = (props, qb) => {
  qb.whereAny({
    isInLibrary: true
  });
  qb.whereAll({
    size: qb.greaterThan(MINIMUM_TARGET_PART_SIZE)
  });
};

/**
 * Checks if any target is at inventory and shows an alert if positive
 *
 * @function checkAvailability
 * @param {IPart[] || ISequence} values - Selected parts or sequences as targets
 */
const checkAvailability = values => {
  let elementIsAvailable = false;
  let valueType = "part";
  let i = 0;
  for (i = 0; i < values.length; i++) {
    let isAvailable = false;

    if (values[i].sequence) {
      // Case element is a part
      if (values[i].sequence.designMaterialAvailabilityView) {
        isAvailable =
          values[i].sequence.designMaterialAvailabilityView.isAvailable &&
          values[i].start === 0 &&
          values[i].end === values[i].size;
      }
    } else {
      // Case element is a sequence
      valueType = "sequence";
      if (values[i].designMaterialAvailabilityView) {
        isAvailable = values[i].designMaterialAvailabilityView.isAvailable;
      }
    }

    if (isAvailable) {
      elementIsAvailable = true;
      break;
    }
  }

  if (elementIsAvailable) {
    alert(
      `The ${valueType} "${values[i].name}" has a full match available at inventory. The Partitioner Tool will ignore this match (and other full-sized matches) if "Use Inventory Parts" option is enabled.`
    );
  }
};

const SelectPartStep = props => {
  const {
    selectedParts,
    selectedSequences,
    designNameTemplate,
    partNameTemplate,
    stepFormProps: { change },
    Footer,
    footerProps,
    nextStep,
    isSingleDesignFlag
  } = props;

  // let selectedObject;
  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select DNA to be partitioned (Target)"
          helper={`Note that parts or sequences under ${MINIMUM_TARGET_PART_SIZE} base pairs long will be ignored.`}
        />

        <div
          className="tg-flex"
          style={{
            display: "inline-flex",
            justifyContent: "left",
            flexWrap: "wrap"
          }}
        >
          {!selectedParts && (
            <div style={{ flex: 1, maxWidth: 170 }}>
              <GenericSelect
                {...{
                  name: "chosenTargetSequence",
                  onSelect: async values => {
                    change("selectedSequences", values);
                    checkAvailability(values);
                  },
                  onClear: () => change("selectedSequences", undefined),
                  isMultiSelect: true,
                  asReactSelect: false,
                  schema: sequenceSchema,
                  fragment: sequenceFragment,
                  tableParamOptions: {
                    additionalFilter: sequenceFilter,
                    withCheckboxes: true
                  }
                }}
              />
            </div>
          )}

          {!selectedSequences && !selectedParts && (
            <div style={{ flex: 1, maxWidth: 50, marginTop: 20 }}>
              <LabelWithTooltip label="or" />
            </div>
          )}

          {!selectedSequences && (
            <div style={{ flex: 1, maxWidth: 170 }}>
              <GenericSelect
                {...{
                  getButtonText: () => "Select DNA Part",
                  name: "chosenTargetPart",
                  onSelect: async values => {
                    change("selectedParts", values);
                  },
                  onFieldSubmit: async values => {
                    checkAvailability(values);
                  },
                  onClear: () => change("selectedParts", undefined),
                  isMultiSelect: true,
                  asReactSelect: false,
                  schema: partSchema,
                  fragment: partsFragment,
                  tableParamOptions: {
                    additionalFilter: partsFilter,
                    withCheckboxes: true
                  }
                }}
              />
            </div>
          )}
        </div>

        {/* Shows the name of the selected Part or Sequence with a link*/}
        {selectedParts || selectedSequences ? (
          <span>
            Selected {selectedParts ? `Parts` : ""}
            {selectedSequences ? `Sequences` : ""}:{" "}
            {(selectedParts || [])
              .concat(selectedSequences)
              .filter(elem => !!elem)
              .map((elem, elemIndex, all) => (
                <span key={elem.id}>
                  <a
                    className="paragraphHyperReference"
                    href={
                      selectedParts
                        ? `${DNA_PART_URL_PREFIX}/${elem.id}`
                        : `${DNA_SEQUENCE_URL_PREFIX}/${elem.id}`
                    }
                    rel="noreferrer noopener"
                  >
                    <b>{elem.name}</b>
                  </a>
                  {elemIndex < all.length - 1 ? ", " : ""}
                </span>
              ))}
            {/* Shows a checkbox that allows the user to edit the value of isSingleDesign flag*/}
            {selectedParts?.length > 1 || selectedSequences?.length > 1 ? (
              <div
                style={{
                  marginTop: 20
                }}
              >
                <HeaderWithHelper
                  header="Single Design Flag"
                  helper="Enable this flag to output only one design combining multiple selected targets"
                />
                <CheckboxField
                  name="isSingleDesignFlag"
                  label="Single Design"
                  defaultValue={isSingleDesignFlag}
                  tooltipInfo="If checked, the output will be a single design. If unchecked, the output will be a design for each selected part or sequence"
                />
              </div>
            ) : null}
          </span>
        ) : null}
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Choose names for designs and parts"
          helper="You can use templates for naming designs and parts generated by this tool"
        />
        <InputField
          name="designNameTemplate"
          label="Design Name"
          isRequired
          defaultValue={designNameTemplate}
          tooltipInfo="Use a plain name or you can add template variables by adding {{VARIABLE_NAME}}. Available variables are: targetName, targetId, totalParts, assemblyStrategy, enzymeName, incrementingNumber, targetSequenceId, targetPartId"
        />
        <InputField
          name="partNameTemplate"
          label="Part Name"
          isRequired
          defaultValue={partNameTemplate}
          tooltipInfo="Use a plain name or you can add template variables by adding {{VARIABLE_NAME}}. Available variables are: designName, incrementingNumber, start, end, assemblyStrategy, enzymeName, targetName, targetId, targetSequenceId, targetPartId"
        />
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button
            disabled={!size(selectedParts) && !size(selectedSequences)}
            intent={Intent.PRIMARY}
            text="Next"
            onClick={nextStep}
          />
        }
      />
    </React.Fragment>
  );
};

export default compose(
  stepFormValues(
    "selectedParts",
    "selectedSequences",
    "designNameTemplate",
    "partNameTemplate",
    "isSingleDesignFlag"
  )
)(SelectPartStep);
