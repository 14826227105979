/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";

export function convertMicrobialStrainGqlJsonToIntegrationJson(r, options) {
  return {
    //tnrtodo: add aliases here eventually
    name: r.name,
    genotype: r.genotype,
    biosafetyLevel: get(r, "biosafetyLevel.name", "1"),
    selectionMethods: r.strainSelectionMethods.map(({ selectionMethod }) => {
      return selectionMethod.name;
    }),
    genus: get(r, "specie.genus.name"),
    species: get(r, "specie.name"),
    plasmids: r.strainPlasmids.map(({ plasmid }) => {
      return options.DNA_SEQUENCE.convert(plasmid, options);
    }),
    ...(r.sourceMicrobialMaterial && {
      sourceMicrobialMaterial: options.MICROBIAL_MATERIAL.convert(
        r.sourceMicrobialMaterial
      )
    }),
    genomeName: get(r, "genome.name"),
    organismClass: get(r, "target.organismClass.name"),
    growthConditionName: get(r, "growthCondition.name"),
    growthConditionId: get(r, "growthCondition.id"),
    growthConditionDescription: get(r, "growthCondition.description"),
    growthMedium: get(r, "growthCondition.growthMedia.name"),
    temperature: get(r, "growthCondition.temperature"),
    shakerSpeed: get(r, "growthCondition.shakerSpeed"),
    shakerThrow: get(r, "growthCondition.shakerThrow"),
    lengthUnit: get(r, "growthCondition.lengthUnitCode")
  };
}
