/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5InputPartFragment on j5InputPart {
    id
    sourcePart {
      id
    }
    part {
      id
      name
      start
      end
      strand
      sequence {
        id
        name
        size
      }
      size
    }
  }
`;
