/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
// import { appHotkeySets } from "../../hotkeys";
import PlatformHeader from "../../../src-shared/PlatformHeader";
import NotificationPopover from "../../../src-shared/NotificationUI/NotificationPopover";
import "./style.css";
import getAppColor from "../../../src-shared/utils/getAppColor";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { socketWrapper } from "../../../src-shared/socket/socketWrapper";
import { designSearchSettings } from "../../../src-shared/utils/universalSearchSettings";
import {
  disabledFeaturesConfig,
  openInNewTab
} from "../../../src-shared/utils/generalUtils";
import createMenuFromModelNamesArray, {
  getMenuPaths
} from "../../../src-shared/utils/createMenuFromModelNamesArray";
import {
  moleculeMenu,
  designMenu,
  reportMenu,
  dataMenu,
  toolMenu,
  taskMenu
} from "../../utils/headerCategories";
const DataMenu = createMenuFromModelNamesArray([
  "tag",
  "importCollection",
  ...(!disabledFeaturesConfig.alignments ? ["alignment"] : [])
]);
const moleculeSubmenu = [
  { text: "DNA Sequences", navTo: "/sequences" },
  { text: "DNA Parts", navTo: "/parts" },
  { text: "Sequence Features", navTo: "/sequence-features" },
  { text: "DNA Part Sets", navTo: "/part-sets" },
  { text: "RNA Sequences", navTo: "/rna-sequences" },
  { text: "RNA Parts", navTo: "/rna-parts" },
  { text: "Oligos", navTo: "/oligos" },
  { text: "Amino Acid Sequences", navTo: "/amino-acid-sequences" },
  { text: "Amino Acid Parts", navTo: "/amino-acid-parts" }
];
const designSubmenu = [
  { text: "Designs", navTo: "/designs" },
  ...(!disabledFeaturesConfig.designTemplates
    ? [{ text: "Design Templates", navTo: "/design-templates" }]
    : []),
  ...(!disabledFeaturesConfig.designSchematics
    ? [{ text: "Schematics", navTo: "/schematics" }]
    : []),
  ...(!disabledFeaturesConfig.designRulesets
    ? [{ text: "Design Rulesets", navTo: "/design-rulesets" }]
    : [])
];
const topMenu = [
  {
    ...moleculeMenu,
    submenu: moleculeSubmenu,
    activeOn: getMenuPaths(moleculeSubmenu)
  },
  {
    ...designMenu,
    submenu: designSubmenu,
    activeOn: getMenuPaths(designSubmenu)
  },
  {
    ...reportMenu,
    navTo: "/assembly-reports"
  },

  {
    ...dataMenu,
    submenu: DataMenu,
    activeOn: getMenuPaths(DataMenu)
  },
  {
    ...toolMenu,
    navTo: "/tools"
  },
  {
    ...taskMenu,
    navTo: "/microservice-tasks"
  }
];

const userExtra = () => [
  {
    text: "Glossary",
    icon: "duplicate",
    shortcut: "^G",
    onClick: () => {
      showDialog({ modalType: "GLOSSARY" });
    }
  },
  {
    text: "Help",
    icon: "help",
    onClick: () => {
      openInNewTab(
        "html_files/collections/313777-design-module.html",
        window.frontEndConfig.tgModuleName
      );
    }
  }
];

function filterActiveMenuItems(menuItems = []) {
  const disabledMenuItems = {};

  if (window.frontEndConfig.hideTaskMenu) {
    disabledMenuItems["Tasks"] = true;
  }

  return menuItems.filter(({ name }) => {
    return !disabledMenuItems[name];
  });
}

class HdeHeader extends Component {
  render() {
    const { logout, currentUser, loggedIn, appRoutes, appHotkeySets } =
      this.props;

    if (currentUser && currentUser.id) {
      socketWrapper.init(currentUser);
    }

    const activeMenuItems = filterActiveMenuItems(topMenu);
    return (
      <PlatformHeader
        {...{ currentUser, logout, loggedIn, appRoutes }}
        // className="compact-nav"
        appName={window.frontEndConfig.designTitle || "Design"}
        backgroundColor={getAppColor()}
        menuItems={activeMenuItems}
        userExtraItems={userExtra()}
        onSearch={null}
        hotkeySets={appHotkeySets}
        searchSettings={designSearchSettings}
        extraContent={[
          <NotificationPopover
            currentUser={currentUser}
            key="NotificationPopover"
          />
        ]}
      />
    );
  }
}

export default HdeHeader;
