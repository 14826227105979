/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes, useHotkeys } from "@blueprintjs/core";
import { TgSelect } from "@teselagen/ui";
import React, { useState } from "react";
import appGlobals from "../appGlobals";
// import ButtonWithHotkey from "./ButtonWithHotkey";
import { useMemo } from "react";

function NavJump({ navSuggestItems, hotkey }) {
  // {navSuggestItems.map(item => {
  //   return (
  //     <MenuItem
  //       key={item.path}
  //       text={item.label}
  //       label={item.path}
  //       onClick={() => {
  //         this.onClose();
  //         history.push(item.navTo);
  //       }}
  //     />
  //   );
  // })}
  const tooltip = "Quick Jump to Library";
  const [searchOpen, setSearchOpen] = useState(false);

  // important: hotkeys array must be memoized to avoid infinitely re-binding hotkeys
  const hotkeys = useMemo(
    () => [
      {
        combo: hotkey,
        global: true,
        allowInInput: true,
        label: tooltip,
        onKeyDown: e => {
          e.preventDefault();
          e.stopPropagation();
          setSearchOpen(val => !val);
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useHotkeys(hotkeys);

  let inner;
  if (searchOpen) {
    inner = (
      <TgSelect
        autoFocus
        className="tg-nav-jump-search"
        onBlur={() => {
          setTimeout(() => {
            setSearchOpen(false);
          }, 200);
        }}
        onChange={value => {
          setSearchOpen(false);
          if (value) {
            appGlobals.history.push(value.value);
          }
        }}
        options={navSuggestItems.map(item => {
          return {
            label: (
              <div>
                {item.label}
                <div style={{ fontSize: 9 }} className={Classes.TEXT_MUTED}>
                  {item.path}
                </div>
              </div>
            ),
            value: item.navTo
          };
        })}
      />
    );
  } else {
    // inner = (
    //   <ButtonWithHotkey
    //     className="tg-nav-jump-button"
    //     icon="search-text"
    //     tooltip={tooltip}
    //     hotkey={hotkey}
    //     onClick={() => setSearchOpen(true)}
    //     minimal
    //   />
    // );
    inner = null;
  }

  return inner;
}

export default NavJump;
