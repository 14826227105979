/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { map } from "lodash";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export function getAlphnumericWellLocation(positionArray = []) {
  return map(positionArray, (position = {}) =>
    getAliquotContainerLocation(position, {
      force2D: true
    })
  );
}
