/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Icon, Tag } from "@blueprintjs/core";
import classnames from "classnames";
import humanizeDuration from "humanize-duration";
import "./style.css";

const NotificationLineItem = props => {
  const { notification, history, onOpen } = props;

  let handleOpen;
  let linkToUse = notification.link;
  if (linkToUse) {
    ["build/client", "design/client", "test/client", "evolve/client"].forEach(
      str => {
        if (linkToUse.startsWith(str)) {
          linkToUse = linkToUse.replace(str, "");
        }
        if (linkToUse.startsWith("/" + str)) {
          linkToUse = linkToUse.replace("/" + str, "");
        }
      }
    );

    handleOpen = () => {
      onOpen && onOpen();
      history.push(linkToUse);
      console.info(`notification opened:`, notification);
    };
  }

  const cancelOption = "";
  // // Commenting this out because it should never get hit... the date prop does not appear to be getting passed in
  // // Pushing this logic up to the NotificationPopover
  // if (notification.date) {
  //   switch (notification.notificationIntent) {
  //     case "primary":
  //       // cancelOption = (
  //       //   <a
  //       //     onClick={() => {
  //       //       window.console.info('Cancel clicked')
  //       //       window.console.info(notification)
  //       //     }}
  //       //   >
  //       //     Cancel
  //       //   </a>
  //       // )
  //       break;
  //     case "warning":
  //       cancelOption = (
  //         <a
  //           onClick={() => {
  //             window.toastr.error(notification.error);
  //           }}
  //         >
  //           Check warnings
  //         </a>
  //       );
  //       break;
  //     case "danger":
  //       cancelOption = (
  //         <a
  //           onClick={() => {
  //             window.toastr.error(notification.error.toString());
  //           }}
  //         >
  //           Check error
  //         </a>
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // }
  return (
    <div
      className={classnames("notification-card", {
        "notification-card-unread": !notification.hasBeenRead
      })}
    >
      {notification.statusMsg && (
        <Tag
          className="notification-status"
          intent={notification.notificationIntent || "none"}
        >
          {notification.statusMsg}
        </Tag>
      )}
      <div
        onClick={handleOpen}
        title={linkToUse}
        className={classnames("notification-name", {
          "notification-name-openable": handleOpen
        })}
      >
        {notification.message}
      </div>
      <span className="notification-time">
        <Icon icon="time" iconSize={12} />
        <span>
          {humanizeDuration(Date.now() - new Date(notification.createdAt), {
            round: true,
            largest: 1
          })}{" "}
          ago
        </span>
      </span>
      {cancelOption}
    </div>
  );
};

export default NotificationLineItem;
