/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// import * as Url from "url";
import Url from "url";
/**
 * This util function transforms hostUrls specific to "test" and "evolve"
 * and converts them to their "build" version.
 *
 * Eventually this might not be needed if we unifiy test, evolve and build server.
 */
function bteHostUrl(hostUrl, moduleName) {
  const module_name = moduleName || process.env.TG_MODULE_NAME;
  const {
    protocol,
    host, // includes port
    // path, // includes 'search' params.
    pathname: originalPathname, // does not include search params.
    search // Probably not needed, but just in case.
  } = Url.parse(hostUrl);

  let pathname = originalPathname;
  if (module_name === "evolve") {
    pathname = pathname.replace("evolve", "build");
  } else if (module_name === "test") {
    pathname = pathname.replace("test", "build");
  }

  return Url.format({
    protocol,
    host,
    pathname,
    search
  });
}

function bteModuleName(moduleName) {
  return ["test", "evolve"].includes(moduleName) ? "build" : moduleName;
}

export { bteHostUrl, bteModuleName };
