/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { capitalize } from "lodash";
import QueryBuilder from "tg-client-query-builder";

const makeMaterialSearchObject = (type: string) => {
  const additionalFilter = (qb: QueryBuilder) => {
    return qb.whereAll({
      materialTypeCode: type
    });
  };

  let displayName = type === "DNA" ? type : capitalize(type.toLowerCase());
  displayName += " Materials";

  return {
    model: "material",
    displayName,
    additionalFilter,
    route: `/${type.toLowerCase()}-materials`
  };
};

export default makeMaterialSearchObject;
