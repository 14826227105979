/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { filter, forEach, keyBy } from "lodash";
import DNA_SEQUENCE from "./DNA_SEQUENCE";
import AMINO_ACID from "./AMINO_ACID";
import DNA_MATERIAL from "./DNA_MATERIAL";
import MICROBIAL_MATERIAL from "./MICROBIAL_MATERIAL";
import MICROBIAL_STRAIN from "./MICROBIAL_STRAIN";
import ALIQUOT from "./ALIQUOT";
import TUBE from "./TUBE";
import PLATE from "./PLATE";
import DNA_PART from "./DNA_PART";
import DESIGN from "./DESIGN";
import REACTION_MAP from "./REACTION_MAP";
import REACTION_DESIGN from "./REACTION_DESIGN";
import EQUIPMENT from "./EQUIPMENT";
import DESIGN_PART from "./DESIGN_PART";
import shortid from "shortid";
import { aliasModels } from "../../../constants";
import upsertUniqueAliases from "../../sequence-import-utils/upsertUniqueAliases";
import { updateTagsOnExternalRecords } from "../../tag-utils/utils";
import { isoContext } from "@teselagen/utils";
import { deleteRecordsHelper } from "../deleteUtils";
import { updateExtendedPropertiesOnExternalRecords } from "./utils";
import { getModelFromSubtype } from "../../utils/getModelFromSubtype";
import SELECTION_METHOD from "./SELECTION_METHOD";
import ORGANISM from "./ORGANISM";

async function handleDeletions({ recordsToImport, model }, ctx) {
  const toDelete = [];
  recordsToImport.forEach(r => {
    if (r.__remove) {
      if (!r.id) {
        throw new Error("Records being deleted must have an id");
      }
      toDelete.push({ ...r, __typename: model });
    }
  });
  if (!toDelete.length) return;
  await deleteRecordsHelper(
    {
      records: toDelete,
      noDeleteAlert: true,
      // TODO: Figure out what the API should do on these.
      // There are some forbidden deletes, so
      // for now it will just go through if its a 'canContinue' operation.
      confirmDeleteAlert: (message, options) => {
        console.warn(message);
        return options.canContinue;
      }
    },
    ctx
  );
}

async function updateAliasesOnExternalRecords({ records, model }, ctx) {
  const { safeQuery } = ctx;
  if (!aliasModels.includes(model)) return;

  const recordIds = records.map(r => r.id || r.duplicate.id);

  const recordsWithExistingAliases = await safeQuery(
    [model, `id aliases { id name }`],
    {
      variables: {
        filter: {
          id: recordIds
        }
      }
    }
  );
  const keyed = keyBy(recordsWithExistingAliases, "id");
  const newAliases = [];
  records.forEach(record => {
    const id = record.id || record.duplicate.id;
    const rWithExistingAliases = keyed[id];
    (record.aliases || []).forEach(alias => {
      const hasAlias = rWithExistingAliases?.aliases?.some(recordAlias => {
        return recordAlias.name.toLowerCase() === alias.toLowerCase();
      });
      if (!hasAlias) {
        newAliases.push({
          name: alias,
          [model + "Id"]: id
        });
      }
    });
  });
  await upsertUniqueAliases(newAliases, ctx);
}

const getUpsertHandlers = (ctx = isoContext) => {
  const upsertHandlers = {
    ALIQUOT,
    AMINO_ACID,
    DNA_SEQUENCE,
    DNA_MATERIAL,
    MICROBIAL_MATERIAL,
    MICROBIAL_STRAIN,
    OLIGO: DNA_SEQUENCE,
    TUBE,
    PLATE,
    DNA_PART,
    DESIGN,
    REACTION_MAP,
    REACTION_DESIGN,
    EQUIPMENT,
    DESIGN_PART,
    SELECTION_METHOD,
    ORGANISM
  };

  forEach(upsertHandlers, (handler, key) => {
    upsertHandlers[key] = async options => {
      let { recordsToImport, model = getModelFromSubtype(key) } = options;

      recordsToImport = filter(recordsToImport, r => !r.__importFailed);
      if (!recordsToImport || !recordsToImport.length) {
        return;
      }

      //take tags and extendedProperties off (we'll handle them later)
      const tagsExtPropsAliasHolder = [];
      recordsToImport.forEach(r => {
        if (key === "OLIGO") r.sequenceTypeCode = key;
        if (!r.cid) r.cid = shortid();

        const holder = {
          tags: {
            original: r.tags,
            ...(r.__oldRecord && { __oldRecordTags: r.__oldRecord.tags }),
            ...(r.__newRecord && { __newRecordTags: r.__newRecord.tags })
          },
          extendedProperties: {
            original: r.extendedProperties,
            ...(r.__oldRecord && {
              __oldRecordExtendedProperties: r.__oldRecord.extendedProperties
            }),
            ...(r.__newRecord && {
              __newRecordExtendedProperties: r.__newRecord.extendedProperties
            })
          },
          aliases: {
            original: r.aliases,
            ...(r.__oldRecord && {
              __oldRecordAliases: r.__oldRecord.aliases
            }),
            ...(r.__newRecord && {
              __newRecordAliases: r.__newRecord.aliases
            })
          }
        };
        tagsExtPropsAliasHolder.push(holder);
        delete r.tags;
        if (r.__oldRecord) delete r.__oldRecord.tags;
        if (r.__newRecord) delete r.__newRecord.tags;
        delete r.extendedProperties;
        if (r.__oldRecord) delete r.__oldRecord.extendedProperties;
        if (r.__newRecord) delete r.__newRecord.extendedProperties;
        delete r.aliases;
        if (r.__oldRecord) delete r.__oldRecord.aliases;
        if (r.__newRecord) delete r.__newRecord.aliases;
      });

      await handleDeletions(options, ctx);

      await handler(
        {
          ...options,
          recordsToImport: recordsToImport,
          upsertHandlers: upsertHandlers
        },
        ctx
      );
      //pop tags and extendedProperties back on
      recordsToImport.forEach((r, i) => {
        const tagsHolder = tagsExtPropsAliasHolder[i].tags;
        const extendedPropertiesHolder =
          tagsExtPropsAliasHolder[i].extendedProperties;
        const aliasesHolder = tagsExtPropsAliasHolder[i].aliases;
        r.tags = tagsHolder.original;
        if (tagsHolder.__oldRecordTags) {
          r.__oldRecord.tags = tagsHolder.__oldRecordTags;
        }
        if (tagsHolder.__newRecordTags) {
          r.__newRecord.tags = tagsHolder.__newRecordTags;
        }
        r.extendedProperties = extendedPropertiesHolder.original;
        if (extendedPropertiesHolder.__oldRecordExtendedProperties) {
          r.__oldRecord.extendedProperties =
            extendedPropertiesHolder.__oldRecordExtendedProperties;
        }
        if (extendedPropertiesHolder.__newRecordExtendedProperties) {
          r.__newRecord.extendedProperties =
            extendedPropertiesHolder.__newRecordExtendedProperties;
        }
        r.aliases = aliasesHolder.original;
        if (aliasesHolder.__oldRecordAliases) {
          r.__oldRecord.aliases = aliasesHolder.__oldRecordAliases;
        }
        if (aliasesHolder.__newRecordAliases) {
          r.__newRecord.aliases = aliasesHolder.__newRecordAliases;
        }

        return r;
      });

      const filteredRecords = recordsToImport.filter(r => !r.__importFailed);
      const args = {
        records: filteredRecords,
        model: model
      };
      await updateTagsOnExternalRecords(args, ctx);
      await updateExtendedPropertiesOnExternalRecords(args, ctx);
      await updateAliasesOnExternalRecords(args, ctx);
    };
  });

  return upsertHandlers;
};

export default getUpsertHandlers;
