/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { logDebug } = require("./logDebug");
const { shouldIdBeNull } = require("./shouldIdBeNull");
const {
  headers: { activeLabHeaderKey }
} = require("./constants");
const { validateUserLabId } = require("./validateUserLabId");

function getLabIdFromContext(context) {
  if (typeof context === "undefined") return;
  if (typeof context.req === "undefined") return;
  const labId =
    (context.req.get && context.req.get(activeLabHeaderKey)) ||
    context.req.headers[activeLabHeaderKey];

  logDebug("getLabIdFromContext labId: ", labId);

  // NOTE: looks like it could be a good idea to return the actual lab IDs here
  // instead of "all". However, we need to refactor some code that is currently expecting
  // this function to return a single string (not an array).
  if (labId === "all") {
    return labId;
  }

  if (shouldIdBeNull(labId)) return null;

  if (!labId) return;

  const user = context?.req?.user;
  if (user) {
    logDebug(typeof user.labs);
    logDebug(
      `User ${user.id} is in labs ${user.labs.join(
        ","
      )}. Checking if in lab ${labId}.`
    );
    logDebug(user);
    // todo may have string vs int comparison problems here
    const validLabId = validateUserLabId({ user, labId });
    if (!validLabId) {
      const msg = `User with id ${user.id} doesn't have role in lab with id ${labId}`;
      console.error(msg);
      throw new Error(msg);
    }
    return labId;
  } else {
    const msg = `No user in context for request`;
    console.error(msg);
    throw new Error(msg);
  }
}

module.exports = { getLabIdFromContext };
