/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { getDesignState } from "../../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../actions";
import { isClassicDesign } from "../../selectors/classicViewSelectors";
import { socketWrapper } from "../../../src-shared/socket/socketWrapper";
import { takeEvery, call, put, select } from "redux-saga/effects";
import appGlobals from "../../../src-shared/appGlobals";
import { disabledFeaturesConfig } from "../../../src-shared/utils/generalUtils";

const updateTreeLayout = treeLayout =>
  actions.ui.designEditor.general.updateViewOptions({ treeLayout });

async function fetchDesignFromServer({ payload: designId }) {
  const { designState, isTemplate, labId } = await socketWrapper.openDesign(
    designId
  );
  return { designState, isTemplate, labId };
}

function* handleFetchDesignFromServer(designId) {
  try {
    yield put(actions.designIo.fetchStarted());

    const { designState: designFromServer } = yield call(
      fetchDesignFromServer,
      designId
    );

    yield put(actions.designIo.setDesign(designFromServer));

    const designState = yield select(getDesignState);
    yield put(actions.designIo.setLastSavedState(designState));

    const preferredDesignViewMode = disabledFeaturesConfig.hierarchicalDesigns
      ? "classic"
      : appGlobals.currentUser.preferredDesignViewMode;

    const isClassic = isClassicDesign({
      design: designState
    }).success;

    let layout;
    // todo: vertical and horizontal are flip-flopped in the db and in the UI
    if (preferredDesignViewMode === "horizontal") {
      layout = "vertical";
    } else if (preferredDesignViewMode === "vertical") {
      layout = "horizontal";
    } else {
      // default to classic mode if valid
      layout = isClassic ? "classic" : "horizontal";
    }
    yield put(updateTreeLayout(layout));
  } catch (e) {
    // TODO: Better handling.
    console.error(e);
    window.toastr.error(`Error loading design.`);
    appGlobals.history.push("/designs");
  }
}

export default function* watchFetchDesignFromServer() {
  yield takeEvery(
    actions.designIo.fetchFromServer,
    handleFetchDesignFromServer
  );
}
