/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { difference } from "lodash";
import {
  InputField,
  TextareaField,
  ReactSelectField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../withQuery";
import { arrayToIdOrCodeValuedOptions } from "../../../utils/formUtils";
import { safeUpsert, safeQuery, safeDelete } from "../../../apolloMethods";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";
import { isBuild } from "../../../../../tg-iso-shared/src/utils/isModule";
import { identity } from "lodash";

class UpdateSequence extends Component {
  onSubmit = async values => {
    const {
      hideModal,
      initialValues: {
        inductionMethodIds: initialInductionMethodIds,
        sequenceTypeCode: initialSequenceTypeCode
      } = {},
      selectedRecords = [],
      fromLibrary,
      sequenceTypeCode,
      plasmidInductionMethods
    } = this.props;
    const { id, name, description, inductionMethodIds } = values;

    const sequenceIdToSequenceMap = {};
    const sequenceIds = [];
    if (fromLibrary) {
      selectedRecords.forEach(record => sequenceIds.push(record.id));
    }
    try {
      const sequencesWithAliquotInfo = await safeQuery(
        [
          "sequence",
          // fragments are needed for molecular weight
          "id name polynucleotideMaterial { id samples { id aliquots { id } } } sequenceFragments { id fragment}"
        ],
        {
          variables: {
            filter: {
              id: id || sequenceIds
            }
          }
        }
      );
      let error;

      const sequencesWithAliquots = [];
      sequencesWithAliquotInfo.forEach(sequence => {
        const hasAliquot =
          sequence.polynucleotideMaterial &&
          sequence.polynucleotideMaterial.samples.some(
            sample => sample.aliquots.length > 0
          );
        if (hasAliquot) sequencesWithAliquots.push(sequence.name);
        if (!sequenceIdToSequenceMap[sequence.id]) {
          sequenceIdToSequenceMap[sequence.id] = getSequence(sequence);
        }
      });
      if (
        sequencesWithAliquots.length > 0 &&
        sequenceTypeCode !== initialSequenceTypeCode
      ) {
        error = `The following sequences have aliquots and cannot
        have their sequence type changed: ${sequencesWithAliquots.join(", ")}.`;
      }

      if (error) {
        window.toastr.error(error);
        return false;
      }
      if (fromLibrary) {
        const sequencesToUpdate = sequenceIds.map(id => ({
          id,
          sequenceTypeCode: sequenceTypeCode
        }));
        await safeUpsert(
          [
            "sequence",
            "id sequenceTypeCode sequenceType {code name} molecularWeight"
          ],
          sequencesToUpdate
        );
      } else {
        await safeUpsert(
          [
            "sequence",
            "id name description sequenceTypeCode sequenceType {code name} molecularWeight"
          ],
          {
            id,
            name,
            description,
            sequenceTypeCode
          }
        );
      }
      let newInductionMethodIds = inductionMethodIds || [];
      if (initialInductionMethodIds) {
        newInductionMethodIds = difference(
          inductionMethodIds,
          initialInductionMethodIds
        );
        const removedInductionMethodIds = difference(
          initialInductionMethodIds,
          inductionMethodIds
        );
        const plasmidInductionMethodsToDelete = plasmidInductionMethods.reduce(
          (acc, pim) => {
            if (removedInductionMethodIds.includes(pim.inductionMethod.id)) {
              acc.push(pim.id);
            }
            return acc;
          },
          []
        );
        await safeDelete(
          "inductionMethodPlasmid",
          plasmidInductionMethodsToDelete
        );
      }
      await safeUpsert(
        "inductionMethodPlasmid",
        newInductionMethodIds.map(inductionMethodId => ({
          plasmidId: id,
          inductionMethodId
        }))
      );
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating sequence.");
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      sequenceTypes = [],
      inductionMethods = [],
      initialValues: {
        inductionMethodIds: initialInductionMethodIds,
        sequenceTypeCode
      } = {},
      fromLibrary
    } = this.props;
    const isGenomicRegion = sequenceTypeCode === "GENOMIC_REGION";
    const isRNA = sequenceTypeCode === "RNA";
    const isOligo = sequenceTypeCode === "OLIGO";

    return (
      <form>
        <div className={Classes.DIALOG_BODY}>
          {!fromLibrary && (
            <React.Fragment>
              <InputField
                label="Name"
                name="name"
                isRequired
                placeholder="Enter sequence name..."
              />
              <TextareaField
                label="Description"
                name="description"
                style={{ minHeight: 100 }}
                placeholder="Enter sequence description..."
              />
              {isBuild() && !isGenomicRegion && (
                <ReactSelectField
                  label="Induction Methods"
                  name="inductionMethodIds"
                  options={arrayToIdOrCodeValuedOptions(inductionMethods)}
                  multi
                  defaultValue={initialInductionMethodIds}
                />
              )}
            </React.Fragment>
          )}
          {!isGenomicRegion && !isRNA && !isOligo && (
            <ReactSelectField
              label="Sequence Type"
              name="sequenceTypeCode"
              isRequired
              options={arrayToIdOrCodeValuedOptions(sequenceTypes)}
              placeholder="Select sequence type..."
            />
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </form>
    );
  }
}
// how to update sequences to be type CDS, this necessary?
export default compose(
  wrapDialog({ title: "Update Sequence" }),
  withQuery(["sequenceType", "code name isCircular"], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        filter: { code: ["CIRCULAR_DNA", "LINEAR_DNA", "OLIGO"] }
      }
    }
  }),
  isBuild()
    ? withQuery(["inductionMethod", "id name"], {
        isPlural: true,
        showLoading: true,
        inDialog: true
      })
    : identity,
  reduxForm({
    form: "UpdateSequence",
    initialValues: {
      inductionMethods: {}
    }
  }),
  tgFormValues("sequenceTypeCode")
)(UpdateSequence);
