/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import getActiveLabId from "../../tg-iso-shared/src/utils/getActiveLabId";
import getActiveProjectId from "../../tg-iso-shared/src/utils/getActiveProjectId";
import appGlobals from "./appGlobals";
import { get } from "lodash";
import { getUserLabs } from "./utils/labUtils";
import { getUserProjects } from "./utils/projectUtils";
import { formatDateTime } from "./utils/dateUtils";

const allParams = [
  {
    id: "uniqid",
    getExample: () => {
      return shortid();
    }
  },
  {
    id: "timestamp",
    getExample: () => {
      return formatDateTime(new Date());
    }
  },
  {
    id: "username",
    getExample: () => {
      return appGlobals.currentUser && appGlobals.currentUser.username;
    }
  },
  {
    id: "userInitials",
    getExample: () => {
      return (
        get(appGlobals, "currentUser.firstName[0]", "") +
        get(appGlobals, "currentUser.lastName[0]", "")
      );
    }
  },
  {
    id: "userEmail",
    getExample: () => {
      return appGlobals.currentUser && appGlobals.currentUser.email;
    }
  },
  {
    id: "labname",
    getExample: () => {
      return (
        (
          getUserLabs(appGlobals.currentUser).find(
            l => l.id === getActiveLabId()
          ) || {}
        ).name || "Common"
      );
    }
  },
  {
    id: "projectName",
    getExample: () => {
      return (
        (
          getUserProjects(appGlobals.currentUser).find(
            l => l.id === getActiveProjectId({ returnUnassigned: true })
          ) || {}
        ).name || "Unassigned"
      );
    }
  },
  {
    id: "projectInitials",
    getExample: () => {
      const projectName =
        (
          getUserProjects(appGlobals.currentUser).find(
            l => l.id === getActiveProjectId({ returnUnassigned: true })
          ) || {}
        ).name || "Unassigned";
      const projectInitials = projectName
        .split(" ")
        .map(word => word[0])
        .join("");
      return projectInitials;
    }
  }
];

export function getDefaultValueSharedParams() {
  return allParams.reduce((acc, { id, getExample }) => {
    acc[id] = getExample();
    return acc;
  }, {});
}
