/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import queryString from "query-string";
import { getTextFromEl } from "@teselagen/ui";
import { Button, Intent, Tabs, Tab, InputGroup } from "@blueprintjs/core";
import Helmet from "react-helmet";
import "./style.css";
import { isString } from "lodash";

function LaunchToolButton(props) {
  const { launchTool, link } = props;
  return (
    <Button
      onClick={() => launchTool(link)}
      intent={Intent.PRIMARY}
      text="Launch Tool"
    />
  );
}

class ToolLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: ""
    };
    const { toolSchemas } = this.props;
    this.toolSchemas = toolSchemas;
  }

  componentDidUpdate() {
    const {
      location: { search }
    } = this.props;

    // This 'nofilter' param (e.g., used from a tool description link in toolSchemas.js) added to location.search,
    // allows going to a tool's hash even if the tool itself is filtered out by the tool filter.
    // Useful when the tool searchbar is NOT empty and tools descriptions have links to filtered tools.
    // For example the 'Parse Experiment Data' and 'Import Experiment Data' tools.
    const { nofilter } = queryString.parse(search);
    if (nofilter) {
      this.renderToolWithoutFilters();
    }
  }

  // This renders a tool description while also clearing the tool filter searchValue.
  renderToolWithoutFilters() {
    const { location } = this.props;
    this.props.history.replace(`/tools${location.hash}`);
    this.setState({
      searchValue: ""
    });
  }

  launchTool = link => {
    this.props.history.push(link);
  };

  getFilteredSchemas = _searchValueSync => {
    const { searchValue: _searchValueState } = this.state;
    const searchValue = _searchValueSync || _searchValueState;
    return searchValue
      ? this.toolSchemas.filter(t => {
          const titleMatch = t.title
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          let descriptionMatch = false;
          if (t.description) {
            if (isString(t.description)) {
              descriptionMatch = t.description
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            } else {
              const string = getTextFromEl(t.description);
              descriptionMatch = string
                ?.toLowerCase?.()
                .includes(searchValue.toLowerCase());
            }
          }
          return titleMatch || descriptionMatch;
        })
      : this.toolSchemas;
  };

  renderTools = () => {
    return this.getFilteredSchemas().map(
      ({
        code,
        title,
        disabled,
        fullTitle,
        description,
        input,
        output,
        tabId
      }) => {
        if (!disabled && code !== "onlineDnaOrdering") {
          return (
            <Tab
              key={title}
              id={tabId}
              title={title}
              panel={
                <div className="tool-library-info">
                  <h5>{fullTitle || title}</h5>
                  {description}
                  <div style={{ margin: "10px 0" }}>
                    <span className="bold-light">Input</span>: {input}
                  </div>
                  <div style={{ margin: "10px 0" }}>
                    <span className="bold-light">Output</span>: {output}
                  </div>
                  <div className="tool-info-footer">
                    <LaunchToolButton
                      launchTool={this.launchTool}
                      link={`/tools/${tabId}`}
                    />
                  </div>
                </div>
              }
            />
          );
        } else {
          return null;
        }
      }
    );
  };

  onTabChange = newTabId => {
    this.props.history.replace(`/tools#${newTabId}`);
  };

  updateSearchValue = e => {
    const searchValue = e.target.value;
    const filteredSchemas = this.getFilteredSchemas(searchValue);
    if (filteredSchemas.length) {
      const bestMatchTool = filteredSchemas[0];
      this.onTabChange(bestMatchTool.tabId);
      this.toolSearchLink = `/tools/${bestMatchTool.tabId}`;
    }
    this.setState({
      searchValue
    });
  };

  launchToolFromSearch = selectedTabId => {
    if (selectedTabId) {
      this.launchTool(`/tools/${selectedTabId}`);
    } else if (this.toolSearchLink) {
      this.launchTool(this.toolSearchLink);
    }
  };

  render() {
    const { searchValue } = this.state;
    const { location } = this.props;

    const selectedTabId = location.hash
      ? location.hash.replace("#", "")
      : this.toolSchemas[0].tabId;

    return (
      <div
        className="tool-library-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Helmet title="Tool Library" />
        <h2 style={{ marginBottom: 25 }}>Tool Library</h2>
        <div
          className="tool-library"
          style={{
            width: "100%",
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: 5
          }}
        >
          <InputGroup
            autoFocus
            style={{ marginBottom: 15, maxWidth: 250 }}
            leftIcon="search"
            type="search"
            value={searchValue}
            onKeyDown={e => {
              if (e.key === "Enter") {
                return this.launchToolFromSearch(selectedTabId);
              }
              const downArrow = e.keyCode === 40;
              const upArrow = e.keyCode === 38;
              if (selectedTabId && (downArrow || upArrow)) {
                e.stopPropagation();
                const filteredSchemas = this.getFilteredSchemas();
                // down arrow
                const indexOfSelected = filteredSchemas.findIndex(
                  tool => tool.tabId === selectedTabId
                );
                if (indexOfSelected > -1) {
                  const toSelect =
                    filteredSchemas[
                      downArrow ? indexOfSelected + 1 : indexOfSelected - 1
                    ];
                  if (downArrow) {
                    if (indexOfSelected > -1 && toSelect) {
                      this.onTabChange(toSelect.tabId);
                    } else if (filteredSchemas.length) {
                      this.onTabChange(filteredSchemas[0].tabId);
                    }
                    // up arrow
                  } else if (upArrow) {
                    if (indexOfSelected > -1 && toSelect) {
                      this.onTabChange(toSelect.tabId);
                    } else if (filteredSchemas.length) {
                      this.onTabChange(
                        filteredSchemas[filteredSchemas.length - 1].tabId
                      );
                    }
                  }
                }
              }
            }}
            onChange={this.updateSearchValue}
          />
          <Tabs
            vertical
            onChange={this.onTabChange}
            renderActiveTabPanelOnly
            selectedTabId={selectedTabId}
          >
            {this.renderTools()}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ToolLibrary;
