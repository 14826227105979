/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment extendedValueLibraryDisplayFragment on extendedStringValueView {
    id
    value
    type
    extendedPropertyId
    targetModel
    linkIds
  }
`;
