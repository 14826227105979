/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniqBy } from "lodash";
import { isoContext } from "@teselagen/utils";
import { isBuild } from "../utils/isModule";
import buildAliasModels from "../../../tg-iso-lims/src/utils/aliasModels";

let aliasModels = [];
if (isBuild()) {
  aliasModels = [...buildAliasModels];
}

export default async function upsertUniqueAliases(
  aliases,
  { safeUpsert } = isoContext
) {
  const uniqAliases = uniqBy(aliases, a => {
    let recordId;
    for (const model of aliasModels) {
      if (recordId) break;
      recordId = a[model + "Id"];
    }
    return a.name + "-" + recordId;
  });
  await safeUpsert("alias", uniqAliases, {
    excludeResults: true
  });
}
