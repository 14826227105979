/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty, pick, isNil, map } from "lodash";
import shortid from "shortid";
import {
  filterSequenceUploads,
  parseSequenceText,
  sequenceJSONtoGraphQLInput
} from "./utils";
import { guessIfSequenceIsDnaAndNotProtein } from "@teselagen/sequence-utils";
import { checkDuplicateSequencesExtended } from "./checkDuplicateSequences";
import processSequences from "./processSequences";
import { isoContext } from "@teselagen/utils";
import createTaggedItems from "../tag-utils/createTaggedItems";
import {
  addTaggedItemsBeforeCreate,
  updateTagsOnExternalRecords
} from "../tag-utils/utils";
import { isBuild as getIsBuild } from "../utils/isModule";
import { getSequence } from "../utils/getSequence";
import { getMaterialFields } from "./getMaterialFields";
import { forEach, flatMap } from "lodash";
import Promise from "bluebird";
const taggedItems = `taggedItems {
  id
  tagId
  tagOptionId
}`;

/**
 * Uploads dna sequence files and creates materials, cds, and aminos appropriately
 * @param {object} params
 * @param {boolean=} params.allowDuplicates create duplicates if found
 * @param {boolean=} params.isFileUpload is file upload or paste
 * @param {boolean=} params.isGenomicRegionUpload uploading genomic regions
 * @param {boolean=} params.isGuideRNA
 * @param {boolean=} params.isRNA
 * @param {string=} params.labId lab to place sequences in
 * @param {boolean=} params.noImportCollection
 * @param {boolean=} params.promptForDuplicates
 * @param {string=} params.rnaTypeId
 * @param {boolean=} params.isMaterial
 * @param {string=} params.scaffoldSequence
 * @param {string=} params.scaffoldSequenceId
 * @param {number=} params.scaffoldSequenceStart
 * @param {function=} params.setActiveLab function to set the active lab appropriately
 * @param {any[]=} params.sequenceJsons
 * @param {string[]=} params.sequenceNames names of the sequences. Will take priority over given names of sequences in other formats
 * @param {(string[] | object[])=} params.sequenceTexts pasted sequences, array of strings (genbank, fasta or raw sequences) or teselagen json
 * @param {string=} params.sequenceTypeCode sequence type
 * @param {any=} params.sequenceUpload sequence files
 * @param {object[]=} params.tags tags to apply to sequences and materials
 * @param {boolean=} params.treatAllSeqsAsLinear
 * @param {boolean=} params.treatFastaAsCircular
 */
export default async function uploadDnaSequences(
  {
    allowDuplicates = false,
    isFileUpload = false,
    isGenomicRegionUpload = false,
    isGuideRNA = false,
    isRNA = false,
    noImportCollection = false,
    promptForDuplicates = false,
    rnaTypeId,
    isMaterial = false,
    scaffoldSequence,
    scaffoldSequenceId,
    scaffoldSequenceStart,
    sequenceJsons = undefined,
    sequenceNames = undefined,
    sequenceTexts = [],
    sequenceTypeCode, //optional (used as legacy way to import and force the sequence type)
    sequenceUpload = undefined,
    tags = [],
    treatAllSeqsAsLinear = false, //Not sure why but this is the preferred way for design.
    treatFastaAsCircular = false //this is the preferred way to set a default sequence type
  },
  ctx = isoContext
) {
  const seqCidToIdMap = {};
  const { safeUpsert, startImportCollection } = ctx;
  const sequenceMaterials = [];
  const sequenceUpdates = [];
  const existingMaterialsToTag = [];
  let sequences = [];
  const warnings = [];

  const buildSpecificFields = `  polynucleotideMaterialId polynucleotideMaterial { id ${taggedItems} }`;
  const isBuild = getIsBuild();
  treatAllSeqsAsLinear = isNil(treatAllSeqsAsLinear)
    ? !isBuild
    : treatAllSeqsAsLinear;
  const newSequenceFragment = `id cid name labId circular hash ${taggedItems} aliases { id name } ${
    isBuild ? buildSpecificFields : ""
  }`;

  if (isGenomicRegionUpload) {
    sequenceTypeCode = "LINEAR_DNA";
  }
  if (isRNA) {
    sequenceTypeCode = "RNA";
  }
  if (isFileUpload) {
    //tnw: these sequences might include a linked material
    sequences = await filterSequenceUploads(
      {
        allSequenceFiles: [...sequenceUpload],
        warnings,
        isRNA,
        isMaterial,
        isGenomicRegionUpload,
        sequenceTypeCode,
        isGuideRNA
      },
      ctx
    );
  } else if (sequenceJsons) {
    sequences = map(sequenceJsons, s => sequenceJSONtoGraphQLInput(s));
  } else {
    if (!sequenceTexts.length) {
      throw new Error("No sequence text provided.");
    }

    if (sequenceNames && sequenceTexts.length !== sequenceNames.length) {
      throw new Error(
        "Number of sequence names must match the number of sequences."
      );
    }

    sequences = await Promise.map(sequenceTexts, (sequenceText, idx) => {
      const sequenceName = sequenceNames && sequenceNames[idx]; // If sequenceNames not provided it will obtain from sequenceText (can be teselagen json, genbank, fasta)
      return parseSequenceText(sequenceText, sequenceName, {
        defaultToCircular:
          sequenceTypeCode === "CIRCULAR_DNA" || treatFastaAsCircular,
        sequenceTypeCode
      });
    });
    sequences = flatMap(sequences);

    if (sequences.messages) {
      sequences.messages.forEach(message => {
        if (message && message.includes("Illegal character")) {
          throw new Error("Upload contained an invalid DNA Sequence");
        }
      });
    }
  }

  if (!sequences.length) {
    throw new Error("No sequence files found.");
  }
  if (isGenomicRegionUpload) {
    sequences.forEach(sequence => {
      sequence.circular = false;
      sequence.sequenceTypeCode = "GENOMIC_REGION";
    });
  }

  const notDNASequence = sequences.find(
    sequence =>
      !guessIfSequenceIsDnaAndNotProtein(getSequence(sequence) || "", {
        loose: true
      })
  );
  if (notDNASequence) {
    throw new Error(
      notDNASequence.name +
        " is a protein sequence. Please provide valid DNA Sequences"
    );
  }

  const {
    uniqueInputSequences = [],
    duplicateSequencesFound = [],
    duplicatesOfInputSequences = [],
    allInputSequencesWithAttachedDuplicates = [],
    handleUpserts
  } = allowDuplicates
    ? //if we're allowing duplicates, then we'll consider all the input sequences as unique
      { uniqueInputSequences: sequences }
    : await checkDuplicateSequencesExtended(
        sequences,
        {
          fragment: newSequenceFragment,
          treatAllSeqsAsLinear,
          isGenomicRegionUpload,
          waitToUpsert: promptForDuplicates
        },
        ctx
      );
  let sequencesToContinueWith = allInputSequencesWithAttachedDuplicates;

  if (promptForDuplicates) {
    const seqsWithDups = allInputSequencesWithAttachedDuplicates.filter(
      sequence => sequence.duplicateFound
    );
    sequencesToContinueWith = allInputSequencesWithAttachedDuplicates.filter(
      sequence => !sequence.duplicateFound
    );
    // promptForDuplicates delays the upsert so that we can choose which duplicates to keep
    // and which would be new sequences
    if (seqsWithDups.length) {
      const duplicatesDialog =
        // eslint-disable-next-line import/no-restricted-paths
        require("../../../client/src-shared/components/Dialogs/DuplicatesDialog").default;
      const duplicatesToImport = await duplicatesDialog({
        dialogProps: {
          title: `Choose Duplicate Sequences to Import`,
          style: {
            width: 650
          }
        },
        message:
          "Choose which duplicate sequences you would like to import. Duplicates that are not chosen will be aliased on existing sequences.",
        schema: [
          {
            displayName: "Sequence Name",
            path: "name",
            width: 120
          },
          {
            displayName: "Matches",
            path: "duplicateFound.name",
            width: 120
          },
          {
            displayName: "Match Type",
            width: 120,
            render: (v, r) => {
              return r.duplicateFound.id
                ? "Sequence in DB"
                : "Sequence in Import";
            }
          }
        ],
        entities: seqsWithDups
      });
      if (duplicatesToImport.userCancelled)
        return {
          userCancelled: true
        };
      const cleanedNewDups = duplicatesToImport.map(d => {
        // by removing duplicateFound these sequences will be treated as new
        delete d.duplicateFound;
        if (d.duplicateOfInputSeq) {
          delete d.duplicateOfInputSeq;
          // remove the duplicate from the list input seq duplicates
          duplicatesOfInputSequences.splice(
            duplicatesOfInputSequences.indexOf(d),
            1
          );
        }
        return d;
      });
      seqsWithDups.forEach(sequence => {
        if (!duplicatesToImport.includes(sequence)) {
          // we still want to continue with these for alias creation logic
          // but they will not be created as new
          sequencesToContinueWith.push(sequence);
        }
      });
      sequencesToContinueWith = sequencesToContinueWith.concat(cleanedNewDups);
      // put them onto unique sequences so that they will be upserted
      uniqueInputSequences.push(...cleanedNewDups);
      await handleUpserts(sequencesToContinueWith);
    } else {
      await handleUpserts(sequencesToContinueWith);
    }
  }

  // tnw: add logic here to check if a new material exists on the sequence that we need to make
  // push them onto the sequenceMaterials array so that they will be created instead of the default material creation
  let createMaterials = true;
  sequencesToContinueWith.forEach(sequence => {
    if (sequence.polynucleotideMaterial) {
      createMaterials = false;
    }
  });

  if (isBuild && !isGenomicRegionUpload) {
    sequencesToContinueWith.forEach(sequence => {
      if (sequence.duplicateFound && sequence.duplicateFound.id) {
        const dupS = sequence.duplicateFound;

        // create a material for the existing sequence if it doesnt have one
        if (!dupS.polynucleotideMaterialId) {
          const cid = shortid();
          sequenceMaterials.push({
            cid,
            name: dupS.name,
            ...getMaterialFields(isRNA)
          });
          sequenceUpdates.push({
            id: dupS.id,
            polynucleotideMaterialId: `&${cid}`
          });
        } else {
          existingMaterialsToTag.push(dupS.polynucleotideMaterial);
        }
      }
    });
  }

  const tagSequenceHelper = [];
  const sequencesToCreate = await Promise.all(
    uniqueInputSequences.map(async sequence => {
      if (sequence.tags) {
        // this will handle tags from the api. A little more loose form
        if (!sequence.cid) sequence.cid = shortid();
        tagSequenceHelper.push({
          id: `&${sequence.cid}`,
          tags: sequence.tags
        });
        delete sequence.tags;
      }
      if (isBuild && !isGenomicRegionUpload && createMaterials) {
        const cid = shortid();
        sequenceMaterials.push({
          cid,
          name: sequence.polynucleotideMaterial?.name || sequence.name,
          ...getMaterialFields(isRNA)
        });
        sequence.polynucleotideMaterialId = `&${cid}`;
      }
      if (isRNA) {
        sequence.rnaTypeId = rnaTypeId;
        if (isGuideRNA && !isFileUpload) {
          if (scaffoldSequenceId) {
            sequence.scaffoldSequenceId = scaffoldSequenceId;
          } else {
            const [scaffoldSequenceExists] = await ctx.safeQuery(
              ["scaffoldSequence", "id"],
              {
                variables: {
                  filter: { sequence: scaffoldSequence }
                }
              }
            );
            if (scaffoldSequenceExists) {
              sequence.scaffoldSequenceId = scaffoldSequenceExists.id;
            } else {
              sequence.scaffoldSequence = {
                sequence: scaffoldSequence
              };
            }
          }
          sequence.scaffoldSequenceStart = scaffoldSequenceStart;
        }
      }
      return sequence;
    })
  );

  let createdMaterials = [];
  let importCollectionName;
  if (isGenomicRegionUpload) {
    importCollectionName = "Genomic Region Upload";
  } else if (isRNA) {
    importCollectionName = "RNA Sequence Upload";
  } else {
    importCollectionName = "DNA Sequence Upload";
  }
  let importCollectionId;
  if (startImportCollection && !noImportCollection) {
    const r = await startImportCollection(importCollectionName);
    importCollectionId = r.importCollectionId;
  }

  if (isBuild && createMaterials) {
    createdMaterials = await safeUpsert(
      ["material", "id name"],
      addTaggedItemsBeforeCreate(sequenceMaterials, tags)
    );
  }

  const updatedSequences = await safeUpsert(
    ["sequence", `id ${taggedItems}`],
    sequenceUpdates
  );
  const _createdSequences = await safeUpsert(
    "sequence",
    addTaggedItemsBeforeCreate(sequencesToCreate, tags)
  );

  if (isBuild) {
    // Get back all of the upserted sequence ids (and pre-existing duplicates)
    const allSequences = duplicateSequencesFound.concat(_createdSequences);
    const allSequenceIds = allSequences.map(seq => seq.id);
    await processSequences(allSequenceIds, ctx);
  }

  if (!isEmpty(tags)) {
    const itemsToTag = [updatedSequences, existingMaterialsToTag];
    for (const itemArray of itemsToTag) {
      await createTaggedItems(
        {
          selectedTags: tags,
          records: itemArray
        },
        ctx
      );
    }
  }
  if (tagSequenceHelper.length) {
    await updateTagsOnExternalRecords(
      {
        records: tagSequenceHelper,
        model: "sequence"
      },
      ctx
    );
  }

  const createdSequences = await ctx.safeQuery(
    ["sequence", newSequenceFragment],
    {
      variables: {
        filter: {
          id: _createdSequences.map(s => s.id)
        }
      }
    }
  );
  forEach(createdSequences, s => {
    seqCidToIdMap[s.cid] = s.id;
  });

  forEach(allInputSequencesWithAttachedDuplicates, s => {
    if (s.duplicateFound && s.duplicateFound.id) {
      //case where seq was a duplicate of an existing sequence
      //map seq cid to existing duplicate seq's id
      seqCidToIdMap[s.cid] = s.duplicateFound.id;
    } else if (s.duplicateFound && s.duplicateFound.cid) {
      //case where seq was a duplicate of a different input sequence
      //map seq cid to newly created input seq's id
      seqCidToIdMap[s.cid] = seqCidToIdMap[s.duplicateFound.cid];
    }
  });
  const duplicateSequences = (sequencesToContinueWith || [])
    .filter(seq => seq.duplicateFound && seq.duplicateFound.id)
    .map(s => {
      return {
        ...pick(s, "name", "size", "circular", "duplicateFound"),
        id: s.duplicateFound.id
      };
    });

  const allSeqIds = [
    ...createdSequences.map(seq => seq.id),
    ...duplicateSequences.map(seq => seq.id)
  ];
  return {
    allSeqIds,
    seqCidToIdMap,
    importCollectionId,
    createdMaterials,
    warnings,
    createdSequences,
    existingSequences: duplicateSequencesFound,
    duplicateSequences,
    duplicatesOfInputSequences
  };
}
