/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniqBy, identity } from "lodash";
import getActiveLabId, {
  COMMON_LAB_ID
} from "../../../tg-iso-shared/src/utils/getActiveLabId";
import getActiveProjectId, {
  ALL_PROJECTS_CID,
  UNASSIGNED_PROJECT_ID
} from "../../../tg-iso-shared/src/utils/getActiveProjectId";
import appGlobals from "../appGlobals";
import { labModels } from "../../../tg-iso-shared/constants";
import { isAdmin } from "./generalUtils";
import { disabledProjectsLocalStorageKey } from "./useDisabledProjects";

export const lastSelectedProjectKey = "lastSelectedProjectId";
export const projectStorageKey = "activeProjectId";

export { ALL_PROJECTS_CID, UNASSIGNED_PROJECT_ID };

export function changeActiveProject(activeProjectId) {
  const oldActive = getActiveProjectId({ returnUnassigned: true });
  if (activeProjectId === oldActive) return;
  window.localStorage.setItem(lastSelectedProjectKey, activeProjectId);
  window.sessionStorage.setItem(projectStorageKey, activeProjectId);
  window.allowProjectChange = true;
  // do a full page refresh to stop weirdness from having mismatched projects.
  // The sessionStorage project will be automatically set to this selected project when the app is reloaded
  if (appGlobals.activeRecordId) {
    let newUrl = "/";
    if (window.frontEndConfig.tgModuleName) {
      newUrl = `/${window.frontEndConfig.tgModuleName}/client`;
    }
    // if there is an active record open then go to the home page so it does not auto switch lab on reload
    window.location.href = newUrl;
  } else {
    window.location.reload();
  }
}

export const getUserProjects = (currentUser, _activeLabId) => {
  const { projectRoles = [] } = currentUser || appGlobals.currentUser;
  let labToCompare;
  if (_activeLabId) {
    labToCompare = _activeLabId === COMMON_LAB_ID ? null : _activeLabId;
  } else {
    labToCompare = getActiveLabId();
  }
  return [
    {
      name: "Unassigned",
      id: UNASSIGNED_PROJECT_ID
    },
    // a user may be both admin and member to a lab, so a uniqBy project.id is needed here
    // TODO: also probably prohibit a user from being both admin and member of a project (check if this also happens for labs)
    ...uniqBy(
      projectRoles
        .map(({ project }) => project)
        .filter(identity)
        .filter(p => p.labId === labToCompare)
        .sort((a, b) => (a.name || "").localeCompare(b.name || "")),
      project => project.id
    )
  ];
};

export function unassignedProjectFilter(qb, model) {
  return {
    id: qb.notRelated(`projectItem.${model}Id`).whereAll({
      [`${model}Id`]: qb.notNull()
    })
  };
}

export function addActiveProjectFilter(qb, options = {}) {
  const disabledProjects = window.localStorage.getItem(
    disabledProjectsLocalStorageKey
  );
  // admins can disable the project filter to be able to see all items across all projects
  if (isAdmin() && disabledProjects && disabledProjects !== "false") {
    return;
  }
  const { pathToProjectId = "projectItems.projectId", model } = options;

  const activeProjectId = getActiveProjectId({ returnUnassigned: true });
  const allProjectsTagId = window.frontEndConfig.allProjectsTagId;
  if (activeProjectId === UNASSIGNED_PROJECT_ID) {
    // query for all items that do not belong to a project
    // the not null is needed for the sql to work

    const unassignedProjectFilter = qb
      .notRelated(`projectItem.${model}Id`)
      .whereAll({
        [`${model}Id`]: qb.notNull()
      });

    const anyFilters = [
      {
        // Views do not have a reference to the ProjectItems table,
        // so the projectItems subquery needs to be done with the view's nest model.
        id: options.isUsingView
          ? qb.related(`${model}.id`, false).whereAll({
              id: unassignedProjectFilter
            })
          : unassignedProjectFilter
      }
    ];
    // all project tag will show up when unassigned is selected or when any project is selected
    if (allProjectsTagId) {
      anyFilters.push({
        [pathToProjectId]: allProjectsTagId
      });
    }
    qb.whereAny(...anyFilters);
  } else if (activeProjectId && activeProjectId !== ALL_PROJECTS_CID) {
    const filters = [
      {
        [pathToProjectId]: activeProjectId
      }
    ];
    if (allProjectsTagId) {
      filters.push({
        [pathToProjectId]: allProjectsTagId
      });
    }
    qb.whereAny(...filters);
  }
}

export function shouldShowProjects(model) {
  return (
    window.frontEndConfig.projectTags &&
    labModels.includes(model) &&
    model !== "project"
  );
}
