/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { showProgressToast } from "@teselagen/ui";
import { generateQuery } from "@teselagen/apollo-methods";
import papaparse from "papaparse";

import { compose } from "redux";
import QueryBuilder from "tg-client-query-builder";
import j5ReportFragment from "../../graphql/fragments/j5ReportFragment";
import { get, merge } from "lodash";
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import uuid from "uuid";
import defaultAsyncWrap from "../../../src-shared/utils/defaultAsyncWrap";
import j5RunConstructFragment from "../../graphql/fragments/j5RunConstructFragment";
import j5InputSequenceFragment from "../../graphql/fragments/j5InputSequenceFragment";
import j5PcrReactionFragment from "../../graphql/fragments/j5PcrReactionFragment";
import j5AssemblyPieceFragment from "../../../src-shared/graphql/fragments/j5AssemblyPieceFragment.gql";
import j5DirectSynthesisFragment from "../../graphql/fragments/j5DirectSynthesisFragment";
import { j5OligoSynthesisFragment } from "../../../src-shared/fragments/j5OligoSynthesisFragment";
import j5OligoDownloadFragment from "../../graphql/fragments/j5OligoDownloadFragment";
import {
  safeUpsert,
  safeQuery,
  useTgQuery
} from "../../../src-shared/apolloMethods";
import exportOligosFields from "./exportOligosFields";
import client from "../../../src-shared/apolloClient";
import SharedJ5ReportRecordView from "../../../src-shared/SharedJ5ReportRecordView";
import {
  chunkSequenceToFragments,
  getSequenceSequence,
  getSequenceOfPart
} from "../../../../tg-iso-shared/src/sequence-import-utils/utils";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import "./style.css";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { openInNewTab } from "../../../src-shared/utils/generalUtils";
import getRequestHeaderKeys from "../../../../tg-iso-shared/src/utils/getRequestHeaderKeys";
import { download } from "../../../src-shared/utils/downloadTest";
import shortid from "shortid";
import j5AnnealedOligoFragment from "../../graphql/fragments/j5AnnealedOligoFragment";
import SaveJ5SeqsToSequenceDialog from "../Dialogs/SaveJ5SeqsToSequenceDialog";
import saveStreamResponse from "../../../src-shared/utils/saveStreamResponse";
import recordViewEnhancer from "../../../src-shared/recordViewEnhancer";
import withQuery from "../../../src-shared/withQuery";
import integrationOptionsFragment from "../../../src-shared/fragments/integrationOptionsFragment";
import { showStackedDialog } from "../../../src-shared/StackedDialog";
import ExportToExternalDbDialog from "../../../src-shared/ExternalIntegrations/ExportToExternalDbDialog";
import { withProps } from "recompose";
import j5InputPartFragment from "../../graphql/fragments/j5InputPartFragment";

class J5ReportRecordView extends React.Component {
  state = { updatingJSON: false };

  componentDidMount() {
    const { j5Report } = this.props;
    this.uniqueID = uuid();
    //checks if microservice missed cancel and upserts ok status
    if (j5Report && j5Report.jsonUpdateProgress === "cancel") {
      safeUpsert("j5Report", {
        id: j5Report.id,
        jsonUpdateProgress: "0_Starting Update"
      });
      this.setState({ updatingJSON: false });
    }

    //starts polling for j5report json update status if it is updating
    if (
      j5Report &&
      j5Report.jsonUpdateProgress !== "100_Ready for Download" &&
      j5Report.jsonUpdateProgress !== "0_Starting Update" &&
      j5Report.jsonUpdateProgress !== "cancel" &&
      j5Report.jsonUpdateProgress !== null
    ) {
      const query = generateQuery(["j5Report", "id jsonUpdateProgress"]);
      this.observableQuery = client.watchQuery({
        query,
        pollInterval: 500,
        variables: {
          id: j5Report.id
        }
      });
      this.observableQuery.subscribe({
        next: ({ data }) => {
          const progress = data.j5Report.jsonUpdateProgress;
          const splitProgress = progress
            ? progress.split("_")
            : [0, "Starting Update"];
          showProgressToast(
            splitProgress[1],
            parseInt(splitProgress[0], 10) / 100,
            this.uniqueID
          );
          if (data.j5Report.jsonUpdateProgress === "100_Ready for Download") {
            this.observableQuery.stopPolling();
            window.toastr.success(
              `Finished Updating JSON for ${j5Report.name}`
            );
            safeUpsert("j5Report", {
              id: j5Report.id,
              jsonUpdateProgress: "0_Starting Update"
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    //stop polling when user leaves page
    if (this.observableQuery) {
      this.observableQuery.stopPolling();
    }
  }

  handleViewWarningsClick = async () => {
    const { j5Report } = this.props;
    if (!j5Report) return;
    const j5LogMessages = await safeQuery(
      [
        "j5LogMessage",
        "id message j5LogMessageTypeCode j5LogMessagePriorityCode j5LogMessageJoins { id specificMsg }"
      ],
      {
        variables: {
          filter: {
            j5ReportId: j5Report.id
          }
        }
      }
    );

    showDialog({
      modalType: "J5_LOGS",
      modalProps: {
        j5LogMessages: j5LogMessages
      }
    });
  };

  handleExportToJsonClick = async fileName => {
    window.toastr.success("Download is starting");
    const { j5Report } = this.props;
    try {
      await saveStreamResponse({
        url: "/getJ5Json",
        body: {
          j5ReportId: j5Report.id
        },
        filename: fileName || j5Report.name
      });
    } catch (e) {
      console.error(e);
      window.toastr.error("Error retrieving j5 report");
    }
  };

  handleUpdateJsonClick = async () => {
    window.toastr.success("Generating New J5 JSON");
    const { j5Report } = this.props;
    this.setState({ updatingJSON: true });

    try {
      //start polling
      const query = generateQuery(["j5Report", "id jsonUpdateProgress"]);
      if (this.observableQuery) {
        this.observableQuery.stopPolling();
      }
      this.observableQuery = client.watchQuery({
        query,
        pollInterval: 500,
        variables: {
          id: j5Report.id
        }
      });
      this.observableQuery.subscribe({
        next: ({ data }) => {
          const progress = data.j5Report.jsonUpdateProgress;
          const splitProgress = progress
            ? progress.split("_")
            : [0, "Starting Update"];
          this.activeToastr = showProgressToast(
            splitProgress[1],
            parseInt(splitProgress[0], 10) / 100,
            this.uniqueID
          );
          if (data.j5Report.jsonUpdateProgress === "100_Ready for Download") {
            this.observableQuery.stopPolling();
            window.toastr.success(
              `Finished Updating JSON for ${j5Report.name}`
            );
            safeUpsert("j5Report", {
              id: j5Report.id,
              jsonUpdateProgress: "0_Starting Update"
            });
          }
        }
      });

      fetch((window.frontEndConfig.serverBasePath || "") + "/updateJ5Json", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getRequestHeaderKeys()
        },
        body: JSON.stringify({ j5ReportId: j5Report.id })
      }).then(res => {
        this.setState({ updatingJSON: false });
        safeUpsert("j5Report", {
          id: j5Report.id,
          jsonUpdateProgress: "0_Starting Update"
        });
        if (res.status === 500) {
          window.toastr.error(`${j5Report.name} is not finished updating`);
        }
      });
    } catch (error) {
      console.error(error);
      window.toastr.error("Error updating J5 JSON");
      this.setState({ updatingJSON: false });
      safeUpsert("j5Report", {
        id: j5Report.id,
        jsonUpdateProgress: "0_Starting Update"
      });
    }
  };

  handleCancelJsonClick = async () => {
    const { j5Report } = this.props;
    try {
      fetch((window.frontEndConfig.serverBasePath || "") + "/cancelJ5Json", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...getRequestHeaderKeys()
        },
        body: JSON.stringify({ j5ReportId: j5Report.id })
      }).then(() => {
        this.setState({ updatingJSON: false });
        this.observableQuery && this.observableQuery.stopPolling();
        this.activeToastr && this.activeToastr();
      });
    } catch (error) {
      this.setState({ updatingJSON: false });
      console.error(error);
      window.toastr.error("Error canceling JSON update");
    }
  };

  handleExportToCsvClick = async fileName => {
    window.toastr.success("Download is starting");
    const { j5Report } = this.props;
    try {
      await saveStreamResponse({
        url: "/getJ5Csv",
        body: {
          j5ReportId: j5Report.id
        },
        filename: fileName || j5Report.name
      });
      window.toastr.success("File downloaded");
    } catch (error) {
      console.error(error);
      window.toastr.error("Error retrieving j5 report");
    }
  };

  handleExportOligosToCsvClick = async () => {
    window.toastr.success("Download is starting");
    const { j5Report } = this.props;

    const j5OligoSyntheses = await safeQuery(j5OligoDownloadFragment, {
      variables: {
        filter: {
          j5ReportId: j5Report.id
        }
      }
    });

    const csvString = await papaparse.unparse([
      ["OligoSynthesis"],
      exportOligosFields.map(field => field.displayName),
      ...j5OligoSyntheses.map(j5Oligo =>
        exportOligosFields.map(field =>
          field.render
            ? field.render(get(j5Oligo, field.path))
            : get(j5Oligo, field.path)
        )
      )
    ]);
    download(csvString, `Oligo_Synthesis_${j5Report.name}.csv`);
  };

  handleEmbedInVectorClick = () => {
    const { j5Report, refetchJ5Report, refetchJ5ReportLinkages } = this.props;
    showDialog({
      modalType: "PART_LIBRARY",
      modalProps: {
        title: "Choose Part for PCR Products Sequencing Vector",
        isSingleSelect: true,
        onSubmit: defaultAsyncWrap(async ([part]) => {
          const indexToPartCid = j5Report.j5PcrReactions.map(() => shortid());
          const indexToApCid = j5Report.j5PcrReactions.map(() => shortid());
          const indexToInputPartCid = j5Report.j5PcrReactions.map(() =>
            shortid()
          );
          const bbApCid = shortid();
          const bbInputPartCid = shortid();

          const backboneSeq = await safeQuery(
            [
              "sequence",
              "id size circular sequenceFragments {id index fragment}"
            ],
            { variables: { id: part.sequence.id } }
          );
          const backboneSeqStr = getSequenceOfPart(
            part,
            getSequenceSequence(backboneSeq)
          );
          await safeUpsert(
            "part",
            j5Report.j5PcrReactions.map((pcr, i) => ({
              cid: indexToPartCid[i],
              name: "pcr_" + pcr.id,
              sequenceId: pcr.pcrProductSequence.id,
              start: 0,
              end: pcr.pcrProductSequence.size - 1,
              strand: 1
            }))
          );

          const newJ5ReportCid = shortid();
          await safeUpsert("j5Report", {
            cid: newJ5ReportCid,
            isCombinatorial: j5Report.j5PcrReactions.length > 1,
            assemblyBatchId: j5Report.assemblyBatchId,
            name: j5Report.name + " - PCR Fragment Subcloning",
            designId: j5Report.design.id,
            assemblyMethod: "PCR Fragment Subcloning",
            partId: part.id,
            j5InputSequences: [
              {
                sequenceId: part.sequence.id,
                j5InputParts: [
                  {
                    cid: bbInputPartCid,
                    partId: part.id
                  }
                ]
              },
              ...j5Report.j5PcrReactions.map((pcr, i) => ({
                sequenceId: pcr.pcrProductSequence.id,
                j5InputParts: [
                  {
                    cid: indexToInputPartCid[i],
                    partId: "&" + indexToPartCid[i]
                  }
                ]
              }))
            ]
          });

          await safeUpsert("j5AssemblyPiece", [
            {
              cid: bbApCid,
              j5ReportId: "&" + newJ5ReportCid,
              name: part.name,
              type: "",
              ...(part.start === 0 &&
              part.end === backboneSeq.size - 1 &&
              part.strand === 1
                ? { sequenceId: backboneSeq.id }
                : {
                    sequence: {
                      circular: backboneSeq.circular,
                      isJ5Sequence: true,
                      isInLibrary: false,
                      name: "Sequencing Vector",
                      description: "",
                      sequenceFragments:
                        chunkSequenceToFragments(backboneSeqStr),
                      size: backboneSeqStr.length
                    }
                  }),
              j5AssemblyPieceParts: [
                {
                  j5InputPartId: "&" + bbInputPartCid
                }
              ]
            },
            ...j5Report.j5PcrReactions.map((pcr, i) => ({
              cid: indexToApCid[i],
              j5ReportId: "&" + newJ5ReportCid,
              name: `pcr_${pcr.id} Sequencing Intermediate`,
              type: "",
              sequenceId: pcr.pcrProductSequence.id,
              j5AssemblyPieceParts: [
                {
                  j5InputPartId: "&" + indexToInputPartCid[i]
                }
              ]
            }))
          ]);

          await safeUpsert(
            "j5RunConstruct",
            j5Report.j5PcrReactions.map((pcr, i) => {
              const seqStr =
                backboneSeqStr + getSequenceSequence(pcr.pcrProductSequence);
              return {
                j5ReportId: "&" + newJ5ReportCid,
                name: `pcr_${pcr.id} Sequencing Intermediate`,
                index: i,
                sequence: {
                  circular: true,
                  isJ5Sequence: true,
                  isInLibrary: false,
                  name: `pcr_${pcr.id} Sequencing Intermediate`,
                  description: "",
                  sequenceFragments: chunkSequenceToFragments(seqStr),
                  size: seqStr.length
                },
                j5ConstructAssemblyPieces: [
                  {
                    index: 0,
                    assemblyPieceId: "&" + bbApCid
                  },
                  {
                    index: 1,
                    assemblyPieceId: "&" + indexToApCid[i]
                  }
                ]
              };
            })
          );

          await safeUpsert("j5Report", { id: j5Report.id, partId: part.id });

          await safeUpsert("j5ReportLinkage", {
            mainJ5ReportId: j5Report.id,
            sequencingJ5ReportId: "&" + newJ5ReportCid
          });

          await refetchJ5Report();
          await refetchJ5ReportLinkages();

          window.toastr.success(
            "Successfully created report for PCR fragment subcloning."
          );
        }, "Error embedding in vector.")
      }
    });
  };

  /**
   * See if the design is combinatorial. Unfortunately, this information isn't available
   * as a single field on the report, so we use the number of constructs as a proxy.
   * @returns {boolean}
   */
  isCombinatorial() {
    const { j5Report } = this.props;
    if (!j5Report) return false;
    return !!j5Report.isCombinatorial;
  }

  isGoldenGate = () => {
    const { j5Report } = this.props;
    if (!j5Report) return false;
    return j5Report.assemblyMethod === "GoldenGate";
  };

  isGibson() {
    const { j5Report } = this.props;
    if (!j5Report) return false;
    return j5Report.assemblyMethod === "SLIC/Gibson/CPEC";
  }

  isMainReport = () => {
    const { j5ReportLinkages = [] } = this.props;
    const id = this.props.id || get(this.props, "match.params.id");
    return (
      !j5ReportLinkages.length ||
      j5ReportLinkages.some(l => l.mainJ5ReportId === id)
    );
  };

  getSequencingReportId = () => {
    const { j5ReportLinkages = [] } = this.props;

    if (!this.isMainReport()) return false;
    return get(
      j5ReportLinkages.find(l => l.sequencingJ5ReportId),
      "sequencingJ5ReportId"
    );
  };

  handleGoToOriginalJ5ReportClick = () => {
    const { j5ReportLinkages = [], history } = this.props;
    const link = j5ReportLinkages[0];
    if (!link)
      return window.toastr.error("No linkage to main j5 report found.");
    history.push(`/assembly-reports/${link.mainJ5ReportId}`);
  };

  handleGoToSequencingReport = () => {
    const { history } = this.props;
    history.push(`/assembly-reports/${this.getSequencingReportId()}`);
  };

  //titleElements get construct and oligos from props
  //construct and oligos are loaded into props from withQuerys in J5ReportContainer

  /**
   * Get the fields to add to the assembly piece schema to show the information
   * about the overhangs/overlaps.
   */
  getOverhangFields() {
    if (!this.isGoldenGate() && !this.isGibson()) return [];
    const over = this.isGoldenGate() ? "Overhang" : "Overlap";
    return [
      this.isGoldenGate() && {
        path: "overhangWithPrevious",
        type: "string",
        displayName: `${over} w/ Previous`
      },
      this.isGoldenGate() && {
        path: "overhangWithPrevious",
        type: "number",
        displayName: `${over} w/ Previous Length`,
        render: over => (over ? over.length : "n/a")
      },
      {
        path: "overhangWithNext",
        type: "string",
        displayName: `${over} w/ Next`
      },
      {
        path: "overhangWithNext",
        type: "number",
        width: 100,
        displayName: `${over} w/ Next Length`,
        render: over => (over ? over.length : "n/a")
      },
      {
        path: "relativeOverhang",
        type: "number",
        displayName: `Relative ${over} Position`
      }
    ].filter(x => x);
  }
  handleRenameClickPrevVersions = record => {
    showDialog({
      modalType: "GENERIC_RENAME",
      modalProps: { record }
    });
  };

  handleRenameClick = record => {
    const { j5Report } = this.props;

    showDialog({
      modalType: "GENERIC_RENAME",
      modalProps: {
        record,
        onSubmit: async ({ name }) => {
          let sequenceId, sequenceCid;
          const splitRecordId = record.id.split("_");
          const recordId = splitRecordId[splitRecordId.length - 1];
          const namingOverrides = { output: {} };

          if (record.oligo) {
            sequenceId = record.oligo.sequence.id;
            sequenceCid = record.oligo.sequence.cid;
            await safeUpsert(["j5Oligo", "id name"], {
              id: record.oligo.id,
              name
            });
            const oligoCid = record.oligo.cid;
            namingOverrides.output.j5Oligo = {
              [oligoCid.slice(
                0,
                oligoCid.indexOf("_@") // slice off the import cid
              )]: { name }
            };
          } else if (record.sequence) {
            sequenceId = record.sequence.id;
            sequenceCid = record.sequence.cid;
          }

          if (sequenceId) {
            await safeUpsert("sequence", {
              id: sequenceId,
              name
            });
            namingOverrides.output.sequence = {
              [sequenceCid.slice(0, sequenceCid.indexOf("_@"))]: { name }
            };
          }

          // top level filter on sequences requires us to nest our query to update fields
          let frag = "id name";
          if (sequenceId) {
            frag = "id name sequence { id name }";
          }
          if (record.oligo) {
            frag = "id name oligo { id sequence { id name } }";
          }
          await safeUpsert([record.__typename, frag], {
            id: recordId,
            name
          });
          namingOverrides.output[record.__typename] = {
            [record.cid.slice(0, record.cid.indexOf("_@"))]: { name }
          };
          const upToDateJ5Report = await safeQuery(
            ["j5Report", "id namingOverrides"],
            {
              variables: {
                filter: {
                  id: j5Report.id
                }
              }
            }
          );

          const existingNamingOverrides = upToDateJ5Report[0].namingOverrides;
          merge(namingOverrides, existingNamingOverrides);

          await safeUpsert("j5Report", {
            id: j5Report.id,
            namingOverrides
          });

          window.toastr.success(`Changed Name To ${name}`);
        }
      }
    });
  };

  getExternalExportButton = (fn, integrations) => {
    const exportIntegrations = integrations.filter(
      integration => integration.integrationTypeCode === "EXPORT"
    );
    if (!exportIntegrations.length) return undefined;
    return (
      <Popover
        key="exportToExternalDb"
        content={
          <Menu>
            {exportIntegrations.map((integration, i) => {
              return (
                <MenuItem
                  key={`exportExternal-${i}`}
                  text={integration.name}
                  onClick={() => fn(integration)}
                ></MenuItem>
              );
            })}
          </Menu>
        }
      >
        <Button style={{ marginLeft: 10 }} text="Export to External DB" />
      </Popover>
    );
  };

  saveableSeqsTitleEls = ({
    isAnnealedOligos,
    isj5AssemblyPieces,
    isSynthonSequences,
    isOligos,
    model,
    refetchSeqTable
  } = {}) => {
    const { j5Report } = this.props;

    const j5ReportId = this.props.j5Report.id;
    const refetchHelper = {};
    function showSaveDialog(extra) {
      showDialog({
        ModalComponent: SaveJ5SeqsToSequenceDialog,
        modalProps: {
          isOligos,
          isAnnealedOligos,
          isj5AssemblyPieces,
          isSynthonSequences,
          j5ReportIds: j5ReportId,
          afterSave: () => {
            refetchSeqTable();
            refetchHelper.refetch?.();
          },
          ...extra
        }
      });
    }
    const qb = new QueryBuilder(model);
    const buttons = [
      <SaveToSeqLibBtn
        {...{
          unsavedSeqsFilter: qb
            .whereAll({
              j5ReportId,
              [`${isOligos ? "oligo." : ""}sequence.isInLibrary`]: false
            })
            .toJSON(),
          model,
          refetchHelper,
          isOligos,
          showSaveDialog
        }}
        key="saveToSeqLib"
      ></SaveToSeqLibBtn>
    ];
    if (!isSynthonSequences) {
      buttons.push(
        this.getExternalExportButton(
          async integration => {
            const getSavedSequences = async () => {
              let itemsWithSequence;
              if (isOligos) {
                itemsWithSequence = await safeQuery(
                  [
                    model,
                    "id oligo { id name sequenceId sequence { id isInLibrary } } "
                  ],
                  {
                    isPlural: true,
                    variables: {
                      filter: { j5ReportId: j5Report.id }
                    }
                  }
                );
                itemsWithSequence = itemsWithSequence.map(item => item.oligo);
              } else {
                itemsWithSequence = await safeQuery(
                  [model, "id name sequenceId sequence { id isInLibrary } "],
                  {
                    isPlural: true,
                    variables: {
                      filter: { j5ReportId: j5Report.id }
                    }
                  }
                );
              }
              const savedSequences = itemsWithSequence
                .filter(item => item?.sequence?.isInLibrary)
                .map(item => item.sequence);
              return {
                savedSequences,
                allSaved: itemsWithSequence.length === savedSequences.length
              };
            };

            const { savedSequences, allSaved } = await getSavedSequences();

            const handleDialog = records => {
              if (records.length) {
                showStackedDialog({
                  ModalComponent: ExportToExternalDbDialog,
                  modalProps: {
                    model: "sequence",
                    records,
                    integrationId: integration.id,
                    dialogProps: {
                      title: `Export - ${integration.name}`
                    }
                  }
                });
              }
            };
            if (allSaved) {
              handleDialog(savedSequences);
            } else {
              showSaveDialog({
                afterSave: async () => {
                  await refetchSeqTable();
                  const { savedSequences } = await getSavedSequences();
                  handleDialog(savedSequences);
                }
              });
            }
          },
          isOligos
            ? this.props.oligoIntegrations
            : this.props.sequenceIntegrations
        )
      );
    }

    return buttons;
  };

  render() {
    const { j5Report } = this.props;

    return (
      <div className="hde-j5-report-container j5-report-container">
        <SharedJ5ReportRecordView
          {...{
            ...this.props,
            onExportAsCsvClick: this.handleExportToCsvClick,
            onExportAsJsonClick: this.handleExportToJsonClick,
            onExportOligosAsCsvClick: this.handleExportOligosToCsvClick,
            replaceOligoPartCids: false,
            pcrReactionsTitleElements:
              this.isGoldenGate() &&
              !!this.isMainReport() &&
              (this.getSequencingReportId() ? (
                `Embedding in ${get(j5Report, "part.name")}`
              ) : (
                <Button
                  key="embedInSequencingVectorButton"
                  text="Embed in Sequencing Vector"
                  onClick={this.handleEmbedInVectorClick}
                />
              )),
            saveableSeqsTitleEls: this.saveableSeqsTitleEls,
            fragmentMap: {
              j5RunConstruct: j5RunConstructFragment,
              j5InputSequence: j5InputSequenceFragment,
              j5InputPart: j5InputPartFragment,
              j5PcrReaction: j5PcrReactionFragment,
              j5OligoSynthesis: j5OligoSynthesisFragment,
              j5AnnealedOligo: j5AnnealedOligoFragment,
              j5AssemblyPiece: j5AssemblyPieceFragment,
              j5DirectSynthesis: j5DirectSynthesisFragment
            },
            additionalHeaderButtons: [
              j5Report && !j5Report.version ? (
                this.state.updatingJSON ? (
                  <Button
                    style={{ marginLeft: 10 }}
                    key="cancelJson"
                    text="Cancel JSON Update"
                    onClick={this.handleCancelJsonClick}
                  />
                ) : (
                  <Button
                    style={{ marginLeft: 10 }}
                    key="updateJson"
                    text="Generate Updated JSON"
                    onClick={this.handleUpdateJsonClick}
                  />
                )
              ) : null,
              <Button
                style={{ marginLeft: 10 }}
                key="viewWarnings"
                text="View Warnings"
                onClick={this.handleViewWarningsClick}
              />,
              !this.isMainReport() && [
                <Button
                  key="goToOriginalReport"
                  text="Go to Original Assembly Report"
                  onClick={this.handleGoToOriginalJ5ReportClick}
                />
              ],
              this.getSequencingReportId() && [
                <Button
                  key="gotoSeqAssemblyReport"
                  text="Go to Sequencing Assembly Report"
                  onClick={this.handleGoToSequencingReport}
                />
              ]
            ],
            dataTableProps: {
              contextMenu: ({ selectedRecords }) => {
                const record = selectedRecords[0];
                const extraMenuItems = [];
                if (
                  selectedRecords.length === 1 &&
                  record.name &&
                  record.__typename
                ) {
                  extraMenuItems.push(
                    <MenuItem
                      key="renameSelectedCell"
                      onClick={() =>
                        j5Report && j5Report.version
                          ? this.handleRenameClick(record)
                          : this.handleRenameClickPrevVersions(record)
                      }
                      icon="edit"
                      text="Rename"
                    />
                  );
                }
                if (
                  selectedRecords.length === 1 &&
                  selectedRecords[0].__typename === "j5RunConstruct"
                ) {
                  extraMenuItems.push(
                    <MenuItem
                      key="openInNewTab"
                      onClick={() =>
                        openInNewTab(
                          modelNameToLink(
                            selectedRecords[0].sequence.__typename,
                            selectedRecords[0].sequence.id,
                            "design"
                          )
                        )
                      }
                      icon="document-share"
                      text="Open construct in a new tab"
                    />
                  );
                }
                return extraMenuItems;
              }
            }
          }}
        />
      </div>
    );
  }
}

export default compose(
  withQuery(integrationOptionsFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      pageSize: 999900,
      variables: {
        filter: {
          integrationTypeCode: ["EXPORT"],
          subtype: "DNA_SEQUENCE"
        }
      }
    }
  }),
  withProps(props => {
    if (props.integrations) {
      return {
        sequenceIntegrations: props.integrations
      };
    }
  }),
  withQuery(integrationOptionsFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      pageSize: 999900,
      variables: {
        filter: {
          integrationTypeCode: ["EXPORT"],
          subtype: "OLIGO"
        }
      }
    }
  }),
  withProps(props => {
    if (props.integrations) {
      return {
        oligoIntegrations: props.integrations
      };
    }
  }),
  recordViewEnhancer(j5ReportFragment),
  withQuery(["j5ReportLinkage", "id mainJ5ReportId sequencingJ5ReportId"], {
    isPlural: true,
    options: props => {
      const qb = new QueryBuilder("j5ReportLinkage");
      const id =
        props.id || props.recordIdOverride || get(props, "match.params.id");
      return {
        variables: {
          filter: qb
            .whereAll({
              mainJ5ReportId: id,
              sequencingJ5ReportId: qb.notNull()
            })
            .orWhereAll({
              sequencingJ5ReportId: id,
              mainJ5ReportId: qb.notNull()
            })
            .toJSON()
        }
      };
    }
  }),
  withQuery(
    ["j5RunConstruct", "id name sequence { id isJ5Sequence isInLibrary }"],
    {
      isPlural: true,
      options: props => {
        const id =
          props.id || props.recordIdOverride || get(props, "match.params.id");
        return {
          variables: {
            filter: {
              j5ReportId: id
            }
          }
        };
      }
    }
  )
)(J5ReportRecordView);

function SaveToSeqLibBtn({
  unsavedSeqsFilter,
  model,
  isOligos,
  refetchHelper,
  showSaveDialog
}) {
  const res = useTgQuery(
    [
      model,
      `id name ${
        isOligos
          ? `oligo {id sequence {id name isInLibrary} }`
          : `sequence {id name isInLibrary}`
      }`
    ],
    {
      variables: { filter: unsavedSeqsFilter, pageSize: 1 }
    }
  );
  refetchHelper.refetch = res.refetch;
  const isResave = !res?.entities?.length;
  return (
    <Button
      loading={res?.loading}
      text={`${isResave ? `Re-` : ""}Save to ${
        isOligos ? "Oligo" : "Sequence"
      } Library`}
      onClick={() => {
        showSaveDialog({ isResave });
      }}
    />
  );
}
