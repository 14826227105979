/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { readAsText } from "promise-file-reader";

import defaultJ5OutputNamingTemplateMap from "../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import {
  isCsvFile,
  parseCsvString
} from "../../../tg-iso-shared/src/utils/fileUtils";

import { computeBinNamesFromFileData } from "./designUtils";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import uploadDnaSequences from "../../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import { isDesign } from "../../../tg-iso-shared/src/utils/isModule";
import { safeQuery } from "../../src-shared/apolloMethods";
import { reduce } from "lodash";

const createDesignMutationsFromCsvGenbank = async files => {
  const csvFile = files.find(isCsvFile);

  const genbankFiles = files.filter(file => file.name.endsWith(".gb"));

  const { allSeqIds } = await uploadDnaSequences({
    isFileUpload: true,
    promptForDuplicates: isDesign(),
    sequenceUpload: genbankFiles
  });
  const parts = await safeQuery(["part", "id name start end strand"], {
    filter: {
      "sequence.id": allSeqIds
    }
  });
  const partNameToId = reduce(
    parts,
    (acc, part) => {
      if (acc[part.name]) {
        throw new Error(
          `Detected more than one part in the uploaded sequences with the name ${part.name}. Please rename and try again.`
        );
      }
      acc[part.name] = part.id;
      return acc;
    },
    {}
  );

  const csvFileData = await readAsText(csvFile.originalFileObj);
  const parsedCsvString = await parseCsvString(csvFileData);
  const binNameToIconCid = {};
  const binNames = computeBinNamesFromFileData(parsedCsvString.data[0]);
  const partNameRows = [];
  parsedCsvString.data.map(row => partNameRows.push(Object.values(row)));

  const partNamesByBin = [];
  let uniqPartNames = [];
  let longestColumn = 0;

  partNameRows.forEach(row => {
    longestColumn++;
    row.forEach((name, i) => {
      partNamesByBin[i] = partNamesByBin[i] || [];
      partNamesByBin[i].push(name);
    });
    uniqPartNames = uniqPartNames.concat(row);
  });

  const binCids = binNames.map(() => shortid());
  const cardCids = binNames.map(() => shortid());

  const designCid = shortid();
  const reactionCid = shortid();

  const refDesign = "&" + designCid;

  const mutations = [
    {
      entity: "design",
      inputs: {
        cid: designCid,
        name: csvFile.name.slice(0, csvFile.name.indexOf(".")),
        type: "grand-design",
        numRows: longestColumn,
        layoutType:
          csvFile.name.indexOf(".list.") > -1 ? "list" : "combinatorial"
      }
    },
    {
      entity: "card",
      inputs: {
        designId: refDesign,
        isRoot: true,
        name: "",
        circular: true,
        binCards: binNames.map((binName, i) => ({
          designId: refDesign,
          index: i,
          bin: {
            designId: refDesign,
            cid: binCids[i],
            direction: binName[0] !== "<",
            iconId: binNameToIconCid[binName] || "&USER-DEFINED",
            name:
              binName[0] === "<" || binName[0] === ">"
                ? binName.slice(1)
                : binName,
            elements: partNamesByBin[i]
              .map((partName, x) => {
                if (partName) {
                  return {
                    partId: partNameToId[partName],
                    name: partName,
                    designId: refDesign,
                    index: x
                  };
                }
                return null;
              })
              .filter(e => e !== null)
          }
        })),
        outputReaction: {
          designId: refDesign,
          cid: reactionCid,
          assemblyMethodId: "&gibson-slic-cpec",
          name: "Gibson/SLIC/CPEC",
          customJ5Parameter: {
            ...getDefaultParamsAsCustomJ5ParamName(),
            isLocalToThisDesignId: refDesign
          },
          reactionJ5OutputNamingTemplates: Object.keys(
            defaultJ5OutputNamingTemplateMap
          ).map(outputTarget => ({
            designId: refDesign,
            j5OutputNamingTemplate: {
              designId: refDesign,
              outputTarget,
              ...defaultJ5OutputNamingTemplateMap[outputTarget]
            }
          })),
          cards: cardCids.map((cardCid, i) => ({
            designId: refDesign,
            inputIndex: i,
            circular: true,
            cid: cardCid,
            name: ""
          }))
        }
      }
    },
    {
      entity: "binCard",
      inputs: cardCids.map((cardCid, i) => ({
        designId: refDesign,
        index: i,
        binId: "&" + binCids[i],
        cardId: "&" + cardCid
      }))
    },
    {
      entity: "junction",
      inputs: binCids.map((binCid, i) => ({
        designId: refDesign,
        junctionTypeCode: "SCARLESS",
        isPhantom: false,
        reactionId: "&" + reactionCid,
        fivePrimeCardId: "&" + cardCids[i],
        fivePrimeCardEndBinId: "&" + binCid,
        fivePrimeCardInteriorBinId: "&" + binCid,
        threePrimeCardId: "&" + cardCids[i % cardCids.length],
        threePrimeCardStartBinId: "&" + binCids[i % binCids.length],
        threePrimeCardInteriorBinId: "&" + binCids[i % binCids.length]
      }))
    }
  ];

  return mutations;
};

export default createDesignMutationsFromCsvGenbank;
