/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { endsWith, mapValues, keyBy } from "lodash";
import appGlobals from "../../appGlobals";
import auth from "../../auth";
import CurrentUserContext from "../../context/CurrentUserContext";
import GlobalDialog from "../../GlobalDialog";
import StackedDialog from "../../StackedDialog";
import MobxModal from "../../MobxModal";
import { MainContent } from "../MainContent";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import { updateFeatureTypeOverrides } from "../../../../tg-iso-shared/src/utils/updateFeatureTypeOverrides";
import userQuery from "../../graphql/queries/userQuery";
import joinUrl from "url-join";
import { initActiveLabId } from "../../utils/labUtils";
import { safeQuery } from "../../apolloMethods";
import client from "../../apolloClient";
import appSettingsFragmentGql from "../../graphql/fragments/appSettingsQuery.gql";
import { withRouter } from "react-router-dom";
import getActiveProjectId from "../../../../tg-iso-shared/src/utils/getActiveProjectId";
import { lastSelectedProjectKey } from "../../utils/projectUtils";

const loadDefaultProject = async () => {
  if (window.frontEndConfig.projectTags) {
    try {
      const lastSelectedProject = localStorage.getItem(lastSelectedProjectKey);
      const currentActiveProjectId = getActiveProjectId({
        returnUnassigned: true
      });

      if (lastSelectedProject === null) {
        const { data } = await window.api.request({
          method: "POST",
          url: "/handle-default-project",
          data: {
            currentActiveProjectId
          }
        });
        if (data.projectId) {
          localStorage.setItem(lastSelectedProjectKey, data.projectId);
        }
      }
    } catch (error) {
      console.error("Error checking default project", error);
    }
  }
};

const loadGlobals = async ({ loggedIn }) => {
  if (loggedIn) {
    try {
      await unitGlobals.init(safeQuery);
      await updateFeatureTypeOverrides({ safeQuery });
    } catch (error) {
      console.error(`error loading globals:`, error);
    }
  }
};

const loadAppSettings = async () => {
  const {
    data: {
      appSettings: { results: appSettings }
    }
  } = await client.query({
    query: appSettingsFragmentGql,
    fetchPolicy: "network-only"
  });
  window.frontEndConfig.appSettings = mapValues(
    keyBy(appSettings, "code"),
    "value"
  );
};

const getCurrentUser = async () => {
  const {
    data: {
      currentUser: [currentUser]
    }
  } = await client.query({
    query: userQuery,
    fetchPolicy: "network-only"
  });

  // set user for segment analytics
  try {
    if (
      currentUser &&
      currentUser.id &&
      window.frontEndConfig.segmentAnalyticsKey
    ) {
      const { id, ...rest } = currentUser;
      window.analytics.identify(id, rest);
    }
  } catch (error) {
    console.error("Error setting user for analytics");
    console.error(error);
  }
  return currentUser;
};

const useData = () => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const token = auth.getAuthToken();
      if (!token) {
        setLoading(false);
        return;
      }
      try {
        const statusUrl = joinUrl(
          window.frontEndConfig.serverBasePath,
          "/auth-status"
        );

        const checkTokenResp = await fetch(statusUrl, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` }
        });

        if (checkTokenResp.ok) {
          const tokenPayload = await checkTokenResp.json();
          initActiveLabId(tokenPayload);
          const currentUser = await getCurrentUser();

          appGlobals.currentUser = currentUser;
          const loggedIn = currentUser && currentUser.id;

          await loadGlobals({ loggedIn });
          await loadAppSettings();
          await loadDefaultProject();

          setCurrentUser(currentUser);
        } else {
          const checkTokenJson = await checkTokenResp.json();
          const { isAuthorization, message } = checkTokenJson;

          if (isAuthorization && message) {
            window.toastr.error(message);
          } else {
            window.toastr.error(
              `An error occurred validating your user account. Your account may no longer be active.`
            );
          }

          auth.logout();
        }
      } catch (error) {
        console.error("Error checking token");
        console.error(error);
      }
      setLoading(false);
    };
    loadData();
    window.addEventListener("authToken", loadData);
    return () => {
      window.removeEventListener("authToken", loadData);
    };
  }, []);

  const refetchCurrentUser = () => {
    setLoading(true);
    getCurrentUser().then(currentUser => {
      setCurrentUser(currentUser);
      setLoading(false);
    });
  };

  return { loading, currentUser, refetchCurrentUser };
};

const logout = () => {
  appGlobals.logout();
};

const routerSessionManager = () => {
  appGlobals.logout = () => {
    const isNotLogin = !endsWith(window.location.pathname, "/login");
    const notPasswordReset = !window.location.pathname.match("/password-reset");

    if (isNotLogin && notPasswordReset) {
      auth.logout();
    } else {
      console.warn("Redirect not required on AppContentManager");
    }
  };
};

const _AppContentManager = ({
  Alert,
  appBodyClassName,
  appHotkeySets,
  appRoutes,
  Header,
  Helper,
  history,
  location,
  mainStore,
  mobXModalComponents,
  modalComponents
}) => {
  const { loading, currentUser, refetchCurrentUser } = useData();

  useEffect(() => {
    if (mainStore && mainStore.common)
      mainStore.common.set({ history: history });
  }, [history, mainStore]);

  useEffect(() => {
    appGlobals.history = history;
    routerSessionManager();
    let historyListener;

    return () => {
      if (historyListener) {
        historyListener();
      }
    };
  }, [history]);

  const loggedIn = currentUser && currentUser.id;

  return (
    <CurrentUserContext.Provider value={{ currentUser, refetchCurrentUser }}>
      <GlobalDialog
        history={history}
        modalComponents={modalComponents}
        currentUser={currentUser}
      />
      <StackedDialog history={history} currentUser={currentUser} />
      {mainStore && (
        <MobxModal
          mobXModalComponents={mobXModalComponents}
          history={history}
        />
      )}
      {Alert && <Alert history={history} />}
      {Helper && <Helper currentUser={currentUser} history={history} />}
      <MainContent
        appBodyClassName={appBodyClassName}
        appHotkeySets={appHotkeySets}
        appRoutes={appRoutes}
        currentUser={currentUser}
        Header={Header}
        loading={loading}
        location={location}
        loggedIn={loggedIn}
        logout={logout}
        refetchCurrentUser={refetchCurrentUser}
      />
    </CurrentUserContext.Provider>
  );
};

const AppContentManager = withRouter(_AppContentManager);

export { AppContentManager };
