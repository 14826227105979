/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* global RequestInit RequestInfo */
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import {
  from,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import shortid from "shortid";
import appGlobals from "./appGlobals";
import { addPendingRequest, cancelPendingRequest } from "./requestTracker";
import simulateLatency from "./simulateLatency";
import getRequestHeaderKeys from "../../tg-iso-shared/src/utils/getRequestHeaderKeys";
import "./api";
import { gqlClientProxy } from "@teselagen/graphql-utils";
import gqlErrorFormatter from "../../tg-iso-shared/src/gqlErrorFormatter";
import { some } from "lodash";

// These are hasura aggregation gql fields, which do not come with an 'id'
// so we shouldnt warn about that
const EXCLUDED_SUFFIX_FIELDS_FROM_ID = ["_aggregate", "_max_fields"];

// This 'buildGraphQlClient' function is simply a factory for apollo clients.
function buildGraphQlClient(moduleName: string) {
  const customFetch = async (
    uri: RequestInfo | URL,
    init?: RequestInit | undefined
  ): Promise<Response> => {
    const key = shortid();
    addPendingRequest(key, "apollo");
    try {
      await simulateLatency();
      const resp = await fetch(uri, init);
      cancelPendingRequest(key, "apollo");
      return Promise.resolve(resp);
    } catch (err) {
      cancelPendingRequest(key, "apollo");
      return Promise.reject(err);
    }
  };

  const httpLink = new HttpLink({
    uri: operation =>
      gqlClientProxy(operation, {
        moduleName,
        hasura: { disableHasura: !window.frontEndConfig[`hasuraEnabled`] }
      }),
    fetch: customFetch,
    credentials: "include"
  });

  const onErrorLink = onError(
    ({ graphQLErrors, networkError, response, operation }) => {
      if (!response) response = {};
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (networkError?.message) {
            networkError.message += `\n${message}`;
          }
          if (message === "Wrong credentials.") {
            console.error("errorLink.response", response);
            if (appGlobals.logout) {
              console.error("logging out");
              appGlobals.logout();
            } else {
              console.warn(
                "appGlobals.logout not defined, cant execute logout"
              );
            }
          }
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
        response.errors = graphQLErrors;
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        const parsedNetworkError = networkError as unknown as {
          result: { isAuthorization: boolean };
        };
        if (
          parsedNetworkError.result &&
          parsedNetworkError.result.isAuthorization
        ) {
          if (appGlobals.logout) {
            console.error(
              "Logging out because token got corrupted or invalidated, please check the server for more information."
            );

            appGlobals.logout();
          } else {
            console.warn("appGlobals.logout not defined, cant execute logout");
          }
        }
      }
      if (response && response.errors && response.errors.length) {
        gqlErrorFormatter(response.errors, operation);
      }
      return;
    }
  );

  const headerLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...getRequestHeaderKeys(moduleName),
        // tgreen: we want the ability to override the headers here. For example, ignoring the active batch
        // for nested data
        ...headers
      }
    };
  });

  const links = [onErrorLink, headerLink, httpLink];

  // use with apollo-client
  const link = from(links);

  function dataIdFromObject(result: {
    __typename: string;
    id: string;
    code: string;
    results: [];
    partId: string;
  }): string | null {
    if (
      result.results ||
      result.__typename.includes("Payload") ||
      result.__typename === "Mutation"
    ) {
      return null;
    }
    if ((result.code || result.id) && result.__typename) {
      return result.__typename + "-" + (result.code || result.id);
    } else if (result.__typename === "partSeqContextView") {
      return result.__typename + "-" + result.partId;
    } else {
      if (result.__typename.includes("CursorResult")) return null;
      if (
        some(EXCLUDED_SUFFIX_FIELDS_FROM_ID, excludedSuffix =>
          result.__typename.includes(excludedSuffix)
        )
      ) {
        return null;
      }
      // eslint-disable-next-line no-debugger
      debugger;
      console.error(
        "Watch out! You should include a id in your query/mutation result for " +
          result.__typename
      );
      return null;
    }
  }

  const client: ApolloClient<NormalizedCacheObject> & {
    removeFromCache?: (records: Object[]) => void;
  } = new ApolloClient({
    connectToDevTools: true,
    link,
    cache: new InMemoryCache({
      dataIdFromObject
    } as any)
  });

  client.removeFromCache = (records: any[]) => {
    records.forEach(record => {
      const cacheId = dataIdFromObject(record);
      (client.cache as any).data.delete(cacheId);
    });
  };

  return client;
}

const client = buildGraphQlClient(window.frontEndConfig.tgModuleName);

if (window.Cypress) {
  window.Cypress.tgApolloClient = client;
}

export default client;
