/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "recompose";
import { withTableParams } from "@teselagen/ui";
import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import React from "react";
import withQuery from "./withQuery";
import { withProps } from "recompose";
import {
  tagModels,
  importCollectionModels,
  workflowCollectionModels,
  extendedPropertyModels,
  labModels,
  aliasModels,
  externalRecordIdentifierModels,
  externalReferenceKeys,
  isDeprecatedModels,
  barcodeModels,
  lockableModels,
  expirationDateModels
} from "../../tg-iso-shared/constants";
import withLibraryExtendedPropertyColumns from "./enhancers/withLibraryExtendedPropertyColumns";
import buildAliasFragment from "./fragments/buildAliasFragment";
import {
  generalLibraryColumns,
  labGroupColumn,
  importCollectionColumn,
  aliasColumn,
  dateColumns,
  workflowCollectionColumn,
  lockedToCommonColumn,
  nameWithDeprecateColumn,
  projectsColumn,
  barcodeColumn,
  barcodeViewColumn
} from "./utils/libraryColumns";
import { addTagFilterToQuery } from "./utils/tagUtils";
import { tagColumn } from "./utils/tagColumn";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { identity } from "lodash";
import modelNameToLink from "./utils/modelNameToLink";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { combineGqlFragments } from "../../tg-iso-shared/utils/gqlUtils";
import {
  addActiveProjectFilter,
  shouldShowProjects
} from "./utils/projectUtils";
import projectItemFragment from "./fragments/projectItemFragment";
import { addLabFilterToQuery } from "./utils/labUtils";
import {
  hasExternalImportIntegration,
  hasIntegration
} from "../../tg-iso-shared/src/utils/integrationTypeSettingsMap/importExportSubtypes";
import integrationOptionsFragment from "./fragments/integrationOptionsFragment";
import { isBuild } from "../../tg-iso-shared/src/utils/isModule";
import simpleAliasFragment from "./fragments/simpleAliasFragment";
import { isAdmin } from "./utils/generalUtils";
import appGlobals from "./appGlobals";
import taggedItemFragment from "../../tg-iso-shared/src/fragments/taggedItemFragment";
import getFragmentNameFromFragment from "../../tg-iso-shared/src/utils/getFragmentNameFromFragment";
import renderExpirationDate from "./utils/renderExpirationDate";
import { getExternalReferenceColumnsWithRender } from "../../tg-iso-shared/utils/getExternalReferenceColumnsWithRender";

export const getAliasFragment = () => {
  if (isBuild()) {
    return buildAliasFragment;
  } else {
    return simpleAliasFragment;
  }
};

export const getGeneralFragmentFields = (model, options = {}) => {
  const {
    updateableModel,
    wrapWithNestModel = identity,
    isUsingView
  } = options;

  const modelToUse = updateableModel || model;
  const hasLab = labModels.includes(modelToUse);
  const withImportCollection = importCollectionModels.includes(modelToUse);
  const withWorkflowCollection = workflowCollectionModels.includes(modelToUse);
  const withRecordLocking =
    window.frontEndConfig.userRecordLocking &&
    lockableModels.includes(modelToUse);
  const withExternalReference = externalRecordIdentifierModels.includes(
    modelToUse
  );
  const withBarcodes = barcodeModels.includes(modelToUse);
  const withDeprecation = isDeprecatedModels.includes(modelToUse);
  let fragment = "";
  if (withImportCollection) {
    fragment += `
      importCollection {
        id
        name
      }
    `;
  }

  if (withExternalReference) {
    fragment += wrapWithNestModel(`
    ${externalReferenceKeys.join("\n")}
  `);
  }

  if (expirationDateModels.includes(model)) {
    fragment += `
    expirationDate
    `;
  }
  if (withBarcodes) {
    if (isUsingView) {
      fragment += `
        barcodeString
      `;
    }
    fragment += wrapWithNestModel(`
      barcode {
        id
        barcodeString
      }
    `);
  }

  if (withDeprecation) {
    fragment += `
      isDeprecated
    `;
  }
  if (withRecordLocking) {
    fragment += wrapWithNestModel(`
      lockId
      childLockId
    `);
  }
  if (withWorkflowCollection) {
    fragment += `
      workflowCollection {
        id
        workflowRun {
          id
          name
        }
      }
    `;
  }
  if (hasLab) {
    fragment += `
      lab {
        id
        name
      }
    `;
  }
  if (!options.noAddedBy && !options.noUser) {
    fragment += `
    user {
      id
      username
      email
    }
    `;
    if (!isUsingView) {
      fragment += `
      updatedByUser {
        id
        username
        email
      }`;
    }
  }

  fragment += `
      createdAt
      updatedAt
    `;
  return fragment;
};

export const libraryExtendedStringValues = `
  extendedStringValueViews {
    id
    value
    type
    extendedPropertyId
    targetModel
    linkIds
  }
`;

/**
 * libraryEnhancer
 * @param {object} schema schema for table
 * @param {string} form name for table
 * @property {gql fragment or array} fragment for query
 * @property {function} additionalFilter additionalFilter for tableParams
 * @property {boolean} urlConnected table connected to url
 * @property {boolean} withDisplayOptions table with display menu
 * @property {object} withQueryOptions options passed to withQuery
 * @return {enhancer}: enhancer which adds table params, query, and delete
 */
const libraryEnhancer = options => {
  const {
    schema: partialSchema,
    formName,
    additionalFilter: passedAdditionalFilter,
    additionalOrFilter,
    urlConnected = true,
    withDisplayOptions = true,
    includeLabColumnIfAdmin,
    fragment: _fragment,
    withQueryOptions,
    updateableModel: _updateableModel,
    transformEntities: _passedTransformEntities,
    withSelectedEntities = true,
    integrationSubtype,
    isInfinite
  } = options;

  if (!partialSchema) {
    console.error("schema not passed to libraryEnhancer.");
  }

  let fragment = _fragment;
  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }
  const model = getModelNameFromFragment(fragment);
  const updateableModel = _updateableModel || model;
  const withTags = tagModels.includes(updateableModel);
  const withIntegration = hasIntegration(updateableModel, integrationSubtype);
  const withExternalImportIntegration = hasExternalImportIntegration(
    updateableModel,
    integrationSubtype
  );
  const withAliases = aliasModels.includes(updateableModel);
  const withExtendedProperties = extendedPropertyModels.includes(
    updateableModel
  );
  const withProjects = shouldShowProjects(updateableModel);

  let transformEntities = _passedTransformEntities;
  if (_updateableModel && !_passedTransformEntities) {
    transformEntities = (entities = []) => {
      return entities.map(e => {
        const actualRecord = e[_updateableModel];
        if (!actualRecord) {
          // tgreen: weird test failure here
          console.error(
            "array library entity was missing updateable model:",
            e
          );
        }
        const transformed = {
          ...e,
          ...actualRecord
        };
        return transformed;
      });
    };
  }
  const isUsingView = updateableModel && updateableModel !== model;

  const wrapWithNestModel = str => {
    if (isUsingView) {
      return ` ${updateableModel} { id ${str} } `;
    } else {
      return str;
    }
  };

  let additionalFragment = getGeneralFragmentFields(model, {
    ...options,
    isUsingView,
    wrapWithNestModel
  });

  if (withExtendedProperties) {
    additionalFragment += wrapWithNestModel(libraryExtendedStringValues);
  }

  const allFragments = [fragment];
  if (withTags) {
    additionalFragment += wrapWithNestModel(`
      taggedItems {
        ...taggedItemFragment
      }
    `);
    allFragments.push(taggedItemFragment);
  }
  if (withProjects) {
    additionalFragment += wrapWithNestModel(`
      projectItems {
        ...projectItemFragment
      }
    `);
    allFragments.push(projectItemFragment);
  }
  if (withAliases) {
    const aliasFragmentToUse = getAliasFragment();
    additionalFragment += wrapWithNestModel(`
      aliases {
        ...${getFragmentNameFromFragment(aliasFragmentToUse)}
      }
    `);
    allFragments.push(aliasFragmentToUse);
  }

  allFragments.push(additionalFragment);
  fragment = combineGqlFragments(allFragments);

  const schema = getSchema(partialSchema, model, {
    ...options,
    isUsingView,
    updateableModel
  });

  const additionalFilter = (...args) => {
    const [props, qb, currentParams] = args;

    if (withTags) {
      let tagOptions;
      if (updateableModel !== model) {
        tagOptions = {
          pathToTaggedItems: `${updateableModel}.taggedItems`
        };
      }
      addTagFilterToQuery(currentParams.tags, qb, tagOptions);
    }

    if (withProjects) {
      let pathToProjectId;
      if (updateableModel !== model) {
        pathToProjectId = `${updateableModel}.projectItems.projectId`;
      }
      addActiveProjectFilter(qb, {
        pathToProjectId,
        model: updateableModel,
        isUsingView
      });
    }

    addLabFilterToQuery(currentParams.labFilter, qb);

    if (isDeprecatedModels.includes(model)) {
      if (!currentParams.showDeprecated) {
        qb.whereAll({
          isDeprecated: false
        });
      }
    }
    if (passedAdditionalFilter) {
      passedAdditionalFilter(...args);
    }
    if (props.embeddedLibraryFilter) {
      props.embeddedLibraryFilter({ model, updateableModel }, ...args);
    }
  };
  const toCompose = [
    withProps(() => {
      return {
        model: updateableModel,
        isLibraryTable: true,
        withIntegration,
        withExternalImportIntegration
      };
    }),
    // only make this query if the library supports showing custom info
    ...(withIntegration
      ? [
          withQuery(integrationOptionsFragment, {
            isPlural: true,
            showLoading: true,
            options: {
              pageSize: 999900,
              variables: {
                filter: {
                  integrationTypeCode: ["CUSTOM_INFO", "UPDATE", "EXPORT"],
                  subtype: withIntegration.key
                }
              }
            }
          })
        ]
      : []),
    withProps(props => {
      const schemaToUse = props.schema || schema;
      if (includeLabColumnIfAdmin && isAdmin(appGlobals.currentUser)) {
        const labColumn = schemaToUse.fields.find(
          col => col.path === "lab.name"
        );
        if (labColumn) {
          labColumn.isHidden = false;
        }
      }
      return {
        schema: schemaToUse
      };
    }),
    withTableParams({
      urlConnected,
      formName: formName || `${updateableModel}LibraryTable`,
      withDisplayOptions,
      defaults: {
        order: ["-modified"]
      },
      withSelectedEntities,
      additionalFilter,
      additionalOrFilter
    }),
    withQuery(fragment, {
      isPlural: true,
      ...withQueryOptions,
      ...(isInfinite ? { pageSize: 999999 } : {})
    }),
    withProps(({ tableParams: _tableParams }) => {
      let tableParams = {
        ..._tableParams,
        updateableModel,
        transformEntities,
        isInfinite
      };
      if (transformEntities && tableParams.entities) {
        tableParams = {
          ...tableParams,
          entities: transformEntities(tableParams.entities)
        };
      }
      return {
        refetch: tableParams.onRefresh,
        libraryFragment: fragment,
        integrationSubtype: withIntegration && withIntegration.key,
        tableParams
      };
    })
  ];

  if (withExtendedProperties) {
    toCompose.unshift(
      withLibraryExtendedPropertyColumns({
        schema,
        model,
        updateableModel,
        showLoading: true
      })
    );
  }

  return compose(...toCompose);
};

function getSchema(partialSchema, model, options = {}) {
  const {
    noAddedBy,
    hideCreatedFields,
    updateableModel,
    showIdColumnByDefault = false,
    isUsingView
  } = options;
  let schema = partialSchema;
  let fields;
  if (Array.isArray(schema)) {
    fields = [...schema];
    schema = { model: updateableModel, fields };
  } else {
    schema = {
      ...schema,
      fields: [...schema.fields]
    };
    fields = schema.fields;
  }

  if (barcodeModels.includes(updateableModel)) {
    const col = isUsingView ? barcodeViewColumn : barcodeColumn;
    fields.splice(1, 0, col);
  }
  // should go after name column
  if (aliasModels.includes(updateableModel)) {
    fields.splice(1, 0, aliasColumn);
  }

  if (isDeprecatedModels.includes(updateableModel)) {
    fields.unshift(nameWithDeprecateColumn);
  }

  fields.unshift({
    displayName: "ID",
    type: "string",
    path: "id",
    isHidden: !showIdColumnByDefault
  });

  if (labModels.includes(updateableModel)) {
    fields.unshift(lockedToCommonColumn);
  }

  if (expirationDateModels.includes(model)) {
    fields.push({
      path: "expirationDate",
      type: "timestamp",
      displayName: "Expiration",
      render: v => {
        return renderExpirationDate(v);
      }
    });
  }

  if (tagModels.includes(updateableModel)) {
    fields.push(tagColumn);
  }

  if (shouldShowProjects(updateableModel)) {
    let projectsColumnToUse = projectsColumn;
    if (model !== updateableModel) {
      projectsColumnToUse = {
        ...projectsColumnToUse,
        path: `${updateableModel}.${projectsColumnToUse.path}`
      };
    }
    fields.push(projectsColumnToUse);
  }

  if (importCollectionModels.includes(updateableModel)) {
    fields.push(importCollectionColumn);
  }
  if (workflowCollectionModels.includes(updateableModel)) {
    fields.push(workflowCollectionColumn);
  }
  // do not show the normal lab group column in single lab mode
  if (labModels.includes(updateableModel)) {
    fields.push({
      ...labGroupColumn,
      isHidden: true
    });
  }

  if (externalRecordIdentifierModels.includes(updateableModel)) {
    fields.push(
      ...getExternalReferenceColumnsWithRender({
        prefix: isUsingView ? updateableModel : ""
      })
    );
  }

  if (noAddedBy) {
    fields.push(...dateColumns);
  } else {
    if (isUsingView) {
      fields.push(
        ...generalLibraryColumns.filter(col => col.displayName !== "Updated By")
      );
    } else {
      fields.push(...generalLibraryColumns);
    }
  }
  fields = fields.map(f => {
    // simple string fields will be handled in @teselagen/ui
    if (typeof f === "string") return f;
    const toRet = { ...f };
    if (hideCreatedFields) {
      if (toRet.path === `createdAt` || toRet.path === "updatedAt") {
        toRet.isHidden = true;
      }
    }
    if (toRet.withLink) {
      toRet.getClipboardData = v => v;
      toRet.render = (v, r) => {
        if (v) {
          return (
            <Link to={modelNameToLink(get(r, toRet.path.replace(".name", "")))}>
              {v}
            </Link>
          );
        }
      };
    }
    return toRet;
  });
  schema.fields = fields;
  return schema;
}

export default libraryEnhancer;
