/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import b64ToBlob from "b64-to-blob";
import { Classes } from "@blueprintjs/core";
import { CheckboxField, DialogFooter, InfoHelper } from "@teselagen/ui";
import { download } from "../utils/downloadTest";
import { wrapDialog } from "@teselagen/ui";
import SequenceExportFormatsRadio from "../SequenceExportFormatsRadio";

class ExportSequencesDialog extends React.Component {
  onSubmit = async values => {
    const { hideModal, sequenceIds, isProtein } = this.props;
    try {
      const valuesToSend = { ...values };
      if (values.format === "genbank") {
        delete valuesToSend.exportInSingleFastaFile;
      } else if (values.format === "fasta") {
        delete valuesToSend.includeParts;
      } else if (values.format === "json") {
        delete valuesToSend.exportInSingleFastaFile;
      }

      const {
        data: { success, err, base64File }
      } = await window.api.request({
        method: "POST",
        url: "/exportSequences",
        data: {
          ...valuesToSend,
          isProtein,
          sequenceIds
        }
      });
      if (!success) {
        console.error(err);
        throw new Error(err);
      } else {
        window.toastr.success("Successfully exported sequence.");
      }
      download(
        b64ToBlob(base64File, "application/zip"),
        "Sequences.zip",
        "application/zip",
        true
      );

      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting sequence(s).");
      hideModal();
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      format,
      isProtein
    } = this.props;
    const gb = isProtein ? "GenPept" : "Genbank";
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <SequenceExportFormatsRadio isProtein={isProtein} />
          {(format === "genbank" || format === "json") && (
            <CheckboxField
              name="includeParts"
              label={`Include Parts (${gb}/JSON Only)`}
              defaultValue
            />
          )}
          {(format === "genbank" || format === "json") && (
            <CheckboxField
              name="includeAssemblyAnnotations"
              defaultValue
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Include Assembly Annotations ({gb}/JSON Only) &nbsp;
                  <InfoHelper content="Unchecking this will strip any Assembly Pieces, Assembly Warnings & Lineage Annotations from the genbank features"></InfoHelper>
                </div>
              }
            />
          )}
          {format === "fasta" && (
            <CheckboxField
              name="exportInSingleFastaFile"
              label="Export sequences in a single Fasta file (Fasta Only)"
              defaultValue
            />
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          text="Export"
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Export Options",
    style: {
      width: 450
    }
  }),
  reduxForm({
    form: "exportSequencesForm", // a unique name for this form
    enableReinitialize: true
  }),
  tgFormValues("format")
)(ExportSequencesDialog);
