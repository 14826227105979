/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5DirectSynthesisFragment on j5DirectSynthesis {
    id
    cid
    name
    cost
    sequence {
      id
      cid
      j5AssemblyPiece {
        id
        name
      }
      name
      size
      isJ5Sequence
      isInLibrary
    }
  }
`;
