/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useReducer, useState } from "react";
import { compose } from "recompose";
import {
  DataTable,
  DialogFooter,
  getSelectedEntities,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  dateModifiedColumn,
  userColumns
} from "../../../../../src-shared/utils/libraryColumns";
import { tagColumnWithRender } from "../../../../../src-shared/utils/tagColumn";
import { reduxForm } from "redux-form";
import { importSequenceFromBuildToDesign } from "../../../../../src-shared/utils/sequenceUtils";
import {
  createNewDesignPart,
  handleBuildSequencePart,
  PART_FRAGMENT,
  searchForPartInInventory
} from "./utils";
import LabelWithTooltip from "../../../../../src-shared/LabelWithTooltip";

// In a first pass we'll only look for parts in the forward strand
const SEARCH_PART_IN_REVERSE_STRAND = false;

const schema = [
  {
    path: "name",
    displayName: "Name",
    render: (value, record) => {
      return (
        <a
          href={`/build/client/dna-materials/${record.polynucleotideMaterial.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      );
    }
  },
  // { path: "exact", type: "boolean", displayName: "Exact Match" },
  { path: "circular", type: "boolean", displayName: "Circular" },
  { path: "size", type: "number", displayName: "Size" },
  {
    path: "compatibleOverhangs",
    type: "boolean",
    displayName: "Digest Compatibility",
    render: (value, record) => {
      const tooltipMessage = !value && record.cutSiteValidationMessage;
      return (
        <LabelWithTooltip
          label={value ? "True" : "False"}
          tooltip={tooltipMessage}
        />
      );
    }
  },
  {
    path: "associatedAliquots",
    type: "number",
    displayName: "Associated Aliquots"
  },
  tagColumnWithRender,
  userColumns,
  dateModifiedColumn
];

const initialState = {
  exactMatches: [],
  containsMatches: [],
  searching: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "EXACT":
      return {
        ...state,
        exactMatches: action.payload.map(match => ({ ...match, exact: true })),
        searching: false
      };
    case "CONTAINS":
      return {
        ...state,
        containsMatches: action.payload.map(match => ({
          ...match,
          exact: false
        })),
        searching: false
      };
    case "SEARCHING":
      return { ...state, searching: true };
    default:
      return state;
  }
};

function transformMaterialRecords(entities) {
  return entities.map(material => {
    const aliquotIdArray = [];
    material.polynucleotideMaterial.samples.forEach(sample =>
      sample.aliquots.forEach(aliquot => aliquotIdArray.push(aliquot.id))
    );
    return {
      ...material,
      name: material.name,
      compatibleOverhangs: !material.cutSiteValidationMessage,
      associatedAliquots: aliquotIdArray.length
    };
  });
}

const SequenceMatches = props => {
  const { sequences: entities, loading, isDigest } = props;
  return (
    <DataTable
      topLeftItems={
        <HeaderWithHelper
          width="100%"
          header="Materials for design part"
          helper={
            <div>
              <span>
                These DNA materials contain an exact match of the selected
                design part.
              </span>
              <br />
              {isDigest && (
                <span>
                  Note: overhang compatibility is computed for <b>BsaI</b>{" "}
                  recognition site.
                </span>
              )}
            </div>
          }
        />
      }
      formName="sequenceMatches"
      entities={transformMaterialRecords(entities)}
      isSingleSelect
      schema={
        isDigest
          ? schema
          : schema.filter(
              _schemaColumn => _schemaColumn.path !== "compatibleOverhangs"
            )
      }
      isLoading={loading}
    />
  );
};

const InventorySearchDialog = props => {
  const {
    hideModal,
    handleSubmit,
    element,
    part,
    fas,
    // NOTE: overhangs may not be defined.
    // they depend on the assembly method used recognition site selected.
    // For Golden Gate, the recognition site is configured in j5 paremeters,
    // for other methods its determined by the chosen enzyme.
    overhangs,
    elementDesignInfo,
    createElements,
    changeFas,
    restrictionEnzyme
  } = props;

  const [matches, dispatch] = useReducer(reducer, initialState);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    const selectedEntities =
      getSelectedEntities(window.teGlobalStore, "sequenceMatches") || [];
    const [selectedEntity] = selectedEntities;
    if (selectedEntity) {
      const {
        newSequences: [newSequence],
        dupSequences: [{ duplicateFound: existingSequence } = {}]
      } = await importSequenceFromBuildToDesign([selectedEntity.id]);
      const newSourceSequence = existingSequence || newSequence;
      const newPart = await handleBuildSequencePart(part, newSourceSequence, {
        searchReverse: SEARCH_PART_IN_REVERSE_STRAND,
        ...(fas?.name === "DIGEST" &&
          selectedEntity.compatibleOverhangs && {
            digestInfo: {
              cut1: selectedEntity.cutSites[0],
              // I think we can assume cutsites come in order from left to right.
              //Not sure yet if its relative to the source sequence or the part annotation strand.
              cut2: selectedEntity.cutSites[1]
            }
          })
      });

      // Swaps the current part of the selected element
      // with the new part.
      await createNewDesignPart({
        part: newPart,
        element,
        fas,
        elementDesignInfo,
        createElements,
        changeFas
      });
    }
    setSubmitting(false);
    hideModal();
  };

  useEffect(() => {
    dispatch({ type: "SEARCHING" });
    searchForPartInInventory({
      part,
      overhangs,
      searchReverse: SEARCH_PART_IN_REVERSE_STRAND,
      onMatches: (searchType, matches) => {
        dispatch({ type: searchType, payload: matches });
      },
      restrictionEnzyme
    });
  }, [part, overhangs, restrictionEnzyme]);

  return (
    <div className={Classes.DIALOG_BODY}>
      <SequenceMatches
        sequences={[...matches.containsMatches, ...matches.exactMatches]}
        isDigest={fas?.name === "DIGEST"}
        loading={matches.searching}
      />
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default compose(
  wrapDialog({
    title: "Inventory Results for Part",
    style: { width: "70%" }
  }),
  reduxForm({ form: "inventoryResultsForPart" }),
  withQuery(
    ["part", `${PART_FRAGMENT[1]} sequence { id fullSequence circular}`],
    {
      showLoading: true,
      inDialog: true,
      options: props => {
        return {
          variables: {
            id: props.element.partId
          }
        };
      }
    }
  )
)(InventorySearchDialog);
