/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { createContext } from "react";
import classNames from "classnames";
import { IconSize, Icon } from "@blueprintjs/core";

// Create the context
export const LibrarySidePanelContext = createContext();

function LibrarySidePanelWrapper({
  open,
  children,
  extraButtons,
  setOpen,
  alwaysShowInspector,
  inspectorWidth
}) {
  if (alwaysShowInspector) open = true;
  let content = children;
  if (!children) {
    content = <DefaultLibrarySidePanelText />;
  }
  return (
    <div
      className={classNames("library-viewer-container", {
        alwaysShowInspector
      })}
      style={{ position: "relative" }}
    >
      <div
        style={{
          width: open ? inspectorWidth || 500 : 0
        }}
        className="library-viewer"
      >
        {open && (
          <div style={{ minWidth: 300 }}>
            {/* Wrap the children with the Provider */}
            <LibrarySidePanelContext.Provider
              value={{ inLibrarySidePanel: true }}
            >
              {content}
            </LibrarySidePanelContext.Provider>
          </div>
        )}
      </div>
      {extraButtons}
      {alwaysShowInspector ? null : (
        <div
          style={{ cursor: "pointer", right: 10, top: 5, position: "absolute" }} //1
          className="inspector-toggle-button"
        >
          <Icon
            intent="primary"
            icon={open ? "cross" : "caret-left"} //3
            iconSize={IconSize.LARGE} //1
            onClick={() => setOpen(!open)}
          />
        </div>
      )}
    </div>
  );
}

function DefaultLibrarySidePanelText() {
  return (
    <div
      style={{
        padding: 10,
        marginTop: 50,
        textAlign: "center"
      }}
    >
      <div style={{ marginTop: 20 }}>Select a record to view its details.</div>
    </div>
  );
}

export default LibrarySidePanelWrapper;
