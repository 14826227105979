/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { isSuperUser } = require("./isSuperUser");
const { logDebug } = require("./logDebug");

function getAllLabIdsFromContext(context) {
  if (typeof context === "undefined") return [];
  if (typeof context.req === "undefined") return [];
  if (isSuperUser(context)) return [];
  const user = context?.req?.user;
  if (user) {
    // todo may have string vs int comparison problems here
    if (user.labs?.length) {
      const labIds = [...user.labs];
      logDebug(`User with id ${user.id} is in labs ${labIds.join(", ")}`);
      return labIds;
    } else {
      logDebug(`User with id ${user.id} has no labs`);
      return [];
    }
  } else {
    const msg = `No user in context for request`;
    console.error(msg);
    throw new Error(msg);
  }
}

module.exports = { getAllLabIdsFromContext };
