/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { dnaIcon, designIcon } from "@teselagen/ui";
import LandingPageTimelineInner, {
  timelineItemWrapper
} from "../../../src-shared/LandingPageTimelineInner";
import {
  disabledFeaturesConfig,
  isAdmin
} from "../../../src-shared/utils/generalUtils";
import { designLibraryFilter } from "../../utils/designUtils";
import {
  dataMenu,
  designMenu,
  moleculeMenu,
  reportMenu
} from "../../utils/headerCategories";
import { landingPageSeqItems } from "../../../src-shared/utils/landingPageItems";

const timelineModels = ["design", "sequence", "j5Report"];
const countModels = ["part"];

const showNormalLabTabs = isAdmin();

if (showNormalLabTabs) {
  countModels.push("restrictionEnzyme");
}

const timelineIcons = {
  sequence: dnaIcon,
  j5Report: "document",
  design: designIcon
};
const toCountGroups = [
  {
    ...moleculeMenu,
    items: [
      ...landingPageSeqItems,
      "partset",
      {
        model: "part",
        filter: qb => {
          qb.whereAll({
            "sequence.isInLibrary": true
          });
        }
      }
    ]
  },
  {
    ...designMenu,
    items: [
      {
        model: "design",
        filter: qb => {
          designLibraryFilter(qb);
        }
      },
      "schematic",
      "ruleSet",
      ...(disabledFeaturesConfig.designTemplates
        ? []
        : [
            {
              model: "design",
              name: "Design Templates",
              filter: qb => {
                qb.whereAll({
                  type: "design-template"
                });
              }
            }
          ])
    ]
  },
  {
    ...reportMenu,
    items: [
      {
        model: "j5Report"
      }
    ]
  },
  {
    ...dataMenu,
    items: ["tag", "importCollection", "alignment"]
  }
];
function LandingPageTimeline(props) {
  return (
    <LandingPageTimelineInner
      {...props}
      {...{
        toCountGroups,
        timelineModels,
        timelineIcons
      }}
    />
  );
}

export default compose(
  timelineItemWrapper([
    "j5Report",
    {
      model: "sequence",
      filter: qb => {
        qb.whereAny({
          isInLibrary: true
        });
      }
    },
    {
      model: "design",
      filter: qb => {
        designLibraryFilter(qb);
      }
    }
  ])
)(LandingPageTimeline);
