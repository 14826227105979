/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import actions from "../../actions";
import { handleActions } from "redux-actions";

const initialState = {
  open: true
};

export default handleActions(
  {
    [actions.ui.navPanel.toggle]: ({ open }) => {
      return {
        open: !open
      };
    }
  },
  initialState
);
