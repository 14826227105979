/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
// import { DataTable } from "@teselagen/ui";
import { Button, Card, Classes } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { stringify } from "qs";
import { map, camelCase, forEach, get } from "lodash";
import modelNameToLink from "../../utils/modelNameToLink";

// import { privateRoutes } from "../../../appRoutes";
import "./style.css";
import { openInNewTab } from "../../utils/generalUtils";
import appGlobals from "../../appGlobals";
import { kebabCase } from "lodash";
import {
  getSuggestedNextTools,
  keyedFullToolSchemas
} from "../../utils/toolUtils";

// breaking here because a private routes is importing
// this file => circular
export const generateSuccessPageLink = (
  itemOrItems,
  {
    label,
    inventoryItemTypeCode,
    dataItemTypeCode,
    model,
    isUpdate,
    successPageMessage
  },
  key
) => {
  const modelName =
    model || camelCase(dataItemTypeCode || inventoryItemTypeCode);
  const plural = Array.isArray(itemOrItems);
  const itemIds = plural ? itemOrItems.map(({ id }) => id) : [itemOrItems.id];
  const forcedCreate = plural
    ? get(itemOrItems, "[0].wasCreated")
    : itemOrItems.wasCreated;
  let labelToUse;
  if (successPageMessage) {
    labelToUse = successPageMessage(itemIds.length);
  } else {
    labelToUse = `${label} ${
      isUpdate && !forcedCreate ? "Updated" : "Created"
    }: ${itemIds.length}`;
  }

  let linkToCheckifLinkable = modelNameToLink(modelName);
  if (!plural) linkToCheckifLinkable += "/:id";
  // TODO: cant do this because then a circular dependency problem occures
  // if (!privateRoutes[linkToCheckifLinkable]) {
  // return label;
  // }
  let link;
  if (plural) {
    link =
      linkToCheckifLinkable +
      (itemIds.length === 1
        ? `/${itemIds[0]}`
        : "?filters=id__inList__" + itemIds.join(";"));
  } else {
    link = linkToCheckifLinkable.replace(":id", itemOrItems.id);
  }

  return (
    <a
      key={key}
      onClick={() =>
        window.Cypress ? appGlobals.history.push(link) : openInNewTab(link)
      }
      style={{ fontSize: 20, marginBottom: 15 }}
    >
      {labelToUse}
    </a>
  );
};

class SuccessPage extends Component {
  resetTool = () => {
    this.props.resetTool && this.props.resetTool();
  };

  returnToLibrary = () => {
    const { history } = this.props;
    history.push(history.location.pathname.replace("/tools/", "/tools#"));
  };

  renderContent() {
    const {
      toolSchema: { output },
      submittedData
    } = this.props;
    return map(output?.ioItems, (item, key) => {
      const itemOrArrayOfItems = submittedData[key];
      if (itemOrArrayOfItems) {
        if (Array.isArray(itemOrArrayOfItems) && !itemOrArrayOfItems.length)
          return;
        return generateSuccessPageLink(itemOrArrayOfItems, item, key);
      }
    });
  }

  renderCustomContent() {
    const { innerContentOverride, submittedData } = this.props;

    return innerContentOverride(submittedData);
  }

  launchExecuteWorklist = () => {
    const {
      toolSchema: { output },
      submittedData,
      history
    } = this.props;

    const params = {
      worklistIds: undefined,
      reactionMapIds: undefined
    };

    forEach(output.ioItems, (item, key) => {
      const itemOrArrayOfItems = submittedData[key];
      if (!itemOrArrayOfItems) return;
      const itemIds = Array.isArray(itemOrArrayOfItems)
        ? itemOrArrayOfItems.map(item => item.id)
        : [itemOrArrayOfItems.id];
      if (item.dataItemTypeCode === "WORKLIST") {
        params.worklistIds = itemIds;
      } else if (item.dataItemTypeCode === "REACTION_MAP") {
        params.reactionMapIds = itemIds;
      }
    });

    history.push();
  };

  render() {
    const {
      toolSchema: { name, notPartOfLibrary, output },
      innerContentOverride,
      submittedData,
      history
    } = this.props;

    const suggestedNextTools = getSuggestedNextTools(
      this.props.toolSchema,
      keyedFullToolSchemas
    );

    let nextToolButton;
    return (
      <div className="success-page tg-flex column align-center">
        <h2>Successfully Completed {name}</h2>
        {!innerContentOverride
          ? this.renderContent()
          : this.renderCustomContent()}
        {!notPartOfLibrary && (
          <div className="tg-flex" style={{ marginTop: 20 }}>
            <Button
              text="Return to Tool Library"
              onClick={this.returnToLibrary}
            />
            <div className="tg-flex-separator" />
            <Button text="Restart Tool" onClick={this.resetTool} />
            {nextToolButton}
          </div>
        )}
        {!!suggestedNextTools.length && (
          <div style={{ marginTop: 35, width: 450 }}>
            <div style={{ fontSize: 16, textAlign: "center" }}>
              Suggested Next Tools
            </div>
            <br />
            {suggestedNextTools.map(toolCode => {
              const toolSchema = keyedFullToolSchemas[toolCode];

              if (
                !toolSchema ||
                toolSchema.deprecated ||
                toolSchema.workflowOnly
              )
                return null;

              const pathname = `/tools/${kebabCase(toolSchema.title)}`;
              let hash;

              if (toolCode === "executeWorklist") {
                const params = {
                  worklistIds: undefined,
                  reactionMapIds: undefined
                };

                forEach(output.ioItems, (item, key) => {
                  const itemOrArrayOfItems = submittedData[key];
                  if (!itemOrArrayOfItems) return;
                  const itemIds = Array.isArray(itemOrArrayOfItems)
                    ? itemOrArrayOfItems.map(item => item.id)
                    : [itemOrArrayOfItems.id];
                  if (item.dataItemTypeCode === "WORKLIST") {
                    params.worklistIds = itemIds;
                  } else if (item.dataItemTypeCode === "REACTION_MAP") {
                    params.reactionMapIds = itemIds;
                  }
                });
                hash = stringify(params);
              }
              return (
                <div key={toolCode}>
                  <Card
                    className="tg-tool-suggestion"
                    interactive
                    style={{ marginBottom: 7 }}
                    onClick={() =>
                      history.push({
                        pathname,
                        hash
                      })
                    }
                  >
                    <a style={{ fontSize: 14 }}>{toolSchema.title}</a>
                    <div
                      className={Classes.TEXT_MUTED}
                      style={{ fontSize: 12, marginTop: 5 }}
                    >
                      {toolSchema.description}
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SuccessPage);
