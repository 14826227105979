/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { keyBy } from "lodash";
import { reduxForm, FieldArray } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  Loading,
  InputField,
  CheckboxField,
  DialogFooter,
  InfoHelper,
  ReactSelectField,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../withQuery";

import { withProps, branch } from "recompose";
import { Classes } from "@blueprintjs/core";
import pluralize from "pluralize";
import shortid from "shortid";

import { RemoveButton, AddButton } from "../FieldArrayButtons";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import GenericSelect from "../GenericSelect";
import withRecordAliases from "../enhancers/withRecordAliases";
import {
  inventoryItemTypeCodeToModel,
  inventoryItemTypeMap
} from "../../../tg-iso-shared/src/utils/inventoryUtils";

import { safeUpsert, safeQuery, safeDelete } from "../apolloMethods";
import { getAliasFragment } from "../libraryEnhancer";
import { isBuild } from "../../../tg-iso-shared/src/utils/isModule";
import getFragmentNameFromFragment from "../../../tg-iso-shared/src/utils/getFragmentNameFromFragment";

class UpdateRecordAliasesDialog extends Component {
  onSubmit = async values => {
    const {
      record,
      hideModal,
      refetchAliases,
      initialValues: { aliases: initialAliases = [] } = {}
    } = this.props;
    const { aliases = [] } = values;
    try {
      const aliaseIdsToDelete = [];

      const keyedInitialAliases = keyBy(initialAliases, "name");
      const newAliasNames = aliases.map(a => a.name);
      if (initialAliases.length) {
        initialAliases.forEach(alias => {
          if (!newAliasNames.includes(alias.name)) {
            aliaseIdsToDelete.push(alias.id);
          }
        });
        await safeDelete("alias", aliaseIdsToDelete);
      }

      const newAliases = [];

      const aliasTargetHelpers = {};

      aliases.forEach(alias => {
        if (alias.smart) {
          const model = alias.smartAliasTarget.__typename;
          aliasTargetHelpers[model] = aliasTargetHelpers[model] || [];
          aliasTargetHelpers[model].push(alias.smartAliasTarget.id);
        }
      });

      const inventoryItemsToCreate = [];
      const aliasTargetInventoryItemResolvers = {};

      for (const model in aliasTargetHelpers) {
        const key = `${model}Id`;
        const invItems = await safeQuery(["inventoryItem", `id ${key}`], {
          variables: {
            filter: {
              [key]: aliasTargetHelpers[model]
            }
          }
        });
        const keyedInvItems = keyBy(invItems, key);
        aliasTargetInventoryItemResolvers[model] = {};
        aliasTargetHelpers[model].forEach(itemId => {
          if (keyedInvItems[itemId]) {
            aliasTargetInventoryItemResolvers[model][itemId] =
              keyedInvItems[itemId].id;
          } else {
            const cid = shortid();
            inventoryItemsToCreate.push({
              cid,
              inventoryItemTypeCode: inventoryItemTypeMap[model],
              [key]: itemId
            });
            aliasTargetInventoryItemResolvers[model][itemId] = `&${cid}`;
          }
        });
      }

      const aliasUpdates = [];
      aliases.forEach(alias => {
        if (!keyedInitialAliases[alias.name]) {
          const newAlias = {
            [record.__typename + "Id"]: record.id,
            name: alias.name
          };

          // handle smart linkage
          if (alias.smart && alias.smartAliasTarget) {
            const { id, __typename } = alias.smartAliasTarget;
            const targetInventoryItemId =
              aliasTargetInventoryItemResolvers[__typename][id];
            newAlias.targetInventoryItemId = targetInventoryItemId;
          }
          newAliases.push(newAlias);
        } else if (
          keyedInitialAliases[alias.name] &&
          keyedInitialAliases[alias.name].smart !== alias.smart
        ) {
          // changing smartness
          if (!alias.smart) {
            aliasUpdates.push({
              id: alias.id,
              targetInventoryItemId: null
            });
          } else if (alias.smartAliasTarget) {
            const { id, __typename } = alias.smartAliasTarget;
            const targetInventoryItemId =
              aliasTargetInventoryItemResolvers[__typename][id];
            aliasUpdates.push({
              id: alias.id,
              targetInventoryItemId
            });
          }
        }
      });

      await safeUpsert("inventoryItem", inventoryItemsToCreate);
      await safeUpsert("alias", newAliases);
      await safeUpsert("alias", aliasUpdates);

      const aliasFragment = getAliasFragment();
      const fragment = `{
          id
          aliases {
            ...${getFragmentNameFromFragment(aliasFragment)}
          }
        }
      `;
      await safeQuery([record.__typename, fragment, [aliasFragment]], {
        variables: {
          id: record.id
        }
      });
      await refetchAliases();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating aliases.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit, change } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <FieldArray name="aliases" component={AliasRows} change={change} />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </div>
    );
  }
}

function _AliasRows({ fields, aliases, change }) {
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={index}>
            <div className="tg-flex width100">
              <div style={{ flex: 1, marginLeft: 10 }}>
                <InputField label="Name" name={`${field}.name`} isRequired />
                {isBuild() && (
                  <>
                    <div className="tg-flex">
                      <CheckboxField
                        label="Smart Alias"
                        name={`${field}.smart`}
                      />
                      <InfoHelper
                        style={{ marginLeft: 8, marginTop: 7 }}
                        content="Smart aliases link to other items."
                      />
                    </div>
                    {aliases[index].smart && (
                      <SmartFields
                        change={change}
                        field={field}
                        smartAliasType={aliases[index].smartAliasType}
                        smartAliasTarget={aliases[index].smartAliasTarget}
                      />
                    )}
                  </>
                )}
              </div>
              <div style={{ marginLeft: 10 }}>
                <RemoveButton fields={fields} index={index} />
              </div>
            </div>
            {index !== fields.length - 1 && <hr className="tg-section-break" />}
          </React.Fragment>
        );
      })}
      <AddButton fields={fields} label="Add Alias" />
    </div>
  );
}

const AliasRows = tgFormValues("aliases")(_AliasRows);

const smartTypeOptions = ["strain", "aminoAcidSequence", "sequence"]
  .map(model => ({
    label: modelNameToReadableName(model, { upperCase: true }),
    value: model
  }))
  .concat({
    label: "Coding Sequence",
    value: "codingSequence"
  });

const getModelFromType = smartAliasType => {
  if (smartAliasType === "codingSequence") {
    return "sequence";
  }
  return smartAliasType;
};

const tableParamOptionsForCodingSequences = {
  additionalFilter: {
    isCds: true
  }
};

function SmartFields({ field, change, smartAliasType, smartAliasTarget }) {
  let tableParamOptions;
  if (smartAliasType === "codingSequence") {
    tableParamOptions = tableParamOptionsForCodingSequences;
  }
  return (
    <div>
      <ReactSelectField
        name={`${field}.smartAliasType`}
        isRequired
        options={smartTypeOptions}
        onFieldSubmit={() => {
          if (smartAliasTarget) {
            change(`${field}.smartAliasTarget`, null);
          }
        }}
        label="Smart Alias Target Type"
      />
      {smartAliasType && (
        <div className="tg-flex align-center">
          <div>
            <GenericSelect
              name={`${field}.smartAliasTarget`}
              isRequired
              fragment={[getModelFromType(smartAliasType), "id name"]}
              nameOverride={
                smartAliasType === "codingSequence" ? "CDS" : undefined
              }
              tableParamOptions={tableParamOptions}
            />
          </div>
          {smartAliasTarget && (
            <div
              style={{
                marginRight: 8,
                marginBottom: 15,
                marginLeft: "auto",
                textAlign: "right",
                flex: 1
              }}
            >
              {smartAliasTarget.name}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function validate(values) {
  const errors = {
    aliases: []
  };
  if (values.aliases && values.aliases.length) {
    const aliasNames = [];
    values.aliases.forEach((alias, i) => {
      const error = {};
      let nameError;

      if (alias.name) {
        if (aliasNames.includes(alias.name)) {
          nameError = "Duplicate aliases are not allowed.";
        } else {
          aliasNames.push(alias.name);
        }
      }

      if (nameError) {
        error.name = nameError;
      }

      errors.aliases[i] = error;
    });
  }
  return errors;
}

const smartTargetModels = ["aminoAcidSequence", "strain", "sequence"];

const targetModelIds = (props, model) => {
  const modelIds = [];
  const { aliases = [] } = props;
  aliases.forEach(alias => {
    const id =
      alias.targetInventoryItem && alias.targetInventoryItem[model + "Id"];
    if (id) modelIds.push(id);
  });
  return modelIds;
};

const withSmartTargets = smartTargetModels.map(model => {
  let fragment = "id name";
  if (model === "sequence") fragment += " sequenceTypeCode";
  return withQuery([model, fragment], {
    isPlural: true,
    skip: props => !targetModelIds(props, model).length,
    options: props => {
      return {
        variables: {
          filter: {
            id: targetModelIds(props, model)
          }
        }
      };
    }
  });
});

const smartTargetsLoading = props =>
  smartTargetModels.some(model => props[pluralize(model) + "Loading"]);

export default compose(
  wrapDialog({ title: "Update Aliases" }),
  withRecordAliases,
  ...withSmartTargets,
  branch(
    props => props.aliasesLoading || smartTargetsLoading(props),
    () => () => <Loading inDialog />
  ),
  withProps(props => {
    const { aliases = [] } = props;
    const keyedSmartHelpers = {};
    smartTargetModels.forEach(model => {
      const items = props[pluralize(model)] || [];
      keyedSmartHelpers[model] = keyBy(items, "id");
    });

    const initialAliases = aliases.map(alias => {
      if (alias.targetInventoryItem) {
        const model =
          inventoryItemTypeCodeToModel[
            alias.targetInventoryItem.inventoryItemTypeCode
          ];
        const id = alias.targetInventoryItem[model + "Id"];
        if (
          model &&
          id &&
          keyedSmartHelpers[model] &&
          keyedSmartHelpers[model][id]
        ) {
          let smartAliasType = model;
          const smartAliasTarget = keyedSmartHelpers[model][id];

          if (model === "sequence" && smartAliasTarget.isCDS === true) {
            smartAliasType = "codingSequence";
          }

          return {
            ...alias,
            smart: true,
            smartAliasType,
            smartAliasTarget
          };
        }
      }
      return alias;
    });

    return {
      initialValues: {
        aliases: initialAliases
      }
    };
  }),
  reduxForm({
    form: "updateRecordAliases",
    validate
  })
)(UpdateRecordAliasesDialog);
