/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { CollapsibleCard, Loading } from "@teselagen/ui";

import InfiniteScroll from "react-infinite-scroller";
import CommentWithReplies from "./CommentWithReplies";
import commentFragment from "./commentFragment";
import AddComment from "./AddComment";
import { getInitialMentions, getLocationHashPathName } from "./utils";
import "./style.css";
import { safeQuery } from "../apolloMethods";
import { uniqBy } from "lodash";

function scrollCommentsToTop() {
  const element = document.getElementById("tg-comment-list");
  if (element) {
    element.scrollTop = 0; // element.scrollHeight;
  }
}

class CommentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      isReplying: null,
      loadingComments: false,
      editingCommentId: null
    };
    //tnr: this will only work if we have 1 set of comments being displayed on the page
    //if we need more we can come up with a better system
    window.__tgReloadComments = this.refetchPage1;
  }
  componentWillUnmount() {
    delete window.__tgReloadComments;
  }

  componentDidUpdate(oldProps) {
    const { record: oldRecord = {} } = oldProps;
    const { record = {} } = this.props;
    if (
      record.id !== oldRecord.id ||
      record.__typename !== oldRecord.__typename
    ) {
      this.setState(
        {
          hasMore: false,
          comments: [],
          totalComments: null
        },
        () => {
          this.loadComments(1);
        }
      );
    }
  }

  getFilterKey = () => {
    const { record } = this.props;
    return record.__typename + "Id";
  };

  loadComments = async pageToLoad => {
    const { comments: oldComments = [], loadingComments } = this.state;
    const { record } = this.props;
    try {
      if (loadingComments) return;
      this.setState({
        loadingComments: true
      });
      const newComments = await safeQuery(commentFragment, {
        variables: {
          sort: ["-createdAt"],
          pageSize: 40,
          pageNumber: pageToLoad,
          filter: {
            [this.getFilterKey()]: record.id
          }
        }
      });
      const totalComments = newComments.totalResults;
      let comments = oldComments.concat(newComments);
      //dedupe and sort by date
      comments = uniqBy(comments, "id").sort(
        (c, c2) => new Date(c2.createdAt) - new Date(c.createdAt)
      );
      const hasMore = newComments.length < newComments.totalResults;
      this.setState({
        loadingComments: false,
        totalComments,
        comments,
        hasMore
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading comments.");
    }
  };
  refetchPage1 = () => {
    this.loadComments(1);
  };

  startReply = commentId => {
    this.setState({
      isReplying: commentId
    });
  };

  cancelReply = () => {
    this.setState({
      isReplying: null
    });
  };

  getNewTotal = async () => {
    const { record } = this.props;
    try {
      const comments = await safeQuery(["comment", "id"], {
        variables: {
          pageSize: 1,
          filter: {
            [this.getFilterKey()]: record.id
          }
        }
      });
      this.setState({
        totalComments: comments.totalResults
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  afterCommentEdit = editedComment => {
    const { comments = [] } = this.state;
    const indexOfEdited = comments.findIndex(c => c.id === editedComment.id);
    if (indexOfEdited > -1) {
      const newComments = [...comments];
      newComments[indexOfEdited] = editedComment;
      this.setState({
        comments: newComments
      });
    }
  };

  afterCommentDelete = async commentId => {
    this.setState({
      comments: this.state.comments.filter(c => c.id !== commentId)
    });
    this.getNewTotal();
  };

  afterCommentCreate = newComment => {
    this.getNewTotal();
    this.setState(
      {
        comments: [newComment].concat(this.state.comments)
      },
      () => {
        scrollCommentsToTop();
      }
    );
  };

  toggleEdit = (comment = {}) => {
    const { editingCommentId } = this.state;

    this.setState({
      editingCommentId: editingCommentId === comment.id ? null : comment.id
    });
  };

  render() {
    const { comments = [], isReplying, hasMore } = this.state;

    const {
      refetch: maybeRefetch,
      refetchComments,
      currentUser,
      record,
      noCard,
      addCommentOverrides,
      client,
      noAddComment,
      isDisabled,
      disableDelete,
      withMentions = true
    } = this.props;

    const handleMentionsSubmit = async (commentId, message) => {
      const mentions = getInitialMentions(message);
      if (!mentions.length) return;
      try {
        await window.api.request({
          method: "POST",
          baseURL: window.frontEndConfig.serverBasePath || "",
          url: "/comment-mentions",
          data: {
            ...getLocationHashPathName(),
            commentId,
            userIds: mentions.map(m => m.id)
          }
        });
      } catch (error) {
        console.error("error:", error);
      }
    };

    const refetch = refetchComments || maybeRefetch;
    const inner = (
      <div>
        {!(noAddComment || isDisabled) && !this.state.loadingComments && (
          <AddComment
            {...this.props}
            client={client}
            record={record}
            currentUser={currentUser}
            withMentions={withMentions}
            handleMentionsSubmit={handleMentionsSubmit}
            label="Add a comment"
            placeholder="Add a comment..."
            afterCreate={this.afterCommentCreate}
            {...addCommentOverrides}
          />
        )}
        <div
          id="tg-comment-list"
          style={{
            maxHeight: 500,
            overflow: "auto",
            marginBottom: 15,
            marginTop: 15,
            padding: 10
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadComments}
            hasMore={hasMore}
            loader={<Loading key="loading-comments" inDialog />}
            useWindow={false}
          >
            {comments.map(comment => (
              <div key={comment.id} className="comment-with-replies-container">
                <CommentWithReplies
                  {...{
                    comment,
                    client,
                    withMentions,
                    currentUser,
                    disableDelete,
                    editingCommentId: this.state.editingCommentId,
                    toggleEdit: this.toggleEdit,
                    afterEdit: this.afterCommentEdit,
                    afterDelete: this.afterCommentDelete,
                    startReply: this.startReply,
                    cancelReply: this.cancelReply,
                    isReplying: isReplying,
                    handleMentionsSubmit,
                    refetch,
                    record
                  }}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
    if (noCard) return inner;
    else
      return (
        <CollapsibleCard title="Comments" icon="comment">
          {inner}
        </CollapsibleCard>
      );
  }
}

export default CommentCard;
