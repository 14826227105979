/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// import { combineReducers } from 'redux'
import designEditor from "./designEditor";
import navPanel from "./navPanel";
import combineReducersWithFullState from "../combineReducersWithFullState";
import containerArray from "./records/containerArray";

import { combineReducers } from "redux";

export default (...args) => {
  return combineReducersWithFullState({
    designEditor,
    navPanel,
    records: combineReducers({
      containerArray
    })
  })(...args);
};
