/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export const getServiceSpecificInput = microserviceQueue => {
  if (!microserviceQueue) return {};
  const { id, service, input } = microserviceQueue;
  let taskName = id;
  let serviceSpecificInput = [["Input", ""]];

  if (service === "j5") {
    // j5-specific name & input to display
    const { design_name, assembly_method } = input;
    taskName = design_name;
    serviceSpecificInput = [["Assembly Method", assembly_method]];
  } else if (service === "standard-alignment") {
    // alignment-specific name & input to display
    const { name, alignmentType, alignmentAlgorithm } = input.options;
    taskName = name;
    serviceSpecificInput = [
      ["Alignment Type", alignmentType.replace(/_/g, " ")],
      ["Alignment Algorithm", alignmentAlgorithm.toUpperCase()],
      [
        "Aligned Sequences",
        input.sequencesToAlign.map((seq, i) => {
          return (
            <div key={i}>
              <span>{seq.name}</span>
            </div>
          );
        })
      ]
    ];
  } else if (service === "crickit-manager") {
    // crickit-specific name & input to display
    const {
      profileName,
      username,
      seqName,
      upstreams,
      sequenceCount,
      restrictionEnzyme,
      applyExtraEffort,
      filterRBS,
      geneEndAdapted,
      deltagUpstreamBases,
      markovRatio,
      species
    } = input.crickitInput;
    taskName = seqName;
    serviceSpecificInput = [
      ["Username", username],
      ["Sequence Name", seqName],
      [
        `Upstreams (${Object.keys(upstreams).length})`,
        Object.entries(upstreams).map(([name, seq], i) => {
          return (
            <div key={i}>
              <span>{name}: </span>
              <span>{seq}</span>
            </div>
          );
        })
      ],
      ["Sequence Count", sequenceCount],
      [
        `Restriction Enzyme (${restrictionEnzyme.length})`,
        restrictionEnzyme.map((re, i) => {
          return (
            <div key={i}>
              <span>{re}</span>
            </div>
          );
        })
      ],
      ["Apply Extra Effort", applyExtraEffort ? "Yes" : "No"],
      ["Filter RBS", filterRBS ? "Yes" : "No"],
      ["Gene End Adapted", geneEndAdapted ? "Yes" : "No"],
      ["DeltaG Upstream Bases", deltagUpstreamBases],
      ["Markov Ratio", markovRatio],
      ["Species", species]
    ];
  } else if (service === "vendor-vector") {
    // for bioshop's vendor-vector microservice, using vendor as the name to display
    taskName = input.config && input.config.vendor;
  } else if (service === "vendor-scoring-and-pricing") {
    // for bioshop's vendor-scoring-and-pricing microservice, using name of submitted sequence(s) as the name to display
    const sequenceNames = input.sequences.map(seq => seq.name);
    taskName = input.sequences && sequenceNames.join();
  } else if (service === "partition-tool") {
    taskName = input.design_name;
  } else if (service === "homology-path") {
    const { optional_parameters, required_parameters } =
      input.homologyPathInput;
    const {
      design_name,
      partition_identity,
      min_zscore_cutoff,
      temp,
      optimize_overlap_tm,
      overlap_tm_target_temp
    } = optional_parameters;
    const { oligo_design_parameters, primer_design_parameters } =
      required_parameters;
    const {
      maximum_length,
      maximum_overlap,
      minimum_length,
      minimum_overlap,
      target_length,
      design_type
    } = oligo_design_parameters;
    const { target_primer_length, target_primer_tm_temp } =
      primer_design_parameters;
    taskName = design_name;
    serviceSpecificInput = [
      ["Design Type", design_type?.replace(/_/g, " ")],
      ["Min Size (bp)", minimum_length],
      ["Target Size (bp)", target_length],
      ["Max Size (bp)", maximum_length],
      ["Min Overlap (bp)", minimum_overlap],
      ["Max Overlap (bp)", maximum_overlap],
      ["Target Primer Size (bp)", target_primer_length],
      ["Primer Target TM Temp (°C)", target_primer_tm_temp]
    ];
    if (design_type === "maximize_recycling") {
      serviceSpecificInput.push([
        "Estimated Identity [0-1.0]",
        partition_identity
      ]);
    } else if (design_type === "minimize_complexity") {
      serviceSpecificInput.push(["Min Z-Score Cutoff", min_zscore_cutoff]);
      serviceSpecificInput.push(["Temp for ΔG Calculations (°C)", temp]);
      serviceSpecificInput.push([
        "Overlap Target TM Temp (°C)",
        overlap_tm_target_temp
      ]);
      serviceSpecificInput.push(["Optimize Overlap TMs", optimize_overlap_tm]);
    } else if (design_type === "gapped_design") {
      serviceSpecificInput.push([
        "Overlap Target TM Temp (°C)",
        overlap_tm_target_temp
      ]);
      serviceSpecificInput.push(["Optimize Overlap TMs", optimize_overlap_tm]);
    }
  } else if (service === "ds-tools") {
    const { job } = input;

    if (job === "crispr-tool") {
      taskName = input.design_name;
      const {
        kwargs: {
          options: {
            guideLength,
            minOnTargetScore,
            maxNumberOnKOScore,
            pamSite
          }
        }
      } = input;
      serviceSpecificInput = [
        ["Guide RNA Length", guideLength],
        ["Minimum on target score", minOnTargetScore],
        ["Max generated gRNAs", maxNumberOnKOScore],
        ["PAM Site", pamSite]
      ];
    }
  }

  return { taskName, serviceSpecificInput };
};
