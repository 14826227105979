/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import delay from "delay";
import { isInteger, isNumber, random } from "lodash";
import { logTrackedRequests, getRequestCount } from "./requestTracker";

export default function simulateLatency() {
  const latency = getLatency();
  if (latency > 0) {
    logTrackedRequests(
      `[REQUEST LATENCY] Delay network request by ${latency} ms`
    );
    return delay(latency);
  }
  return Promise.resolve();
}

export function getLatency() {
  if (
    window.frontEndConfig &&
    (window.simulateLatencyOverride ||
      window.frontEndConfig.simulateLatency ||
      window.frontEndConfig.simulateInitialLatency)
  ) {
    let latency =
      window.simulateLatencyOverride ||
      window.frontEndConfig.simulateLatency ||
      window.frontEndConfig.simulateInitialLatency;

    try {
      if (latency.indexOf("-") > -1) {
        // it's a range
        const { min, max } = parsePositiveRange(latency);
        latency = random(min, max);
      } else {
        latency = parsePositiveInteger(latency);
      }
      if (
        window.frontEndConfig.simulateInitialLatency &&
        !window.simulateLatencyOverride
      ) {
        //simulate latency is called after pending requests are added to the queue
        //so if the count is greater than or equal to 2 don't add more latency
        if (getRequestCount() >= 2) {
          latency = 0;
          logTrackedRequests(
            `[REQUEST LATENCY] Reducing latency to 0ms because initial latency is set and 2 requests are already queued`
          );
        }
      }
      return latency;
    } catch (error) {
      console.error(error);
      console.warn(
        `[REQUEST LATENCY] invalid simulateLatency value ${latency} in frontEndConfig. No latency will be simulated!`
      );
    }
  }
  return 0;
}

export function parsePositiveInteger(str) {
  if (!isNumber(Number(str))) {
    throw new Error(
      `Unable to parse: ${str} to a positive integer becasue it is not a number!`
    );
  }

  const numVal = Number(str);

  if (!isInteger(numVal)) {
    throw new Error(
      `Unable to parse: ${numVal} to a positive integer becasue it is not an integer!`
    );
  }

  const value = Math.round(numVal);
  if (value < 0) {
    throw new Error(
      `Unable to parse: ${str} to a positive integer becasue it is not positive!`
    );
  }
  return value;
}

export function parsePositiveRange(str) {
  const [minStr, maxStr] = str.split("-");

  // console.log(`Parsing range minStr: ${minStr} maxStr: ${maxStr}`);

  const min = parsePositiveInteger(minStr);
  const max = parsePositiveInteger(maxStr);

  if (min >= max) {
    throw new Error(
      `Unable to parse: ${str} to a positive integer range becasue the first value is not greater than the second!`
    );
  }

  return { min, max };
}
