/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5PcrReactionFragment on j5PcrReaction {
    id
    cid
    name
    note
    oligoDeltaTm
    oligoDeltaTm3Prime
    oligoMeanTm
    oligoMeanTm3Prime
    pcrProductSequence {
      id
      size
      j5AssemblyPiece {
        id
        name
      }
    }
    primaryTemplate {
      id
      name
    }
    secondaryTemplate {
      id
      name
    }
    forwardPrimer {
      id
      name
    }
    reversePrimer {
      id
      name
    }
  }
`;
