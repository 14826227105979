/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { nonDNASeqTypes } from "../../../tg-iso-shared/src/sequence-import-utils/utils";

export const landingPageSeqItems = [
  {
    // amino acid sequences
    model: "aminoAcidSequence"
  },
  {
    // sequences
    model: "sequence",
    filter: qb => {
      return qb.whereAll({
        isInLibrary: true,
        sequenceTypeCode: qb.notInList(nonDNASeqTypes)
      });
    }
  },
  {
    // oligos
    model: "sequence",
    name: "oligos",
    filter: qb => {
      qb.whereAll({
        isInLibrary: true,
        sequenceTypeCode: "OLIGO"
      });
    }
  }
];
