/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import buildTagModels from "../tg-iso-lims/src/utils/includeTagModels";
import buildAliasModels from "../tg-iso-lims/src/utils/aliasModels";
import {
  extendedPropertyLinkableLimsModels,
  includeExtendedPropertyLimsModels,
  commonLabModels as buildCommonLabModels
} from "@teselagen/utils";
import { includeImportCollectionModels } from "@teselagen/utils";
import buildIncludeWorkflowCollectionModels from "../tg-iso-lims/src/utils/includeWorkflowCollectionModels";
import buildIncludeExternalRecordIdentifierModels from "../tg-iso-lims/src/utils/includeExternalRecordIdentifierModels";
import buildIncludeIsDeprecatedModels from "../tg-iso-lims/src/utils/includeIsDeprecatedModels";
import buildBarcodeModels from "../tg-iso-lims/src/utils/barcodeModels";
import { lockableModels as _lockableModels } from "./src/lockableModels";
import buildExpirationDateModels from "../tg-iso-lims/src/utils/includeExpirationDateModels";

export const tagModels = buildTagModels;
export const aliasModels = buildAliasModels;
export const expirationDateModels = buildExpirationDateModels;
export const importCollectionModels = includeImportCollectionModels;
// aliquots are not included in the workflow collection models yet because they are not a
// direct output from a workflow tool
export const workflowCollectionModels =
  buildIncludeWorkflowCollectionModels.filter(m => m !== "aliquot");
export const externalRecordIdentifierModels =
  buildIncludeExternalRecordIdentifierModels;
export const externalRecordIdentifierModelsNoUpdate = [
  "reactionMap",
  "reactionDesign",
  "equipmentItem"
];
export const isDeprecatedModels = buildIncludeIsDeprecatedModels;
export const extendedPropertyLinkableModels =
  extendedPropertyLinkableLimsModels;
export const commonLabModels = buildCommonLabModels;
export const lockableModels = _lockableModels;
export const barcodeModels = buildBarcodeModels;
export const labModels = [
  "additiveMaterial",
  "project",
  "alignment",
  "aliquot",
  "aliquotContainer",
  "aminoAcidSequence",
  "batch",
  "containerArray",
  "customerRequest",
  "dataSet",
  "dataTable",
  "functionalProteinUnit",
  "genome",
  "j5Report",
  "job",
  "lot",
  "material",
  "plateMapGroup",
  "reactionMap",
  "report",
  "sample",
  "sequence",
  "sequencingQualityControlReport",
  "strain",
  "vendorOrder",
  "workflowDefinition",
  "workflowRun",
  "worklist",
  "regionAnnotation",
  "sequenceFeature",
  "dataGrid",
  "assaySubject",
  "experiment",
  "assay",
  "importFileSet",
  "evolveModel",
  "codonMap",
  "design",
  "aminoAcidSequence",
  "sequence",
  "part",
  "partset",
  "schematic",
  "enzyme",
  "entry"
];
export const labModelsNoEdit = ["j5Report"];
export const batchModels = [
  "material",
  "sample",
  "aliquot",
  "aliquotContainer",
  "dataSet",
  "dataTable",
  "containerArray",
  "vendorOrder",
  // "lot", TODO add to batches to datamodel
  "j5Report",
  "plateMapGroup",
  "reactionMap",
  "worklist"
];

export const extendedPropertyModels = includeExtendedPropertyLimsModels;

export const externalReferenceKeys = [
  "externalReferenceSystem",
  "externalReferenceId",
  "externalReferenceType",
  "externalReferenceUrl"
];
