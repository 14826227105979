/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

export default ({ partIdsKey = "matchingPartIds" } = {}) => WrappedComponent =>
  class extends React.Component {
    state = {
      loading: true,
      partIds: []
    };
    async componentDidMount() {
      try {
        const { userId, startRegexes = [], endRegexes = [] } = this.props;
        if (!startRegexes.length && !endRegexes.length) {
          this.setState({ loading: false });
          return;
        }
        const {
          data: { success, err, partIds }
        } = await window.api.request({
          method: "POST",
          url: "/getPartIdsByRegexes",

          timeout: 1000000000000,
          data: {
            userId: userId || localStorage.getItem("userId"),
            startRegexes,
            endRegexes
          }
        });
        if (!success) {
          console.error(err);
          throw new Error(err);
        }

        this.setState({
          loading: false,
          partIds
        });
      } catch (e) {
        console.error(e);
        window.toastr.error("Error filtering parts.");
      }
    }
    render() {
      const { loading, partIds } = this.state;
      return (
        <WrappedComponent
          {...{
            [partIdsKey + "Loading"]: loading,
            [partIdsKey]: partIds,
            ...this.props
          }}
        />
      );
    }
  };
