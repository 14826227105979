/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { logDebug } = require("./logDebug");

function filterLabRolesForSingleUserLabs(user) {
  const { defaultLabId } = user;
  if (process.env.SINGLE_USER_LABS) {
    if (!user.appRoleCodes["ADMIN"]) {
      logDebug(
        `[AUTH-UTILS] Only returning default lab and lab role for user ${user.username}`,
        user
      );

      // if someone accidentally enables the ability to create labs in
      // single user mode it should have no effect
      delete user.appRoleCodes["LAB_CREATOR"];

      // eslint-disable-next-line eqeqeq
      user.labs = user.labs.filter(id => id == defaultLabId);
      user.labRoles = user.labRoles.filter(
        // eslint-disable-next-line eqeqeq
        ({ labId }) => labId == defaultLabId
      );
      logDebug(
        `[AUTH-UTILS] Filtered labs and lab roles for user ${user.username}`,
        user
      );
    } else {
      logDebug(
        `[AUTH-UTILS] Returning all labs and lab roles because user ${user.username} is an ADMIN`
      );
    }
  }

  return user;
}

module.exports = { filterLabRolesForSingleUserLabs };
