/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import { showDialog } from "../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import DnaSequenceRecordView from "../../src-shared/DNASequenceRecordView";
import UploadOligosDialog from "../components/Dialogs/UploadOligosDialog";

const schema = {
  model: "sequence",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "fullSequenceRaw",
      type: "string",
      displayName: "Sequence"
    },
    { path: "size", type: "number", displayName: "Size" },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    }
  ]
};

class OligoLibrary extends Component {
  showUpload = () => {
    showDialog({
      ModalComponent: UploadOligosDialog,
      modalProps: {
        refetchOligos: this.props.refetch
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        noOpen
        libraryName="oligo"
        RecordViewInspector={DnaSequenceRecordView}
        isLibraryTable
        getRoute={row => {
          return `/oligos/${row.id}`;
        }}
        showUploadFunction={this.showUpload}
      />
    );
  }
}

const fragment = gql`
  fragment oligoLibraryFragment on sequence {
    id
    name
    description
    size
    fullSequenceRaw
    isJ5Sequence
    isInLibrary
  }
`;

const OligoLibraryContainer = compose(
  libraryEnhancer({
    fragment,
    schema,
    integrationSubtype: "OLIGO",
    additionalFilter: (props, qb) => {
      qb.whereAll({ sequenceTypeCode: "OLIGO" });
      qb.whereAny({
        isInLibrary: true
      });
    },
    withSelectedEntities: true,
    noAddedBy: true
  })
)(OligoLibrary);

export default OligoLibraryContainer;
