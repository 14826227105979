/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import React from "react";
import {
  MenuItem,
  MenuDivider,
  Button,
  Icon,
  Tooltip
} from "@blueprintjs/core";
import j5ReportLibraryFragment from "../graphql/fragments/j5ReportLibraryFragment";
import { showDialog } from "../../src-shared/GlobalDialog";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import { safeQuery } from "../../src-shared/apolloMethods";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import SaveJ5SeqsToSequenceDialog from "../components/Dialogs/SaveJ5SeqsToSequenceDialog";
import saveStreamResponse from "../../src-shared/utils/saveStreamResponse";
import J5Report from "../components/J5Report";
import {
  ReportStatusEnum,
  getReportStatus,
  j5ReportHasErrors
} from "../../../tg-iso-shared/src/j5-report";

function getReportStatusCell(j5Report) {
  let color;

  const status = getReportStatus({ j5Report });

  switch (status) {
    case ReportStatusEnum.SAVING:
      color = "#FF8F02";
      break;
    case ReportStatusEnum.ERROR:
      color = "#f54242";
      break;
    case ReportStatusEnum.COMPLETED:
      color = "#2ECC71";
      break;
    case ReportStatusEnum.COMPLETED_WITH_WARNINGS:
      color = "#ffd56e";
      break;
    default:
      console.error(`Report status not recognized: ${status}`);
      color = "#f54242";
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon
        style={{ color, marginRight: 5 }}
        icon="full-circle"
        iconSize={10}
      />
      {status}
    </div>
  );
}

const statusColumn = {
  path: "status",
  type: "string",
  displayName: "Status",
  render(__, j5Report) {
    return getReportStatusCell(j5Report);
  }
};

async function showJ5ErrorLogs(report) {
  try {
    const j5LogMessages = await safeQuery(
      [
        "j5LogMessage",
        "id message j5LogMessageTypeCode j5LogMessagePriorityCode j5LogMessageJoins { id specificMsg }"
      ],
      {
        variables: {
          filter: {
            j5ReportId: report.id
          }
        }
      }
    );
    showDialog({
      modalType: "J5_LOGS",
      modalProps: {
        j5LogMessages
      }
    });
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error loading report.");
  }
}

const schema = {
  model: "j5Report",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "originalDesign.name",
      type: "string",
      displayName: "Design",
      withLink: true
    },
    { path: "assemblyMethod", type: "string", displayName: "Assembly Method" },
    statusColumn,
    {
      path: "j5LogMessages",
      displayName: "Warnings/Errors",
      searchDisabled: true,
      getTitleAttr: () => {},
      render(j5LogMessages, j5Report) {
        return (
          <Tooltip
            className="bp3-minimal"
            content="Click to view assembly warnings."
          >
            <Button
              icon="eye-open"
              className="bp3-minimal"
              onClick={async e => {
                e.stopPropagation();
                showJ5ErrorLogs(j5Report);
              }}
            >
              {j5LogMessages.length}
            </Button>
          </Tooltip>
        );
      }
    }
  ]
};

const handleExportToJsonClick = async selectedRecords => {
  window.toastr.success("Download is starting");
  selectedRecords.forEach(async record => {
    try {
      await saveStreamResponse({
        url: "/getJ5Json",
        body: {
          j5ReportId: record.id
        },
        filename: record.name
      });
    } catch (e) {
      console.error(e);
      window.toastr.error("Error retrieving j5 report");
    }
  });
};

const handleExportToCsvClick = async selectedRecords => {
  window.toastr.success("Download is starting");
  selectedRecords.forEach(async record => {
    try {
      await saveStreamResponse({
        url: "/getJ5Csv",
        body: {
          j5ReportId: record.id
        },
        filename: record.name
      });
    } catch (error) {
      console.error(error);
      window.toastr.error("Error retrieving j5 report");
    }
  });
};

class AssemblyLibrary extends React.Component {
  contextMenu = options => {
    const {
      selectedRecords,
      openMenuItem,
      deleteMenuItem,
      tagMenuItem,
      projectMenuItem,
      editExtendedPropertiesMenuItem,
      extraContextItems
    } = options;
    if (selectedRecords.some(record => record.status === "saving")) {
      return window.toastr.warning(
        "This report is in the process of being saved to the database, please wait and try again later"
      );
    }

    const saveSeqs = (
      <MenuItem
        key="saveToSeqLibrary"
        icon="floppy-disk"
        text="Save to Sequence Library"
      >
        {[
          { type: "placeholder", name: "Assembled Constructs" },
          { type: "isj5AssemblyPieces", name: "Assembly Pieces" },
          { type: "isAnnealedOligos", name: "Annealed Oligos" },
          { type: "isOligos", name: "Oligos" }
        ].map(({ type, name }) => {
          return (
            <MenuItem
              text={name}
              key={name}
              onClick={() => {
                showDialog({
                  ModalComponent: SaveJ5SeqsToSequenceDialog,
                  modalProps: {
                    [type]: true,
                    j5ReportIds: selectedRecords.map(r => r.id)
                  }
                });
              }}
            ></MenuItem>
          );
        })}
      </MenuItem>
    );
    const exportMenuItem = (
      <MenuItem key="export" icon="export" text="Export">
        <MenuItem
          key="exportCSV"
          onClick={() => handleExportToCsvClick(selectedRecords)}
          text="CSV"
        />
        <MenuItem
          key="exportJSON"
          onClick={() => handleExportToJsonClick(selectedRecords)}
          text="JSON"
        />
      </MenuItem>
    );

    return [
      openMenuItem,
      deleteMenuItem,
      tagMenuItem,
      projectMenuItem,
      editExtendedPropertiesMenuItem,
      <MenuDivider key="md1" />,
      saveSeqs,
      exportMenuItem,
      ...extraContextItems
    ];
  };

  onDoubleClick = async j5Report => {
    if (j5Report.status === "saving")
      return window.toastr.warning(
        "This report is in the process of being saved to the database, please wait and try again later"
      );
    if (j5ReportHasErrors(j5Report)) {
      showJ5ErrorLogs(j5Report);
    } else {
      this.props.history.push(`/assembly-reports/${j5Report.id}`);
    }
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        noNewItem
        contextMenu={this.contextMenu}
        generateExtraContextMenuItems={this.additionalContextMenu}
        RecordViewInspector={J5Report}
        withExport
        isLibraryTable
        onDoubleClick={this.onDoubleClick}
        model="j5Report"
      />
    );
  }
}

export default compose(
  libraryEnhancer({
    schema,
    fragment: j5ReportLibraryFragment,
    withSelectedEntities: true
  })
)(AssemblyLibrary);
