/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const {
  userRoleCodes: { ADMIN_ROLE }
} = require("./constants");
const { logDebug } = require("./logDebug");
const { shouldIdBeNull } = require("./shouldIdBeNull");

function validateUserLabId({ user, labId }) {
  if (shouldIdBeNull(labId)) return true;
  else if (
    (labId && user?.labs?.length && user.labs.includes(labId)) ||
    // NOTE: There's also the LAB_CREATOR_ROLE, which is sometimes enabled
    // for MEMBER users in order to run CUD ops on the Lab table. But that doesn't
    // have to do with the active/context 'labId'.
    user.appRoleCodes[ADMIN_ROLE] ||
    user.superuser
  ) {
    logDebug(`User with id ${user.id} has role in lab with id ${labId}`);
    return true;
  } else {
    return false;
  }
}

module.exports = { validateUserLabId };
