/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const impersonatedUserIdHeaderKey = "tg-lims-user-id";
const activeLabHeaderKey = "tg-active-lab-id";
const activeProjectHeaderKey = "tg-project-id";
const activeBatchHeaderKey = "tg-active-batch-id";
const importCollectionHeaderKey = "tg-active-import-collection-id";
const moduleSelectorHeaderKey = "module-selector";
const authorizationHeaderKey = "authorization";

// Bearer prefix should always be capitalized (according to Hasura).
const BEARER_PREFIX = "Bearer ";

const headers = {
  impersonatedUserIdHeaderKey,
  activeLabHeaderKey,
  activeProjectHeaderKey,
  activeBatchHeaderKey,
  importCollectionHeaderKey,
  moduleSelectorHeaderKey,
  authorizationHeaderKey
};

const SYSTEM_ROLE = "SYSTEM";
const ADMIN_ROLE = "ADMIN";
const MEMBER_ROLE = "MEMBER";
const LAB_CREATOR_ROLE = "LAB_CREATOR";
const LAB_ADMIN = "LAB_ADMIN";

const userRoleCodes = {
  SYSTEM_ROLE,
  ADMIN_ROLE,
  MEMBER_ROLE,
  LAB_CREATOR_ROLE,
  LAB_ADMIN
};

const labRoleCodes = {
  ADMIN_ROLE,
  MEMBER_ROLE
};

module.exports.headers = headers;
module.exports.userRoleCodes = userRoleCodes;
module.exports.labRoleCodes = labRoleCodes;
module.exports.BEARER_PREFIX = BEARER_PREFIX;
